import React from "react";

// adapted from alisson
export const PowerPlug = ({ color = "currentColor" }) => (
  <>
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.66669 8.84849C2.66669 8.37988 3.04657 8 3.51517 8H20.4849C20.9535 8 21.3334 8.37988 21.3334 8.84848V8.84848C21.3334 13.5345 17.5346 17.3333 12.8485 17.3333H11.1515C6.46548 17.3333 2.66669 13.5345 2.66669 8.84849V8.84849Z"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.66669 3.33333C4.66669 2.22876 5.56212 1.33333 6.66669 1.33333H8.00002C9.10459 1.33333 10 2.22876 10 3.33333V7.99999H4.66669V3.33333Z"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14 3.33333C14 2.22876 14.8954 1.33333 16 1.33333H17.3333C18.4379 1.33333 19.3333 2.22876 19.3333 3.33333V7.99999H14V3.33333Z"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <line
        x1="11.75"
        y1="18.0833"
        x2="11.75"
        y2="22.5833"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </>
);
