import React, { Component } from "react";
import api from "../../../services/api";

import {
  Button,
  Descriptions,
  Drawer,
  Form,
  Icon,
  Input,
  Table,
  Tooltip,
} from "antd";
import { withRouter } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Show from "../../../contexts/PermissionContext/Show";
import "./index.css";
import { FreireContext } from "../../../utils/freireContext";
import * as texts from "../locales";
import { sorter } from "../../../utils/sorter";
class ListRecipient extends Component {
  static contextType = FreireContext;

  state = {
    name: "seila",
    loadingTable: false,
    loadingSave: false,
    data: [],
    recipient: {},
    admin: false,
    companys: [],
    dateFiltrada: [],
    tablePage: 1,
  };

  componentDidMount() {
    this.getRecipient();
  }

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.filter();
    }
  };

  async getRecipient(params = {}) {
    this.setState({ loadingTable: true });

    const { data } = await api.get("recipient");

    this.setState({
      dateFiltrada: sorter(data, "recipientName"),
      loadingTable: false,
      data: data,
    });
  }

  openDrawer = async (recipientID) => {
    const { data } = await api.get(`recipient/${recipientID}`);
    this.setState({ visible: true, recipient: data });
  };

  closeDrawer = () => {
    this.setState({ visible: false });
  };

  filter = () => {
    var data = this.state.data;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (values.recipientName) {
          data = data.filter((str) => {
            return (
              str.recipientName
                .toLowerCase()
                .indexOf(values.recipientName.toLowerCase()) >= 0
            );
          });
          this.setState({
            dateFiltrada: sorter(data, "recipientName"),
            tablePage: 1,
          });
        }
      }
    });
  };

  clearFilters = () => {
    this.props.form.resetFields();
    this.setState({ dateFiltrada: sorter(this.state.data, "recipientName") });
    this.getRecipient();
  };

  goNew = () => {
    this.props.history.push("/dashboard/new_recipient");
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { freire } = this.context;

    const columns = [
      {
        title: freire(texts.NAME),
        dataIndex: "recipientName",
        key: "recipientName",
      },
      {
        title: freire(texts.ACTIONS),
        key: "acoes",
        width: "10%",
        render: (text, data) => (
          <Show when={"show-data-recipient"}>
            <Tooltip placement="top" title={freire(texts.SHOW_DETAILS)}>
              <Button
                shape="circle"
                size="small"
                ghost
                type="primary"
                style={{ marginRight: 8 }}
                onClick={() => this.openDrawer(data.recipient_id)}
              >
                <Icon type="eye" />
              </Button>
            </Tooltip>
          </Show>
        ),
      },
    ];

    return (
      <div className="container">
        <Breadcrumbs
          breadcrumbs={[
            freire(texts.ADMIN),
            freire(texts.MANAGEMENT),
            freire(texts.RECIPIENTS),
          ]}
        />
        <div className="filter">
          <Form wrapperCol={{ span: 24 }}>
            <Form.Item>
              <div style={{ flexDirection: "row", display: "flex" }}>
                {getFieldDecorator("recipientName")(
                  <Input
                    className="firstInput"
                    placeholder={freire(texts.SEARCH_BY_NAME)}
                    onKeyPress={this.handleKeyPress}
                    onChange={(e) => {
                      e.target.value = e.target.value.trim();
                    }}
                  />
                )}
                <Button
                  icon="search"
                  className="buttonSearch"
                  onClick={this.filter}
                >
                  {freire(texts.SEARCH)}
                </Button>
                <Show when={"add-recipient"}>
                  <Button
                    icon="plus"
                    className="buttonNew"
                    onClick={this.goNew}
                    type="primary"
                  >
                    {freire(texts.NEW)}
                  </Button>
                </Show>
              </div>
            </Form.Item>
          </Form>

          <div className="footer">
            <Button style={{ marginRight: 10 }} onClick={this.clearFilters}>
              {freire(texts.CLEAN)}
            </Button>
            <Button onClick={this.filter} type="primary">
              {freire(texts.FILTER)}
            </Button>
          </div>
        </div>

        <Table
          style={{ backgroundColor: "white" }}
          loading={this.state.loadingTable}
          rowKey={(data) => data._id}
          dataSource={this.state.dateFiltrada}
          columns={columns}
          pagination={{
            current: this.state.tablePage,
            onChange: (page) => this.setState({ tablePage: page }),
          }}
        />

        <Drawer
          title={freire(texts.RECIPIENT_DATA)}
          width={500}
          onClose={this.closeDrawer}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          {/* <Row gutter={16}>
            <Col span={16}> */}
          <Descriptions title={freire(texts.BANK_DATA)} bordered column={1}>
            <Descriptions.Item label={freire(texts.AGENCY)}>
              {this.state.visible
                ? this.state.recipient.bank_account.agencia
                : null}
            </Descriptions.Item>
            <Descriptions.Item label={freire(texts.ACCOUNT)}>
              {this.state.visible
                ? this.state.recipient.bank_account.conta
                : null}
            </Descriptions.Item>
            <Descriptions.Item label={freire(texts.BANK_CODE)}>
              {this.state.visible
                ? this.state.recipient.bank_account.bank_code
                : null}
            </Descriptions.Item>
            <Descriptions.Item label={freire(texts.AGENCY_CHECK_DIGIT)}>
              {this.state.visible
                ? this.state.recipient.bank_account.agencia_dv
                : null}
            </Descriptions.Item>
            <Descriptions.Item label={freire(texts.ACCOUNT_TYPE)}>
              {this.state.visible
                ? this.state.recipient.bank_account.type
                : null}
            </Descriptions.Item>
            <Descriptions.Item label={freire(texts.ACCOUNT_VERIFICATION_DIGIT)}>
              {this.state.visible
                ? this.state.recipient.bank_account.conta_dv
                : null}
            </Descriptions.Item>
            <Descriptions.Item label={freire(texts.FULL_NAME_BUSINESS_NAME)}>
              {this.state.visible
                ? this.state.recipient.bank_account.legal_name
                : null}
            </Descriptions.Item>
            <Descriptions.Item label={freire(texts.ACCOUNT_CPF_OR_CNPJ)}>
              {this.state.visible
                ? this.state.recipient.bank_account.document_number
                : null}
            </Descriptions.Item>
          </Descriptions>
          {/* </Col>
          </Row> */}
          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#fff",
              textAlign: "right",
            }}
          >
            <Button onClick={this.closeDrawer} style={{ marginRight: 8 }}>
              {freire(texts.CLOSE)}
            </Button>
          </div>
        </Drawer>
      </div>
    );
  }
}
const PageListRecipient = Form.create({ name: "filters" })(ListRecipient);
export default withRouter(PageListRecipient);
