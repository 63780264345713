import { CustomError } from "./custom-error";

type Reject = (reason?: any) => void;

type Executor<T> = (
  resolve: (value: T | PromiseLike<T>) => void,
  reject: Reject
) => void;

export type Abort = () => void;

export class AbortError extends CustomError {}

export class AbortablePromise<T = void> extends Promise<T> {
  /**
   * Creates a new Promise.
   * @param executor A callback used to initialize the promise. This callback is passed two arguments:
   * a resolve callback used to resolve the promise with a value or the result of another promise,
   * and a reject callback used to reject the promise with a provided reason or error.
   */
  constructor(executor: Executor<T>, abort: Abort) {
    let rejectFn: Reject;
    const _executor: Executor<T> = (resolve, reject) => {
      rejectFn = reject;
      executor(resolve, reject);
    };

    super(_executor);

    this._abort = abort;
    this._reject = rejectFn!;
  }

  private _abort: Abort;

  private _reject: Reject;

  public abort: Abort = () => {
    this._abort();
    this._reject(new AbortError());
  };
}
