import moment from "moment";
import { formatters } from "../../../../../utils/formatters";
import * as texts from "../../../locales";
import { getCouponStatusText } from "../../../utils";

const getColumns = (freire, campaignResume) => {
  const formatUser = (coupon) => {
    if (!coupon.user) return "----";
    const { email, displayName } = coupon.user;

    const displayNameStr = displayName ? ` (${displayName})` : "";
    return email + displayNameStr;
  };

  return {
    // Ambos
    cod: {
      label: freire(texts.COD),
      key: "cod",
    },
    status: {
      label: freire(texts.STATUS),
      key: "status",
      formatter: (coupon) => {
        const couponStatus = getCouponStatusText(coupon.status);

        if (couponStatus) {
          return freire(couponStatus.text);
        }

        return "----";
      },
    },
    user: {
      label: freire(texts.USER),
      key: "user",
      formatter: formatUser,
    },

    // Simple
    usageLimit: {
      label: freire(texts.LIMIT_USE),
      key: "usageLimit",
      formatter: ({ usageLimit }) => {
        if (usageLimit === -1) return freire(texts.NO_LIMIT);

        return usageLimit;
      },
    },
    usageQty: {
      label: freire(texts.QTD_USED),
      key: "usageQty",
    },
    expiresAt: {
      label: freire(texts.EXP_AT),
      key: "expiresAt",
      formatter: (coupon) => {
        if (!campaignResume.couponDuration) {
          return moment(campaignResume.expireDate).format("L LTS");
        }
        if (coupon.expiresAt) {
          return moment(coupon.expiresAt).format("L LTS");
        }
        return "";
      },
    },

    // Credit
    consumed: {
      label: freire(texts.CONSUMED_VALUE),
      key: "consumed",
      formatter: (coupon) =>
        formatters.currency(
          freire.userLanguage,
          campaignResume?.companyCurrency
        )((coupon.campaignDetails.benefit.value - coupon.balance) / 100),
    },
    remaining: {
      label: freire(texts.AMOUNT_REMAINING),
      key: "remaining",
      formatter: (coupon) =>
        formatters.currency(
          freire.userLanguage,
          campaignResume?.companyCurrency
        )(coupon.balance / 100),
    },
  };
};

const CouponsCsvColumns = { getColumns };

export default CouponsCsvColumns;
