import {
  Button,
  Card,
  Dropdown,
  Form,
  Icon,
  Input,
  notification,
  Popconfirm,
  Table,
  Tag,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Show from "../../../contexts/PermissionContext/Show";
import api from "../../../services/api";
import EditDrawer from "./EditDrawer";
import "./index.css";
import { useFreire } from "../../../utils/freireContext";
import * as texts from "../locales";
import { formatters } from "../../../utils/formatters";
import { sorter } from "../../../utils/sorter";

const ListAlertGroups = ({ form, history }) => {
  const [currentGroup, setCurrentGroup] = useState({});
  const [loadingTable, setLoadingTable] = useState(false);
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [loadingDrawer, setLoadingDrawer] = useState(false);
  const [tablePage, setTablePage] = useState(1);
  const { freire } = useFreire();

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.filter();
    }
  };

  const [columns] = useState([
    {
      title: freire(texts.GROUP),
      dataIndex: "name",
      key: "name",
    },
    {
      title: freire(texts.EMAIL),
      dataIndex: "emails",
      key: "emails",
      width: "35%",
      render: (emails) => renderTags(emails, "purple"),
    },
    {
      title: freire(texts.PHONES),
      dataIndex: "phones",
      key: "phones",
      width: "15%",
      render: (phones) => renderTags(phones.map(formatters.phone), "purple"),
    },
    {
      title: freire(texts.STATIONS),
      dataIndex: "stations",
      key: "stations",
      width: "25%",
      render: (stations) => renderStations(stations),
    },
    {
      title: freire(texts.ACTIONS),
      key: "acoes",
      width: 150,
      render: (group, data) => (
        <>
          <Show when={"edit-alert-group"}>
            <Tooltip placement="top" title={freire(texts.SHOW_DETAILS_EDIT)}>
              <Button
                shape="circle"
                size="small"
                ghost
                type="primary"
                style={{ marginRight: 8 }}
                onClick={() => {
                  setVisibleDrawer(true);
                  setCurrentGroup(group);
                }}
              >
                <Icon type="edit" />
              </Button>
            </Tooltip>
          </Show>

          <Show when={"delete-alert-group"}>
            <Tooltip placement="top" title={freire(texts.DELETE)}>
              <Popconfirm
                placement="top"
                title={freire(texts.CONFIRM_DELETE)}
                okText={freire(texts.YES)}
                cancelText={freire(texts.NO)}
                onConfirm={() => deleteGroup(group)}
              >
                <Button
                  style={{ marginRight: 8 }}
                  shape="circle"
                  size="small"
                  ghost
                  type="danger"
                >
                  <Icon type="delete" />
                </Button>
              </Popconfirm>
            </Tooltip>
          </Show>
        </>
      ),
    },
  ]);
  const [dateFiltrada, setDateFiltrada] = useState([]);

  useEffect(() => {
    getAlertGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTags = (tags, color = "blue") => {
    return tags.map((tag) => (
      <Tag className="tagStations" color={color} key={tag}>
        {tag}
      </Tag>
    ));
  };

  const renderStations = (stations = []) => {
    let display = 4;

    let displayList = stations;
    let overlayList = [];

    // Só deve exibir o 'overlay' se a qtd. de itens 'para ocultar' for maior
    // ou igual a 2 para evitar de exibir o 'overlay' com menos de 2 itens
    if (stations.length - display >= 2) {
      displayList = stations.slice(0, display);
      overlayList = stations.slice(display, stations.length);
    }

    return (
      <span>
        {renderTags(displayList)}
        {overlayList.length > 0 ? (
          <Dropdown
            overlay={
              <div>
                <Card className="cardOverlay">{renderTags(overlayList)}</Card>
              </div>
            }
          >
            <div>{freire(texts.MORE_STATIONS(overlayList.length))}</div>
          </Dropdown>
        ) : null}
      </span>
    );
  };

  const closeDrawer = () => {
    setVisibleDrawer(false);
    setCurrentGroup({});
  };

  async function updateGroup(data) {
    try {
      setLoadingDrawer(true);
      await api.put(`alert_group/${currentGroup._id}`, data);

      setLoadingDrawer(false);
      closeDrawer();

      notification.success({
        message: freire(texts.ALL_RIGHT),
        description: freire(texts.GROUP_EDITED_SUCCESS),
      });

      getAlertGroups();
    } catch (error) {
      const {
        response: {
          data: { message },
        },
      } = error;

      notification.error({
        message: freire(texts.WENT_WONG),
        description: message || error.message,
      });
      setLoadingDrawer(false);
    }
  }

  async function deleteGroup(group) {
    try {
      setLoadingTable(true);
      await api.delete(`alert_group/${group._id}`);

      setLoadingTable(false);

      notification.success({
        message: freire(texts.ALL_RIGHT),
        description: freire(texts.GROUP_DELETED_SUCCESS),
      });

      getAlertGroups();
    } catch (error) {
      notification.error({
        message: freire(texts.OOPS),
        description: freire(texts.ERROR_DELETE_GROUP),
      });
      setLoadingTable(false);
    }
  }

  async function getAlertGroups(params = {}) {
    try {
      setLoadingTable(true);
      const { data } = await api.get(`alert_groups`, { params });
      setDateFiltrada(sorter(data));
      setLoadingTable(false);
    } catch (error) {
      notification.error({
        message: freire(texts.OOPS),
        description: freire(texts.ERROR_GET_ALERT_GROUPS),
      });
      setLoadingTable(false);
    }
  }

  const filter = () => {
    form.validateFields((err, values) => {
      if (!values.name) values.name = undefined;

      if (!err) {
        getAlertGroups(values);
      }
    });

    setTablePage(1);
  };

  const clearFilters = () => {
    form.resetFields();
    setDateFiltrada([]);
    getAlertGroups();
  };

  const goNew = () => {
    history.push("/dashboard/ocpp/new_alert_group");
  };

  return (
    <div className="container">
      <Breadcrumbs
        breadcrumbs={[
          freire(texts.ADMIN),
          freire(texts.GROUPS),
          freire(texts.LISTING_ALERTS_GROUPS),
        ]}
      />
      <div className="filter">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            filter();
          }}
          wrapperCol={{ span: 24 }}
        >
          <Form.Item>
            <div style={{ flexDirection: "row", display: "flex" }}>
              {form.getFieldDecorator("name")(
                <Input
                  onSubmit={filter}
                  className="firstInput"
                  placeholder={freire(texts.SEARCH_BY_GROUP_NAME)}
                  onKeyPress={handleKeyPress}
                  onChange={(e) => {
                    e.target.value = e.target.value.trim();
                  }}
                />
              )}
              <Button icon="search" className="buttonSearch" onClick={filter}>
                {freire(texts.SEARCH)}
              </Button>
              <Show when={"add-alert-group"}>
                <Button
                  icon="plus"
                  className="buttonNew"
                  onClick={goNew}
                  type="primary"
                >
                  {freire(texts.NEW)}
                </Button>
              </Show>
            </div>
          </Form.Item>
        </Form>

        <div className="footer">
          <Button style={{ marginRight: 10 }} onClick={clearFilters}>
            {freire(texts.CLEAR)}
          </Button>
          <Button onClick={filter} type="primary">
            {freire(texts.FILTER)}
          </Button>
        </div>
      </div>

      <Table
        style={{ backgroundColor: "white" }}
        scroll={{ x: 1300 }}
        tableLayout="auto"
        rowKey={(data) => data._id}
        loading={loadingTable}
        dataSource={dateFiltrada}
        columns={columns}
        pagination={{
          current: tablePage,
          onChange: (page) => setTablePage(page),
        }}
      />

      <EditDrawer
        visible={visibleDrawer}
        loading={loadingDrawer}
        onClose={closeDrawer}
        onSave={(values) => updateGroup(values)}
        group={currentGroup}
      />
    </div>
  );
};
const PageListAlertGroups = Form.create({ name: "filters" })(ListAlertGroups);
export default withRouter(PageListAlertGroups);
