import { Show } from "../../contexts/PermissionContext";
import { useFreire } from "../../utils/freireContext";
import { Copy } from "../Copy";
import * as texts from "../../pages/Users/locales";

export const AddressTooltip = ({ address }) => {
    const { freire } = useFreire();
    return (
        <div>
            {(address.cep || address.cpa) && (
                <div>
                    <div>
                        <strong>{freire(texts.ZIP_CODE)}:</strong>{" "}
                        <Copy>{address.cep || address.cpa}</Copy>
                    </div>
                    <div>
                        <strong>{freire(texts.PUBLIC_PLACE)}:</strong>{" "}
                        <Copy>{address.logradouro || address.direccion}</Copy>
                    </div>
                    <div>
                        <strong>{freire(texts.NUMBER)}:</strong>{" "}
                        <Copy>{address.numero}</Copy>
                    </div>
                    <div>
                        <strong>{freire(texts.COMPLEMENT)}:</strong>{" "}
                        <Copy>{address.complemento}</Copy>
                    </div>
                    <div>
                        <strong>{freire(texts.NEIGHBORHOOD)}:</strong>{" "}
                        <Copy>{address.bairro || address.barrio}</Copy>
                    </div>
                    <div>
                        <strong>{freire(texts.CITY)}:</strong>{" "}
                        <Copy>{address.cidade || address.ciudad?.nombre}</Copy>
                    </div>
                    <div>
                        <strong>{freire(texts.STATE)}:</strong>{" "}
                        <Copy>{address.estado || address.provincia?.nombre}</Copy>
                    </div>
                    <Show when="developer">
                        <div>
                            <br />
                            <div>
                                <strong>{freire(texts.CITY_ID)}:</strong>{" "}
                                <Copy>{address.cidadeId || address.ciudad?.id}</Copy>
                            </div>
                            <div>
                                <strong>{freire(texts.STATE_ID)}:</strong>{" "}
                                <Copy>{address.estadoId || address.provincia?.id}</Copy>
                            </div>
                            <div>
                                <strong>{freire(texts.STATE_UF)}:</strong>{" "}
                                <Copy>{address.estadoUf || address.provincia?.code}</Copy>
                            </div>
                        </div>
                    </Show>
                </div>
            )}
        </div>
    );
};