import { Breadcrumb, Button } from "antd";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import api from "../../services/api";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import firebase from "../../services/firebaseConfig";
import * as texts from "./locales";
import "./index.css";
import { useFreire } from "../../utils/freireContext";

const Docs = () => {
  const [doc, setDoc] = useState({});
  const [showDoc, setShowDoc] = useState(false);
  const { freire } = useFreire();

  const getDocs = async () => {
    const { data } = await api.get("docs");
    data.servers = [
      {
        url: process.env.REACT_APP_API_URL,
      },
    ];
    setDoc(data);
    setShowDoc(true);
  };

  useEffect(() => {
    getDocs();
  }, []);

  const getToken = async () => {
    const token = await firebase.auth()?.currentUser?.getIdToken();
    navigator.clipboard.writeText(token);
  };

  return (
    <div className="container">
      <Breadcrumb>
        <Breadcrumb.Item>{freire(texts.ADMIN)}</Breadcrumb.Item>
        <Breadcrumb.Item>{freire(texts.DOCS)}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="button-div">
        <Button className="button" onClick={getToken}>
          {freire(texts.COPY_BEARER)}
        </Button>
      </div>
      {showDoc && <SwaggerUI spec={doc} />}
    </div>
  );
};

export default withRouter(Docs);
