import {
  Button,
  Col,
  Form,
  Icon,
  Input,
  notification,
  Popconfirm,
  Popover,
  Radio,
  Row,
  Table,
  Tooltip,
} from "antd";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Show from "../../../contexts/PermissionContext/Show";
import api from "../../../services/api";
import EditDrawer from "./EditDrawer";
import "./index.css";
import StatisticsDrawer from "./StatisticsDrawer";
import { FreireContext } from "../../../utils/freireContext";
import * as texts from "../locales";
import moment from "moment";

class ListPublicity extends Component {
  static contextType = FreireContext;

  state = {
    name: "seila",
    loadingTable: false,
    loadingEditDrawer: false,
    visibleEditDrawer: false,
    currentPublicity: null,
    visibleStatisticsDrawer: false,
    data: [],
    publicity: [],
    dateFiltrada: [],
    tablePage: 1,
  };

  componentDidMount() {
    this.getPublicitys();
  }

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.filter();
    }
  };

  async getPublicitys(params = {}) {
    this.setState({ loadingTable: true });

    const { data } = await api.get(`/publicities`, { params });

    this.setState({ dateFiltrada: data, loadingTable: false });
  }

  filtroDate = async (nome) => {
    this.setState({ loadingTable: true });

    const { data: dateFiltrada } = await api.get("publicity", {
      params: { name: nome },
    });

    this.setState({ dateFiltrada, loadingTable: false });
  };

  openEditDrawer = (currentPublicity) => {
    this.setState({ visibleEditDrawer: true, currentPublicity });
  };

  closeEditDrawer = () => {
    this.setState({ visibleEditDrawer: false });
  };

  openStatisticsDrawer = (currentPublicity) => {
    this.setState({ visibleStatisticsDrawer: true, currentPublicity });
  };

  closeStatisticsDrawer = () => {
    this.setState({ visibleStatisticsDrawer: false });
  };

  onSalvePublicity = async (data) => {
    const { freire } = this.context;

    this.setState({ loadingEditDrawer: true }, async () => {
      try {
        await api.put(`publicity/${this.state.currentPublicity._id}`, data);

        this.setState({ visibleEditDrawer: false, loadingEditDrawer: false });

        notification.success({
          message: freire(texts.ALL_RIGHT),
          description: freire(texts.CAMPAIGN_EDITED_SUCCESS),
        });

        this.getPublicitys();
      } catch (error) {
        notification.error({
          message: freire(texts.OOPS),
          description: freire(texts.ERROR_EDIT_CAMPAIGN),
        });
        this.setState({ loadingEditDrawer: false });
      }
    });
  };

  onDeletePublicity = async (data) => {
    const { freire } = this.context;

    try {
      if (data._id) {
        await api.delete(`publicity/${data._id}`);

        notification.success({
          message: freire(texts.ALL_RIGHT),
          description: freire(texts.PUBLICITY_DELETED),
        });

        this.getPublicitys();
      }
    } catch (error) {
      const {
        response: {
          data: { message },
        },
      } = error;

      notification.error({
        message: freire(texts.WENT_WONG),
        description: message || error.message,
      });
    }
  };

  filter = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.getPublicitys(values);
      }
    });

    this.setState({ tablePage: 1 });
  };

  clearFilters = () => {
    this.props.form.resetFields();
    this.getPublicitys();
  };

  goNew = () => {
    this.props.history.push("/dashboard/new_advertising");
  };

  getColumns = () => {
    const { freire } = this.context;

    return [
      {
        title: freire(texts.NAME),
        dataIndex: "name",
        width: "15%",
        key: "name",
      },
      {
        title: freire(texts.IMAGE),
        dataIndex: "img",
        width: "15%",
        key: "img",
        render: (img, publicity) => (
          <Popover
            placement="top"
            content={
              <div>
                <img
                  id={img.url}
                  onError={() =>
                    document
                      .getElementById(img.url)
                      .setAttribute("alt", freire(texts.ERROR_LOAD_IMAGE))
                  }
                  style={{ width: 400 }}
                  src={img.url}
                  alt={freire(texts.IMAGE_CAMPAING)}
                />
              </div>
            }
            title={publicity.name}
          >
            <a target="_blank" rel="noopener noreferrer" href={img.url}>
              {img.name || freire(texts.IMAGE)}
            </a>
          </Popover>
        ),
      },
      {
        title: freire(texts.ACTION_URL),
        dataIndex: "urlAction",
        // width: "15%",
        key: "urlAction",
        render: (urlAction) =>
          urlAction ? (
            <Tooltip placement="top" title={urlAction}>
              <a target="_blank" rel="noopener noreferrer" href={urlAction}>
                {urlAction.length > 25
                  ? urlAction.slice(0, 25) + "..."
                  : urlAction}
              </a>
            </Tooltip>
          ) : (
            "----"
          ),
      },
      {
        title: freire(texts.START_DATE_TIME),
        dataIndex: "startDate",
        key: "startDate",
        width: "15%",
        render: (startDate) => <span>{moment(startDate).format("L LTS")}</span>,
      },
      {
        title: freire(texts.END_DATE_TIME),
        dataIndex: "endDate",
        key: "endDate",
        width: "15%",
        render: (endDate) =>
          endDate ? <span>{moment(endDate).format("L LTS")}</span> : "----",
      },
      {
        title: freire(texts.INTERVAL_DAYS),
        dataIndex: "intervalDays",
        key: "intervalDays",
        width: "10%",
        render: (intervalDays) => (
          <span>{intervalDays ? `${intervalDays} dias` : "----"}</span>
        ),
      },
      {
        title: freire(texts.ENABLED),
        dataIndex: "enabled",
        width: "10%",
        key: "enabled",
        render: (text, data) => (
          <Icon type={data.enabled ? "check" : "close"} />
        ),
      },
      {
        title: freire(texts.ACTIONS),
        key: "acoes",
        width: "10%",
        render: (text, data) => (
          <>
            <Show when={"edit-advertising"}>
              <Tooltip placement="top" title={freire(texts.SHOW_DETAILS_EDIT)}>
                <Button
                  shape="circle"
                  size="small"
                  ghost
                  type="primary"
                  style={{ marginRight: 8 }}
                  onClick={() => this.openEditDrawer(data)}
                >
                  <Icon type="edit" />
                </Button>
              </Tooltip>
            </Show>

            <Show when={"show-advertising-statistics"}>
              <Tooltip placement="top" title={freire(texts.SHOW_STATS)}>
                <Button
                  shape="circle"
                  size="small"
                  ghost
                  type="primary"
                  style={{
                    color: "#3f8600",
                    borderColor: "#3f8600",
                    marginRight: 8,
                  }}
                  onClick={() => this.openStatisticsDrawer(data)}
                >
                  <Icon type="bar-chart" />
                </Button>
              </Tooltip>
            </Show>

            <Show when={"delete-advertising"}>
              <Tooltip placement="top" title={freire(texts.DELETE)}>
                <Popconfirm
                  placement="top"
                  title={freire(texts.CONFIRM_DELETE)}
                  okText={freire(texts.YES)}
                  cancelText={freire(texts.NO)}
                  onConfirm={() => this.onDeletePublicity(data)}
                >
                  <Button shape="circle" size="small" ghost type="danger">
                    <Icon type="delete" />
                  </Button>
                </Popconfirm>
              </Tooltip>
            </Show>
          </>
        ),
      },
    ];
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      visibleEditDrawer,
      loadingEditDrawer,
      currentPublicity,
      visibleStatisticsDrawer,
    } = this.state;
    const { freire } = this.context;

    const columns = this.getColumns();

    return (
      <div className="container">
        <Breadcrumbs
          breadcrumbs={[freire(texts.ADMIN), freire(texts.PUBLICITY)]}
        />
        <div className="filter">
          <Form wrapperCol={{ span: 24 }}>
            <Form.Item>
              <div style={{ flexDirection: "row", display: "flex" }}>
                {getFieldDecorator("name")(
                  <Input
                    className="firstInput"
                    placeholder={freire(texts.SEARCH_BY_NAME)}
                    onKeyPress={this.handleKeyPress} 
                    onChange={(e) => {
                      e.target.value = e.target.value.trim();
                    }}
                  />
                )}
                <Button
                  icon="search"
                  className="buttonSearch"
                  onClick={this.filter}
                >
                  {freire(texts.SEARCH)}
                </Button>
                <Show when={"add-advertising"}>
                  <Button
                    icon="plus"
                    className="buttonNew"
                    onClick={this.goNew}
                    type="primary"
                  >
                    {freire(texts.NEW)}
                  </Button>
                </Show>
              </div>
            </Form.Item>

            <Row gutter={[16, 8]}>
              <Col span={6}>
                <Form.Item label={freire(texts.ENABLED)}>
                  {getFieldDecorator("enabled")(
                    <Radio.Group buttonStyle="solid">
                      <Radio.Button value={true}>
                        {freire(texts.YES)}
                      </Radio.Button>
                      <Radio.Button value={undefined}>---</Radio.Button>
                      <Radio.Button value={false}>
                        {freire(texts.NO)}
                      </Radio.Button>
                    </Radio.Group>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <div className="footer">
            <Button style={{ marginRight: 10 }} onClick={this.clearFilters}>
              {freire(texts.CLEAR)}
            </Button>
            <Button onClick={this.filter} type="primary">
              {freire(texts.FILTER)}
            </Button>
          </div>
        </div>

        <Table
          style={{ backgroundColor: "white" }}
          scroll={{ x: 1300 }}
          tableLayout="auto"
          loading={this.state.loadingTable}
          rowKey={(data) => data._id}
          dataSource={this.state.dateFiltrada}
          columns={columns}
          pagination={{
            current: this.state.tablePage,
            onChange: (page) => this.setState({ tablePage: page }),
          }}
        />

        <EditDrawer
          visible={visibleEditDrawer}
          loading={loadingEditDrawer}
          onClose={() => this.closeEditDrawer()}
          onSave={(values) => this.onSalvePublicity(values)}
          publicity={currentPublicity}
        />

        <StatisticsDrawer
          visible={visibleStatisticsDrawer}
          onClose={() => this.closeStatisticsDrawer()}
          publicityID={currentPublicity ? currentPublicity._id : ""}
          publicityName={currentPublicity ? currentPublicity.name : ""}
        />
      </div>
    );
  }
}
const PageListPublicity = Form.create({ name: "filters" })(ListPublicity);
export default withRouter(PageListPublicity);
