import React from "react";
import { useFreire } from "../../utils/freireContext";
import { Country as ICountry } from "../../interfaces/country";

interface ICountryFlag {
  disabled?: boolean;
  showFlag?: boolean;
  country: ICountry;
}

const CountryFlag: React.FC<ICountryFlag> = ({
  disabled,
  showFlag = true,
  country: { code, flags, title },
}) => {
  const { freire } = useFreire();

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {showFlag && (
        <img
          alt={code}
          src={flags.png}
          style={{
            width: 20,
            marginRight: 8,
            opacity: disabled ? 0.5 : 1,
          }}
        />
      )}
      {`${freire(title)} (${code})`}
    </div>
  );
};

export default CountryFlag;
