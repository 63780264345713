import { useEffect } from "react";

export const useMountUnmount = (mountEffect, unmountEffect) => {
  useEffect(() => {
    mountEffect();

    return () => {
      unmountEffect();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
