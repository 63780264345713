import React, { Component } from "react";
import { Button, Col, Form, Input, Row, Drawer, Switch } from "antd";
import { withRouter } from "react-router-dom";
import { FreireContext } from "../../../utils/freireContext";
import * as texts from "../locales";
class EditDrawer extends Component {
  static contextType = FreireContext;

  componentDidUpdate(prevProps, prevState) {
    if (this.props.visible !== prevProps.visible) {
      if (this.props.visible) {
        this.props.form.setFieldsValue(this.props.publicity);
      }
    }
  }

  onClickSave() {
    this.props.form.validateFields(async (err, payload) => {
      if (err) {
        return;
      }

      this.props.onSave(payload);
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { visible, onClose, loading } = this.props;
    const { freire } = this.context;

    return (
      <Drawer
        title={freire(texts.EDIT_PUBLICITY_CAMPAIGN)}
        width={500}
        onClose={() => onClose()}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={freire(texts.NAME)}>
                {getFieldDecorator("name", {
                  rules: [
                    {
                      required: true,
                      message: freire(texts.REQUIRED_FIELD),
                    },
                  ],
                })(<Input placeholder={freire(texts.NAME_EX)} />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={freire(texts.ACTION_URL)}>
                {getFieldDecorator("urlAction", {
                  rules: [
                    {
                      required: true,
                      message: freire(texts.REQUIRED_FIELD),
                    },
                  ],
                })(<Input placeholder={freire(texts.ACTION_URL_EX)} />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={freire(texts.ENABLED)}>
                {getFieldDecorator("enabled", {
                  valuePropName: "checked",
                })(
                  <Switch
                    checkedChildren={freire(texts.YES)}
                    unCheckedChildren={freire(texts.NO)}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            width: "100%",
            borderTop: "1px solid #e9e9e9",
            padding: "10px 16px",
            background: "#FFF",
            textAlign: "right",
          }}
        >
          <Button onClick={() => onClose()} style={{ marginRight: 8 }}>
            {freire(texts.CANCEL)}
          </Button>
          <Button
            loading={loading}
            onClick={() => this.onClickSave()}
            type="primary"
          >
            {freire(texts.SAVE)}
          </Button>
        </div>
      </Drawer>
    );
  }
}

const ComponentEditDrawer = Form.create({ name: "edit" })(EditDrawer);
export default withRouter(ComponentEditDrawer);
