 export const formatAddress = (address) => {
    if (!address) {
      return "----";
    }

    // Brazil address
    if(address.cep) {
      const logradouro = address.logradouro ?? "----";
      const numero = address.numero ?? "----";
      const complemento = address.complemento ? `, ${address.complemento}` : "";
      const bairro = address.bairro ?? "----";
      const cidade = address.cidade ?? "----";
      const estadoUf = address.estadoUf ?? "----";
      const cep = address.cep ?? "----";
  
      return `${logradouro}, ${numero}${complemento}, ${bairro}, ${cidade} - ${estadoUf}, ${cep}`;
    }

    // Argentina address
    if(address.cpa) {
      const direccion = address.direccion ?? "----";
      const numero = address.numero ?? "----";
      const complemento = address.complemento ? ` - ${address.complemento}` : "";
      const barrio = address.barrio ?? "----";
      const ciudad = address.ciudad?.nombre ?? "----";
      const provincia = address.provincia?.nombre ?? "----";
      const cpa = address.cpa ?? "----";

      return `${direccion}, ${numero}${complemento}, ${barrio}, ${ciudad} - ${provincia}, ${cpa}`;
    }
  };