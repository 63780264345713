import { currentLanguage } from "../utils/current-language";
import firebase from "./firebaseConfig";

export async function setTokenRequestInterceptor(config) {
  const token = await firebase.auth()?.currentUser?.getIdToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}

export const setLanguageInterceptor = async (config) => {
  if (currentLanguage.ref !== null) {
    config.headers["Accept-Language"] = currentLanguage.ref;
  }

  return config;
};
