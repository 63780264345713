import React from 'react'
import { useFreire } from '../../../../../utils/freireContext';
import { useNewCampaignContext } from '../../context';
import * as texts from "../../../locales";
import { Col, Form, Input, Radio, Row, Switch, Tooltip, Icon } from 'antd';
import MyDivider from '../../../../../components/MyDivider';
import './style.css';

export default function CampaignQuota({ showDivider = false }) {
  const { freire } = useFreire();
  const { form } = useNewCampaignContext();
  const { getFieldDecorator } = form;

  const { quota: campaignQuota } = form.getFieldsValue();

  const {
    quota = {
      enabled: campaignQuota?.enabled,
      type: campaignQuota?.type,
      totalBalance: campaignQuota?.totalBalance,
      accumulateToNextPeriod: campaignQuota?.accumulateToNextPeriod,
      couponsExpireOnTotalUsage: campaignQuota?.couponsExpireOnTotalUsage
    },
  } = form.getFieldsValue();

  const requiredFieldRule = {
    required: true,
    message: freire(texts.REQUIRED_FIELD),
  };

  return (
    <>
      <span className="form-field-title">
        {freire(texts.QUOTA).toUpperCase()}
      </span>
      <Row>
        <Form.Item className="custom-form-item" labelAlign='left' label={freire(texts.CAMPAIGN_HAS_RESTRITIVE_QUOTA)}>
          {getFieldDecorator("quota.enabled", {
            valuePropName: "checked",
            initialValue: quota.enabled || false,
          })(
            <Switch
              checkedChildren={freire(texts.YES)}
              unCheckedChildren={freire(texts.NO)}
            />
          )}
        </Form.Item>
      </Row>

      {quota.enabled && (
        <>
          <Row >
            <Col span={12}>
              <Form.Item labelAlign='top' label={freire(texts.MODEL)}>
                {getFieldDecorator("quota.type", {
                  initialValue: quota.type || 'unique',
                })(
                  <Radio.Group
                    disabled={!quota.enabled}
                    className="customRadioGroup campaignQuota"
                    buttonStyle="solid"
                  >
                    <Radio.Button value={'unique'}>
                      {`${freire(texts.UNIQUE_QUOTA)}`}
                    </Radio.Button>
                    <Radio.Button value={'monthlyRecurring'}>
                      {`${freire(texts.MONTHLY_RENOVATION_QUOTA)}`} {" "}
                      <Tooltip title={freire(texts.QUESTION_MONTLY_RENEW)}>
                      <Icon type="question-circle-o" />
                    </Tooltip>
                    </Radio.Button>
                  </Radio.Group>

                )}
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Form.Item labelAlign="left" label={freire(texts.AVAIABLE_VALUE)}>
              {getFieldDecorator("quota.totalBalance", {
                ...(quota.enabled && {rules: [requiredFieldRule]}),
                initialValue: quota.totalBalance || null,
              })(
                <Input
                  className="custom-input-number"
                  disabled={!quota.enabled}
                  placeholder={freire(texts.EX_PARAM("3.000,00"))}
                />
              )}
            </Form.Item>
          </Row>

          <Row>
            {quota.type === 'monthlyRecurring' && (
              <Form.Item className="custom-form-item" labelAlign='right' label={freire(texts.VALUE_ACCUMULATE_TO_NEXT_PERIOD)}>
                {getFieldDecorator("quota.accumulateToNextPeriod", {
                  valuePropName: "checked",
                  initialValue: quota.accumulateToNextPeriod || false,
                })(
                  <Switch
                    checkedChildren={freire(texts.YES)}
                    unCheckedChildren={freire(texts.NO)}
                  />
                )}
              </Form.Item>
            )}
          </Row>
        </>
      )}
      {showDivider && <MyDivider />}
    </>
  )
}
