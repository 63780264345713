export const ADMIN = {
  pt: "Admin",
};

export const DOCS = {
  pt: "Docs",
};

export const COPY_BEARER = {
  pt: "Copiar Bearer",
};
