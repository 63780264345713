import { useEffect, useRef } from "react";

export const useOnUpdate = (effect, deps) => {
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      return effect();
    }

    isMounted.current = true;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
