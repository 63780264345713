import { FooterProps } from "../interfaces";
import { Button, Icon } from "antd";
import { useFreire } from "../../../utils/freireContext";
import { useContext } from "react";
import * as texts from "../locales";
import "../index.css";
import { BusinessHourContext } from "../index";

export default function Footer({ isSaved }: FooterProps) {
  const { freire } = useFreire();
  const { loading, onSaveBusinessHourPeriod, disabled } =
    useContext(BusinessHourContext);
  return (
    <div className="saveContainer">
      {isSaved ? (
        <Button disabled={true} className="saveButtonDisable">
          {freire(texts.SAVED_HOUR)}
        </Button>
      ) : (
        <>
          <div className="notSavedWarning">
            <Icon type="warning" className="iconWarning" />
            {freire(texts.SAVE_HOUR_WARNING)}
          </div>
          <Button
            disabled={loading || disabled}
            className="saveButton"
            onClick={onSaveBusinessHourPeriod}
          >
            {freire(texts.SAVE_HOUR)}
          </Button>
        </>
      )}
    </div>
  );
}
