import React from "react";
import InfoRow from "../../../../../../components/InfoRow";
import { Icon, Tag } from "antd";
import moment from "moment";
import { formatters } from "../../../../../../utils/formatters";

interface IdleColProps {
  charge: any;
  texts: any;
  freire: any;
}

export const IdleStartRow: React.FC<IdleColProps> = ({
  charge,
  texts,
  freire,
}) => {
  return (
    <InfoRow
      labelSpan={7}
      valueSpan={17}
      label={`${freire(texts.IDLE_TIME)}:`}
      value={`Inicio: ${moment(charge.startIdlePeriod).format("L LTS")}`}
      extraItems={
        <Tag className="paymentStatusTag" style={{ paddingLeft: 6 }}>
          <Icon
            type="clock-circle"
            style={{
              marginRight: 5,
            }}
          />
          {formatters.duration(freire)(
            moment(charge.meterValues.lastTimestamp).diff(
              charge.startIdlePeriod,
              "hours"
            ) === 0
              ? moment.duration(
                  moment(charge.meterValues.lastTimestamp).diff(
                    charge.startIdlePeriod,
                    "minutes"
                  ),
                  "minutes"
                )
              : moment.duration(
                  moment(charge.meterValues.lastTimestamp).diff(
                    charge.startIdlePeriod,
                    "hours"
                  ),
                  "hours"
                )
          )}
        </Tag>
      }
      bold={false}
      tooltip={freire(
        texts.AGO(
          formatters.duration(freire)(
            moment().diff(charge.meterValues.lastTimestamp, "hours") === 0
              ? moment.duration(
                  moment().diff(charge.meterValues.lastTimestamp, "minutes"),
                  "minutes"
                )
              : moment.duration(
                  moment().diff(charge.meterValues.lastTimestamp, "hours"),
                  "hours"
                )
          )
        )
      )}
    />
  );
};
