import React, { useMemo } from "react";
import { Table } from "antd";
import { useFreire } from "../../../../../utils/freireContext";
import { COUPON_TYPE } from "../../../../../services/utils";

import ResumeColumns from "./columns";

const { FREE, PERCENTAGE, FIXED_VALUE, CREDIT } = COUPON_TYPE;

const ResumeTable = ({ loading, campaignResume }) => {
  const { freire } = useFreire();

  const columns = useMemo(() => {
    const resumeColumns = ResumeColumns.getColumns(freire);

    if (
      [FREE, PERCENTAGE, FIXED_VALUE].includes(campaignResume?.benefit?.type)
    ) {
      return [
        resumeColumns.amountOfCoupons,
        resumeColumns.benefit,
        resumeColumns.usageLimitPerCoupon,
        resumeColumns.amountOfActiveCoupons,
        resumeColumns.themeAlias,
      ];
    } else if (campaignResume?.benefit?.type === CREDIT) {
      return [
        resumeColumns.amountOfCoupons,
        resumeColumns.balancePerCoupon,
        resumeColumns.balance,
        resumeColumns.amountOfActiveCoupons,
        resumeColumns.themeAlias,
      ];
    }

    return [];
  }, [freire, campaignResume]);

  return (
    <Table
      style={{ backgroundColor: "white", marginTop: 16 }}
      tableLayout="auto"
      loading={loading}
      rowKey={(data) => data?._id}
      dataSource={campaignResume ? [campaignResume] : []}
      columns={columns}
      pagination={false}
    />
  );
};

export default ResumeTable;
