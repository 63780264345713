export const HELP = {
  pt: "Você pode pesquisar uma estação pelo ID, nome ou endereço. ",
  es: "Puedes buscar una estación por ID, nombre o dirección. ",
  en: "You can search for a station by ID, name or address. ",
};

export const STATIONS = {
  pt: "Estações",
  es: "Estaciones",
  en: "Stations",
};

export const STATIONS_PLACEHOLDER = {
  pt: "Selecione as estações",
  es: "Selecciona las estaciones",
  en: "Select stations",
};

export const COMPANIES = {
  pt: "Empresas",
  es: "Empresas",
  en: "Companies",
};

export const COMPANIES_PLACEHOLDER = {
  pt: "Selecione as empresas",
  es: "Selecciona las empresas",
  en: "Select companies",
};

export const CONNECTION = {
  pt: "Conexão",
  es: "Conexión",
  en: "Connection",
};

export const CONNECTION_LABEL_ALL = {
  pt: "Todas",
  es: "Todas",
  en: "All",
};

export const CONNECTION_LABEL_ACTIVE = {
  pt: "Ativas",
  es: "Activas",
  en: "Active",
};

export const CONNECTION_LABEL_DISABLED = {
  pt: "Desativadas",
  es: "Desactivadas",
  en: "Disabled",
};

export const VISIBILITY = {
  pt: "Visibilidade",
  es: "Visibilidad",
  en: "Visibility",
};

export const VISIBILITY_LABEL_ALL = {
  pt: "Todos",
  es: "Todos",
  en: "All",
};

export const VISIBILITY_LABEL_GROUPS = {
  pt: "Grupos",
  es: "Grupos",
  en: "Groups",
};

export const VISIBILITY_LABEL_HIDDEN = {
  pt: "Ocultas",
  es: "Ocultas",
  en: "Hidden",
};
