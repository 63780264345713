import { Empty, Radio, Spin } from "antd";
import React, { Fragment } from "react";
import { useFreire } from "../../../../utils/freireContext";
import * as texts from "../../locales";

import "./styles.css";

const SelectStation = ({
  loading = false,
  stations = [],
  onChange = () => {},
}) => {
  const { freire } = useFreire();

  return (
    <Fragment>
      <h3 style={{ color: "#FC385C" }}>{freire(texts.SELECT_ONE_STATION)}</h3>
      <div className="containerRadioGroup">
        <Radio.Group
          onChange={(e) => onChange(e.target.value)}
          style={{ width: "100%" }}
        >
          {loading ? (
            <div className="containerSpin">
              <Spin tip={freire(texts.LOADING_STATIONS)} />
            </div>
          ) : stations.length > 0 ? (
            stations.map((station) => (
              <div key={station.stationId} className="containerRadio">
                <Radio
                  style={{ display: "flex", alignItems: "flex-start" }}
                  value={station}
                >
                  <div>
                    <span style={{ fontWeight: "bold", fontSize: 14 }}>
                      {station.name}
                    </span>
                    <br />
                    <span
                      style={{
                        fontSize: 12,
                        color: "#565559",
                        opacity: 0.65,
                      }}
                    >
                      {station.stationId}
                    </span>
                  </div>
                </Radio>
              </div>
            ))
          ) : (
            <Empty
              description={freire(texts.STATION_NOT_FOUND)}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )}
        </Radio.Group>
      </div>
    </Fragment>
  );
};

export default SelectStation;
