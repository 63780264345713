export const COPY = {
  pt: "Copiar",
  es: "Copiar",
  en: "Copy",
};

export const COPIED = {
  pt: "Copiado com sucesso!",
  es: "¡Copiado con éxito!",
  en: "Copied successfully!",
};
