import React from "react";

// adapted from antd
export const Warning = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="64 64 896 896"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(64, 64)">
      <circle fill="currentColor" cx="50%" cy="50%" r="50%" />
    </g>
    <path
      d="M464 688a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm24-112h48c4.4 0 8-3.6 8-8V296c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8z"
      fill="white"
    />
  </svg>
);
