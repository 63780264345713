import React, { useEffect } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Drawer,
  Switch,
  Select,
  notification,
  message,
} from "antd";
import Show from "../../../contexts/PermissionContext/Show";
import api from "../../../services/api";
import { useAccess } from "../../../contexts/PermissionContext";
import { useFreire } from "../../../utils/freireContext";
import * as texts from "../locales";

function EditDrawer({
  form,
  visible,
  loading,
  onClose,
  onSave,
  user,
  companies = [],
  permissionGroups = [],
}) {
  const { freire } = useFreire();
  const { hasPermission } = useAccess();
  const canEditUserPermission =
    hasPermission("edit-permission-group-user-dev") ||
    hasPermission("edit-permission-group-user-tupi");

  useEffect(() => {
    async function getUserPermissionGroup() {
      try {
        const {
          data: { permissionGroup },
        } = await api.get(`/user/${user.uid}/permission-group`);

        form.setFieldsValue({ permissionGroup });
      } catch (error) {
        notification.error({
          message: "Ops...",
          description: freire(texts.GET_GROUP_PERMISSION_USERS),
        });
      }
    }

    if (visible) {
      if (canEditUserPermission) {
        getUserPermissionGroup();
      }

      const values = {
        displayName: user?.displayName,
        email: user?.email,
        admin: user?.customClaims?.admin || false,
        companyID: user?.customClaims?.companyID,
      };

      form.setFieldsValue(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const handlerSave = () => {
    form.validateFields(async (err, payload) => {
      if (err) return;

      payload.companyName = undefined;

      if (payload.admin) {
        if (!payload.companyID) {
          message.error("Informe a empresa");
          return;
        }
        if (canEditUserPermission && !payload.permissionGroup) {
          message.error("Informe o grupo de permissão");
          return;
        }

        const company = companies.find((cp) => cp._id === payload.companyID);
        payload.companyName = company?.companyName;
      } else {
        payload.companyID = undefined;
        payload.permissionGroup = undefined;
      }

      onSave(payload);
    });
  };

  const { getFieldDecorator, getFieldsValue, resetFields } = form;

  const { admin } = getFieldsValue();

  return (
    <Drawer
      title={freire(texts.EDIT_USER)}
      width={500}
      onClose={() => onClose()}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form id="form_edit_user" colon={false} wrapperCol={{ span: 24 }}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label={freire(texts.NAME)}>
              {getFieldDecorator("displayName")(
                <Input placeholder={"----"} disabled />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label={freire(texts.EMAIL)}>
              {getFieldDecorator("email")(
                <Input placeholder={freire(texts.EMAIL)} disabled />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Show when={"make-user-admin"}>
            <Col span={6}>
              <Form.Item label={freire(texts.ADMIN)}>
                {getFieldDecorator("admin", {
                  initialValue: false,
                  valuePropName: "checked",
                })(
                  <Switch
                    checkedChildren={freire(texts.YES)}
                    unCheckedChildren={freire(texts.NO)}
                    disabled={loading}
                    onChange={(value) => {
                      if (!value) resetFields(["companyID", "permissionGroup"]);
                    }}
                  />
                )}
              </Form.Item>
            </Col>
          </Show>
          <Show when={"edit-company-user"}>
            <Col span={18}>
              <Form.Item label={freire(texts.COMPANY)}>
                {getFieldDecorator("companyID", {
                  rules: [
                    {
                      required: admin,
                      message: freire(texts.REQUIRED_FIELD),
                    },
                  ],
                })(
                  <Select
                    disabled={loading || !admin}
                    placeholder={freire(texts.SELECT_COMPANY)}
                    optionFilterProp="children"
                    showSearch
                  >
                    {companies.map((company) => {
                      return (
                        <Select.Option key={company._id} value={company._id}>
                          {company.companyName}
                        </Select.Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Show>
        </Row>
        {canEditUserPermission && (
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={freire(texts.ACCESS_GROUP_PERMISSIONS)}>
                {getFieldDecorator("permissionGroup", {
                  rules: [
                    {
                      required: admin,
                      message: freire(texts.REQUIRED_FIELD),
                    },
                  ],
                })(
                  <Select
                    disabled={loading || !admin}
                    placeholder={freire(texts.SELECT_GROUP)}
                    optionFilterProp="children"
                    showSearch
                  >
                    {permissionGroups.map((group) => (
                      <Select.Option key={group._id} value={group._id}>
                        {group.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
      <div
        style={{
          position: "absolute",
          right: 0,
          bottom: 0,
          width: "100%",
          borderTop: "1px solid #e9e9e9",
          padding: "10px 16px",
          background: "#fff",
          textAlign: "right",
        }}
      >
        <Button onClick={onClose} style={{ marginRight: 20 }}>
          {freire(texts.CANCEL)}
        </Button>
        <Button loading={loading} onClick={handlerSave} type="primary">
          {freire(texts.SAVE)}
        </Button>
      </div>
    </Drawer>
  );
}

const ComponentEditDrawer = Form.create({ name: "edit" })(EditDrawer);
export default ComponentEditDrawer;
