import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Radio,
  Row,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import Breadcrumbs from "../../../components/Breadcrumbs";
import apiOcpp from "../../../services/apiOcpp";
import "./index.css";
import moment from "moment";
import { sorter } from "../../../utils/sorter";
import { useFreire } from "../../../utils/freireContext";
import * as texts from "../locales";

const ListTags = ({ form }) => {
  const [loadingTable, setLoadingTable] = useState(false);
  const [tablePage, setTablePage] = useState(1);
  const { freire } = useFreire();
  const [columns] = useState([
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: freire(texts.COD),
      dataIndex: "idTag",
      key: "idTag",
    },
    {
      title: freire(texts.USER_TAG),
      dataIndex: "isTagUser",
      key: "isTagUser",
      render: (isTagUser) => (
        <span>{isTagUser ? freire(texts.YES) : freire(texts.NO)}</span>
      ),
    },
    {
      title: freire(texts.DESCRIPTION),
      dataIndex: "note",
      key: "note",
      render: (note) => <span>{note || "----"}</span>,
    },
    {
      title: freire(texts.MAX_CHARGER),
      dataIndex: "maxActiveTransactionCount",
      key: "maxActiveTransactionCount",
      render: (maxActiveTransactionCount) =>
        maxActiveTransactionCount || (
          <span style={{ color: "red" }}>{freire(texts.DISABLE)}</span>
        ),
    },
    {
      title: freire(texts.EXPIRATION_DATE),
      dataIndex: "expiryDate",
      key: "expiryDate",
      render: (expiryDate) => (
        <span>{expiryDate ? moment(expiryDate).format("L LTS") : "----"}</span>
      ),
    },
  ]);

  const [tagsList, setTagsList] = useState([]);

  useEffect(() => {
    getTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTags = async () => {
    try {
      setLoadingTable(true);
      const { data: tags } = await apiOcpp.get(`tags`, {
        params: {
          ...form.getFieldsValue(),
        },
      });
      setTagsList(sorter(tags, "idTag"));
    } catch (error) {
      notification.error({
        message: "Ops...",
        description: freire(texts.SEARCH_TAG_ERROR),
      });
    } finally {
      setLoadingTable(false);
    }
  };

  const filter = () => {
    form.validateFields((err, values) => {
      if (!values.name) values.name = undefined;

      if (!err) {
        getTags(values);
      }
    });

    setTablePage(1);
  };

  const clearFilters = () => {
    form.resetFields();
    setTagsList([]);
    getTags();
  };

  return (
    <div className="container">
      <Breadcrumbs
        breadcrumbs={[
          freire(texts.ADMIN),
          freire(texts.OCPP),
          freire(texts.TAGS),
        ]}
      />
      <div className="filter">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            filter();
          }}
          wrapperCol={{ span: 24 }}
        >
          <Form.Item>
            <div style={{ flexDirection: "row", display: "flex" }}>
              {form.getFieldDecorator("idTag")(
                <Input
                  onSubmit={filter}
                  className="firstInput"
                  placeholder={freire(texts.SEARCH_BY_CODE_TAG)}
                />
              )}
              <Button icon="search" className="buttonSearch" onClick={filter}>
                {freire(texts.SEARCH)}
              </Button>
            </div>
          </Form.Item>

          <Row gutter={[16, 8]}>
            <Col span={8}>
              <Form.Item colon={false} label={freire(texts.USER_TAG)}>
                {form.getFieldDecorator("isTagUser")(
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value={true}>
                      {freire(texts.YES)}
                    </Radio.Button>
                    <Radio.Button value={undefined}>----</Radio.Button>
                    <Radio.Button value={false}>
                      {freire(texts.NO)}
                    </Radio.Button>
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <div className="footer">
          <Button style={{ marginRight: 10 }} onClick={clearFilters}>
            {freire(texts.CLEAN)}
          </Button>
          <Button onClick={filter} type="primary">
            {freire(texts.FILTER)}
          </Button>
        </div>
      </div>

      <Table
        style={{ backgroundColor: "white" }}
        loading={loadingTable}
        rowKey={(data) => data.id}
        dataSource={tagsList}
        columns={columns}
        scroll={{ x: 1300 }}
        tableLayout="auto"
        pagination={{
          current: tablePage,
          onChange: (page) => setTablePage(page),
        }}
      />
    </div>
  );
};
const PageListRfidCampaigns = Form.create({ name: "filters" })(ListTags);
export default withRouter(PageListRfidCampaigns);
