import { HourProps } from "../interfaces";
import { Button, Icon, TimePicker } from "antd";
import { useFreire } from "../../../utils/freireContext";
import { useContext } from "react";
import * as texts from "../locales";
import moment from "moment";
import "../index.css";
import { BusinessHourContext } from "../index";

export default function Hour({
  hour,
  weekDay,
  hourIndex,
  hourLength,
}: HourProps) {
  const { freire } = useFreire();

  const {
    loading,
    onNewHourInDay,
    onDeleteHourInDay,
    onChangeInitialHour,
    onChangeFinalHour,
    disabled,
  } = useContext(BusinessHourContext);
  return (
    <div className="hourContainer">
      <span className="hourFirstText">{freire(texts.OF)}</span>
      <TimePicker
        disabled={loading || disabled}
        className="hourTimePicker"
        allowClear={false}
        format="HH:mm"
        value={moment(hour.initial, "HH:mm")}
        onChange={(value) => onChangeInitialHour(value, weekDay, hourIndex)}
      />
      <span className="hourSecondText">{freire(texts.TO)}</span>
      <TimePicker
        disabled={loading || disabled}
        className="hourTimePicker"
        allowClear={false}
        format="HH:mm"
        value={moment(hour.final, "HH:mm")}
        onChange={(value) =>
          onChangeFinalHour(value, weekDay, hourIndex, hourLength)
        }
      />
      {hourIndex === 0 ? (
        hourLength <= 1 ? (
          <Button
            disabled={loading || disabled}
            className="buttonAddHour"
            onClick={() => onNewHourInDay(weekDay)}
          >
            <Icon type="plus" className="buttonAddHourIcon" />
            <span className="buttonAddHourText">
              {freire(texts.ADDITIONAL_HOUR)}
            </span>
          </Button>
        ) : null
      ) : (
        <Button
          disabled={loading || disabled}
          icon="delete"
          className="buttonDeleteHour"
          onClick={() => onDeleteHourInDay(weekDay, hourIndex)}
        />
      )}
    </div>
  );
}
