import React, { useContext } from "react";

export const NewCampaignContext = React.createContext({
  form: undefined,
  userCompany: undefined,
  allCompanies: [],
  allStations: [],
  selectedCompany: undefined,
});

export const useNewCampaignContext = () => {
  const context = useContext(NewCampaignContext);

  return context;
};
