import { Form, Layout } from "antd";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./index.css";
import api from "../../../services/api";

const { Content } = Layout;

class Home extends Component {
  state = {
    powerbi: null,
    loading: true,
  };

  componentDidMount = async () => {
    const { data } = await api.get(`/user/company`);

    if (data?.powerbi) {
      this.setState({ powerbi: data.powerbi, loading: false });
    }
  };

  render() {
    const { powerbi, loading } = this.state;
    return (
      <Content className="content">
        {!loading && (
          <iframe
            title="PowerBIClient"
            width="100%"
            height="800"
            src={powerbi}
            frameborder="0"
            allowFullScreen="true"
          ></iframe>
        )}
      </Content>
    );
  }
}

const PageHome = Form.create({ name: "inputs" })(Home);
export default withRouter(PageHome);
