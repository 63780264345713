import { Radio } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import RGroup from "antd/lib/radio/group";
import { RadioButtonProps } from "antd/lib/radio/radioButton";
import { forwardRef } from "react";

interface IRadioGroup extends RadioButtonProps {
  options: Array<{ label: string; value: string | undefined }>;
  onChangeCallback: ((e: RadioChangeEvent) => void) | undefined;
}

const RadioGroup = forwardRef<RGroup, IRadioGroup>(
  ({ options, onChangeCallback, ...rest }, ref) => {
    return (
      <Radio.Group buttonStyle="solid" {...rest} ref={ref}>
        {options.map(({ value, label }) => (
          <Radio.Button
            onChange={(e) => {
              onChangeCallback && onChangeCallback(e);
              rest.onChange && rest.onChange(e);
            }}
            value={value}
            key={label}
          >
            {label}
          </Radio.Button>
        ))}
      </Radio.Group>
    );
  }
);

export default RadioGroup;
