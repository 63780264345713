import React, { memo } from "react";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { useAccess } from "../../../contexts/PermissionContext";
import "./index.css";
import { useFreire } from "../../../utils/freireContext";
import * as texts from "../locales";
// TODO: update button

const SystemAvailability = memo(() => {
  const { hasPermission } = useAccess();
  const { freire } = useFreire();

  const canViewDetailed = hasPermission("show-system-availability-detailed");

  const src = canViewDetailed
    ? process.env.REACT_APP_DETAILED_UPTIME_URL
    : process.env.REACT_APP_UPTIME_URL;

  const height = parseInt(
    canViewDetailed
      ? process.env.REACT_APP_DETAILED_UPTIME_HEIGHT
      : process.env.REACT_APP_UPTIME_HEIGHT
  );

  return (
    <div className="container">
      <Breadcrumbs
        breadcrumbs={[
          freire(texts.ADMIN),
          freire(texts.AVAILABILITY),
          freire(texts.SYSTEM),
        ]}
      />
      <div className="dashboard-wrapper">
        <div style={{ height: height - 150, overflowY: "hidden" }}>
          <iframe
            title={freire(texts.DASHBOARD)}
            src={src}
            frameBorder={0}
            width="100%"
            height={height + 150}
          />
        </div>
      </div>
    </div>
  );
});

export default SystemAvailability;
