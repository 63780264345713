import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  notification,
  Row,
  Select,
  Spin,
  Switch,
} from "antd";
import * as texts from "../../locales";
import { useCallback, useEffect, useState } from "react";
import SelectCountry from "../../../../components/SelectCountry";
import SelectLocation from "../../../../components/SelectLocation";
import api from "../../../../services/api";
import { sorter } from "../../../../utils/sorter";
import { ICompany } from "../../../../interfaces/company";
import {
  getCountryCode,
  getStateAndCity,
} from "../../../../utils/getLocationInfos";
import { ILocation } from "../../../../interfaces/location";

interface IEditLocationDrawerProps {
  visible: boolean;
  loading: boolean;
  freire: any;
  location: any;
  form: any;
  closeDrawer: () => void;
  onSave: (newLocation: ILocation) => void;
}

const EditDrawer = ({
  visible,
  form,
  location: locationProp,
  freire,
  closeDrawer,
  onSave,
}: IEditLocationDrawerProps) => {
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState<ICompany[]>();
  const [location, setLocation] = useState<any>(null);
  const [initialAddress, setInitialAddress] = useState<any>({});
  const [selectedCountry, setSelectedCountry] = useState<any>(null);

  const getLocation = useCallback(async () => {
    setLoading(true);
    try {
      const { data: location } = await api.get(`locations/${locationProp?.id}`);

      setLocation(location);
      setInitialAddress({
        country: location?.country,
        state: location?.state,
        city: location?.city,
      });
      setSelectedCountry(location?.country);
    } catch (error) {
      notification.error({
        message: freire(texts.OOPS),
        description: freire(texts.ERROR_GET_LOCATION),
      });
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [freire]);

  const getCompanies = useCallback(async () => {
    setLoading(true);
    try {
      const { data: companies } = await api.get("company");

      const companiesNotHolding = sorter(
        companies.filter((company: ICompany) => !company.holding),
        "companyName"
      );
      setCompanies(companiesNotHolding);
    } catch (error) {
      notification.error({
        message: freire(texts.OOPS),
        description: freire(texts.ERROR_LIST_COMPANIES),
      });
    } finally {
      setLoading(false);
    }
  }, [freire]);

  const handleLatLong = (value: any) => {
    const hasLatLong = value.includes(",");

    if (hasLatLong) {
      const [lat, lng] = value.split(", ");

      form.setFieldsValue({
        "coordinates.latitude": lat,
        "coordinates.longitude": lng,
      });
    }
  };

  useEffect(() => {
    getLocation();
    getCompanies();
  }, [getCompanies, getLocation]);

  useEffect(() => {
    if (visible && location) {
      form.resetFields();
      const province = location?.state?.includes("AR-")
        ? location?.state
        : undefined;

      form.getFieldDecorator("address2.state", {
        initialValue: location?.state,
      });
      form.getFieldDecorator("address2.city", { initialValue: location?.city });
      form.getFieldDecorator("address2.province", { initialValue: province });
      form.getFieldDecorator("name", { initialValue: location?.name });
      form.getFieldDecorator("address", { initialValue: location?.address });
      form.getFieldDecorator("coordinates.latitude", {
        initialValue: location?.coordinates?.latitude,
      });
      form.getFieldDecorator("coordinates.longitude", {
        initialValue: location?.coordinates?.longitude,
      });
      form.getFieldDecorator("phone", { initialValue: location?.phone });
      form.getFieldDecorator("company_id", {
        initialValue: location?.company_id,
      });
      form.getFieldDecorator("freeParking", {
        initialValue: location?.freeParking,
      });
      form.getFieldDecorator("private", {
        initialValue: location?.private,
      });
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line
  }, [location, visible]);

  const onSelectCountry = () => {
    setLocation({
      ...location,
      country: form.getFieldValue("country"),
      address2: {
        city: undefined,
        state: undefined,
        province: undefined,
      },
    });
    form.resetFields(["address2.city", "companyID"]);
  };

  const onClickSave = async () => {
    form.validateFields(async (err: any, params: any) => {
      if (err) {
        return;
      }

      if (params.address2) {
        const { state, city } = getStateAndCity(
          params.country,
          params.address2
        );

        params.state = state;
        params.city = city;

        delete params.address2;
      }

      if (initialAddress?.country !== params.country) {
        if (
          initialAddress?.state === params.state ||
          initialAddress?.city === params.city
        ) {
          notification.error({
            message: freire(texts.OOPS),
            description: freire(texts.ERROR_STATE_CITY),
          });
          return;
        }
      }

      if (params.company_id) {
        const selectedCompany = companies?.find(
          (company) => company._id === params.company_id
        );

        params.parent_company_id = selectedCompany?.parentCompanyID;
        params.currency = selectedCompany?.currency;
        params.language = selectedCompany?.language;
      }

      if (params.country) {
        params.country_code = getCountryCode(params.country);
      }

      onSave(params);
    });
  };

  const { getFieldDecorator } = form;
  const requiredFieldRule = {
    required: true,
    message: freire(texts.REQUIRED_FIELD),
  };

  return (
    <Drawer
      title={freire(texts.EDIT_LOCATION)}
      width={500}
      onClose={closeDrawer}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Spin spinning={loading} />
        </div>
      )}
      {!loading && location && (
        <>
          <Col span={20}>
            <Form wrapperCol={{ span: 24 }}>
              <Row gutter={[16, 0]}>
                <Col span={24}>
                  <Form.Item label={freire(texts.NAME)}>
                    {getFieldDecorator("name", {
                      rules: [requiredFieldRule],
                    })(<Input placeholder={freire(texts.NAME)} />)}
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <SelectCountry
                    form={form}
                    label={texts.COUNTRY}
                    disabled
                    initialValue={location?.country}
                    showWarn={false}
                    onSelect={onSelectCountry}
                  />
                </Col>
                <Col span={6}>
                  <Form.Item label={freire(texts.LATITUDE)}>
                    {getFieldDecorator("coordinates.latitude", {
                      rules: [requiredFieldRule],
                    })(
                      <Input
                        onBlur={(e) => handleLatLong(e.target.value)}
                        style={{ width: "100%" }}
                        placeholder={freire(texts.LATITUDE_EX)}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label={freire(texts.LONGITUDE)}>
                    {getFieldDecorator("coordinates.longitude", {
                      rules: [requiredFieldRule],
                    })(
                      <Input
                        style={{ width: "100%" }}
                        placeholder={freire(texts.LATITUDE_EX)}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label={freire(texts.ADDRESS)}>
                    {getFieldDecorator("address")(
                      <Input placeholder={freire(texts.ADDRESS)} />
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <SelectLocation
                  form={form}
                  required={true}
                  countryCode={selectedCountry}
                  currentLocation={location?.address2}
                />
              </Row>

              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <Form.Item label={freire(texts.PHONE)}>
                    {getFieldDecorator("phone")(
                      <Input placeholder={freire(texts.PHONE)} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label={freire(texts.PRIVATE_LOCATION_QUESTION)}>
                    {getFieldDecorator("private", {
                      valuePropName: "checked",
                    })(
                      <Switch
                        checkedChildren={freire(texts.YES)}
                        unCheckedChildren={freire(texts.NO)}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label={freire(texts.FREE_PARKING_QUESTION)}>
                    {getFieldDecorator("freeParking", {
                      valuePropName: "checked",
                    })(
                      <Switch
                        checkedChildren={freire(texts.YES)}
                        unCheckedChildren={freire(texts.NO)}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label={freire(texts.COMPANY)}
                    extra={freire(texts.SELECT_COMPANY_WARN)}
                  >
                    {getFieldDecorator("company_id")(
                      <Select
                        placeholder={freire(texts.SELECT_COMPANY)}
                        showSearch
                        optionFilterProp="children"
                        disabled={loading}
                      >
                        {companies &&
                          companies.map((company) => (
                            <Select.Option
                              disabled={company.country !== selectedCountry}
                              key={company._id}
                              value={company._id}
                            >
                              {company.companyName}
                            </Select.Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
          <div className="footer">
            <Button loading={loading} onClick={onClickSave} type="primary">
              {freire(texts.SAVE)}
            </Button>
          </div>
        </>
      )}
    </Drawer>
  );
};

const ComponentEditDrawer = Form.create<IEditLocationDrawerProps>({
  name: "edit",
})(EditDrawer);
export default ComponentEditDrawer;
