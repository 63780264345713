import React from "react";
import { Table } from "antd";

export const paginationInitConfig = {
  page: 1,
  limit: 10,
  // orderBy: "_id",
  // sort: -1,
};

const TablePagination = ({
  pageSizeOptions = ["10", "25", "50", "100"],
  bodyStyle,
  ...props
}) => {
  // const [paginationConfig, setPaginationConfig] = useState({});

  const { overflowX = "auto", ..._bodyStyle } = bodyStyle ?? {};

  const onChangeTable = (pagination, _, sorter, __) => {
    const paginationAux = {
      // ...paginationConfig,
      page: pagination.current,
      limit: pagination.pageSize,
      // orderBy: sorter.order ? sorter.columnKey : undefined,
      // sort: sorter.order
      //   ? sorter.order === "descend"
      //     ? "desc"
      //     : "asc"
      //   : undefined,
    };

    // setPaginationConfig(paginationAux);
    props.onPagination?.(paginationAux);
  };

  return (
    <Table
      {...props}
      bodyStyle={{ overflowX, ..._bodyStyle }}
      pagination={{
        showSizeChanger: true,
        total: props.total,
        pageSizeOptions,
        defaultCurrent: paginationInitConfig.page,
        defaultPageSize: paginationInitConfig.limit,
        // TODO: translate
        showTotal: (total, range) => (
          <span>
            {range[0]}-{range[1]} de <strong>{total}</strong> items
          </span>
        ),
        ...(props.current && { current: props.current }),
      }}
      onChange={onChangeTable}
    />
  );
};

export default TablePagination;
