import { Breadcrumb } from "antd";
import React, { forwardRef } from "react";
import slugify from "slugify";

const Breadcrumbs = forwardRef(({ breadcrumbs }: {breadcrumbs: string[]}, ref: any) => {
  return (
    <Breadcrumb ref={ref}>
      {breadcrumbs.map((breadcrumb) => (
        <Breadcrumb.Item key={slugify(breadcrumb, { lower: true })}>
          {breadcrumb}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
});

export default Breadcrumbs;
