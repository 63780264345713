import { Col, Form, Input, InputNumber, Row, Select } from "antd";
import React, { useEffect, useMemo } from "react";
import { useFreire } from "../../utils/freireContext";
import * as texts from "./locales";
import { formatters } from "../../utils/formatters";
import { formatarMoeda } from "../../components/FormatingUtils";

const { Option } = Select;

const TAX_MODEL_TYPES = ["Take rate", "Full service", "Licença de software"];

type CompanyFinancialFields = {
  contractedPlan?: string;
  monthlyFee?: number;
  numberChargers?: number;
  taxModel?: string;
  tupiRate?: number;
};

type Props = {
  form: any;
  plans: any[];
  country: any;
  fieldsToEdit?: CompanyFinancialFields;
  visibleDrawer?: boolean;
};

const FinancialForm: React.FC<Props> = ({
  form,
  plans,
  country,
  fieldsToEdit,
  visibleDrawer,
}) => {
  const { freire } = useFreire();

  const { getFieldDecorator, setFieldsValue } = form;

  useEffect(() => {
    if (fieldsToEdit && visibleDrawer) {
      setFieldsValue({
        contractedPlan: fieldsToEdit?.contractedPlan ?? undefined,
        monthlyFee: fieldsToEdit?.monthlyFee
          ? formatarMoeda(String(fieldsToEdit.monthlyFee))
          : undefined,
        numberChargers: fieldsToEdit?.numberChargers ?? undefined,
        taxModel: fieldsToEdit?.taxModel ?? undefined,
        tupiRate: fieldsToEdit?.tupiRate
          ? fieldsToEdit.tupiRate * 100
          : undefined,
      } as CompanyFinancialFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleDrawer]);

  const currencySymbol = country
    ? formatters.currency(freire.userLanguage, country.currency)(null)
    : "";

  const getFormattedCurrency = (value: any, hideCurrency = false) => {
    if (!country) return "";

    const formattedValue = formatters.currency(
      freire.userLanguage,
      country.currency
    )(value);

    if (hideCurrency) {
      return formattedValue.replace(currencySymbol, "").trim();
    }

    return formattedValue;
  };

  const totalMonthlyAmount = useMemo(() => {
    const monthlyFee =
      Number(form.getFieldValue("monthlyFee")?.replace(/[.,]/g, "") ?? 0) / 100;

    const numberChargersValue = form.getFieldValue("numberChargers") ?? "0";
    const numberChargers = /^\d+$/.test(numberChargersValue)
      ? Number(numberChargersValue)
      : 0;

    return monthlyFee * numberChargers;
  }, [form]);

  const onChangeContractedPlan = (contractedPlan: string) => {
    const selectedPlan = plans.find((plan) => plan.name === contractedPlan);

    if (selectedPlan) {
      setFieldsValue({
        monthlyFee: getFormattedCurrency(selectedPlan.price, true),
      });
    }
  };

  return (
    <>
      <span className="titleNewCompanyEdt">{freire(texts.FINANCIAL)}</span>

      <Row gutter={[16, 0]}>
        <Col span={12}>
          <Form.Item label={freire(texts.CONTRACTED_PLAN)}>
            {getFieldDecorator("contractedPlan", {
              onChange: onChangeContractedPlan,
            })(
              <Select placeholder={freire(texts.PLACEHOLDER_CONTRACTED_PLAN)}>
                {plans.map((plan) => (
                  <Option key={plan._id} value={plan.name}>
                    {plan.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={freire(texts.MONTHLY_FEE(currencySymbol))}>
            {getFieldDecorator("monthlyFee", {
              normalize: (input: string) => {
                return input ? formatarMoeda(String(input)) : "0,00";
              },
            })(<Input style={{ color: "#555" }} />)}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <Form.Item label={freire(texts.NUMBER_CHARGERS)}>
            {getFieldDecorator("numberChargers")(
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Ex.: 2"
                min={0}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={freire(texts.TOTAL_MONTHLY_AMOUNT(currencySymbol))}>
            <Input
              prefix={getFormattedCurrency(totalMonthlyAmount, true)}
              disabled
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <Form.Item label={freire(texts.TAX_MODEL)}>
            {getFieldDecorator("taxModel")(
              <Select placeholder={freire(texts.PLACEHOLDER_TAX_MODEL)}>
                {TAX_MODEL_TYPES.map((taxModelName) => (
                  <Option key={taxModelName} value={taxModelName}>
                    {taxModelName}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={freire(texts.TUPI_RATE)}>
            {getFieldDecorator("tupiRate")(
              <InputNumber
                precision={2}
                style={{ width: "100%" }}
                min={0}
                max={100}
                step={0.1}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default FinancialForm;
