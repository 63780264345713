export const ALL_RIGHT = {
  pt: "Tudo certo!",
  es: "¡Muy bien!",
  en: "All set!",
};

export const GROUP_EDITED_SUCCESS = {
  pt: "Grupo editado com sucesso!",
  es: "¡Grupo editado con éxito!",
  en: "Group successfully edited!",
};

export const GROUP_CREATED_SUCCESS = {
  pt: "Grupo criado com sucesso!",
  es: "¡Grupo creado con éxito!",
  en: "Group successfully created!",
};

export const OOPS = {
  pt: "Ops...",
  es: "Epa...",
  en: "Ops...",
};

export const WENT_WONG = {
  pt: "Algo deu errado!",
  es: "¡Algo salió mal!",
  en: "Something went wrong!",
};

export const NEW_REPORT_GROUP = {
  pt: "Novo grupo de relatórios",
  es: "Nuevo grupo de informes",
  en: "New report group",
};

export const CREATE_REPORT_GROUP = {
  pt: "Crie um grupo de relatórios",
  es: "Crear un grupo de informes",
  en: "Create a report group",
};

export const ERROR_EDIT_GROUP = {
  pt: "Erro ao editar grupo!",
  es: "¡Error al editar el grupo!",
  en: "Error editing group!",
};

export const GROUP_DELETED_SUCCESS = {
  pt: "Grupo deletado com sucesso!",
  es: "¡Grupo eliminado con éxito!",
  en: "Group successfully deleted!",
};

export const ERROR_GROUP_DELETE = {
  pt: "Erro ao deletar grupo",
  es: "Error al eliminar el grupo",
  en: "Error deleting group",
};

export const ERROR_GET_REPORT_GROUPS = {
  pt: "Erro ao buscar grupos de relatórios",
  es: "Error al buscar grupos de informes",
  en: "Error search report groups",
};

export const ADMIN = {
  pt: "Admin",
  es: "Administración",
  en: "Admin",
};

export const GROUPS = {
  pt: "Grupos",
  es: "Grupos",
  en: "Groups",
};

export const LISTING_REPORTS_GROUPS = {
  pt: "Listagem - Grupos de relatórios",
  es: "Lista - Grupos de informes",
  en: "Listing - Report Groups",
};

export const SEARCH_BY_GROUP_NAME = {
  pt: "Pesquise pelo nome do grupo...",
  es: "Busque por el nombre del grupo...",
  en: "Search by group name...",
};

export const SEARCH = {
  pt: "Pesquisar",
  es: "Buscar",
  en: "Search by group name",
};

export const NEW = {
  pt: "Novo",
  es: "Nuevo",
  en: "New report group",
};

export const CLEAR = {
  pt: "Limpar",
  es: "Borrar",
  en: "Clear",
};

export const FILTER = {
  pt: "Filtrar",
  es: "Filtro",
  en: "Filter",
};

export const GROUP = {
  pt: "Grupo",
  es: "Grupo",
  en: "Group",
};

export const EMAIL = {
  pt: "E-mails",
  es: "Correos electrónicos",
  en: "E-mails",
};

export const STATIONS = {
  pt: "Estações",
  es: "Estaciones",
  en: "Stations",
};

export const SELECT_ALL = (number) => ({
  pt: `Selecionar todas (${number})`,
  es: `Seleccionar todos (${number})`,
  en: `Select all (${number})`,
});

export const SAVE = {
  pt: "Salvar",
  es: "Guardar",
  en: "Save",
};

export const CANCEL = {
  pt: "Cancelar",
  es: "Cancelar",
  en: "Cancel",
};

export const SELECT_STATIONS = {
  pt: "Selecione as estações",
  es: "Seleccione las estaciones",
  en: "Select the stations",
};

export const ACTIONS = {
  pt: "Ações",
  es: "Acciones",
  en: "Actions",
};

export const SHOW_DETAILS_EDIT = {
  pt: "Ver detalhes | Editar",
  es: "Ver detalles | Editar",
  en: "See details | Edit",
};

export const DELETE = {
  pt: "Deletar",
  es: "Borrar",
  en: "Delete",
};

export const CONFIRM_DELETE = {
  pt: "Deseja realmente DELETAR?",
  es: "¿Realmente quieres BORRAR?",
  en: "Do you really want to DELETE?",
};

export const YES = {
  pt: "Sim",
  es: "Sí",
  en: "Yes",
};

export const NO = {
  pt: "Não",
  es: "No",
  en: "No",
};

export const ERROR_GET_STATIONS = {
  pt: "Erro ao buscar as estações",
  es: "Error en la búsqueda de estaciones",
  en: "Error searching stations",
};

export const GROUP_NAME = {
  pt: "Nome do Grupo",
  es: "Nombre del grupo",
  en: "Group Name",
};

export const GROUP_NAME_EX = {
  pt: "Ex.: Condomínio Abc",
  es: "p.ej.: Condominio Abc",
  en: "Example: Condomínio Abc",
};

export const REQUIRED_FIELD = {
  pt: "Campo obrigatório",
  es: "Campo obligatorio",
  en: "Mandatory field",
};

export const EMAIL_LIST = {
  pt: "Lista de e-mail",
  es: "Lista de correo electrónico",
  en: "E-mail list",
};

export const INPUT_EMAILS = {
  pt: "Digite os e-mails",
  es: "Escriba los correos electrónicos",
  en: "Type the e-mails",
};

export const INFORM_VALID_EMAIL = {
  pt: "Informe um e-mail válido",
  es: "Introduzca un correo electrónico válido",
  en: "Inform a valid e-mail",
};

export const EDIT_GROUP = {
  pt: "Editar Grupo",
  es: "Editar grupo",
  en: "Edit Group",
};

export const INFO = {
  pt: "Informações",
  es: "Información",
  en: "Information",
};
