import CountryFlag from "../../../../components/SelectCountry/CountryFlag";
import { Country as ICountry } from "../../../../interfaces/country";
import { ICompany } from "../../../../interfaces/company";
import * as texts from "../../locales";
import { Button, Icon, Tooltip, Popconfirm } from "antd";

interface IColumnsProps {
  companies: ICompany[];
  countries: ICountry[];
  freire: any;
  openStationsDrawer: (data: any) => void;
  openLocationEditDrawer: (data: any) => void;
  onDeleteLocation: (data: any) => void;
}

export default function getColumns({
  companies,
  countries,
  freire,
  openStationsDrawer,
  openLocationEditDrawer,
  onDeleteLocation,
}: IColumnsProps) {
  const RenderCompany = (companyId: string) => {
    return (
      companies?.find((company) => company._id === companyId)?.companyName ??
      "----"
    );
  };

  const RenderCountry = (country_code: string) => {
    const country = countries?.find((country) => country.code === country_code);
    return country ? <CountryFlag country={country} /> : "----";
  };

  return [
    {
      title: freire(texts.NAME),
      dataIndex: "name",
      key: "name",
    },
    {
      title: freire(texts.COMPANY),
      dataIndex: "company_id",
      key: "company_id",
      render: RenderCompany,
    },
    {
      title: freire(texts.ADDRESS),
      dataIndex: "address",
      key: "address",
    },
    {
      title: freire(texts.COUNTRY),
      dataIndex: "country",
      key: "country",
      render: RenderCountry,
    },
    {
      title: freire(texts.ACTIONS),
      key: "acoes",
      width: "10%",
      render: (text: any, data: any) => (
        <>
          <Tooltip placement="top" title={freire(texts.SEE_STATIONS)}>
            <Button
              shape="circle"
              size="small"
              ghost
              type="primary"
              style={{ marginRight: 8 }}
              onClick={() => openStationsDrawer(data)}
            >
              <Icon type="eye" />
            </Button>
          </Tooltip>

          <Tooltip placement="top" title={freire(texts.EDIT_LOCATION)}>
            <Button
              shape="circle"
              size="small"
              ghost
              type="primary"
              style={{ marginRight: 8 }}
              onClick={() => openLocationEditDrawer(data)}
            >
              <Icon type="edit" />
            </Button>
          </Tooltip>

          <Tooltip placement="top" title={freire(texts.DELETE_LOCATION)}>
            <Popconfirm
              placement="top"
              title={freire(texts.QUESTION_DELETE)}
              okText={freire(texts.YES)}
              cancelText={freire(texts.NO)}
              onConfirm={() => onDeleteLocation(data)}
            >
              <Button
                shape="circle"
                size="small"
                ghost
                type="danger"
                style={{ marginRight: 8 }}
              >
                <Icon type="delete" />
              </Button>
            </Popconfirm>
          </Tooltip>
        </>
      ),
    },
  ];
}
