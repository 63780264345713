import React, { useMemo, useRef } from "react";
import { ConfigProvider } from "antd";
import Routes from "./routes";

import { AccessProvider } from "./contexts/PermissionContext";

import moment from "moment-timezone";
import "moment/locale/pt-br";
// import "moment/locale/en-us";
import "moment/locale/es";
import { useForceUpdate } from "./hooks/force-update";
import Freire from "./utils/freire";
import { FreireContext } from "./utils/freireContext";
import { currentLanguage } from "./utils/current-language";
import { DEFAULT_LANGUAGE, LANGUAGES } from "./config/languages";

const LOCAL_STORAGE_LANGUAGE_KEY = "language";

const navigatorLanguage = navigator?.language ?? navigator.userLanguage;
const getLanguageCode = (locale) => locale?.match(/^[a-z]+/i)?.[0];

const hasOwnProperty = (object, key) =>
  Object.prototype.hasOwnProperty.call(object, key);

const getLanguage = (locale) => {
  if (hasOwnProperty(LANGUAGES, locale)) {
    return locale;
  }

  const languageCode = getLanguageCode(locale);
  if (hasOwnProperty(LANGUAGES, languageCode)) {
    return languageCode;
  }

  return DEFAULT_LANGUAGE;
};

const App = () => {
  const forceUpdate = useForceUpdate();

  const firstLanguageChange = useRef(true);

  const onLanguageChange = (freire) => {
    const languageCode = getLanguage(freire.userLanguage);
    currentLanguage.ref = languageCode;

    const language = LANGUAGES[languageCode];
    moment.locale(language.moment);
    forceUpdate();
    if (firstLanguageChange.current) {
      firstLanguageChange.current = false;
    } else {
      localStorage.setItem(LOCAL_STORAGE_LANGUAGE_KEY, freire.userLanguage);
    }
  };

  const storedLanguage = localStorage.getItem(LOCAL_STORAGE_LANGUAGE_KEY);

  const freire = useMemo(
    () => {
      const userLanguage = storedLanguage ?? navigatorLanguage;
      return Freire(userLanguage, DEFAULT_LANGUAGE, onLanguageChange);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const languageCode = getLanguage(freire.userLanguage);
  const language = LANGUAGES[languageCode];

  return (
    <FreireContext.Provider value={{ freire }}>
      <ConfigProvider locale={language.antd}>
        <AccessProvider>
          <Routes />
        </AccessProvider>
      </ConfigProvider>
    </FreireContext.Provider>
  );
};

export default App;
