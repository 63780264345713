import React, { useState } from "react";
import {
  Button,
  Select,
  Input,
  Form,
  Col,
  Row,
  Icon,
  Steps,
  notification,
  message,
  TreeSelect,
} from "antd";
import { withRouter } from "react-router-dom";
import { validate as isValidEmail } from "email-validator";
import api from "../../../services/api";
import "./index.css";
import { getTreeData } from "../../../services/utils";
import { useFreire } from "../../../utils/freireContext";
import * as texts from "../locales";
const { Step } = Steps;

const NewAlertGroup = ({ form, history }) => {
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingStations, setLoadingStations] = useState(false);
  const [stations, setStations] = useState([]);
  const { freire } = useFreire();

  async function getStations() {
    try {
      setLoadingStations(true);
      setStations([]);

      const { data } = await api.get(`select/connected_stations`, {
        params: {
          disabled: false,
          test: false,
          centralSystem: true,
        },
      });

      setLoadingStations(false);
      setStations(data);
    } catch (error) {
      message.error(freire(texts.ERROR_GET_STATIONS));
      setLoadingStations(false);
    }
  }

  const save = () => {
    form.validateFields(async (err, payload) => {
      if (err) {
        return;
      }

      try {
        setLoadingSave(true);

        await api.post("alert_groups", {
          ...payload,
          phones: payload.phones ?? [],
        });

        notification.success({
          message: freire(texts.ALL_RIGHT),
          description: freire(texts.GROUP_CREATED_SUCCESS),
        });
        setLoadingSave(false);

        history.push("/dashboard/ocpp/alert_groups");
      } catch (error) {
        setLoadingSave(false);

        const {
          response: {
            data: { message },
          },
        } = error;

        notification.error({
          message: freire(texts.WENT_WONG),
          description: message || error.message,
        });
      }
    });
  };

  return (
    <div className="container">
      <div className="content">
        <Row gutter={[16, 0]}>
          <Col span={6}>
            <Steps current={1} size="small" direction="vertical">
              <Step
                icon={<Icon type="profile" />}
                title={freire(texts.NEW_ALERT_GROUP)}
                description={freire(texts.CREATE_ALERT_GROUP)}
              />
            </Steps>
          </Col>

          <Col span={18}>
            <Form wrapperCol={{ span: 24 }}>
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <Form.Item label={freire(texts.GROUP_NAME)}>
                    {form.getFieldDecorator("name", {
                      rules: [
                        {
                          required: true,
                          message: freire(texts.REQUIRED_FIELD),
                        },
                      ],
                    })(
                      <Input
                        disabled={loadingSave}
                        placeholder={freire(texts.GROUP_NAME_EX)}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={freire(texts.EMAIL_LIST)}>
                    {form.getFieldDecorator("emails", {
                      rules: [
                        {
                          required: true,
                          message: freire(texts.REQUIRED_FIELD),
                        },
                      ],
                    })(
                      <Select
                        mode="tags"
                        disabled={loadingSave}
                        style={{ width: "100%" }}
                        placeholder={freire(texts.INPUT_EMAILS)}
                        onSelect={(value) => {
                          if (!isValidEmail(value)) {
                            message.warning(freire(texts.INFORM_VALID_EMAIL));
                            const emails = form.getFieldValue("emails");

                            const index = emails.indexOf(value);
                            if (index > -1) {
                              emails.splice(index, 1);
                              form.setFieldsValue({ emails });
                            }
                          }
                        }}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={freire(texts.PHONES_LIST)}>
                    {form.getFieldDecorator("phones", {
                      // rules: [
                      //   {
                      //     required: true,
                      //     message: freire(texts.REQUIRED_FIELD),
                      //   },
                      // ],
                    })(
                      <Select
                        mode="tags"
                        disabled={loadingSave}
                        style={{ width: "100%" }}
                        placeholder={freire(texts.INPUT_PHONES)}
                        onSelect={(value) => {
                          const isValid = (value) => value.length > 0;
                          if (!isValid(value)) {
                            message.warning(freire(texts.IINFORM_VALID_PHONE));
                            const phones = form.getFieldValue("phones");

                            const index = phones.indexOf(value);
                            if (index > -1) {
                              phones.splice(index, 1);
                              form.setFieldsValue({ phones });
                            }
                          }
                        }}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label={freire(texts.STATIONS)}>
                    {form.getFieldDecorator("stations", {
                      rules: [
                        {
                          required: true,
                          message: freire(texts.REQUIRED_FIELD),
                        },
                      ],
                    })(
                      <TreeSelect
                        multiple
                        treeCheckable
                        treeNodeFilterProp="title"
                        showCheckedStrategy={TreeSelect.SHOW_CHILD}
                        disabled={loadingSave || loadingStations}
                        searchPlaceholder={freire(texts.SELECT_STATIONS)}
                        dropdownStyle={{ maxHeight: 220 }}
                        treeData={getTreeData({
                          title: freire(texts.SELECT_ALL(stations.length)),
                          loading: loadingStations,
                          children: stations.map((station) => ({
                            title: `${station.name} (${station.stationID})`,
                            key: station._id,
                            value: station.stationID,
                          })),
                        })}
                        onDropdownVisibleChange={(opened) => {
                          if (opened && stations.length === 0) {
                            getStations();
                          }
                        }}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>

            <div className="footer">
              <Button
                style={{ marginRight: 20 }}
                onClick={() => history.push("/dashboard/ocpp/alert_groups")}
                type="default"
                disabled={loadingSave}
              >
                {freire(texts.CANCEL)}
              </Button>
              <Button loading={loadingSave} onClick={save} type="primary">
                {freire(texts.SAVE)}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const PageNewAlertGroup = Form.create({ name: "inputs" })(NewAlertGroup);
export default withRouter(PageNewAlertGroup);
