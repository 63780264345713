import { Col, Icon, Popover, Row, Tag } from "antd";
import { Copy } from "../Copy";
import { Show } from "../../contexts/PermissionContext";

const PopoverWhen = ({ children, fallback, tooltip, tooltipWhen }) => {
  const content = <Popover content={tooltip}>{children}</Popover>;

  return tooltipWhen ? (
    <Show when={tooltipWhen} fallback={fallback}>
      {content}
    </Show>
  ) : (
    content
  );
};

const PopoverContent = ({
  allowCopy,
  hasTooltip,
  tooltip,
  tooltipWhen,
  value,
}) => {
  const content = allowCopy ? (
    <Copy>{value}</Copy>
  ) : (
    <span>{value ?? "----"}</span>
  );

  return !allowCopy && hasTooltip ? (
    <PopoverWhen fallback={content} tooltip={tooltip} tooltipWhen={tooltipWhen}>
      {content}
    </PopoverWhen>
  ) : (
    content
  );
};

const ExtraPopover = ({ tooltip, tooltipWhen }) => (
  <PopoverWhen tooltip={tooltip} tooltipWhen={tooltipWhen}>
    <Tag className="paymentStatusTag" style={{ marginRight: -8 }}>
      <Icon
        type="info"
        style={{ color: "#909090", marginRight: -3, marginLeft: -3 }}
      />
    </Tag>
  </PopoverWhen>
);

const InfoRow = ({
  label,
  value,
  tooltip = null,
  tooltipWhen = null,
  bold = true,
  bordered = true,
  extraItems,
  last = false,
  noBorder = last,
  labelSpan = 8,
  valueSpan = 16,
  allowCopy = false,
}) => {
  const hasTooltip = tooltip !== null;

  return (
    // TODO: resolver com css
    <Row
      style={{
        ...(last ? styles.infoRowLast : styles.infoRow),
        ...(!noBorder && {
          borderBottom: bordered ? "1px solid #E2E2E2" : "none",
        }),
      }}
    >
      <Col span={labelSpan}>
        <span>{label}</span>
      </Col>
      <Col
        span={valueSpan}
        style={{
          fontWeight: bold ? "bold" : "normal",
        }}
      >
        <div style={styles.row}>
          <PopoverContent
            allowCopy={allowCopy}
            hasTooltip={hasTooltip}
            tooltip={tooltip}
            tooltipWhen={tooltipWhen}
            value={value}
          />

          {allowCopy && hasTooltip && (
            <ExtraPopover tooltip={tooltip} tooltipWhen={tooltipWhen} />
          )}

          {extraItems}
        </div>
      </Col>
    </Row>
  );
};

const styles = {
  infoRow: {
    display: "flex",
    alignItems: "center",
    padding: "8px 0",
  },
  infoRowLast: {
    display: "flex",
    alignItems: "center",
    padding: "8px 0 0",
    marginBottom: -8,
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
};

export default InfoRow;
