import React, { Component } from "react";
import { Button, Input, Form, Col, Row, Icon, Steps, notification } from "antd";
import { withRouter } from "react-router-dom";
import api from "../../../services/api";
import "./index.css";
import { FreireContext } from "../../../utils/freireContext";
import * as texts from "../locales";
const { Step } = Steps;

class NewPlug extends Component {
  static contextType = FreireContext;

  state = {
    loadingSave: false,
  };

  save = () => {
    const { freire } = this.context;

    this.props.form.validateFields(async (err, payload) => {
      if (err) {
        return;
      }

      try {
        this.setState({ loadingSave: true });

        await api.post("plugs", payload);

        notification.success({
          message: freire(texts.ALL_RIGHT),
          description: freire(texts.PLUG_CREATED_SUCCESS),
        });
        this.setState({ loadingSave: false });

        this.props.history.push("/dashboard/plugs");
      } catch (error) {
        this.setState({ loadingSave: false });

        const {
          response: {
            data: { message },
          },
        } = error;

        notification.error({
          message: freire(texts.WENT_WONG),
          description: message || error.message,
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { loadingSave } = this.state;
    const { freire } = this.context;

    return (
      <div className="container">
        <div className="content">
          <Row gutter={[16, 0]}>
            <Col span={6}>
              <Steps current={1} size="small" direction="vertical">
                <Step
                  icon={<Icon type="profile" />}
                  title={freire(texts.NEW_PLUG)}
                  description={freire(texts.NEW_PLUG_DESCRIPTION)}
                />
              </Steps>
            </Col>

            <Col span={18}>
              <Form wrapperCol={{ span: 24 }}>
                <Row gutter={[16, 0]}>
                  <Col span={12}>
                    <Form.Item label={freire(texts.PLUG_NAME)}>
                      {getFieldDecorator("name", {
                        rules: [
                          {
                            required: true,
                            message: freire(texts.REQUIRED_FIELD),
                          },
                        ],
                      })(
                        <Input
                          disabled={loadingSave}
                          placeholder={freire(texts.PLUG_NAME_EX)}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={freire(texts.ICON)}>
                      {getFieldDecorator("icon", {
                        rules: [
                          {
                            required: true,
                            message: freire(texts.REQUIRED_FIELD),
                          },
                        ],
                      })(
                        <Input
                          disabled={loadingSave}
                          placeholder={freire(texts.ICON_EXAMPLE)}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Form>

              <div className="footer">
                <Button
                  style={{ marginRight: 20 }}
                  onClick={() => this.props.history.push("/dashboard/plugs")}
                  type="default"
                  disabled={loadingSave}
                >
                  {freire(texts.CANCEL)}
                </Button>
                <Button
                  loading={loadingSave}
                  onClick={this.save}
                  type="primary"
                >
                  {freire(texts.SAVE)}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const PageNewPlug = Form.create({ name: "inputs" })(NewPlug);
export default withRouter(PageNewPlug);
