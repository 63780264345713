import * as texts from "../locales";
import { useFreire } from "../../../utils/freireContext";
import "../index.css";
import Hour from "./Hour";
import {
  PrivateHourButtonProps,
  PrivateHourContainerProps,
  PublicHourContainerProps,
  SemiPublicHourProps,
} from "../interfaces";
import PrivateHour from "./PrivateHour";
import TextToolTip from "../../TextToolTip/index";
import { useContext } from "react";
import { BusinessHourContext } from "../index";
import { Button, Icon } from "antd";

export default function SemiPublicHours({ day, weekDay }: SemiPublicHourProps) {
  const { optional_hour } = day;
  const withoutIndexZeroOfOptionalHour = optional_hour![0].final === "";
  const withoutIndexOneOfOptionalHour = optional_hour![1].initial === "";

  return (
    <div className="semiPublicHoursContainer">
      {withoutIndexZeroOfOptionalHour ? (
        <PrivateHourButton weekDay={weekDay} hourIndex={0} />
      ) : (
        <PrivateHourContainer
          weekDay={weekDay}
          optional_hour={optional_hour![0]}
          hourIndex={0}
        />
      )}
      <PublicHourContainer weekDay={weekDay} day={day} />
      {withoutIndexOneOfOptionalHour ? (
        <PrivateHourButton weekDay={weekDay} hourIndex={1} />
      ) : (
        <PrivateHourContainer
          weekDay={weekDay}
          optional_hour={optional_hour![1]}
          hourIndex={1}
        />
      )}
    </div>
  );
}

const PrivateHourContainer = ({
  weekDay,
  optional_hour,
  hourIndex,
}: PrivateHourContainerProps) => {
  const { freire } = useFreire();

  return (
    <div className="privateHourContainer">
      <div className="privateHourRow">
        <span className="privateHourTitle">{freire(texts.PRIVATE)}</span>
        <>
          <TextToolTip
            title={freire(texts.PRIVATE_HOURS_TITLE)}
            icon="question-circle-o"
            info={[{ info: freire(texts.PRIVATE_HOURS_INFO) }]}
          />
        </>
      </div>
      <PrivateHour
        hour={optional_hour}
        weekDay={weekDay}
        hourIndex={hourIndex}
      />
    </div>
  );
};

const PrivateHourButton = ({ weekDay, hourIndex }: PrivateHourButtonProps) => {
  const { freire } = useFreire();
  const { loading, onAddNewOptionalHourInDay, disabled } =
    useContext(BusinessHourContext);

  return (
    <div className="privateHourContainer">
      <div className="privateHourRow">
        <span className="privateHourTitle">{freire(texts.PRIVATE)}</span>
        <>
          <TextToolTip
            title={freire(texts.PRIVATE_HOURS_TITLE)}
            icon="question-circle-o"
            info={[{ info: freire(texts.PRIVATE_HOURS_INFO) }]}
          />
        </>
      </div>
      <Button
        disabled={loading || disabled}
        className="buttonAddHour"
        onClick={() => onAddNewOptionalHourInDay(weekDay, hourIndex)}
      >
        <Icon type="plus" className="buttonAddHourIcon" />
        <span className="buttonAddHourText">
          {freire(texts.ADDITIONAL_HOUR)}
        </span>
      </Button>
    </div>
  );
};

const PublicHourContainer = ({ weekDay, day }: PublicHourContainerProps) => {
  const { freire } = useFreire();
  return (
    <div className="publicHourContainer">
      <div className="publicHourRow">
        <span className="publicHourTitle">{freire(texts.PUBLIC)}</span>
        <TextToolTip
          icon="question-circle-o"
          title={freire(texts.PUBLIC_HOURS_TITLE)}
          info={[{ info: freire(texts.PUBLIC_HOURS_INFO) }]}
        />
      </div>
      {day.hour.map((hour, index) => (
        <Hour
          key={index}
          hour={hour}
          weekDay={weekDay}
          hourIndex={index}
          hourLength={day.hour.length}
        />
      ))}
    </div>
  );
};
