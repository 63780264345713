export const FILTER = {
  pt: "Filtrar",
  es: "Filtrar",
  en: "Filter",
};

export const CLEAN = {
  pt: "Limpar",
  es: "Limpiar",
  en: "Clear",
};

export const SEARCH = {
  pt: "Pesquisar",
  es: "Buscar",
  en: "Search",
};

export const NEW = {
  pt: "Novo",
  es: "Nuevo",
  en: "New",
};

export const NAME = {
  pt: "Nome",
  es: "Nombre",
  en: "Name",
};

export const FILTER_BY = {
  pt: "Filtrar por",
  es: "Filtrar por",
  en: "Filter by",
};

export const FILTER_BY_NAME = {
  pt: "Filtre pelo nome",
  // TODO: translate en
  // TODO: translate es
};
