export const ERROR_GET_INFO_CARD = {
  pt: "Erro as buscar as informações do card",
  es: "Error al buscar las informaciones de la tarjeta",
  en: "Error looking for card information",
};
export const CONNECTED = {
  pt: "Conectadas",
  es: "Conectadas",
  en: "Connected",
};
export const TOTALS = {
  pt: "Totais",
  es: "Totales",
  en: "Total",
};
export const UPDATE = {
  pt: "Atualizar",
  es: "Actualizar",
  en: "Update",
};
export const UPKEEP = {
  pt: "Em manutenção",
  es: "En mantenimiento",
  en: "Under maintenance",
};

export const OPERATION = {
  pt: "OPERAÇÃO",
  es: "OPERACIÓN",
  en: "OPERATION",
};
export const OFFLINE = {
  pt: "OFFLINE",
  es: "OFFLINE",
  en: "OFFLINE",
};
export const STATUS = {
  pt: "STATUS",
  es: "ESTADO",
  en: "STATUS",
};
export const LOOKING_INFO = {
  pt: "Buscando as informações",
  es: "Buscando las informaciones	",
  en: "Looking for information",
};
