import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Drawer,
  Select,
  message,
  TreeSelect,
} from "antd";
import { withRouter } from "react-router-dom";
import { validate as isValidEmail } from "email-validator";
import api from "../../../services/api";
import { getTreeData } from "../../../services/utils";
import { useFreire } from "../../../utils/freireContext";
import * as texts from "../locales";

const EditDrawer = ({ form, onSave, visible, onClose, loading, group }) => {
  const [loadingStations, setLoadingStations] = useState(false);
  const [stations, setStations] = useState([]);
  const { freire } = useFreire();

  useEffect(() => {
    if (visible) {
      setStations([]);
      form.setFieldsValue(group);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  // componentDidUpdate(prevProps, prevState) {
  //   if (this.props.visible !== prevProps.visible) {
  //     if (this.props.visible) {
  //       this.setState({ stations: [] });
  //       this.props.form.setFieldsValue(this.props.group);
  //     }
  //   }
  // }

  async function getStations() {
    try {
      setLoadingStations(true);
      setStations([]);

      const { data } = await api.get(`select/connected_stations`, {
        params: {
          disabled: false,
          test: false,
          centralSystem: true,
        },
      });

      setLoadingStations(false);
      setStations(data);
    } catch (error) {
      message.error(freire(texts.ERROR_GET_STATIONS));
      setLoadingStations(false);
    }
  }

  function save() {
    form.validateFields(async (err, payload) => {
      if (err) {
        return;
      }

      onSave(payload);
    });
  }

  return (
    <Drawer
      title={freire(texts.EDIT_GROUP)}
      width={"40%"}
      onClose={() => (loading ? {} : onClose())}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label={freire(texts.GROUP_NAME)}>
              {form.getFieldDecorator("name", {
                rules: [
                  {
                    required: true,
                    message: freire(texts.REQUIRED_FIELD),
                  },
                ],
              })(
                <Input
                  disabled={loading}
                  placeholder={freire(texts.GROUP_NAME_EX)}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label={freire(texts.EMAIL_LISTING)}>
              {form.getFieldDecorator("emails", {
                rules: [
                  {
                    required: true,
                    message: freire(texts.REQUIRED_FIELD),
                  },
                ],
              })(
                <Select
                  mode="tags"
                  disabled={loading}
                  style={{ width: "100%" }}
                  placeholder={freire(texts.INPUT_EMAILS)}
                  onSelect={(value) => {
                    if (!isValidEmail(value)) {
                      message.warning(freire(texts.INFORM_VALID_EMAIL));
                      var emails = form.getFieldValue("emails");

                      const index = emails.indexOf(value);
                      if (index > -1) {
                        emails.splice(index, 1);
                        form.setFieldsValue({ emails });
                      }
                    }
                  }}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label={freire(texts.STATIONS)}>
              {form.getFieldDecorator("stations", {
                rules: [
                  {
                    required: true,
                    message: freire(texts.REQUIRED_FIELD),
                  },
                ],
              })(
                <TreeSelect
                  multiple
                  treeCheckable
                  treeNodeFilterProp="title"
                  showCheckedStrategy={TreeSelect.SHOW_CHILD}
                  disabled={loading || loadingStations}
                  searchPlaceholder={freire(texts.SELECT_STATIONS)}
                  dropdownStyle={{ maxHeight: 220 }}
                  treeData={getTreeData({
                    title: freire(texts.SELECT_ALL(stations.length)),
                    loading: loadingStations,
                    children: stations.map((station) => ({
                      title: `${station.name} (${station.stationID})`,
                      key: station._id,
                      value: station.stationID,
                    })),
                  })}
                  onDropdownVisibleChange={(opened) => {
                    if (opened && stations.length === 0) {
                      getStations();
                    }
                  }}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div
        style={{
          position: "absolute",
          right: 0,
          bottom: 0,
          width: "100%",
          borderTop: "1px solid #e9e9e9",
          padding: "10px 16px",
          background: "#fff",
          textAlign: "right",
        }}
      >
        <Button
          disabled={loading}
          onClick={() => onClose()}
          style={{ marginRight: 8 }}
        >
          {freire(texts.CANCEL)}
        </Button>
        <Button loading={loading} onClick={() => save()} type="primary">
          {freire(texts.SAVE)}
        </Button>
      </div>
    </Drawer>
  );
};

const ComponentEditDrawer = Form.create({ name: "edit" })(EditDrawer);
export default withRouter(ComponentEditDrawer);
