export const NAME = {
  pt: "Nome",
  en: "Name",
  es: "Nombre",
};

export const ALL_RIGHT = {
  pt: "Tudo certo",
  en: "All set",
  es: "De acuerdo.",
};

export const SUCCESS_DISABLE_COUPON = {
  pt: "Cupom expirado com sucesso!",
  en: "Coupon expired successfully!",
  es: "¡Cupón expirado con éxito!",
};

export const CAMPAIGN_EDITED_SUCCESS = {
  pt: "Campanha editada com sucesso!",
  en: "Successfully edited campaign!",
  es: "¡Campaña editada con éxito!",
};

export const ERROR_EDIT_CAMPAIGN = {
  pt: "Erro ao editar campanha",
  en: "Error editing the campaign",
  es: "Error en la edición de la campaña",
};

export const CAMPAIGN_DELETED_SUCCESS = {
  pt: "Campanha deletada com sucesso!",
  en: "Successfully deleted campaign!",
  es: "¡Campaña eliminada con éxito!",
};

export const ERROR_DELETE_CAMPAIGN = {
  pt: "Erro ao deletar campanha",
  en: "Error deleting the campaign",
  es: "Error al eliminar la campaña",
};

export const ERROR_GET_CAMPAIGN_RESUME = {
  pt: "Erro ao buscar o resumo da campanha",
  // TODO: translate en
  // TODO: translate es
};

export const ERROR_GET_CAMPAIGNS_COUPONS = {
  pt: "Erro ao buscar campanhas de cupons",
  en: "Error searching coupons campaign",
  es: "Error al buscar la campaña de cupones",
};

export const LISTING_CAMPAIGN_COUPONS = {
  pt: "Listagem - Campanha de cupons",
  en: "Listing - Coupon campaign",
  es: "Listado - Campaña de cupones",
};

export const ADMIN = {
  pt: "Admin",
  en: "Admin",
  es: "Administración",
};

export const COUPONS = {
  pt: "Cupons",
  en: "Coupon",
  es: "Cupones",
};

export const SEARCH_CAMPAIGN = {
  pt: "Nome da campanha",
  // TODO: translate en
  // TODO: translate es
};

export const SEARCH = {
  pt: "Pesquisar",
  en: "Search",
  es: "Buscar",
};

export const NEW = {
  pt: "Novo",
  en: "New",
  es: "Nuevo",
};

export const COMPANY = {
  pt: "Empresa",
  es: "Empresa",
  en: "Company",
};

export const COMPANIES = {
  pt: "Empresas",
  es: "Empresas",
  en: "Companies",
};

export const SELECT_COMPANY = {
  pt: "Selecione a empresa",
  en: "Select the company",
  es: "Seleccione la empresa",
};

export const SELECT_COMPANIES = {
  pt: "Selecione as empresas",
  es: "Seleccione las empresas",
  en: "Select companies",
};

export const COUPONS_CODE_TYPE = {
  pt: "Tipo dos cupons",
  en: "Coupons type",
  es: "Tipo de cupones",
};

export const SELECT_TYPE = {
  pt: "Selecione o tipo",
  en: "Select the type",
  es: "Seleccione el tipo",
};

export const EQUALS = {
  pt: "Iguais",
  en: "Equals",
  es: "Iguales",
};

export const DIFFERENT = {
  pt: "Diferentes",
  en: "Differents",
  es: "Diferentes",
};

export const ACTIVED = {
  pt: "Ativada",
  en: "Enabled",
  es: "Activado",
};

export const YES = {
  pt: "Sim",
  en: "Yes",
  es: "Sí",
};

export const FOR = {
  pt: "por",
  es: "por",
  en: "per",
};

export const EMPTY = {
  pt: "---",
};

export const NO = {
  pt: "Não",
  en: "No",
  es: "No",
};

export const CLEAN = {
  pt: "Limpar",
  en: "Clear",
  es: "Limpiar",
};

export const CAMPAIGN = {
  pt: "Campanha",
  en: "Campaign",
  es: "Campaña",
};

export const FILTER = {
  pt: "Filtrar",
  en: "Filter",
  es: "Filtro",
};

export const DOWNLOAD_CSV = {
  pt: "Baixar em formato Excel",
  // TODO: translate es
  // TODO: translate en
};

export const DOWNLOAD_CAMPAIGNS_CSV = {
  pt: "Baixar campanhas em formato Excel",
  // TODO: translate es
  // TODO: translate en
};

export const CSV_FILE_NAME = (date) => ({
  pt: `Campanhas de cupom - ${date}.csv`,
  // TODO: translate es
  // TODO: translate en
});

export const CSV_FILE_NAME_CUPONS = (campaignName, date) => ({
  pt: `Cupons (${campaignName}) - ${date}.csv`,
  // TODO: translate es
  // TODO: translate en
});

export const LOADING_CSV = {
  pt: "Carregando",
  es: "Cargando",
  en: "Loading",
};

export const BENEFIT = {
  pt: "Benefício",
  en: "Benefit",
  es: "Beneficio",
};

export const QUANTITY = {
  pt: "Quantidade",
  // TODO: translate en
  // TODO: translate es
};

export const MAX_QUANTITY = {
  pt: "Quantidade máxima",
  en: "Maximum quantity",
  es: "Cantidad máxima",
};

export const QTD_COUPONS = {
  pt: "Quantidade de cupons",
  en: "Coupons quantity",
  es: "Número de cupones",
};

export const DEFINE_LIMIT_USE_COUPON = {
  pt: "Defina o limite por usuário:",
  // TODO: translate en
  // TODO: translate es
};

export const NUMBER_ACTIVE_COUPONS = {
  pt: "Ativos",
  // TODO: translate en
  // TODO: translate es
};

export const NUMBER_COUPON_USAGE = {
  pt: "Utilização",
  // TODO: translate en
  // TODO: translate es
};

export const USED_VS_TOTAL = {
  pt: <b>Utilizado vs total</b>,
  // TODO: translate en
  // TODO: translate es
};

export const LIMIT_USE = {
  pt: "Limite de uso",
  en: "Use limit",
  es: "Límite de uso",
};

export const NO_LIMIT = {
  pt: "Sem limite",
  en: "No limit",
  es: "Sin límite",
};

export const STATUS = {
  pt: "Status",
  en: "Status",
  es: "Estado",
};

export const CREATED_DATE_TIME = {
  pt: "Criação",
  en: "Creation",
  es: "Creación",
};

export const EXPIRATION = {
  pt: "Expiração",
  // TODO: translate en
  // TODO: translate es
};

export const EXPIRE_DATE_TIME = {
  pt: "Data/hora de expiração",
  en: "Expire date/time",
  es: "Fecha/hora de caducidad",
};

export const ACTIONS = {
  pt: <b>Ações</b>,
  en: "Actions",
  es: "Acciones",
};

export const EDIT = {
  pt: "Editar",
  en: "Edit",
  es: "Editar",
};

export const ADD_COUPONS = {
  pt: "Adicionar mais cupons",
  es: "Adicionar mais cupons",
  en: "Add more coupons",
};

export const OPTIONS_COUPONS = {
  pt: "Opções",
  es: "Opiciones",
  in: "Options",
};

export const EDIT_CAMPAIGN = {
  pt: "Editar campanha",
  en: "Edit campaign",
  es: "Editar campaña",
};

export const DUPLICATE_CAMPAIGN = {
  pt: "Duplicar campanha",
  es: "Duplicar campanha",
  en: "Duplicate campaign",
};

export const ERROR_GET_DETAILS_COUPON = {
  pt: "Erro ao adicionar mais cupons",
  es: "Erro ao adicionar mais cupons",
  en: "Error adding more coupons",
};

export const SHOW_LISTING_COUPONS = {
  pt: "Ver lista de cupons",
  en: "See coupons list",
  es: "Ver lista de cupones",
};

export const REQUIRED_FIELD = {
  pt: "Campo obrigatório",
  en: "Mandatory field",
  es: "Campo obligatorio",
};

export const EX_NAME_FIELD = {
  pt: "Ex.: Promoção de cupons de Natal",
  en: "Example: Promotion of Christmas coupons",
  es: "p.ej.: Promoción de cupones de Navidad",
};

export const INPUT_DATE_TIME_FUTURE = {
  pt: "Selecione data e hora no futuro",
  en: "Select date and time in the future",
  es: "Seleccione la fecha y hora en el futuro",
};

export const SELECT_DATE_TIME = {
  pt: "Selecione data e hora",
  en: "Select date and time",
  es: "Seleccione la fecha y hora",
};

export const STATUS_CAMPAIGN = {
  pt: "Campanha ativa?",
  // TODO: translate en
  // TODO: translate es
};

export const TIME_PERIOD = {
  pt: "Período de tempo",
  en: "Time period",
  es: "Periodo de tiempo",
};

export const CUPON_HAS_USAGE_LIMIT_PER_PERIOD = {
  pt: "Cupons têm limite de uso por período?",
  en: "Coupons have usage limit per period?",
  es: "Los cupones tienen límite de uso por período?",
};

export const FOR_EACH = {
  pt: "A cada",
  en: "Each",
  es: "A cada",
};

export const DISABLED = {
  pt: "Desativada",
  en: "Disabled",
  es: "Desactivado",
};

export const CANCEL = {
  pt: "Cancelar",
  en: "Cancel",
  es: "Cancelar",
};

export const CONTINUE = {
  pt: "Continuar",
  // TODO: translate en
  // TODO: translate es
};

export const SAVE = {
  pt: "Salvar",
  en: "Save",
  es: "Guardar",
};

export const CLOSE = {
  pt: "Fechar",
  en: "Clase",
  es: "Cerrar",
};

export const QTD_USED = {
  pt: "Qtd. usada",
  en: "Used quantity",
  es: "Cantidad usada",
};

export const EXPIRATION_DATE = {
  pt: "Expiração individual",
  en: "Individual expiration",
  es: "Caducación individual",
};
export const EXP_AT = {
  pt: "Data de expiração",
  en: "Expires at",
  es: "Expira en",
};

export const USER = {
  pt: "Usuário",
  en: "User",
  es: "Usuario",
};

export const EXPIRED = {
  pt: "Expirado",
  en: "Expired",
  es: "Expirado",
};

export const INACTIVE = {
  pt: "Inativo",
  // TODO: translate en
  // TODO: translate es
};
export const IN_USE = {
  pt: "Em uso",
  // TODO: translate en
  // TODO: translate es
};
export const AVAILABLE = {
  pt: "Livre",
  // TODO: translate en
  // TODO: translate es
};

export const VALID = {
  pt: "Válido",
  en: "Valid",
  es: "Válido",
};

export const VALIDATE = {
  pt: "Fechar",
  en: "Close",
  es: "Cerrar",
};

export const COD = {
  pt: "Código",
  en: "Code",
  es: "Código",
};

export const CREATED_CAMPAIGN_SUCCESSFULLY = {
  pt: "Campanha criada com sucesso!",
  en: "Successfully created campaign!",
  es: "¡Campaña creada con éxito!",
};

export const WENT_WRONG = {
  pt: "Algo deu errado!",
  en: "Something went wrong!",
  es: "¡Algo salió mal!",
};

export const NEW_CAMPAIGN = {
  pt: "Nova campanha",
  en: "New campaign",
  es: "Nueva campaña",
};

export const NEW_CAMPAIGN_DESCRIPTION = {
  pt: "Crie uma campanha de cupons",
  en: "Create a coupon campaign",
  es: "Crear una campaña de cupones",
};

export const USER_COMPANY = {
  pt: "Empresa do usuário logado",
  en: "Company of the logged user",
  es: "Empresa del usuario conectado",
};

export const CAMPAIGN_NAME = {
  pt: "Nome da campanha",
  en: "Campaign name",
  es: "Nombre de la campaña",
};

export const CAMPAIGN_NAME_EX = {
  pt: "Ex.: Promoção de cupons de Natal",
  en: "Example: Promotion of Christmas coupons",
  es: "p.ej.: Promoción de cupones de Navidad",
};

export const COD_TYPE = {
  pt: "Tipo de Código",
  en: "Code type",
  es: "Tipo de código",
};

export const INSERT_DATE_TIME = {
  pt: "Insira uma data/hora no futuro",
  en: "Enter a date/time in the future",
  es: "Introduzca una fecha/hora en el futuro",
};

export const COD_ERROR_LETTER = {
  pt: "* Apenas letras (A-Z) e números (0-9).",
  en: "* Only letters (A-Z) and numbers (0-9).",
  es: "* Sólo letras (A-Z) y números (0-9)",
};

export const COD_ERROR_MIN = {
  pt: "* Deve ser pelo menos 4 caracteres",
  en: "* Must be at least 4 characters",
  es: "* Debe tener al menos 4 caracteres",
};

export const QUESTION_LIMITE_USE_COUPON = {
  pt: "Limite de uso por usuário",
  // TODO: translate en
  // TODO: translate es
};

export const QUESTION_LIMITE_USE_COUPON_DESCRIPTION = {
  pt: "Se não houver limite, o usuário poderá utilizar o mesmo cupom quantas vezes quiser enquanto a campanha estiver válida.",
  // TODO: translate en
  // TODO: translate es
};

export const QUESTION_LIMITE_PERIOD_COUPON_DESCRIPTION = {
  pt: "Quantas vezes um usuário pode utilizar um cupom dentro de um determinado intervalo de tempo, como, por exemplo, 3 vezes por semana.",
  // TODO: translate en
  // TODO: translate es
};

export const DESCRIPTION_CREDIT = {
  pt: "Crédito",
  en: "Credit",
  es: "Crédito",
};

export const DISCOUNT_FIXED = {
  pt: "Fixo",
  en: "Fixed",
  es: "Fijo",
};

export const DESCRIPTION_DISCOUNT_PERCENT = {
  pt: "Percentual",
  es: "Porcentaje",
  en: "Percentage",
};

export const DESCRIPTION_FREE = {
  pt: "Grátis",
  // TODO: translate en
  // TODO: translate es
};

export const SELECT_BENEFIT = {
  pt: "Selecione o benefício",
  en: "Select the benefit",
  es: "Seleccione el beneficio",
};

export const PREFIX = {
  pt: "Prefixo",
  en: "Prefix",
  es: "Prefijo",
};

export const ENTER_PREFIX = {
  pt: "Digite o prefixo:",
  // TODO: translate en
  // TODO: translate es
};

export const VALUE_CREDIT = (currency) => ({
  pt: `Valor em crédito (${currency})`,
  // TODO: translate en
  // TODO: translate es
});

export const VALUE_FIXED = (currency) => ({
  pt: `Valor fixo (${currency})`,
  en: `Fixed value (${currency})`,
  es: `Valor fijo (${currency})`,
});

export const VALUE_PERCENT = {
  pt: "Percentual (%)",
  en: "Percentage (%)",
  es: "Porcentaje (%)",
};

export const DISCOUNT = (value) => ({
  pt: `Desconto de ${value}`,
  en: `Discount of ${value}`,
  es: `Descuento de ${value}`,
});

export const CREDIT = (value) => ({
  pt: `Crédito de ${value}`,
  // TODO: translate en
  // TODO: translate es
});

export const EX_PARAM = (value) => ({
  pt: `Ex.: ${value}`,
  en: `Ex.: ${value}`,
  es: `p.ej.: ${value}`,
});

export const DISCOUNT_VALUE = (value) => ({
  pt: `${value} de desconto`,
  en: `${value} of discount`,
  es: `${value} descuento`,
});

export const DISCOUNT_PERCENTAGE = (value) => ({
  pt: `${value} de desconto`,
  en: `${value} of discount`,
  es: `${value} descuento`,
});

export const FILE_CSV_COUPONS = (campaign) => ({
  pt: `Cupons - ${campaign || "Campanha"}.csv`,
  en: `Coupons - ${campaign || "Campanha"}.csv`,
  es: `Cupones - ${campaign || "Campaña"}.csv`,
});

export const ERROR_ADD_COUPON = {
  pt: "Erro ao adicionar cupom",
  en: "Error adding the coupon",
  es: "Error al agregar el cupón",
};

export const SUCCESS_ADD_COUPON = (numberCoupons) => ({
  pt: `${numberCoupons} cupons foram adicionados com sucesso!`,
  en: `${numberCoupons} coupons were successfully added!`,
  es: `${numberCoupons} cupones se han añadido correctamente!`,
});

export const ERROR_REMOVE_USER_COUPON = {
  pt: "Erro ao remover usuário do cupom",
  en: "Error removing coupon user",
  es: "Error al eliminar el usuario del cupón",
};

export const SUCCESS_REMOVE_USER_COUPON = {
  pt: "O usuário foi removido do cupom",
  // TODO: translate: en
  // TODO: translate: es
};

export const FILTER_COD = {
  pt: "Filtrar por código",
  en: "Filter by code",
  es: "Filtrar por código",
};

export const MESSAGE = {
  pt: "Mensagem da notificação",
  // TODO: translate en
  // TODO: translate es
};

export const MESSAGE_PLACEHOLDER = {
  pt: "Ex.: Confira o cupom disponibilizado em Perfil > Cupons.",
  // TODO: translate en
  // TODO: translate es
};

export const TITLE = {
  pt: "Titulo da notificação",
  // TODO: translate en
  // TODO: translate es
};

export const TITLE_PLACEHOLDER = {
  pt: "Ex.: Novo cupom adicionado",
  // TODO: translate en
  // TODO: translate es
};

export const REMOVE_USER_COUPON = {
  pt: "Remover usuário",
  es: "Eliminar usuario",
  en: "Remove User",
};

export const ADD_USER_TO_FREE_COUPON = {
  pt: "Vincular usuário",
  es: "Vincular usuario",
  en: "Add User",
};

export const DISABLE_COUPON = {
  pt: "Expirar cupom",
  en: "Disable coupon",
  es: "Expirar cupón",
};

export const REMOVE = {
  pt: "Remover",
  // TODO: translate es
  // TODO: translate en
};

export const HAVE_THEME = {
  pt: "Possui tema?",
  en: "Does it have a theme?",
  es: "¿Tiene tema?",
};

export const ALIAS_THEME = {
  pt: "Alias do tema ?",
  en: "Theme alias?",
  es: "¿Seudónimo del tema?",
};

export const CONFIRM_REMOVE_USER_COUPON = {
  pt: "Você tem certeza de que deseja remover este usuário deste cupom?",
  // TODO: translate en
  // TODO: translate es
};

export const ADD_COUPON = {
  pt: "Quantos novos cupons:",
  en: "How many new coupons: ",
  es: "Cuántos cupones nuevos:",
};

export const ADD_COUPON_QUANTITY = {
  pt: "Cupons em lote?",
  en: "Coupons in batch?",
  es: "Cupones en lote?",
};

export const ADD_COUPON_EX = {
  pt: "Exp.: XXXX-0000",
  en: "Exp.: XXXX-0000",
  es: "Exp: XXXX-0000",
};

export const ADD = {
  pt: "Adicionar",
  en: "Add",
  es: "Agregar",
};

export const EXCLUDED = {
  pt: "Excluido",
  en: "Excluded",
  es: "Excluido",
};

export const CAMPAIGN_INFO = {
  pt: "INFORMAÇÕES DA CAMPANHA",
  // TODO: translate en
  // TODO: translate es
};

export const CAMPAIGN_RULES = {
  pt: "REGRAS DA CAMPANHA",
  // TODO: translate en
  // TODO: translate es
};

export const QUESTION_COD_TYPE_DESCRIPTION = {
  pt: "Códigos iguais são úteis se o seu meio de divulgação é em massa e/ou a sua utilização for em diferentes estações.",
  // TODO: translate en
  // TODO: translate es
};

export const QUESTION_PREFIX_DESCRIPTION = {
  pt: "Serão adicionados ao prefixo 6 números e letras complementares",
  // TODO: translate en
  // TODO: translate es
};

export const QUESTION_MONTLY_RENEW = {
  pt: "A renovação será realizada no dia 1º de cada mês até a expiração da campanha",
};

export const MAINTAINING_RENEWAL = {
  pt: "Os códigos vinculados terão suas utilizações zeradas e disponibilizadas novamente a cada período até a expiração da campanha",
  // TODO: translate en
  // TODO: translate es
};

export const LIMITED = {
  pt: "Com limite",
  en: "With limit",
  es: "Con límite",
};

export const UNLIMITED = {
  pt: "Sem limite",
  en: "No limit",
  es: "Sin límite",
};

export const VALID_PLACES = {
  pt: "LOCAIS VÁLIDOS",
  // TODO: translate en
  // TODO: translate es
};

export const VALID_PLACES_DESCRIPTION = {
  pt: "Os cupons serão válidos nas suas empresas e estações.",
  // TODO: translate en
  // TODO: translate es
};

export const OTHER_INFO = {
  pt: "INFORMAÇÕES COMPLEMENTARES",
  // TODO: translate en
  // TODO: translate es
};

export const THEME = {
  pt: "Tema",
  // TODO: translate en
  // TODO: translate es
};

export const THEME_PLACEHOLDER = {
  pt: "Informe o alias do tema",
  // TODO: translate en
  // TODO: translate es
};

export const NOTIFICATION = {
  pt: "NOTIFICAÇÃO",
  // TODO: translate en
  // TODO: translate es
};

export const NOTIFICATION_DESCRIPTION = {
  pt: "Esse é o texto que aparecerá como notificação quando o usuário adicionar o cupom.",
  // TODO: translate en
  // TODO: translate es
};

export const STATIONS_LABEL = {
  pt: "Estações",
  es: "Estaciones",
  en: "Stations",
};

export const STATIONS_PLACEHOLDER = {
  pt: "Selecione pelo menos uma estação",
  es: "Seleccione al menos una estación",
  en: "Select at least one station",
};

export const ALL_STATIONS = {
  pt: "Todas as estações",
  es: "Todas las estaciones",
  en: "All stations",
};

export const SPECIFIC_STATIONS = {
  pt: "Selecionar estações específicas",
  es: "Seleccionar estaciones específicas",
  en: "Select specific stations",
};

export const ALL_COMPANIES_AND_STATIONS = {
  pt: "Todas as empresas e estações",
  es: "Todas las empresas y estaciones",
  en: "All companies and stations",
};

export const SPECIFIC_COMPANIES_AND_STATIONS = {
  pt: "Selecionar estações de empresas específicas",
  es: "Seleccionar estaciones de empresas específicas",
  en: "Select stations from specific companies",
};

export const ALL_NETWORK_COMPANIES_AND_STATIONS = {
  pt: "Todas as empresas e estações da rede",
  es: "Todas las empresas y estaciones de la red",
  en: "All companies and stations in the network",
};

export const DELETE_COMPANY = {
  pt: "Remover empresa",
  es: "Eliminar empresa",
  en: "Remove company",
};

export const ADD_OTHER_COMPANY = {
  pt: "Adicionar outra empresa",
  es: "Agregar otra empresa",
  en: "Add another company",
};

export const ATTENTION = {
  pt: "Atenção!",
  es: "¡Atención!",
  en: "Attention!",
};

export const SPECIFIC_STATIONS_ERROR_MESSAGE = {
  pt: "Selecione pelo menos uma estação para cada empresa",
  es: "Seleccione al menos una estación para cada empresa",
  en: "Select at least one station for each company",
};

export const SIMPLE_COUPON_TITLE = {
  pt: "Cupom simples",
  // TODO: translate en
  // TODO: translate es
};

export const SIMPLE_COUPON_DESCRIPTION = {
  pt: "Crie campanhas de desconto simples para seus clientes, oferecendo descontos em porcentagem, recargas grátis ou descontos fixos.",
  // TODO: translate en
  // TODO: translate es
};

export const CREDIT_BALANCE_TITLE = {
  pt: "Saldo em crédito",
  // TODO: translate en
  // TODO: translate es
};

export const CREDIT_BALANCE_DESCRIPTION = {
  pt: "Adicione saldo à conta de seus clientes para que possam utilizar a recarga em qualquer estação.",
  // TODO: translate en
  // TODO: translate es
};

export const OOPS = {
  pt: "Ops...",
  es: "Epa",
  en: "Ops",
};

export const ERROR_DISABLE_COUPON = {
  pt: "Não foi possível desativar o cupom",
  es: "No fue posible desactivar el cupón",
  en: "Unable to disable coupon",
};

export const ERROR_COUPON_USER = {
  pt: "Erro ao vincular usuário ao cupom",
  es: "Error al vincular usuario al cupón",
  en: "Error linking user to coupon",
};

export const COUPON_IN_USE = {
  pt: `O usuário já possui este cupom adicionado ao seu perfil!`,
  es: "¡El usuario ya tiene este cupón agregado a su perfil!",
  en: "The user already has this coupon added to their profile!",
};

export const COUPON_FROM_SAME_CAMPAIGN_FOUND = {
  pt: "O usuário já possui um cupom desta mesma campanha!",
  es: "¡El usuario ya tiene un cupón de esta misma campaña!",
  en: "The user already has a coupon from this same campaign!",
};

export const ERROR_COUPON_SAME_EMAIL = {
  pt: "Cupom está adicionado ao seu perfil!",
  es: "Cupom está adicionado ao seu perfil!",
};

export const SUCCESS_COUPON_USER = {
  pt: "Usuário foi vinculado ao cupom com sucesso",
  es: "Usuario vinculado al cupón con exito",
  en: "User was linked to coupon successfully",
};

export const ERROR_DOWNLOAD_FILE = {
  pt: "Não foi possível fazer o download do arquivo",
  es: "No fue posible realizar la descarga del archivo",
  en: "Unable to download file",
};

export const ERROR_GENERATE_FILE = {
  pt: "Não foi possível gerar o arquivo para download",
  es: "No fue posible generar el archivo para descarga",
  en: "Unable to generate file for download",
};

export const CREATED_COUPONS = {
  pt: "Cupons criados",
  // TODO: translate en
  // TODO: translate es
};

export const VALUE_PER_COUPON = {
  pt: "Valor por cupom",
  // TODO: translate en
  // TODO: translate es
};

export const CAMPAIGN_BALANCE = {
  pt: "Balanço da campanha",
  // TODO: translate en
  // TODO: translate es
};

export const TOTAL_AMOUNT_REMAINING = {
  pt: "Total restante",
  // TODO: translate en
  // TODO: translate es
};

export const AMOUNT_REMAINING = {
  pt: "Valor restante",
  // TODO: translate en
  // TODO: translate es
};

export const TOTAL_CONSUMED_VALUE = {
  pt: "Total consumido",
  // TODO: translate en
  // TODO: translate es
};

export const CONSUMED_VALUE = {
  pt: "Valor consumido",
  // TODO: translate en
  // TODO: translate es
};

export const ACTIVE_COUPONS = {
  pt: "Cupons ativos",
  // TODO: translate en
  // TODO: translate es
};

export const BENEFIT_USED = {
  pt: "Benefício utilizado",
  // TODO: translate en
  // TODO: translate es
};

export const USED = {
  pt: "Utilizado",
  en: "Used",
  es: "Utilizado",
};

export const USED_BOLD = {
  pt: <b>Utilização</b>,
  en: "Used",
  es: "Utilizado",
};

export const REST = {
  pt: "Restante",
  en: "Remaining",
  es: "Restante",
};

export const NO_LIMIT_USE = {
  pt: "Sem limite de utilização",
  en: "No usage limit",
  es: "Sin limite de uso",
};
export const EXPIRE = {
  pt: "Expirado",
  en: "Expired",
  es: "Expirado",
};
export const USERS = {
  pt: " usuários",
  en: " users",
  es: " usuarios",
};
export const GO_BACK = {
  pt: "Voltar",
  // TODO: translate en
  // TODO: translate es
};

export const CAMPAIGN_RESUME = {
  pt: "Resumo da campanha",
  // TODO: translate en
  // TODO: translate es
};

export const BENEFIT_TYPE = {
  pt: "Tipo do cupom",
  // TODO: translate en
  // TODO: translate es
};

export const ADD_COUPON_TO_CAMPAIGN = {
  pt: "Adicionar cupom (+)",
  en: "Add coupon (+)",
  es: "Agregar cupón (+)",
};

export const UNIT_TYPE = {
  pt: "Tipo do período",
  // TODO: translate en
  // TODO: translate es
};

export const BENEFIT_DURATION = {
  pt: "Duração do benefício",
  es: "Duración del beneficio",
  en: "Benefit duration",
};

export const DURATION = {
  pt: "Duração",
  es: "Duración",
  en: "Duration",
};

export const MONTH = {
  pt: "Mês",
  // TODO: translate en
  // TODO: translate es
};

export const WEEK = {
  pt: "Semana",
  en: "Week",
  es: "Semana",
};

export const WEEKS = {
  pt: "Semanas",
  en: "Weeks",
  es: "Semanas",
};

export const MONTHS = {
  pt: "meses",
  en: "months",
  es: "meses",
};

export const DAY = {
  pt: "Dia",
  // TODO: translate en
  // TODO: translate es
};

export const DAYS = {
  pt: "dias",
  // TODO: translate en
  // TODO: translate es
};

export const RECURRENCE = {
  pt: "Recorrência",
  es: "Recorrencia",
  en: "Recurrence",
};

export const COUPON_EXPIRE = {
  pt: "Cupons têm expiração individual",
  es: "cupones tienen caducidad individual",
  en: "Individual coupon expiration",
};

export const COUPON_EXPIRE_DESCRIPTION = {
  pt: "A validade de cada cupom começará a contar a partir da data que cada código foi inserido pelo usuário, respeitando a duração configurada acima.\n\nA data de expiração individual do cupom sobrescreve a expiração da campanha, caso ela já tenha encerrado e ainda existam usuários com códigos ativos.",
  // TODO: translate en
  // TODO: translate es
};

export const CAMPAIGN_HAS_RESTRITIVE_QUOTA = {
  pt: "A campanha possui uma cota restritiva",
  en: "The campaign has a restrictive quota",
  es: "La campaña tiene una cuota restrictiva",
};

export const CAMPAIGN_HAS_RESTRITIVE_QUOTA_DESCRIPTION = {
  pt: "Selecione para definir um limite máximo para valor total disponível para a campanha.",
  en: "Select to set a maximum limit for the total value available for the campaign.",
  es: "Seleccione para definir un límite máximo para el valor total disponible para la campaña.",
};

export const MODEL = {
  pt: "Modelo",
  en: "Model",
  es: "Modelo",
};

export const UNIQUE_QUOTA = {
  pt: "Cota única",
  en: "Unique quota",
  es: "Cuota única",
};

export const UNIQUE_VALUE = {
  pt: "Valor único",
  en: "Unique value",
  es: "Valor unico",
};

export const MONTHLY_RENOVATION_QUOTA = {
  pt: "Com renovação mensal",
  en: "With monthly renewal",
  es: "Con renovación mensual",
};

export const RENOVATION_QUOTA = {
  pt: "Com renovação",
  es: "Con renovación",
  en: "With renewal",
};

export const AVAIABLE_VALUE = {
  pt: "Valor disponível (R$)",
  en: "Available value ($)",
  es: "Valor disponible (€)",
};

export const CIPHER = {
  pt: "R$",
  en: "US$",
  es: "$",
};

export const VALUE_ACCUMULATE_TO_NEXT_PERIOD = {
  pt: "O saldo acumula para o próximo período",
  en: "The balance accumulates for the next period",
  es: "El saldo se acumula para el próximo período",
};

export const COUPONS_EXPIRE_AFTER_CAMPAIGN_QUOTA_TOTAL_USAGE = {
  pt: "Os cupons expiram após o consumo total da cota",
  en: "Coupons expire after the total quota consumption",
  es: "Los cupones expiran después del consumo total de la cuota",
};

export const QUOTA = {
  pt: "Cota",
  en: "Quota",
  es: "Cuota",
};

export const CAMPAIGN_DATA = {
  pt: <b>Dados da Campanha</b>,
  en: "Campaign data",
  es: "Datos de campaña",
};

export const ACTIVE = {
  pt: <b>Ativa</b>,
  en: "Active",
  es: "Activo",
};

export const ADDED = {
  pt: <b>Adicionados</b>,
  en: "Added",
  es: "Agregados",
};

export const LINKED = {
  pt: <b>Vinculados</b>,
  es: "Vinculado",
  en: "Linked",
};

export const CREATION_AND_EXPIRATION = {
  pt: <b>Criação e expiração</b>,
  en: "Creation and expiration",
  es: "Creación y vencimiento",
};

export const OPTIONS = {
  pt: "Opções",
  en: "Options",
  es: "Opiciones",
};

export const ADD_COUPONS_TO_CAMPAIGN = {
  pt: "Adicionar Cupons",
  en: "Add Coupons",
  es: "Agregar Cupones",
};

export const PER_QUANTITY = {
  pt: "Por quantidade",
  en: "Per quantity",
  es: "Por cantidad",
};

export const CUSTOM_CODE = {
  pt: "Código customizado",
  en: "Custom code",
  es: "Código personalizado",
};

export const REPORT_QUANTITY = {
  pt: "Informe a quantidade",
  en: "Enter the quantity",
  es: "Informe la cantidad",
};

export const CODES_SPLITED_BY_COMMA = {
  pt: "Digite um ou mais códigos, separados por vírgula",
  en: "Enter one or more codes, separated by comma",
  es: "Ingrese uno o más códigos, separados por coma",
};

export const INCLUDE_NEW_CODE = {
  pt: <b>Incluir novo código</b>,
  en: "Include new code",
  es: "Incluir nuevo código",
};

export const EACH_USER_CAN_LINK_MORE_THAN_ONE_CODE = {
  pt: "Cada usuário pode vincular mais de um cupom desta campanha",
  en: "Each user can link more than one coupon from this campaign",
  es: "Cada usuario puede vincular más de un cupón de esta campaña",
};

export const PARTNERSHIP = {
  pt: "Parcerias",
  en: "Partnership",
  es: "Asociaciones",
};

export const HAS_PARTNERSHIP = {
  pt: "Possui parceria",
  en: "Has Partnership",
  es: "Tiene colaboración",
};

export const SELECT_PARTNERSHIP = {
  pt: "Selecione a parceria",
  en: "Select partnership",
  es: "Seleccionar asociación",
}

export const ADD_COUPONS_USER = {
  pt: "VINCULAR USUÁRIO AO CUPOM",
  en: "LINK USER TO COUPON",
  es: "VINCULAR USUARIO AL CUPON",
};

export const CONFIRM_ADD_COUPON = {
  pt: "Tem certeza de que deseja adicionar este cupom?",
  en: "Are you sure you want to add this coupon?",
  es: "¿Seguro que quieres agregar este cupón?",
};

export const ADD_COUPONS_USERS = {
  pt: "Vincular",
  en: "Link",
  es: "Vincular",
};

export const ENTER_EMAIL = {
  pt: "Digite o e-mail",
  en: "Enter email",
  es: "Digite el correo",
};

export const ENTER_EMAIL_HERE = {
  pt: "Ex.: usuário@mail.com",
  en: "Example: user@mail.com",
  es: "Ej.: usuarios@mail.com",
};

export const ELIGIBLE_USERS_DEFINITION = {
  pt: "Definição para ativação do cupom",
  en: "Definition for coupon activation",
  es: "Definición para activar el cupón",
};

export const CHASSI = {
  pt: "Chassi",
  en: "Chassi",
  es: "Chassi",
};

export const EMAIL = {
  pt: "E-mail",
  en: "Email",
  es: "Correo",
};

export const FULL_REGISTER = {
  pt: "Cadastro completo (CPF, placa e meio de pagamento)",
  en: "Full register (CPF, plate and payment method)",
  es: "Registro completo (CPF, placa y medio de pago)",
};

export const ELIGIBLE_USERS_TO_RECIEVE_COUPON = {
  pt: "Ação para adicionar",
  en: "Action to add",
  es: "Acción para agregar",
};

export const ANYONE_WHO_ADD_COUPON = {
  pt: "Digita o código no app (padrão)",
  en: "Enter the code in the app (default)",
  es: "Ingrese el código en la aplicación (predeterminado)",
};

export const USERS_THAT_FILLED_SPECIFIC_DATA = {
  pt: "Usuários que preencheram dados específicos",
  en: "Users who filled specific data",
  es: "Usuarios que completaron datos específicos",
};

export const ADD_COUPONS_SHEET = (couponType = '') => ({
  pt: `Adicionar planilha CSV com listagem de ${couponType}`,
  en: `Add CSV spreadsheet with ${couponType} list`,
  es: `Agregar hoja de cálculo CSV con lista de ${couponType}`,
});

export const CLICK_OR_DRAG_HERE = {
  pt: 'Clique ou arraste a planilha até aqui',
  en: 'Click or drag the spreadsheet here',
  es: 'Haga clic o arrastre la hoja de cálculo aquí',
};

export const SELECTED_FROM = {
  pt: 'Selecionados a partir de',
  en: 'Selected from',
  es: 'Seleccionados a partir de',
};

export const SPECIFIC_USERS = {
  pt: 'Atende critérios específicos',
  en: 'Meets specific criteria',
  es: 'Cumple con criterios específicos',
};

export const ONLY_FIRST_CHARGE = {
  pt: 'Apenas 1º carga',
  en: 'Only 1st charge',
  es: 'Solo 1ª carga',
};

export const FIRST_CHARGE = {
  pt: 'Primeira carga',
  en: 'First charge',
};

export const MAX_AMOUNT_OF_USE_TO_CREDIT = {
  pt: 'Máximo de cargas para utilizar o crédito',
  en: 'Maximum amount of charges to use the credit',
  es: 'Máximo de cargas para utilizar el crédito',
};

export const DEFINE_CHARGE_LIMIT = {
  pt: 'Defina o limite de cargas',
  en: 'Define the charge limit',
  es: 'Defina el límite de cargas',
};

export const UNLIMITED_CHARGE = {
  pt: 'Ilimitado',
  en: 'Unlimited',
  es: 'Ilimitado',
};

export const WITH_CHARGE_LIMIT = {
  pt: 'Com limite máximo',
  en: 'With maximum limit',
  es: 'Con límite máximo',
};

export const HAS_DISCOUNT_PERCENTAGE_LIMIT = {
  pt: 'Desconto tem limite de percentual por carga?',
  en: 'Does the discount have a percentage limit per charge?',
  es: '¿Tiene el descuento un límite de porcentaje por carga?',
};

export const MAX_PERCENTAGE = {
  pt: 'Percentual máximo',
  en: 'Maximum percentage',
  es: 'Porcentaje máximo',
};

export const ONLY_USERS_WITHOUT_CHARGE = {
  pt: 'Apenas usuários sem carga',
  en: 'Only users without charge',
  es: 'Solo usuarios sin carga',
};
