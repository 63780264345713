import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, Form, notification, Row, Select } from "antd";
import apiOcpp from "../../../../services/apiOcpp";
import { useFreire } from "../../../../utils/freireContext";
import * as texts from "../../locales";

const { Option } = Select;

const TriggerMessage = ({
  stationId = "",
  connectors = [],
  redirectTo = () => {},
  form,
}) => {
  useEffect(() => {
    form.resetFields(["connectorId"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectors]);

  const { getFieldDecorator } = form;
  const [loading, setLoading] = useState(false);
  const { freire } = useFreire();

  const messageTrigger = [
    "BootNotification",
    "DiagnosticsStatusNotification",
    "FirmwareStatusNotification",
    "Heartbeat",
    "MeterValues",
    "StatusNotification",
  ];

  const send = () => {
    form.validateFields(async (err, payload) => {
      if (err) {
        return;
      }

      try {
        setLoading(true);
        const { data } = await apiOcpp.post(
          `/ocpp16/triggerMessage/${stationId}`,
          {
            connectorId: payload.connectorId,
            requestedMessage: payload.requestedMessage,
          }
        );

        setLoading(false);

        if (data && data.result) {
          if (data.result === "Accepted") {
            notification.success({
              message: freire(texts.ALL_RIGHT),
              description: `${freire(texts.STATION_RESPONSE)} '${data.result}'`,
            });
            redirectTo("/dashboard/ocpp/stations");
          } else {
            notification.error({
              message: freire(texts.OOPS),
              description: `${freire(texts.STATION_RESPONSE)} '${data.result}'`,
            });
          }
        } else {
          throw new Error();
        }
      } catch (error) {
        setLoading(false);
        notification.error({
          message: freire(texts.OOPS),
          description: freire(texts.VERIFY_AGAIN),
        });
      }
    });
  };

  return (
    <Fragment>
      <Form layout="vertical" wrapperCol={{ span: 24 }}>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item label={freire(texts.CONNECTOR_ID)}>
              {getFieldDecorator("connectorId")(
                <Select
                  disabled={!stationId}
                  placeholder={freire(texts.SELECT_CONNECTOR_ID)}
                >
                  {connectors.map((connector) => (
                    <Option
                      key={connector.connectorId}
                      value={connector.connectorId}
                    >
                      ID {connector.connectorId}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item label={freire(texts.MESSAGE_TO_REQUEST)}>
              {getFieldDecorator("requestedMessage", {
                rules: [
                  {
                    required: true,
                    message: freire(texts.REQUIRED),
                  },
                ],
              })(
                <Select placeholder={freire(texts.SELECT_MESSAGE)}>
                  {messageTrigger.map((msg) => (
                    <Option key={msg} value={msg}>
                      {msg}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        <Button
          disabled={!stationId || loading}
          loading={loading}
          onClick={send}
          type="primary"
        >
          <span>
            {freire(texts.EXECUTE)}{" "}
            <span style={{ fontWeight: "bold" }}> TriggerMessage</span>
          </span>
        </Button>
      </div>
    </Fragment>
  );
};

const PageTriggerMessage = Form.create({ name: "inputs" })(TriggerMessage);

export default PageTriggerMessage;
