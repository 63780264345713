import { Tooltip } from "antd";
import React from "react";
import { useFreire } from "../../utils/freireContext";
import DotStatus from "./DotStatus";
import styles from "./styles.module.css";
import * as texts from "./locales";

const StatusWithDot = ({
  status,
  textStyle = { fontSize: 14, color: "#565559" },
  containerStyle = {},
  onPress,
  suffixText,
  hideTooltip = false,
  table = false,
  textTooltip = "Aplicar filtro", // TODO: translate
  zendeskTicketID = null,
}) => {
  const { freire } = useFreire();

  return (
    <Tooltip placement="bottom" title={hideTooltip ? "" : textTooltip}>
      <div
        className={[styles.container]}
        style={{
          cursor: onPress ? "pointer" : "auto",
          ...containerStyle,
        }}
        onClick={() => (onPress ? onPress(status) : null)}
      >
        <DotStatus status={status} />
        <span className={styles.textStatus} style={textStyle}>
          {texts[status] !== undefined
            ? freire(texts[status])
            : "-------------"}{" "}
          {suffixText || ""}
        </span>
      </div>
      {table &&
      status === "Unavailable" &&
      zendeskTicketID &&
      zendeskTicketID > 0 ? (
        <a
          style={{ marginLeft: 17, textDecoration: "underline" }}
          rel="noopener noreferrer"
          target="_blank"
          href={`https://tupinambaenergiahelp.zendesk.com/agent/tickets/${zendeskTicketID}`}
        >
          Ticket #{zendeskTicketID}
        </a>
      ) : null}
    </Tooltip>
  );
};

export default StatusWithDot;
