import React from "react";

import { useFreire } from "../../../../../utils/freireContext";
import * as texts from "../../../locales";
import { useNewCampaignContext } from "../../context";
import { Col, Form, Icon, InputNumber, Radio, Row, Tooltip } from "antd";
import MyDivider from "../../../../../components/MyDivider";
import "./style.css";

export default function CampaignUsageLimitPerPeriodRules({
  showDivider = false,
}) {
  const { freire } = useFreire();
  const { form } = useNewCampaignContext();
  const { getFieldDecorator } = form;

  const { usageLimitPerPeriod: capmaignUsageLimitPerPeriod } =
    form.getFieldsValue();

  const {
    usageLimitPerPeriod = {
      enabled: capmaignUsageLimitPerPeriod?.enabled ?? false,
      unit: capmaignUsageLimitPerPeriod?.unit.charAt(-1) ?? "D",
      usageAmount: capmaignUsageLimitPerPeriod?.usageAmount ?? null,
      value: capmaignUsageLimitPerPeriod?.value ?? null,
    },
  } = form.getFieldsValue();

  const requiredFieldRule = {
    required: true,
    message: freire(texts.REQUIRED_FIELD),
  };

  return (
    <>
      <Row>
        <Col span={8}>
          <Form.Item
            labelAlign="left"
            label={
              <span style={{ color: "#000000d9" }}>
                <span>{freire(texts.CUPON_HAS_USAGE_LIMIT_PER_PERIOD)}</span>{" "}
                <Tooltip
                  title={freire(
                    texts.QUESTION_LIMITE_PERIOD_COUPON_DESCRIPTION
                  )}
                >
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator("usageLimitPerPeriod.enabled", {
              initialValue: usageLimitPerPeriod.enabled,
            })(
              <Radio.Group className="customRadioGroup" buttonStyle="solid">
                <Radio.Button value={false}>
                  {freire(texts.UNLIMITED)}
                </Radio.Button>
                <Radio.Button value={true}>
                  {freire(texts.LIMITED)}
                </Radio.Button>
              </Radio.Group>
            )}
          </Form.Item>
        </Col>

        {usageLimitPerPeriod.enabled && (
          <>
            <Col span={6}>
              <Form.Item
                style={{ marginLeft: "20px" }}
                labelAlign="left"
                label={freire(texts.MAX_QUANTITY)}
              >
                {getFieldDecorator("usageLimitPerPeriod.usageAmount", {
                  ...(usageLimitPerPeriod.enabled && {
                    rules: [requiredFieldRule],
                  }),
                  initialValue: usageLimitPerPeriod.usageAmount,
                })(
                  <InputNumber
                    disabled={!usageLimitPerPeriod.enabled}
                    min={1}
                    placeholder={freire(texts.EX_PARAM("50"))}
                  />
                )}
              </Form.Item>
            </Col>

            <Col className="custom-column" span={5}>
              <Form.Item labelAlign="left" label={freire(texts.TIME_PERIOD)}>
                {getFieldDecorator("usageLimitPerPeriod.value", {
                  ...(usageLimitPerPeriod.enabled && {
                    rules: [requiredFieldRule],
                  }),
                  initialValue: usageLimitPerPeriod.value,
                })(
                  <div className="time-period-filed-wrapper">
                    <span>{freire(texts.FOR_EACH)}</span>
                    <InputNumber
                      disabled={!usageLimitPerPeriod.enabled}
                      min={1}
                      max={usageLimitPerPeriod.periodType === "D" ? 31 : 12}
                      placeholder={freire(texts.EX_PARAM("1"))}
                    />
                  </div>
                )}
              </Form.Item>

              <Form.Item colon={false} label=" ">
                {getFieldDecorator("usageLimitPerPeriod.unit", {
                  initialValue: usageLimitPerPeriod.unit || "M",
                })(
                  <Radio.Group
                    disabled={!usageLimitPerPeriod.enabled}
                    className="customRadioGroup"
                    buttonStyle="solid"
                  >
                    <Radio.Button value={"D"}>
                      {`${freire(texts.DAY)}${
                        usageLimitPerPeriod.value > 1 ? "s" : ""
                      }`}
                    </Radio.Button>
                    <Radio.Button value={"W"}>
                      {`${freire(texts.WEEK)}${
                        usageLimitPerPeriod.value > 1 ? "s" : ""
                      }`}
                    </Radio.Button>
                    <Radio.Button value={"M"}>
                      {`${
                        usageLimitPerPeriod.value > 1
                          ? freire(texts.MONTHS).charAt(0).toUpperCase() +
                            freire(texts.MONTHS).slice(1)
                          : freire(texts.MONTH)
                      }`}
                    </Radio.Button>
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>
          </>
        )}
      </Row>
      {showDivider && <MyDivider />}
    </>
  );
}
