export const CLOSE = {
  pt: "Fechar",
  es: "Cerrar",
  en: "Close",
};

export const DOWNLOAD = {
  pt: "Fazer Download",
  es: "Descargar",
  en: "Download",
};

export const CODE = {
  pt: "Código",
  es: "Código",
  en: "Code",
};
