import {
  Button,
  Form,
  Icon,
  Input,
  notification,
  Popover,
  Table,
  Tooltip,
} from "antd";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Show from "../../../contexts/PermissionContext/Show";
import api from "../../../services/api";
import EditDrawer from "./EditDrawer";
import "./index.css";
import { FreireContext } from "../../../utils/freireContext";
import * as texts from "../locales";
import { sorter } from "../../../utils/sorter";

class ListPlugs extends Component {
  static contextType = FreireContext;

  state = {
    currentPlug: {},
    errorPopoverImg: "",
    loadingTable: false,
    loadingSave: false,
    visibleDrawer: false,
    loadingDrawer: false,
    dateFiltrada: [],
    tablePage: 1,
  };

  componentDidMount() {
    this.getPlugs();
  }

  async updatePlug(data) {
    const { freire } = this.context;

    this.setState({ loadingDrawer: true }, async () => {
      try {
        await api.put(`plug/${this.state.currentPlug._id}`, data);

        this.setState({ loadingDrawer: false, visibleDrawer: false });

        notification.success({
          message: freire(texts.ALL_RIGHT),
          description: freire(texts.PLUG_EDITED_SUCESS),
        });

        this.getPlugs();
      } catch (error) {
        notification.error({
          message: freire(texts.OOPS),
          description: freire(texts.ERROR_EDIT_PLUG),
        });
        this.setState({ loadingDrawer: false });
      }
    });
  }

  async getPlugs(params = {}) {
    const { freire } = this.context;

    this.setState({ loadingTable: true }, async () => {
      try {
        const { data } = await api.get(`plugs`, { params });
        this.setState({
          dateFiltrada: sorter(data),
          loadingTable: false,
        });
      } catch (error) {
        notification.error({
          message: freire(texts.OOPS),
          description: freire(texts.ERROR_GET_PLUGS),
        });
        this.setState({ loadingTable: false });
      }
    });
  }

  filter = () => {
    this.props.form.validateFields((err, values) => {
      if (!values.name) values.name = undefined;

      if (!err) {
        this.getPlugs(values);
      }
    });

    this.setState({ tablePage: 1 });
  };

  clearFilters = () => {
    this.props.form.resetFields();
    this.setState({ dateFiltrada: [] });
    this.getPlugs();
  };

  goNew = () => {
    this.props.history.push("/dashboard/new_plug");
  };

  getColumns = () => {
    const { freire } = this.context;

    return [
      {
        title: freire(texts.NAME),
        dataIndex: "name",
        key: "name",
      },
      {
        title: freire(texts.ICON),
        dataIndex: "icon",
        key: "icon",
        width: "65%",
        render: (icon, plug) => (
          <Popover
            placement="top"
            content={
              <div>
                <img
                  id={icon}
                  onError={() =>
                    document
                      .getElementById(icon)
                      .setAttribute("alt", freire(texts.ERROR_LOAD_IMAGES))
                  }
                  style={{ width: 250 }}
                  src={icon}
                  alt="Ícone"
                />
              </div>
            }
            title={plug.name}
          >
            <a target="_blank" rel="noopener noreferrer" href={icon}>
              {icon}
            </a>
          </Popover>
        ),
      },
      {
        title: freire(texts.ACTIONS),
        key: "acoes",
        width: 150,
        render: (plug) => (
          <>
            <Show when={"edit-plug"}>
              <Tooltip placement="top" title={freire(texts.EDIT)}>
                <Button
                  shape="circle"
                  size="small"
                  ghost
                  type="primary"
                  style={{ marginRight: 8 }}
                  onClick={() => {
                    this.setState({
                      visibleDrawer: true,
                      currentPlug: plug,
                    });
                  }}
                >
                  <Icon type="edit" />
                </Button>
              </Tooltip>
            </Show>
          </>
        ),
      },
    ];
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      visibleDrawer,
      loadingDrawer,
      currentPlug,
      loadingTable,
      dateFiltrada,
    } = this.state;
    const { freire } = this.context;

    const columns = this.getColumns();

    return (
      <div className="container">
        <Breadcrumbs
          breadcrumbs={[
            freire(texts.ADMIN),
            freire(texts.MANAGEMENT),
            freire(texts.PLUGS),
          ]}
        />
        <div className="filter">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.filter();
            }}
            wrapperCol={{ span: 24 }}
          >
            <Form.Item>
              <div style={{ flexDirection: "row", display: "flex" }}>
                {getFieldDecorator("name")(
                  <Input
                    onSubmit={this.filter}
                    className="firstInput"
                    placeholder={freire(texts.SEARCH_BY_PLUG_NAME)}
                  />
                )}
                <Button
                  icon="search"
                  className="buttonSearch"
                  onClick={this.filter}
                >
                  {freire(texts.SEARCH)}
                </Button>
                <Show when={"add-plug"}>
                  <Button
                    icon="plus"
                    className="buttonNew"
                    onClick={this.goNew}
                    type="primary"
                  >
                    {freire(texts.NEW)}
                  </Button>
                </Show>
              </div>
            </Form.Item>
          </Form>

          <div className="footer">
            <Button style={{ marginRight: 10 }} onClick={this.clearFilters}>
              {freire(texts.CLEAR)}
            </Button>
            <Button onClick={this.filter} type="primary">
              {freire(texts.FILTER)}
            </Button>
          </div>
        </div>

        <Table
          style={{ backgroundColor: "white" }}
          scroll={{ x: 1300 }}
          tableLayout="auto"
          rowKey={(data) => data._id}
          loading={loadingTable}
          dataSource={dateFiltrada}
          columns={columns}
          pagination={{
            current: this.state.tablePage,
            onChange: (page) => this.setState({ tablePage: page }),
          }}
        />

        <EditDrawer
          visible={visibleDrawer}
          loading={loadingDrawer}
          onClose={() => this.setState({ visibleDrawer: false })}
          onSave={(values) => this.updatePlug(values)}
          plug={currentPlug}
        />
      </div>
    );
  }
}
const PagePlugs = Form.create({ name: "filters" })(ListPlugs);
export default withRouter(PagePlugs);
