import axios from "axios";
import {
  setLanguageInterceptor,
  setTokenRequestInterceptor,
} from "./interceptors";

const apiData = axios.create({
  baseURL: process.env.REACT_APP_DATA_API_URL,
  transitional: {
    clarifyTimeoutError: true,
  },
});

apiData.interceptors.request.use(setTokenRequestInterceptor);
apiData.interceptors.request.use(setLanguageInterceptor);

export default apiData;
