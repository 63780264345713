import pt_BR from "antd/es/locale/pt_BR";
import en_US from "antd/es/locale/en_US";
import es_ES from "antd/es/locale/es_ES";
import * as texts from "../pages/Dashboard/locales";

export const DEFAULT_LANGUAGE = "pt";

export const LANGUAGES = {
  [DEFAULT_LANGUAGE]: {
    antd: pt_BR,
    moment: "pt-br",
    text: texts.PORTUGUES,
  },
  en: {
    antd: en_US,
    moment: "en-us",
    text: texts.ENGLISH,
  },
  es: {
    antd: es_ES,
    moment: "es",
    text: texts.SPANISH,
  },
};
