import React from "react";
import useAccess from "./useAccess";

interface IShowProps {
  when: any;
  resource?: any;
  fallback?: any;
  children?: any;
}

const Show = ({ when, resource, fallback = null, children, ...rest }: IShowProps) => {
  const { hasPermission } = useAccess();

  const show = hasPermission(when, { resource });

  if (!show) {
    return fallback;
  }

  // pass any other props to the children below.. this is needed for things like wrapping <Menu.Item /> components
  return React.Children.map(children, (child) =>
    React.cloneElement(child, rest)
  );
};

export default Show;
