import React from "react";
import { Icon, Tooltip, message } from "antd";
import copy from "copy-to-clipboard";
import * as texts from "./locales";
import { useFreire } from "../../utils/freireContext";
import { CopyIcon } from "../../assets/icons";

const CopyText = ({
  text,
  textStyle = {},
  showIcon = true,
  iconStyle = {},
  disabled = false,
}) => {
  const { freire } = useFreire();

  return (
    <Tooltip placement="right" title={freire(texts.COPY)}>
      <div
        onClick={() => {
          copy(text);
          message.info(freire(texts.COPIED));
        }}
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          width: "fit-content",
        }}
      >
        <span style={textStyle}>{text}</span>
        {showIcon && (
          <Icon
            component={CopyIcon}
            style={{
              fontSize: 20,
              marginLeft: 10,
              opacity: disabled ? 0.3 : 1,
              ...iconStyle,
            }}
          />
        )}
      </div>
    </Tooltip>
  );
};

export default CopyText;
