import React, { Fragment, useEffect, useState } from "react";
import { Modal, Button, Icon, Alert, Spin } from "antd";
import "./index.css";
import api from "../../../../services/api";
import apiOcpp from "../../../../services/apiOcpp";
import { notification } from "antd";
import { useFreire } from "../../../../utils/freireContext";
import * as texts from "../locales";

function StopChargeModal({ visible, currentCharge, onClose, onConfirm }) {
  const { stationID, transactionID, userEmail, startDateTime } = currentCharge;
  const stationName = currentCharge?.station?.name ?? "";
  const { freire } = useFreire();

  const [isStationConnected, setIsStationConnected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingStop, setLoadingStop] = useState(false);

  useEffect(() => {
    const checkIfStationIsConnected = async () => {
      try {
        setLoading(true);

        const {
          data: { isConnected },
        } = await apiOcpp.get(`/station/${stationID}/connection-status`);

        setIsStationConnected(isConnected);
        setLoading(false);
      } catch (error) {
        setIsStationConnected(null);
        setLoading(false);
      }
    };

    if (visible) {
      checkIfStationIsConnected();
    }
  }, [stationID, visible]);

  const stopTransaction = async () => {
    try {
      setLoadingStop(true);

      await api.post(
        `/station/${stationID}/transaction/${transactionID}/stop-admin`
      );

      setLoadingStop(false);
      onConfirm();
    } catch (error) {
      notification.error({
        message: freire(texts.FINISH_CHARGE_ERROR),
        description: freire(texts.TRY_AGAIN_MORE),
      });
      setLoadingStop(false);
    }
  };

  return (
    <Modal
      visible={visible}
      destroyOnClose
      width="40%"
      bodyStyle={{ padding: 16 }}
      centered
      title={freire(texts.ATTENTION)}
      style={{ padding: 0 }}
      closable={false}
      maskClosable={false}
      footer={
        <ModalFooter
          onClose={onClose}
          onConfirm={stopTransaction}
          loading={loading || loadingStop}
        />
      }
    >
      {loading ? (
        <Spin
          tip="Verificando status da estação ..."
          style={{ width: "100%" }}
        />
      ) : (
        <Fragment>
          {typeof isStationConnected === "boolean" ? (
            <Alert
              message={
                isStationConnected
                  ? freire(texts.STATION_TO_OCPP_CONNECT(stationID))
                  : freire(texts.STATION_TO_OCPP_DESCONNECT(stationID))
              }
              type={isStationConnected ? "info" : "warning"}
              showIcon
            />
          ) : null}

          <div className="containerTitle">
            <Icon className="iconTitle" type="question-circle" />
            <span className="textTitle">
              {isStationConnected
                ? freire(texts.WANT_COMPLETE_CHARGER)
                : freire(texts.FORCE_FINISH_CHARGE)}
            </span>
          </div>

          <div style={{ paddingLeft: 20 }}>
            <ul>
              <InfoItem title="ID" text={transactionID} />
              <InfoItem
                title={freire(texts.STATE)}
                text={`${stationName} (${stationID})`}
              />
              <InfoItem
                title={freire(texts.START)}
                text={new Date(startDateTime).toLocaleString("pt-br")}
              />
              <InfoItem title={freire(texts.USER)} text={userEmail} />
            </ul>
          </div>
        </Fragment>
      )}
    </Modal>
  );
}

const InfoItem = ({ title, text }) => (
  <li>
    <span className="textInfo">
      <strong>{title}:</strong> {text}
    </span>
  </li>
);

const ModalFooter = ({ onClose, onConfirm, loading }) => {
  const { freire } = useFreire();

  return (
    <div className="containerFooter">
      <Button key="cancel" disabled={loading} onClick={() => onClose()}>
        {freire(texts.CANCEL)}
      </Button>
      <Button
        style={{ marginLeft: 25, backgroundColor: "#F40000" }}
        key="ok"
        disabled={loading}
        loading={loading}
        type="danger"
        onClick={() => onConfirm()}
      >
        {freire(texts.FINISHED_CHARGE)}
        <Icon type="stop" />
      </Button>
    </div>
  );
};

export default StopChargeModal;
