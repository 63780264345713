import { useEffect } from "react";

export const useOnUnmount = (effect) => {
  useEffect(() => {
    return () => {
      effect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
