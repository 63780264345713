import { COUPON_CODE_TYPE } from "../../../services/utils";
import * as texts from "../locales";

const { EQUALS, DIFFERENT } = COUPON_CODE_TYPE;

const generateFields = ({ freire, companyOptions, onPressButtonNew }) => [
  {
    isMainFilter: true,
    inputSearch: {
      label: freire(texts.CAMPAIGN),
      placeholder: freire(texts.SEARCH_CAMPAIGN),
    },
    buttonNew: {
      permission: "add-cupons",
      text: freire(texts.NEW_CAMPAIGN),
      onPress: onPressButtonNew,
    },
  },
  {
    label: freire(texts.COMPANIES),
    name: "company_ids",
    component: "tupi.select",
    isMultiple: true,
    placeholder: freire(texts.SELECT_COMPANIES),
    options: companyOptions,
    colSpan: 10,
  },
  {
    label: freire(texts.COUPONS_CODE_TYPE),
    name: "codeType",
    component: "tupi.select",
    isMultiple: false,
    placeholder: freire(texts.SELECT_TYPE),
    options: [
      {
        label: freire(texts.EQUALS),
        value: EQUALS,
      },
      {
        label: freire(texts.DIFFERENT),
        value: DIFFERENT,
      },
    ],
    colSpan: 10,
  },
  {
    label: freire(texts.ACTIVED),
    name: "enabled",
    component: "tupi.radioGroup",
    options: [
      {
        label: freire(texts.YES),
        value: true,
      },
      {
        label: freire(texts.EMPTY),
        value: undefined,
      },
      {
        label: freire(texts.NO),
        value: false,
      },
    ],
    colSpan: 5,
  },
];

export default generateFields;
