import React, { useMemo, useRef, useState } from "react";
import CsvDownload from "../../../../../components/CsvDownload";
import { useFreire } from "../../../../../utils/freireContext";
import * as texts from "../../../locales";
import moment from "moment";
import { useOnUpdate } from "../../../../../hooks";
import { notification } from "antd";
import api from "../../../../../services/api";
import { COUPON_TYPE } from "../../../../../services/utils";

import CouponsCsvColumns from "./columns";

const { FREE, PERCENTAGE, FIXED_VALUE, CREDIT } = COUPON_TYPE;

const CouponsCsv = ({ campaignResume }) => {
  const { freire } = useFreire();

  const CSVLinkRef = useRef();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = useMemo(() => {
    const csvColumns = CouponsCsvColumns.getColumns(freire, campaignResume);
    if (
      [FREE, PERCENTAGE, FIXED_VALUE].includes(campaignResume?.benefit?.type)
    ) {
      return [
        csvColumns.cod,
        csvColumns.status,
        csvColumns.user,
        csvColumns.usageLimit,
        csvColumns.usageQty,
        csvColumns.expiresAt,
      ];
    } else if (campaignResume?.benefit?.type === CREDIT) {
      return [
        csvColumns.cod,
        csvColumns.status,
        csvColumns.user,
        csvColumns.consumed,
        csvColumns.remaining,
        csvColumns.expiresAt,
      ];
    }

    return [];
  }, [freire, campaignResume]);

  const headers = useMemo(
    () => columns.map(({ label, key }) => ({ label, key })),
    [columns]
  );

  const onDownloadExcel = async () => {
    try {
      setLoading(true);
      const {
        data: { docs: coupons },
      } = await api.get(`campaign/${campaignResume?._id}/coupons`, {
        params: {
          page: 1,
          limit: Number.MAX_SAFE_INTEGER,
        },
      });
      const dataExcel = coupons.map((coupon) =>
        columns.reduce((result, column) => {
          const { key, formatter } = column;

          result[key] = formatter ? formatter(coupon) : coupon[key];

          return result;
        }, {})
      );
      setData(dataExcel);
    } catch (error) {
      notification.error({
        message: freire(texts.OOPS),
        description: freire(texts.ERROR_GENERATE_FILE),
      });
    } finally {
      setLoading(false);
    }
  };

  useOnUpdate(() => {
    if (CSVLinkRef.current && data.length > 0) {
      CSVLinkRef.current.link.click();
    } else {
      notification.error({
        message: freire(texts.OOPS),
        description: freire(texts.ERROR_DOWNLOAD_FILE),
      });
    }
  }, [data]);

  return (
    <CsvDownload
      csvLinkRef={CSVLinkRef}
      fileName={freire(
        texts.CSV_FILE_NAME_CUPONS(
          campaignResume?.name,
          moment().format("L HH[h]mm")
        )
      )}
      linkText={freire(texts.DOWNLOAD_CSV)}
      loading={loading}
      onClick={onDownloadExcel}
      headers={headers}
      data={data}
    />
  );
};

export default CouponsCsv;
