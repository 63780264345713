import axios from "axios";
// import firebase from "./firebaseConfig";

const apiLogs = axios.create({
  baseURL: process.env.REACT_APP_OCPP_LOGS_API_URL,
});

// api.interceptors.request.use(async (config) => {
//   const token = await firebase.auth().currentUser.getIdToken();

//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }

//   return config;
// });

export default apiLogs;
