import { ILanguageTranslation } from "../../interfaces/languages";

export const COPY: ILanguageTranslation = {
  pt: "Copiar",
  es: "Copiar",
  en: "Copy",
};

export const COPIED: ILanguageTranslation = {
  en: "Copied successfully!",
  es: "¡Copiado con éxito!",
  pt: "Copiado com sucesso!",
};
