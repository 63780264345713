import { useState, useEffect, useCallback } from "react";
import {
  Spin,
  Collapse,
  notification,
  Empty,
  Table,
  Button,
  Modal,
  Tag,
  Dropdown,
  Menu,
} from "antd";
import api from "../../../../../services/api";
import { useFreire } from "../../../../../utils/freireContext";
import * as texts from "../../../../../pages/Users/locales";
import CouponBenefitTag from "../../../../../components/CouponBenefitTag";
import { CouponIcon } from "../../../../../assets/icons/ticket.svg";
import moment from "moment";
import {
  ProfileIcon,
  ProfileRemove,
} from "../../../../../assets/icons/users.svg";
import { TicketTag } from "../../../../../assets/icons/ticket.svg";
import { COUPON_TYPE } from "../../../../../services/utils";
import { formatters } from "../../../../../utils/formatters";
import { Copy } from "../../../../../components/Copy";

const { Panel } = Collapse;
const { CREDIT } = COUPON_TYPE;

const Coupons = ({ userID }) => {
  const { freire } = useFreire();

  const [loading, setLoading] = useState(false);
  const [userCouponDetails, setUserCouponDetails] = useState(null);
  const [clickedButtonIndex, setClickedButtonIndex] = useState(null);
  const [unlinkModal, setUnlinkModal] = useState({
    isVisible: false,
    data: null,
  });

  const getCouponDetails = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await api.get(`admin/user/${userID}`);

      const currentDate = new Date();
      const filteredCoupons = data.coupons.filter((coupon) => {
        const expireDate = new Date(
          coupon.campaign.expireDate || coupon.createdAt
        );
        const differenceInMilliseconds = currentDate - expireDate;
        const millisecondsInYear = 1000 * 60 * 60 * 24 * 365;
        return differenceInMilliseconds <= millisecondsInYear;
      });

      filteredCoupons.sort((a, b) => {
        const dateA = new Date(a.campaign.expireDate || a.createdAt);
        const dateB = new Date(b.campaign.expireDate || b.createdAt);
        return dateB - dateA;
      });

      setUserCouponDetails({
        coupons: [...filteredCoupons],
        email: data.email,
      });
    } catch (error) {
      notification.error({
        message: freire(texts.OOPS),
        description: freire(texts.ERROR_GET_DETAILS_COUPON),
      });
    } finally {
      setLoading(false);
    }
  }, [userID, freire]);

  useEffect(() => {
    getCouponDetails();
  }, [getCouponDetails]);

  const handleUnlinkCoupon = async (couponID) => {
    try {
      await api.patch(`/coupon_unlink/${couponID}`);

      setUserCouponDetails((prevUserCouponDetails) => ({
        ...prevUserCouponDetails,
        coupons: prevUserCouponDetails.coupons.filter(
          (coupon) => coupon.id !== couponID
        ),
      }));

      handleCancel();

      notification.success({
        message: freire(texts.OK_COUPON),
        description: freire(texts.COUPON_UNLINK_SUCCESS),
      });
    } catch (error) {
      notification.error({
        message: freire(texts.OOPS),
        description: freire(texts.ERROR_UNLINK_COUPON),
      });
    }
  };

  const showModal = (data) => {
    setUnlinkModal({ isVisible: true, data: data });
  };

  const handleOk = () => {
    setUnlinkModal({ ...unlinkModal, isVisible: false });
  };

  const handleCancel = () => {
    setUnlinkModal({ ...unlinkModal, isVisible: false });
  };

  const formatExpireDate = (expireDate) => {
    const formattedDate = moment(expireDate).format("DD/MM/YYYY");
    const formattedTime = moment(expireDate).format("HH:mm");
    return (
      <>
        {formattedDate}
        <br />
        {formattedTime}
      </>
    );
  };
  const renderStatus = (status, expireDate) => {
    const statusDetails = {
      expired: {
        text: freire(texts.EXPIRED),
        color: "#F40000",
      },
      inUse: {
        text: freire(texts.IN_USE),
        color: "#029B2D",
      },
      inactive: {
        text: freire(texts.INACTIVE),
      },
    };

    const details = statusDetails[status];
    if (!details) return null;

    return (
      <div>
        <span style={{ color: details.color }}>{details.text}</span>
        {expireDate && (
          <div>
            <span>{freire(texts.EXPIRATION)}:</span>
            <br />
            <span>{formatExpireDate(expireDate)}</span>
          </div>
        )}
      </div>
    );
  };

  const renderUsageLimit = (usageLimit) => {
    return usageLimit === -1 ? "Sem Limite" : usageLimit;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setClickedButtonIndex(null);
    }, 500);
    return () => clearTimeout(timer);
  }, [clickedButtonIndex]);

  return (
    <Collapse
      bordered={false}
      onChange={getCouponDetails}
      expandIconPosition="right"
      style={{ marginBottom: 30 }}
    >
      <Panel
        key="personalData"
        id="panel"
        header={
          <span className="drawerInfoTitle">
            {freire(texts.ASSOCIATECUPONS_DATA)}
          </span>
        }
      >
        {loading && (
          <Spin
            style={{ display: "block", marginTop: 25, marginBottom: 25 }}
            tip={freire(texts.LOADING_CUPON_DETAILS)}
          />
        )}
        {!loading &&
          userCouponDetails &&
          userCouponDetails.coupons.length === 0 && (
            <Empty description={freire(texts.USER_EMPTY_COUPONS)} />
          )}
        {!loading &&
          userCouponDetails &&
          userCouponDetails.coupons.length > 0 && (
            <Table
              rowKey="_id"
              pagination={false}
              dataSource={userCouponDetails.coupons}
              columns={[
                {
                  title: freire(texts.COUPON_INFO),
                  dataIndex: "cod",
                  key: "cod",
                  render: (text, data, index) => (
                    <div>
                      <div>
                        <strong>
                          <Copy>{data.campaign.name}</Copy>
                        </strong>
                      </div>
                      <div style={{ marginBottom: "5px" }}>
                        <Copy>{data.campaign.company.companyName}</Copy>
                      </div>
                      <CouponBenefitTag
                        benefit={data.campaign.benefit}
                        currency={data.currency}
                        freire={freire}
                      />
                      <div
                        style={{
                          marginBottom: "2px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <CouponIcon />
                        <Copy style={{ margin: "5px" }}>{data.cod}</Copy>
                      </div>
                      {data.campaign.benefit?.type === CREDIT && (
                        <div
                          style={{
                            marginBottom: "2px",
                          }}
                        >
                          {/* TODO: translate */}
                          <strong>Saldo:</strong>{" "}
                          <span
                            {...(data.balance < 0 && {
                              style: { color: "#b670c2" },
                            })}
                          >
                            {formatters.currency(
                              freire.userLanguage,
                              data.currency
                            )(data.balance / 100)}
                          </span>
                        </div>
                      )}
                    </div>
                  ),
                },
                {
                  title: freire(texts.COUPON_USAGE),
                  dataIndex: "usageQty",
                  key: "usageQty",
                  render: (text, data, index) => (
                    <div>
                      {data.usageLimit === -1 ? (
                        <div>
                          <div style={{ marginBottom: "2px" }}>
                            <strong>{data.usageQty}</strong>
                          </div>
                          <div style={{ marginBottom: "7px" }}>
                            {renderUsageLimit(data.usageLimit)}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <strong>{data.usageQty}</strong> de{" "}
                          {renderUsageLimit(data.usageLimit)}
                        </div>
                      )}
                    </div>
                  ),
                },
                {
                  title: freire(texts.COUPON_STATUS),
                  dataIndex: "status",
                  key: "status",
                  render: (status, record) =>
                    renderStatus(status, record.campaign.expireDate),
                },
                {
                  title: freire(texts.COUPON_ACTIONS),
                  key: "actions",
                  render: (_, record, dataIndex) => (
                    <div>
                      {(record.status === "inUse" ||
                        record.status === "inactive" ||
                        record.status === "available") && (
                        <Dropdown
                          overlay={
                            <Menu
                              onClick={({ key }) => {
                                if (key === "unlink") {
                                  showModal(record);
                                }
                              }}
                            >
                              <Menu.Item
                                key="unlink"
                                style={{ padding: "8px 24px" }}
                              >
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    minWidth: "150px",
                                    minHeight: "30px",
                                  }}
                                >
                                  <ProfileRemove
                                    style={{
                                      width: 12,
                                      height: 12,
                                      marginRight: 8,
                                    }}
                                  />
                                  <span style={{ color: "#1890FF" }}>
                                    {freire(texts.COUPON_UNLINK)}
                                  </span>
                                </span>
                              </Menu.Item>
                            </Menu>
                          }
                        >
                          <Button
                            type="primary"
                            style={{
                              padding: "0px 8px",
                              lineHeight: "20px",
                              height: "20px",
                              fontSize: "26px",
                              border: "none",
                              backgroundColor:
                                clickedButtonIndex === dataIndex
                                  ? "#1890FF"
                                  : "transparent",
                              color:
                                clickedButtonIndex === dataIndex
                                  ? "#FFFFFF"
                                  : "#000000",
                            }}
                          >
                            <div>...</div>
                          </Button>
                        </Dropdown>
                      )}
                    </div>
                  ),
                },
              ]}
            />
          )}
        <Modal
          centered
          width={"50%"}
          style={{ maxWidth: 400 }}
          footer={null}
          closable={false}
          visible={unlinkModal.isVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          maskClosable={true}
          maskStyle={{ backgroundColor: "transparent" }}
        >
          <div
            className="addCouponLabel"
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <ProfileRemove />
            <span
              style={{
                textTransform: "uppercase",
                marginLeft: "7px",
                fontSize: "16px",
              }}
            >
              {freire(texts.COUPON_UNLINK)}
            </span>
          </div>
          <Tag
            style={{
              borderRadius: "6px",
              display: "inline-flex",
              flexDirection: "column",
              fontSize: "16px",
              backgroundColor: "#F3F2F3",
              border: "none",
              color: "#747277",
              padding: "5px 10px",
            }}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                marginBottom: "5px",
                marginTop: "5px",
              }}
            >
              <div style={{ marginRight: "4px", marginLeft: "4px" }}>
                <TicketTag />
              </div>
              <span>{unlinkModal.data && unlinkModal.data.cod}</span>
            </span>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <div style={{ marginRight: "4px" }}>
                <ProfileIcon />
              </div>
              <span>{userCouponDetails && userCouponDetails.email}</span>
            </span>
          </Tag>
          <p style={{ margin: "10px", fontSize: "16px" }}>
            {freire(texts.ARE_YOU_SURE_UNLINK_COUPON)}
          </p>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            {unlinkModal.data && (
              <Button
                type="primary"
                onClick={() => handleUnlinkCoupon(unlinkModal.data.id)}
                style={{
                  width: "180px",
                  height: "45px",
                  borderRadius: "6px",
                  backgroundColor: "#F40000",
                  color: "#FFFFFF",
                  fontSize: "20px",
                  margin: "10px",
                  border: "none",
                }}
              >
                {freire(texts.UNLINK)}
              </Button>
            )}
            <Button
              onClick={handleCancel}
              style={{
                width: "180px",
                height: "45px",
                borderRadius: "6px",
                border: "1px solid ##CECECD",
                color: "#565559",
                fontSize: "20px",
                margin: "10px",
              }}
            >
              {freire(texts.CANCEL)}
            </Button>
          </div>
        </Modal>
      </Panel>
    </Collapse>
  );
};

export default Coupons;
