import React, { useContext } from "react";

export const FreireContext = React.createContext();

export const useFreire = () => {
  // TODO: validate if context is active
  const freire = useContext(FreireContext);
  return freire;
  // TODO: return { freire };
  // TODO: use formatters in context
};
