import React from "react";

// adapted from https://assets.freshping.io/static/media/up_2.307e4106.svg
export const Success = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none">
      <g transform="translate(-480.000000, -303.000000)">
        <g transform="translate(90.000000, 274.000000)">
          <g transform="translate(390.000000, 29.000000)">
            <circle fill="currentColor" cx="50%" cy="50%" r="9" />
            <polyline
              stroke="white"
              strokeWidth="1.6"
              strokeLinecap="round"
              strokeLinejoin="round"
              transform="translate(9.033903, 8.925632) rotate(-360.000000) translate(-9.033903, -8.925632)"
              points="5.7339027 9.1665427 7.77329526 11.2059353 11.0467215 7.932509 11.7573917 7.2218388 12.3339027 6.64532782"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
