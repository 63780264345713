const generateFields = ({
  companyOptions,
  onChangeCompany,
  stationOptions,
}) => [
  {
    label: "Empresas",
    name: "company_ids",
    component: "tupi.select",
    isMultiple: true,
    placeholder: "Selecione as empresas",
    options: companyOptions,
    onChange: onChangeCompany,
    colSpan: 8,
  },
  {
    label: "Estações",
    name: "station_ids",
    component: "tupi.select",
    isMultiple: true,
    placeholder: "Selecione as estações",
    options: stationOptions,
    colSpan: 8,
    help: "Você pode pesquisar uma estação pelo ID, nome ou endereço. ",
  },

  {
    label: "Período",
    name: "dateTime",
    component: "rangePicker",
    locale: "pt_BR",
    placeholder: ["Data inicial", "Data final"],
    colSpan: 8,
  },
];

export default generateFields;
