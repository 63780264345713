import React, { useEffect } from "react";
import { Button, Col, Form, Input, Row, Drawer, Switch, Select } from "antd";
import { Show } from "../../../contexts/PermissionContext";
import { useFreire } from "../../../utils/freireContext";
import * as texts from "../locales";

const { Option } = Select;

const EditDrawer = ({
  form,
  visible,
  loading,
  onClose,
  onSave,
  campaign,
  companies,
}) => {
  useEffect(() => {
    if (visible) {
      form.setFieldsValue(campaign);
      form.setFieldsValue({ company: campaign.company?._id || "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);
  const { freire } = useFreire();

  const onClickSave = () => {
    form.validateFields(async (err, payload) => {
      if (err) {
        return;
      }

      onSave(payload);
    });
  };

  return (
    <Drawer
      title={freire(texts.EDIT_CAMPAIGN)}
      width={500}
      onClose={() => onClose()}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form
        id="form_edit_rfid_campaign"
        hideRequiredMark
        colon={false}
        wrapperCol={{ span: 24 }}
      >
        <Show when={"edit-rfid-company"}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label={freire(texts.COMPANY)}>
                {form.getFieldDecorator("company")(
                  <Select placeholder={freire(texts.SELECT_COMPANY)}>
                    {companies.map((element) => {
                      return (
                        <Option key={element._id} value={element._id}>
                          {element.companyName}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Show>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label={
                <span>
                  {freire(texts.CLIENT)}{" "}
                  <span style={{ fontSize: 13, fontWeight: "normal" }}>
                    {freire(texts.OPTIONAL)}
                  </span>
                </span>
              }
            >
              {form.getFieldDecorator("clientName")(
                <Input placeholder={freire(texts.INFORM_CLIENT_NAME)} />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label={freire(texts.CAMPAIGN_NAME)}>
              {form.getFieldDecorator("name", {
                rules: [
                  {
                    required: true,
                    message: freire(texts.REQUIRED_FIELD),
                  },
                ],
              })(<Input placeholder={freire(texts.INFORM_CLIENT_NAME)} />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label={freire(texts.CAMPAIGN_STATUS)}>
              {form.getFieldDecorator("enabled", {
                valuePropName: "checked",
              })(
                <Switch
                  checkedChildren={freire(texts.ACTIVE)}
                  unCheckedChildren={freire(texts.DESACTIVE)}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div
        style={{
          position: "absolute",
          right: 0,
          bottom: 0,
          width: "100%",
          borderTop: "1px solid #e9e9e9",
          padding: "10px 16px",
          background: "#fff",
          textAlign: "right",
        }}
      >
        <Button onClick={onClose} style={{ marginRight: 20 }}>
          {freire(texts.CANCEL)}
        </Button>
        <Button loading={loading} onClick={onClickSave} type="primary">
          {freire(texts.SAVE)}
        </Button>
      </div>
    </Drawer>
  );
};

const ComponentEditDrawer = Form.create({ name: "edit" })(EditDrawer);
export default ComponentEditDrawer;
