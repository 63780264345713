import { Input, Form, Col, Row, Collapse, Icon} from "antd";
import { useFreire } from "../../../../../utils/freireContext";
import * as texts from "../../../locales";
import { useNewCampaignContext } from "../../context";
import MyDivider from "../../../../../components/MyDivider";

const { Panel } = Collapse;

const Notification = ({ showDivider = true }) => {
  const { freire } = useFreire();
  const { form } = useNewCampaignContext();

  const { getFieldDecorator } = form;

  const customExpandIcon = (panelProps) => (
    <Icon
      type={panelProps.isActive ? 'up' : 'down'}
      style={{ color: "#fd5876", marginLeft: '-12px'}}
    />
  );

  return (
    <Collapse 
      bordered={false} 
      expandIcon={customExpandIcon}
    >
      <Panel 
        id="panel"
        style={{ borderBottom: "none" , background: "#fff"}} 
        header={<span style={{ color: "#fd5876", fontWeight: "bold", marginLeft: "30px" }}>{freire(texts.NOTIFICATION)}</span>}
        key="1">
        <p style={{ color: "#747277" }}>
          {freire(texts.NOTIFICATION_DESCRIPTION)}
        </p>
        <Row gutter={[16, 0]}>
          <Col span={18}>
            <Form.Item
              label={freire(texts.TITLE)}>
              {getFieldDecorator("title")(
                <Input placeholder={freire(texts.TITLE_PLACEHOLDER)} />
              )}
            </Form.Item>
          </Col>
          <Col span={18}>
            <Form.Item label={freire(texts.MESSAGE)}>
              {getFieldDecorator("message")(
                <Input placeholder={freire(texts.MESSAGE_PLACEHOLDER)} />
              )}
            </Form.Item>
          </Col>
        </Row>
        {showDivider && <MyDivider />}
      </Panel>
    </Collapse>
  );
};

export default Notification;