import moment from "moment-timezone";

const YEARS = {
  pt: "a",
  es: "a",
  en: "y",
};

const MONTHS = {
  pt: "m",
};

const DAYS = {
  pt: "d",
};

const HOURS = {
  pt: "h",
};

const MINS = {
  pt: "min",
};

const SECONDS = {
  pt: "s",
};

export const duration =
  (freire) =>
  (
    totalDuration,
    unit,
    showSeconds = true,
    {
      // TODO: test
      years: showYears = true,
      months: showMonths = true,
      days: showDays = true,
      hours: showHours = true,
      minutes: showMinutes = true,
    } = {}
  ) => {
    const parts = [];
    const duration = moment.duration(totalDuration, unit);

    let monthsToAdd = 0;
    let daysToAdd = 0;
    let hoursToAdd = 0;
    let minutesToAdd = 0;
    let secondsToAdd = 0;

    if (duration.valueOf() < 1000) return `${0}${freire(MINS)}`;

    if (duration.years() > 0) {
      if (showYears) {
        const years = Math.floor(duration.years());
        parts.push(`${years}${freire(YEARS)}`);
        // parts.push(years+" "+(years > 1 ? "anos" : "ano"));
      } else {
        monthsToAdd += duration
          .clone()
          .subtract(duration.months(), "months")
          .asMonths();
      }
    }

    if (duration.months() > 0) {
      if (showMonths) {
        const months = monthsToAdd + Math.floor(duration.months());
        parts.push(`${months}${freire(MONTHS)}`);
        // parts.push(months+" "+(months > 1 ? "meses" : "mês"));
      } else {
        daysToAdd += duration
          .clone()
          .subtract(duration.days(), "days")
          .asDays();
      }
    }

    if (duration.days() > 0) {
      if (showDays) {
        const days = daysToAdd + Math.floor(duration.days());
        parts.push(`${days}${freire(DAYS)}`);
        // parts.push(days+" "+(days > 1 ? "dias" : "dia"));
      } else {
        hoursToAdd += duration
          .clone()
          .subtract(duration.hours(), "hours")
          .asHours();
      }
    }

    if (duration.hours() > 0) {
      if (showHours) {
        const hours = hoursToAdd + Math.floor(duration.hours());
        parts.push(`${hours}${freire(HOURS)}`);
        // parts.push(hours+" "+(hours > 1 ? "horas" : "hora"));
      } else {
        minutesToAdd += duration
          .clone()
          .subtract(duration.hours(), "hours")
          .asHours();
      }
    }

    if (duration.minutes() > 0) {
      if (showMinutes) {
        const minutes = minutesToAdd + Math.floor(duration.minutes());
        parts.push(`${String(minutes).padStart(2, "0")}${freire(MINS)}`);
        // parts.push(minutes+" "+(minutes > 1 ? "minutos" : "minuto"));
      } else {
        secondsToAdd += duration
          .clone()
          .subtract(duration.hours(), "hours")
          .asHours();
      }
    }

    if (showSeconds && duration.seconds() > 0) {
      const seconds = secondsToAdd + Math.floor(duration.seconds());
      parts.push(`${String(seconds).padStart(2, "0")}${freire(SECONDS)}`);
      // parts.push(seconds+" "+(seconds > 1 ? "segundos" : "segundo"));
    }

    if (!showSeconds && parts.length === 0) {
      const seconds = Math.floor(duration.seconds());
      parts.push(`${String(seconds).padStart(2, "0")}${freire(SECONDS)}`);
      // TODO: check 0min
    }

    // TODO check 1h 01min 01s

    return parts.join(" ");
  };

// TODO: remove
export const durationOneDayTweak =
  (freire) =>
  (
    totalDuration,
    unit,
    showSeconds = true,
    {
      years: showYears = true,
      months: showMonths = true,
      days: showDays = true,
      hours: showHours = true,
      minutes: showMinutes = true,
    } = {}
  ) => {
    return duration(freire)(totalDuration, unit, showSeconds, {
      years: showYears,
      months: showMonths,
      days: showDays,
      hours: showHours,
      minutes: showMinutes,
    }).replace(/^1d$/, "24" + freire(HOURS));
  };
