import React, { useMemo } from "react";
import TablePagination from "../../../../../components/TablePagination";
import { useFreire } from "../../../../../utils/freireContext";
import { COUPON_TYPE } from "../../../../../services/utils";

import CouponsColumns from "./columns";

const { FREE, PERCENTAGE, FIXED_VALUE, CREDIT } = COUPON_TYPE;

const CouponsTable = ({
  loading,
  couponsList,
  currentPage,
  totalCoupons,
  onPagination,
  campaignResume,
  actions,
}) => {
  const { freire } = useFreire();

  const columns = useMemo(() => {
    const couponsColumns = CouponsColumns.getColumns(
      freire,
      campaignResume,
      actions
    );

    const commonColumns = [
      couponsColumns.cod,
      couponsColumns.status,
      couponsColumns.user,
      couponsColumns.actions
    ];

    if (campaignResume?.autoLinkCouponType) {
      commonColumns.splice(1, 0, couponsColumns.autoLinkCouponType);
    }

    if (
      [FREE, PERCENTAGE, FIXED_VALUE].includes(campaignResume?.benefit?.type)
    ) {
      commonColumns.splice(2, 0, couponsColumns.usageLimit, couponsColumns.usageQty)
    } else if (campaignResume?.benefit?.type === CREDIT) {
      commonColumns.splice(2, 0, couponsColumns.balance, couponsColumns.actions);
    }

    return commonColumns;
  }, [freire, campaignResume, actions]);

  return (
    <TablePagination
      style={{ backgroundColor: "white" }}
      tableLayout="auto"
      loading={loading}
      rowKey={(data) => data?._id}
      dataSource={couponsList}
      columns={columns}
      current={currentPage}
      total={totalCoupons}
      onPagination={onPagination}
    />
  );
};

export default CouponsTable;
