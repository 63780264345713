import React from 'react'
import { Col, Form, Icon, Radio, Row, Switch, Upload } from 'antd'
import { useFreire } from '../../../../../utils/freireContext';
import { useNewCampaignContext } from '../../context';
import * as texts from "../../../locales";
import MyDivider from '../../../../../components/MyDivider';

const { Dragger } = Upload;

export const autoLinkCouponType = {
  EMAIL: 'email',
  CHASSI: 'chassi',
  FULL_REGISTER: 'full_register',
};

export const ELIGIBLE_USERS_TO_USE_COUPON_TYPE = {
  ANYONE: 'ANYONE',
  ELIGIBLE_USERS: 'ELIGIBLE_USERS',
}

export default function EligibleUsers({showDivider = true}) {
  const { freire } = useFreire();
  const { form } = useNewCampaignContext();
  const { getFieldDecorator, getFieldValue } = form as any;

  const isEligibleUsersRule = getFieldValue("eligibleUsersType") === ELIGIBLE_USERS_TO_USE_COUPON_TYPE.ELIGIBLE_USERS

  const requiredFieldRule = {
    required: true,
    message: freire(texts.REQUIRED_FIELD),
  };

  return (
    <>
      <span style={{ color: "#fd5876", fontWeight: "bold", textTransform: 'uppercase' }}>
        {freire(texts.ELIGIBLE_USERS_DEFINITION)}
      </span>
      <Row gutter={[8, 0]}>
        <Col span={16}>
          <Form.Item label={freire(texts.ELIGIBLE_USERS_TO_RECIEVE_COUPON)}>
            {getFieldDecorator("eligibleUsersType", {
              initialValue: ELIGIBLE_USERS_TO_USE_COUPON_TYPE.ANYONE,
              rules: [requiredFieldRule]
            })(
              <Radio.Group
                buttonStyle="solid"
                className="customRadioGroup"
              >
                <Radio.Button value={'ANYONE'}>
                  {freire(texts.ANYONE_WHO_ADD_COUPON)}
                </Radio.Button>
                <Radio.Button value={'ELIGIBLE_USERS'}>
                  {freire(texts.SPECIFIC_USERS)}
                </Radio.Button>
              </Radio.Group>
            )}
          </Form.Item>
        </Col>
      </Row>
      {isEligibleUsersRule && (
        <>
          <Row gutter={[16, 0]}>
            <Col span={8}>
              <Form.Item label={freire(texts.SELECTED_FROM)}>
                {getFieldDecorator("autoLinkCouponType", {
                  initialValue: autoLinkCouponType.CHASSI,
                  ...(isEligibleUsersRule && { rules: [requiredFieldRule] }),
                })(
                  <Radio.Group
                    buttonStyle="solid"
                    className="customRadioGroup"
                  >
                    <Radio value={autoLinkCouponType.CHASSI}>
                      {freire(texts.CHASSI)}
                    </Radio>
                    <Radio value={autoLinkCouponType.EMAIL}>
                      {freire(texts.EMAIL)}
                    </Radio>
                    {/* <Radio value={autoLinkCouponType.FULL_REGISTER}>
                      {freire(texts.FULL_REGISTER)}
                    </Radio> */}
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item>
                {getFieldDecorator('couponsSheet', {
                  ...(isEligibleUsersRule && { rules: [requiredFieldRule] })
                })(
                  <Dragger
                    name="file"
                    multiple={false}
                    beforeUpload={() => false}
                    >
                    <p className="ant-upload-text">
                      <Icon type="upload" />
                      {freire(texts.CLICK_OR_DRAG_HERE)}
                    </p>
                  </Dragger>
                )}
              </Form.Item>
            </Col>
          </Row>
        </>
      )}

      <Row>
        <Col span={8}>
          <Form.Item
            className="custom-form-item"
            labelAlign="right"
            label={freire(texts.ONLY_USERS_WITHOUT_CHARGE)}
          >
            {getFieldDecorator("onlyUsersWithoutCharge", {
              initialValue: false,
              valuePropName: "checked",
            })(
              <Switch
                checkedChildren={freire(texts.YES)}
                unCheckedChildren={freire(texts.NO)}
              />
            )}
          </Form.Item>
        </Col>
      </Row>

      {showDivider && <MyDivider />}
    </>
  )
}
