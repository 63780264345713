export const phone = (phone) => {
  try {
    if (phone && typeof phone === "string") {
      const phoneFormat = /^\+\d{2}(\d{2})(\d{4,5})(\d{4})$/;
      const match = phone.match(phoneFormat);

      if (match) {
        const [, ddd, firstSubPhone, secondSubPhone] = match;
        return `(${ddd}) ${firstSubPhone}-${secondSubPhone}`;
      }
    }

  } catch (error) {
    console.error("Erro ao formatar telefone:", error);
  }
  return phone;
};