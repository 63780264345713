export const CAAS_CONTRACT_MODEL = {
  en: "CaaS (Charger as a service)",
};

export const EAAS_CONTRACT_MODEL = {
  en: "EaaS (Energy as a service)",
};

export const SAAS_CONTRACT_MODEL = {
  en: "SaaS (Software as a service)",
};

export const NO_INFO = {
  pt: "Sem info",
  en: "No info",
  es: "Sin info",
};

export const YES = {
  pt: "Sim",
  es: "SÍ",
  en: "Yes",
};

export const NO = {
  pt: "Não",
  es: "No",
  en: "No",
};

export const ERROR_GET_STATIONS_LOCATIONS = {
  pt: "Erro ao buscar locais de estação",
  es: "Error al buscar lugares de estación",
  en: "Error looking for station locations",
};

export const ERROR_GET_STATIONS_MODELS = {
  pt: "Erro ao buscar modelos de estação",
  es: "Error al buscar modelos de estación",
  en: "Error looking for station models",
};

export const ERROR_GET_STATIONS_TYPES = {
  pt: "Erro ao buscar tipos de estação",
  es: "Error al buscar tipos de estación",
  en: "Error looking for station types",
};

export const ERROR_GET_PLUGS = {
  pt: "Erro ao buscar plugs",
  es: "Error al buscar plugs",
  en: "Error looking for plugs",
};

export const ERROR_INFO_RESERVE_PAYMENT = {
  pt: "Informe o valor do pagamento da reserva",
  es: "Informe el valor del pago de la reserva",
  en: "Enter the payment amount of the reservation",
};

export const ERROR_INFO_CHARGE_PAYMENT_METHOD = {
  pt: "Informe o método do pagamento da carga",
  es: "Informe el método del pago de la carga",
  en: "Specify the method of payment of the charge",
};

export const ERROR_INFO_IDLE_FEE = {
  pt: "Informe o valor da taxa de ociosidade e o período de tolerância.",
  es: "Informe el valor de la tasa de ociosidad y el período de tolerancia.",
  en: "Please provide the idle fee value and the grace period.",
};

export const ERROR_INFO_CHARGE_PAYMENT = {
  pt: "Informe o valor do pagamento do método da carga",
  es: "Informe el valor del pago del método de la carga",
  en: "Specify the payment amount of the charge method",
};

export const METHOD_TIME_MIN = {
  pt: "Minuto(s)",
  en: "Minute(s)",
  es: "Minuto(s)",
};

export const METHOD_TIME_HOUR = {
  pt: "Hora(s)",
  en: "Hour(s)",
  es: "Hora(s)",
};

export const PAYMENT_DESCRIPTION_INCOMPLETE = {
  pt: "Complete as informações",
  en: "Complete the information",
  es: "Complete la información",
};

export const POWER_PLUG = (powerPlug) => ({
  pt: `Tomada ${powerPlug}`,
  es: `Conector ${powerPlug}`,
  en: `Outlet ${powerPlug}`,
});

export const PLUG = {
  pt: "Plug",
  es: "Enchufe",
  en: "Plug",
};

export const CURRENT = {
  pt: "Corrente",
  es: "Corriente",
  en: "Current",
};

export const POWER = (power) => ({
  pt: `Potência${power && " " + power}`,
  es: `Potencia${power && " " + power}`,
  en: `Power${power && " " + power}`,
});

export const SELECT_POWER = {
  pt: "Selecione a potência",
  es: "Seleccione la potencia",
  en: "Select the power",
};

export const STATION_EDIT = {
  pt: "Editar estação",
  es: "Editar estación",
  en: "Edit station",
};

export const INFORMATION = {
  pt: "DADOS GERAIS",
  es: "DATOS GENERALES",
  en: "GENERAL DATA",
};

export const NAME = {
  pt: "Nome",
  es: "Nombre",
  en: "Name",
};
export const STATION_NAME = {
  pt: "Nome da estação",
  es: "Nombre de la estación",
  en: "Station name",
};

export const COMPANY = {
  pt: "Empresa",
  es: "Empresa",
  en: "Company",
};

export const WITHOUT_COMPANY = {
  pt: "Nenhuma empresa",
  es: "Sin compañía",
  en: "Without company",
};

export const SELECT_COMPANY_WARN = {
  pt: "Só é possível alterar empresa alterando o local",
  es: "Solo se puede cambiar la empresa cambiando la ubicación",
  en: "Only the company can be changed by changing the location",
};

export const SELECT_COMPANY = {
  pt: "Selecione a empresa",
  es: "Seleccione la empresa",
  en: "Select the company",
};

export const STATION_ACTIVE = {
  pt: "Estação está ativa",
  es: "Estación está activa",
  en: "Station is active",
};

export const STATION_DISABLED = {
  pt: "Estação está desativada",
  es: "Estación está desactivada",
  en: "Station is disabled",
};

export const SELECT_COUNTRY = {
  pt: "País",
  en: "Country",
  es: "País",
};

export const ADDRESS = {
  pt: "Endereço",
  es: "Dirección",
  en: "Address",
};

export const LATITUDE = {
  pt: "Latitude",
  es: "Latitud",
  en: "Latitude",
};

export const LONGITUDE = {
  pt: "Longitude",
  es: "Longitud",
  en: "Longitude",
};

export const PRIVATE_STATION = {
  pt: "Estação privada",
  es: "Estación privada",
  en: "Private station",
};

export const OPERATION = {
  pt: "OPERAÇÃO",
  es: "OPERACIÓN",
  en: "OPERATION",
};

export const PLUGS = {
  pt: "Plugs",
  es: "Enchufe",
  en: "Plugs",
};

export const ADD_POWER_PLUG = {
  pt: "+ Adicionar tomada",
  es: "+ Agregar conector",
  en: "+ Add outlet",
};

export const REMOVE_ALL_POWER_PLUGS = {
  pt: "- Remover todas tomadas",
  es: "- Eliminar todos los conectores",
  en: "- Remove all outlets",
};

export const ADD_ADAPTERS = {
  pt: "Possui adaptadores",
  es: "Hay adaptadores",
  en: "Has adapters",
};

export const REQUIRED_FIELD = {
  pt: "Campo obrigatório",
  es: "Campo obligatorio",
  en: "Mandatory field",
};

export const ADAPTERS = {
  pt: "Selecione os adaptadores",
  es: "Seleccione los adaptadores",
  en: "Select the adapters",
};

export const BUSINESSHOURS = {
  pt: "Horário de funcionamento",
  en: "Business hours",
  es: "Horario de funcionamiento",
};

export const BUSINESSHOURS_INFO = {
  pt: "Selecione os horários e os dias da semana em que a estação funciona.",
  en: "Select the hours and days of the week in which the station works.",
  es: "Selecciona los horarios y los días de la semana en que la estación funciona.",
};

export const PHONE = {
  pt: "Telefone",
  es: "Teléfono",
  en: "Phone",
};

export const FREE_PARKING = {
  pt: "Estacionamento Grátis",
  es: "Estacionamiento Grátis",
  en: "Free parking",
};

export const PLUGS_CONCURRENTLY = {
  pt: "Conectores funcionam simultaneamente",
  es: "Conectores funcionan simultaneamente",
  en: "Plugs work concurrently",
};

export const TEXT_CONNECTORS = {
  pt: "Quando há uma carga em andamento, os outros conectores continuam disponíveis.",
  en: "When there is a load in progress, the other connectors remain available.",
  es: "Cuando hay una carga en curso, los otros conectores siguen disponibles.",
};

export const NO_INFORMATIONS = {
  pt: "Sem informações",
  es: "Sin información",
  en: "No information",
};

export const SIMULTANEOUS_POWER = {
  pt: "Potência",
  en: "Power",
  es: "Potencia",
};

export const TEXT_POWER = {
  pt: "Quando estão todos conectados, a potência total entregue:",
  en: "When all connected, the total power delivered is:",
  es: "Cuando todos están conectados, la potencia total entregada es:",
};

export const IS_DIVIDE_POWER = {
  pt: "É dividida entre os conectores",
  en: "It is divided between the connectors",
  es: "Se divide entre los conectores",
};

export const FIXED_POWER_BETWEEN_PLUGS = {
  pt: "Continua fixa nos conectores",
  en: "Remains fixed in the connectors",
  es: "Permanece fija en los conectores",
};

export const VISIBILITY = {
  pt: "Visibilidade",
  es: "Visibilidad",
  en: "Visibility",
};

export const HIDDEN = {
  pt: "Oculto",
  es: "Oculto",
  en: "Hidden",
};

export const GROUPS = {
  pt: "Grupos",
  es: "Grupos",
  en: "Groups",
};

export const ALL = {
  pt: "Todos",
  es: "Todos",
  en: "All",
};

export const COUPONS_PAYMENT = {
  pt: "PAGAMENTO",
  en: "PAYMENT",
  es: "PAGO",
};

export const CHARGE = {
  pt: "Carga",
  es: "Carga",
  en: "Charge",
};

export const IDLE_FEE = {
  pt: "Tarifas",
  en: "Idle fee",
  es: "Tarifas",
};

export const ACCEPT_IDLE_FEE = {
  pt: "Ativar taxa de ociosidade",
  en: "Activate idle fee",
  es: "Activar tarifa de ociosidad",
};

export const VALUE_PER_MINUTE = (currency) => ({
  pt: `Valor por minuto (${currency})`,
  en: `Value per minute (${currency})`,
  es: `Valor por minuto (${currency})`,
});

export const GRACE_PERIOD = {
  pt: "Tempo de carência (minuto)",
  en: "Grace period (minute)",
  es: "Período de gracia (minuto)",
};

export const GRACE_PERIOD_MINUTES = {
  pt: "Em minutos",
  en: "In minutes",
  es: "En minutos",
};

export const PAYMENT_CHARGE = {
  pt: "Pagamento na carga",
  es: "Pago en la carga",
  en: "Payment on charge",
};

export const ACCEPT_COUPONS_CHARGE = {
  pt: "Aceita cupom na carga",
  es: "Acepta cupón en la carga",
  en: "Accepts voucher on charge",
};

export const POS_PAYMENT = {
  pt: "Pagamento no caixa",
  en: "Payment at the cashier",
  es: "Pago en caja",
};

export const METHOD = {
  pt: "Modelo de cobrança",
  en: "Payment method",
  es: "Modelo de cobro",
};

export const METHOD_KWH = {
  pt: "Por kWh",
  en: "By kWh",
  es: "Por kWh",
};

export const METHOD_TIME = {
  pt: "Por tempo",
  en: "By time",
  es: "Por tiempo",
};

export const METHOD_FIXED = {
  pt: "Valor fixo",
  en: "Fixed value",
  es: "Valor fijo",
};

export const VALUE = {
  pt: "Valor",
  es: "Valor",
  en: "Value",
};

export const OF_KWH = {
  pt: "do kWh",
  es: "de kWh",
  en: "of kWh",
};

export const FIXED = {
  pt: "Fixo",
  es: "Fijo",
  en: "Fixed",
};

export const VALUE_EX = {
  pt: "Ex.: 5,00",
  es: "p.ej.: 5,00",
  en: "E.g.: 5,00",
};

export const METHOD_CHARGE = {
  pt: "Cobrança",
  en: "Charge",
  es: "Cobranza",
};

export const METHOD_TIME_RELATIVE = {
  pt: "Proporcional por período de tempo",
  en: "Proportional by time period",
  es: "Proporcional por tamaño de tiempo",
};

export const METHOD_TIME_FIXED = {
  pt: "Fixa por período de tempo",
  en: "Fixed by time period",
  es: "Fixa por tamaño de tiempo",
};

export const PAYMENT_PERIOD = {
  pt: "Período de tempo",
  en: "Time period",
  es: "Período de tiempo",
};

export const METHOD_DESCRIPTION = {
  pt: "a cada",
  en: "every",
  es: "a cada",
};

export const PAYMENT_CHARGE_RESUME = {
  pt: "Resumo da cobrança",
  en: "Charge resume",
  es: "Resumen de la cobranza",
};

export const RESERVE = {
  pt: "Reserva",
  es: "Reserva",
  en: "Reservation",
};

export const ACCEPT_RESERVE = {
  pt: "Aceita reserva",
  es: "Acepta reserva",
  en: "Accepts reservation",
};

export const PAYMENT_RESERVE = {
  pt: "Pagamento na reserva",
  es: "Pago en la reserva",
  en: "Pay During Reservation",
};

export const OTHER_SETTINGS = {
  pt: "OUTRAS CONFIGURAÇÕES",
  es: "OTRAS CONFIGURACIONES",
  en: "OTHER SETTINGS",
};

export const TEST_STATION = {
  pt: "Estação de teste",
  es: "Estación de prueba",
  en: "Test station",
};

export const END_CHARGE_LABEL = {
  pt: "Etiqueta para final de carga",
  es: "Etiqueta para final de carga",
  en: "Label for the end of a charge",
};

export const END_CHARGE_LABEL_INFO = {
  pt: 'Será exibida no app ao finalizar uma carga, conforme imagem abaixo. Na ausência de uma etiqueta, o nome "Tupi" será exibido',
  es: 'Se mostrará en la aplicación al final de una carga, como se muestra en la siguiente imagen. Si no hay etiqueta, aparecerá el nombre "Tupi".',
  en: 'It will appear in the app at the end of a charge, as shown below. In the absence of a label, the name "Tupi" will be displayed',
};

export const LABEL = {
  pt: "Etiqueta",
  es: "Etiqueta",
  en: "Label",
};

export const BRAND_EX = {
  pt: "Ex.: Tupi",
  es: "p.ej.: Tupi",
  en: "E.g.: Tupi",
};

export const ALTERNATIVE_QR_CODE = {
  pt: "QRCode alternativo",
  es: "QRCode alternativo",
  en: "Alternative QRCode",
};

export const ALTERNATIVE_QR_CODE_PLACEHOLDER = {
  pt: "Preencha com o valor do QRCode adesivado na estação",
  es: "Complete con el valor del QRCode pegado en la estación",
  en: "Fill in with the value of the QRCode stuck on the station",
};

export const QUALIFICATION_DATE = {
  pt: "Data da habilitação",
  es: "Fecha de la habilitación",
  en: "Qualification Date",
};

export const SELECT_DATE_TIME = {
  pt: "Selecione a data/hora",
  es: "Seleccione la fecha/hora",
  en: "Select Date/Time",
};

export const STATION_TYPE = {
  pt: "Tipo de estação",
  es: "Tipo de estación",
  en: "Type of station",
};

export const STATION_MODEL = {
  pt: "Marca do carregador",
  es: "Marca del cargador",
  en: "Charger Mark",
};

export const STATION_LOCAL = {
  pt: "Tipo de local",
  es: "Tipo de lugar",
  en: "Type of location.",
};

export const STATION_PARKING_SPOT = {
  pt: "Quantidade de vagas",
  en: "Number of parking spots",
  es: "Capacidad de estacionamiento",
};

export const STATION_PARTNERSHIPS = {
  pt: "Parcerias:",
  en: "Partnerships:",
  es: "Parceiros:",
};

export const PARTNERSHIP = {
  pt: "Parceria:",
  en: "Partnership:",
  es: "Parceiro",
};

export const FLAG_TITLE_RECHARGE = {
  pt: "Tipo de recarga",
  en: "Type of recharge",
  es: "Tipo de recarga",
};

export const FLAG_TITLE_ACCESS = {
  pt: "Tipo de acesso",
  en: "Type of access",
  es: "Tipo de acceso",
};

export const FLAG_TITLE_ROAD = {
  pt: "Tipo de via",
  en: "Type of road",
  es: "Tipo de camino",
};

export const EX_PARAM = (value) => ({
  pt: `Ex.: ${value}`,
  en: `Ex.: ${value}`,
  es: `p.ej.: ${value}`,
});

export const FLAG_ROAD = {
  pt: "Rodovia / Estrada",
  en: "road",
  es: "rodovía / carretera",
};

export const FLAG_ROAD_INFO = {
  pt: "Estações / carregadores que estão localizados em rodovias;",
  en: "Stations / chargers located on roads;",
  es: "Estaciones / cargadores que se encuentran en carreteras;",
};

export const FLAG_URBAN = {
  pt: "Urbana",
  en: "Urban",
  es: "Urbana",
};

export const FLAG_URBAN_INFO = {
  pt: "Estações / carregadores que estão localizados em área urbana.",
  en: "Stations / chargers located in urban areas.",
  es: "Estaciones / cargadores que se encuentran en zonas urbanas.",
};

export const FLAG_DESTINATION = {
  pt: "Destination",
  en: "Destination",
  es: "Destination",
};

export const FLAG_DESTINATION_TITLE = {
  pt: ` "Já que estou aqui"`,
  en: `"Already here"`,
  es: `"Ya estoy aqui"`,
};

export const FLAG_DESTINATION_INFO = {
  pt: "Estações e carregadores que estão localizados em supermercados, estacionamentos, restaurantes, hotéis, prédios corporativos, ou seja, o usuário aproveita que está indo utilizar o mercado e utiliza o carregador do estacionamento para fazer a recarga enquanto compra;",
  en: "Stations and chargers located in supermarkets, parking lots, restaurants, hotels, corporate buildings, that is, the user takes advantage of being about to use the market and uses the parking charger to recharge while shopping.",
  es: "Estaciones y cargadores ubicados en supermercados, estacionamientos, restaurantes, hoteles, edificios corporativos, es decir, el usuario aprovecha que está a punto de usar el mercado y utiliza el cargador del estacionamiento para recargar mientras compra.",
};

export const FLAG_ON_THE_GO = {
  pt: "On The Go",
  en: "On The Go",
  es: "On The Go",
};

export const FLAG_ON_THE_GO_TITLE = {
  pt: ` "Preciso recarregar"`,
  en: `"I need to recharge"`,
  es: `"Necesito recargar"`,
};

export const FLAG_ON_THE_GO_INFO = {
  pt: "Postos de combustíveis e estradas. Locais onde o usuário só para com intuito de fazer a recarga, normalmente são carregadores DC de recarga rápida;",
  en: "Gas stations and highways. Places where the user stops solely for the purpose of recharging, typically featuring fast DC chargers.",
  es: "Estaciones de servicio y autopistas. Lugares donde el usuario se detiene únicamente con el propósito de recargar, generalmente con cargadores rápidos de CC.",
};

export const FLAG_HOME_BASED = {
  pt: "Home Based",
  en: "Home Based",
  es: "Home Based",
};

export const FLAG_HOME_BASED_TITLE = {
  pr: ` "Mais prático"`,
  en: `"More practical"`,
  es: `"Más práctico"`,
};

export const FLAG_HOME_BASED_INFO = {
  pt: "Condomínios residenciais, casas, centros de distribuição das empresas (normalmente pontos privados).",
  en: "Residential condominiums, houses, company distribution centers (usually private locations).",
  es: "Condominios residenciales, casas, centros de distribución de empresas (generalmente puntos privados).",
};

export const FLAG_HUB = {
  pt: "Hub",
  en: "Hub",
  es: "Hub",
};

export const FLAG_HUB_INFO = {
  pt: `O mesmo que “On The Go”, mas com uma infraestrutura mais específica e preparada para atender um volume maior de carros. `,
  en: `The same as "On The Go", but with a more specific infrastructure and prepared to handle a larger volume of cars.`,
  es: `Lo mismo que "On The Go", pero con una infraestructura más específica y preparada para atender un volumen mayor de coches.`,
};

export const FLAG_PUBLIC = {
  pt: "Público",
  en: "Public",
  es: "Público",
};

export const FLAG_PUBLIC_INFO = {
  pt: "Qualquer pessoa pode usar;",
  en: "Anyone can use;",
  es: "Cualquiera puede usar;",
};

export const FLAG_SEMI_PUBLIC = {
  pt: "Semipúblico",
  en: "Semi-public",
  es: "Semipúblico",
};

export const FLAG_SEMI_PUBLIC_INFO = {
  pt: "Qualquer pessoa pode usar, desde que use o estabelecimento onde o carregador está inserido (normalmente serão Destination);",
  en: "Anyone can use it, as long as they use the establishment where the charger is located (usually they will be Destination chargers).",
  es: "Cualquier persona puede usarlo, siempre y cuando utilice el establecimiento donde está ubicado el cargador (normalmente serán cargadores de Destino).",
};

export const FLAG_PRIVATE = {
  pt: "Privado",
  en: "Private",
  es: "Privado",
};

export const FLAG_PRIVATE_INFO = {
  pt: "Locais restritos (exemplo: locais que apenas funcionários da empresa parceira podem usar; apenas frotas de determinada empresa...).",
  en: "Restricted locations (example: places that only employees of the partner company can use; only fleets of a certain company...).",
  es: "Lugares restringidos (ejemplo: lugares que solo los empleados de la empresa asociada pueden usar; solo flotas de una empresa específica...).",
};

export const CANCEL = {
  pt: "Cancelar",
  es: "Cancelar",
  en: "Cancel",
};

export const SAVE = {
  pt: "Salvar",
  es: "Guardar",
  en: "Save",
};

export const CONTRACT_MODEL = {
  pt: "MODELO E CONTRATO",
  en: "MODEL AND CONTRACT",
  es: "MODELO Y CONTRATO",
};

export const TUPI_CHARGER = {
  pt: "Carregador Tupi",
  en: "Tupi Charger",
  es: "Cargador Tupi",
};

export const TUPI_INSTALLER = {
  pt: "Instalação Tupi",
  en: "Tupi Installer",
  es: "Instalación Tupi",
};

export const PARTNER_INSTALLER = {
  pt: "Parceiro que fez a instalação",
  en: "Partner Installer",
  es: "Parceiro que hizo la instalación",
};

export const PARTNER_NAME = {
  pt: "Nome do parceiro",
  en: "Partner Name",
  es: "Nombre del parceiro",
};

export const CONTRACT_MODEL_LOWER_CASE = {
  pt: "Modelo de contrato",
  en: "Contract Model",
  es: "Modelo de contrato",
};

export const SELECT = {
  pt: "Selecione",
  en: "Select",
  es: "Seleccionar",
};

export const REMUNERATION_ENERGY = {
  pt: "Tem remuneração de energia?",
  en: "Has Energy Remuneration?",
  es: "¿Tiene remuneración de energía?",
};

export const REMUNERATION_MEDIA = {
  pt: "Tem remuneração de mídia?",
  en: "Has Media Remuneration?",
  es: "¿Tiene remuneración de mádia?",
};

export const INSTALL_DATE = {
  pt: "Data de instalação",
  en: "Install Date",
  es: "Fecha de instalación",
};

export const START_OPERATION = {
  pt: "Início da operação",
  en: "Start Operation",
  es: "Inicio de la operación",
};

export const START_CONTRACT = {
  pt: "Início do contrato",
  en: "Start Contract",
  es: "Inicio del contrato",
};

export const END_CONTRACT = {
  pt: "Fim do contrato",
  en: "End Contract",
  es: "Fin del contrato",
};

export const FIRST_PAYMENT = {
  pt: "1ª cobrança",
  en: "First Payment",
  es: "1ª cobranza",
};

export const RECIPIENT = {
  pt: "Recebedor",
  es: "Receptor",
  en: "Recipient",
};

export const PERCENTAGE = {
  pt: "Porcentagem",
  en: "Percentage",
  es: "Porcentaje",
};

export const PAYMENT_SPLIT_INSTRUCTION = {
  pt: "A divisão de pagamento é configurada no menu Empresas.",
  en: "The payment division is configured in the Companies menu.",
  es: "La división de pago se configura en el menú Empresas.",
};

export const EDIT_INFO = {
  pt: "Editar informações",
  en: "Edit Info",
  es: "Editar información",
};

export const DELETE = {
  pt: "Deletar",
  es: "Borrar",
  en: "Delete",
};

export const ADD_RECIPITER = {
  pt: "Adicionar recebedor",
  en: "Add Recipient",
  es: "Añadir receptor",
};

export const TOTAL = {
  pt: "Total",
  es: "Totale",
  en: "Total",
};

export const METHOD_FIXED_DESCRIPTION = (value, perTime, timeWindow) => ({
  pt: `${value} fixo a cada ${perTime} ${timeWindow}`,
  en: `${value} fixed every ${perTime} ${timeWindow}`,
  es: `${value} fijo cada ${perTime} ${timeWindow}`,
});

export const METHOD_RELATIVE_DESCRIPTION = (value, perTime, timeWindow) => ({
  pt: `${value} a cada ${perTime} ${timeWindow}`,
  en: `${value} every ${perTime} ${timeWindow}`,
  es: `${value} cada ${perTime} ${timeWindow}`,
});

export const RESERVE_VALUE_IN = (currency) => ({
  pt: `Valor da reserva em ${currency}`,
  es: `Valor de la reserva en ${currency}`,
  en: `Reservation amount in ${currency}`,
});

export const SELECT_VISIBILITY_TYPE = {
  pt: "Selecione o tipo de visibilidade",
  en: "Select Visibility Type",
  es: "Seleccione el tipo de visibilidad",
};

export const OR = {
  pt: "ou",
  en: "or",
  es: "o",
};

export const HAS_PARTNERSHIP = {
  pt: "Possui parceria",
  en: "Has Partnership",
  es: "Hay Alianza",
};

export const WARNING_BUSINESS_HOUR = {
  pt: `Clique em “Salvar horário” para confirmar as alterações feitas no horário de funcionamento.`,
  en: `Click on “Save Hour” to confirm the changes made in the business hour.`,
  es: `Haga clic en “Guardar horário” para confirmar los cambios realizados en el horario de funcionamiento.`,
};

export const WARNING_BUSINESS_HOUR_NOT_SAVED = {
  pt: "As alterações feitas no horário de funcionamento não foram salvas.",
  en: "The changes made in the business hour were not saved.",
  es: "Los cambios realizados en el horario de funcionamiento no fueron guardados.",
};

export const STATION_FLAG = {
  pt: "Bandeira da estação:",
  en: "Station Flag:",
  es: "Bandera de la estación:",
};

export const OLD_BUSINESS_HOUR = {
  pt: "Horário de funcionamento em formato livre",
  en: "Business hour in free format",
  es: "Horario de funcionamiento en formato libre",
};

export const OLD_BUSINESS_HOUR_WARNING = {
  pt: "Selecione abaixo os dias e horários para a sistematização correspondente.",
  en: "Select below the days and hours for the corresponding systematization.",
  es: "Selecciona abajo los días y horarios para la sistematización correspondiente.",
};

export const OLD_BUSINESS_HOUR_PLACEHOLDER = {
  pt: "De segunda a sexta, das 7hrs às 22hrs; e nos feriados, das 9:30 às 12:30",
  en: "From Monday to Friday, from 7:00 to 22:00; and on holidays, from 9:30 to 12:30",
  es: "De lunes a viernes, de 7:00 a 22:00; y en feriados, de 9:30 a 12:30",
};

export const ERROR_GET_LOCATIONS = {
  pt: "Erro ao buscar locais",
  en: "Error getting locations",
  es: "Error al obtener ubicaciones",
};

export const LOCALIZATION = {
  pt: "Local",
  en: "Location",
  es: "Localización",
};

export const ERROR_GET_STATION = {
  pt: "Erro ao buscar estação",
  es: "Error al buscar estación",
  en: "Error looking for station",
};

export const LOOKING_FOR_STATION = {
  pt: "Buscando estação...",
  es: "Buscando estación...",
  en: "Looking for station...",
};


export const ACCEPT_PRICE_PER_HOUR = {
  pt: "Possui horário com preço customizado",
  es: "Tiene horario con precio personalizado",
  en: "Has hour with custom price",
};

export const ERROR_PRICE_PER_HOUR_OVERLAPPING_RANGES = {
  pt: "Não é possível definir mais de um preço para um mesmo horário.",
  en: "It is not possible to define more than one price for the same hour.",
  es: "No es posible fijar más de un precio al mismo tiempo.",
};

export const OF = {
  pt: "Das",
  es: "De",
  en: "Of",
};

export const TO = {
  pt: "As",
  es: "A",
  en: "To",
};

export const CHANGE_TIME_SLOTS_WITH_CUSTOM_PRICE = {
  pt: "Alterar faixas de horário com preço customizado",
  es: "Cambiar franjas horarias con precio personalizado",
  en: "Change time slots with custom price",
};