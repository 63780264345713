import { Tag } from "antd";
import { getBenefitDescription } from "../../pages/Coupons/utils";

const CouponBenefitTag = ({ benefit, currency, freire }) => {
  const { tagColor, text } = getBenefitDescription(benefit, currency, freire);

  return (
    <Tag color={tagColor}>{text}</Tag>
  );
};
export default CouponBenefitTag;