import React, { useEffect, useState } from "react";
import { Button, Icon, Modal, Spin } from "antd";
import sticker_16x6 from "../../assets/sticker/16x6.svg";
import sticker_25x12 from "../../assets/sticker/25x12.svg";
import sticker_38x16 from "../../assets/sticker/38x16.svg";
import sticker_tupi_app from "../../assets/sticker/tupi-sticker-simple.svg";
import sticker_tupi_app_full from "../../assets/sticker/tupi-sticker-full.svg";
import "./styles.css";
import { useFreire } from "../../utils/freireContext";
import * as texts from "./locales";

const StickerModal = ({
  visible,
  sizeSticker,
  station,
  qrcode,
  onClose,
  onDownload,
}) => {
  const [loadingSticker, setLoadingSticker] = useState(true);
  const { freire } = useFreire();

  const getSticker = () => {
    switch (sizeSticker) {
      case "16x6":
        return sticker_16x6;
      case "25x12":
        return sticker_25x12;
      case "38x16":
        return sticker_38x16;
      case "sticker_tupi_app":
        return sticker_tupi_app;
      case "sticker_tupi_app_full":
        return sticker_tupi_app_full;
      default:
        break;
    }
  };

  useEffect(() => {
    async function makeSticker() {
      setLoadingSticker(true);

      try {
        await new Promise((resolve) => setTimeout(() => resolve(), 1250));

        // Texto na parte superior
        var title = `${station.name} (${station.stationID}) - ${sizeSticker} cm`;
        var sticker = document.getElementById("sticker");

        if (sticker) {
          var borda = sticker.getSVGDocument().getElementById("border");
          var qr_area = sticker.getSVGDocument().getElementById("qr_area");
          var position = sticker.getSVGDocument().getElementById("qr_position");
          var code_area = sticker.getSVGDocument().getElementById("code_area");
          var code_position = sticker
            .getSVGDocument()
            .getElementById("code_position");

          var x = Number(position.getAttribute("x"));
          var y = Number(position.getAttribute("y"));
          var width = Number(position.getAttribute("width"));
          var height = Number(position.getAttribute("height"));

          // Espaçamento entre o QrCode e o quadrado branco
          var paddingQrCode = width * 0.035; // 3.5%

          // QrCode
          qrcode.setAttribute("x", `${x + paddingQrCode}`);
          qrcode.setAttribute("y", `${y + paddingQrCode}`);
          qrcode.setAttribute("width", `${width - 2 * paddingQrCode}`);
          qrcode.setAttribute("height", `${height - 2 * paddingQrCode}`);

          qr_area.appendChild(qrcode);

          if (borda) {
            // Texto na parte superior
            var text = document.createElementNS(
              "http://www.w3.org/2000/svg",
              "text"
            );
            text.style.fontSize = 8;
            text.style.fontFamily = "Arial, sans-serif";
            text.setAttribute("transform", "matrix(1 0 0 1 65 9.5)");
            text.innerHTML = title;

            borda.appendChild(text);
          }

          if (code_area && code_position) {
            var code_x = Number(code_position.getAttribute("x"));
            var code_y = Number(code_position.getAttribute("y"));
            var code_width = Number(code_position.getAttribute("width"));
            var code_height = Number(code_position.getAttribute("height"));

            var code = document.createElementNS(
              "http://www.w3.org/2000/svg",
              "text"
            );
            code.style.fontSize = sizeSticker === "sticker_tupi_app" ? 25 : 18;
            code.style.fontFamily = "Roboto, sans-serif";
            code.style.fill = "#FFF";
            code.style.fontWeight = "bold";
            code.setAttribute(
              "transform",
              sizeSticker === "sticker_tupi_app"
                ? "matrix(1 0 0 1 20 9.5)"
                : "matrix(1 0 0 1 -5 9.5)"
            );
            code.innerHTML = `${freire(texts.CODE)}: ${station.code}`;

            code.setAttribute("x", `${code_x + paddingQrCode}`);
            code.setAttribute("y", `${code_y + paddingQrCode}`);
            code.setAttribute("width", `${code_width - 2 * paddingQrCode}`);
            code.setAttribute("height", `${code_height - 2 * paddingQrCode}`);

            code_area.appendChild(code);
          }

          setLoadingSticker(false);
        }
      } catch (error) {
        console.log("error ->", error);
        setLoadingSticker(false);
      }
    }

    if (visible) makeSticker();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [station, qrcode, sizeSticker, visible]);

  return (
    <Modal
      destroyOnClose
      width="70%"
      bodyStyle={{ padding: 15 }}
      centered
      title={`Adesivo ${sizeSticker} cm`}
      style={{ padding: 0 }}
      visible={visible}
      onCancel={() => onClose()}
      footer={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button key="cancel" onClick={() => onClose()}>
            {freire(texts.CLOSE)}
          </Button>
          <Button
            style={{ marginLeft: 25 }}
            key="ok"
            disabled={loadingSticker}
            type="primary"
            onClick={() => onDownload()}
          >
            {freire(texts.DOWNLOAD)}
            <Icon type="download" />
          </Button>
        </div>
      }
    >
      <div
        id="modalSticker"
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <object
          aria-label="sticker"
          id="sticker"
          data={getSticker()}
          type="image/svg+xml"
          style={{ width: "100%", height: "100%" }}
        />
        {loadingSticker ? (
          <div className="loadingSticker">
            <Spin />
          </div>
        ) : null}
      </div>
    </Modal>
  );
};

export default StickerModal;
