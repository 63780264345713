import { useEffect, useState } from "react";
import { Button, Modal, Radio, notification } from "antd";
import type { RadioChangeEvent } from "antd/lib/radio";
import { useFreire } from "../../utils/freireContext";
import {
  SHARE_STATION_TITLE,
  CLOSE,
  COPY_SHARE_LINK,
  ALL_RIGHT,
  SHARE_LINK_COPIED,
} from "../../pages/Stations/locales";
import { apps } from "../../constants/apps";
import type { App } from "../../constants/apps";
import "./index.css"
import { getShareStationLink } from "../../constants/branch-io-links";
import copy from "copy-to-clipboard";

type ShareStationProps = {
  visible: boolean;
  stationID: string;
  onClose: () => void;
};

const ShareStation = ({visible, stationID, onClose }: ShareStationProps) => {
  const { freire } = useFreire();

  const [appConfig, setAppConfig] = useState<App>();

  const onChange = (e: RadioChangeEvent) => {
    setAppConfig(e.target.value);
  }

  const onSubmit = async () => {
    if (!appConfig) return;
    if (!appConfig.linkBranchIo) return;
    const link = getShareStationLink({
      link: appConfig.linkBranchIo,
      stationID
    })
    copy(link);

    notification.success({
      message: freire(ALL_RIGHT),
      description: freire(SHARE_LINK_COPIED),
    });

    onClose();
  }

  useEffect(() => {
    setAppConfig(undefined);
  }, [visible]);

  return (
    <Modal
      title={freire(SHARE_STATION_TITLE)}
      visible={visible}
      width="30%"
      destroyOnClose
      centered
      onCancel={() => onClose()}
      footer={[
        <Button key="cancel" onClick={() => onClose()}>
          {freire(CLOSE)}
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={!appConfig}
          onClick={() => onSubmit()}
        >
          {freire(COPY_SHARE_LINK)}
        </Button>,
      ]}
    >
      <Radio.Group
        className="ShareStationRadioContainer"
        value={appConfig}
        onChange={onChange}
      >
        {Object.values(apps).map((app: App) => {
          if (!app.linkBranchIo) return null;
          return (
            <Radio key={app.alias} value={app}>
              {app.name}
            </Radio>
          );
        })}
      </Radio.Group>
    </Modal>
  );
};

export default ShareStation;
