export function debounce(fn, wait = 1000) {
  let interval = null;

  const _cancel = () => {
    if (interval !== null) {
      clearTimeout(interval);
      interval = null;
    }
  };

  function _debounce() {
    _cancel();
    interval = setTimeout(() => fn.apply(this, arguments), wait);
  }

  _debounce.cancel = _cancel;

  return _debounce;
}
