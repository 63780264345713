export const ALL_RIGHT = {
  pt: "Tudo certo",
  es: "Muy bien.",
  en: "All set",
};

export const LOGIN_DONE = {
  pt: "Login realizado com sucesso",
  es: "Inicio de sesión con éxito",
  en: "Login successfully done",
};

export const ONLY_ADMIN_CAN = {
  pt: "Apenas administradores podem acessar esse sistema!",
  es: "Sólo los administradores pueden acceder a este sistema.",
  en: "Only administrators can access the system!",
};

export const WENT_WONG = {
  pt: "Algo deu errado!",
  es: "¡Algo salió mal!",
  en: "Something went wrong!",
};

export const CHECK_AUTH = {
  pt: "Verifique suas credenciais",
  es: "Compruebe sus credenciales",
  en: "Check your credentials",
};

export const EMAIL = {
  pt: "E-mail",
  es: "Correo electrónico",
  en: "E-mail",
};

export const PASSWORD = {
  pt: "Senha",
  es: "Contraseña",
  en: "Password",
};

export const ENTER = {
  pt: "Entrar",
  es: "Entrar en el sistema",
  en: "Login",
};

export const MAKE_LOGIN = {
  pt: "Entrar",
  es: "Acceso",
  en: "Log in",
};
