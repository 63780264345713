export const EXPIRE_PROPOSAL_TITLE = {
  pt: "Expirar proposta",
  es: "Expirar propuesta",
  en: "Expire Proposal",
};

export const PROPOSAL_ID = {
  pt: "ID da Proposta: ",
  es: "ID de la Propuesta: ",
  en: "Proposal ID: ",
};

export const EXPIRE_PROPOSAL_CONFIRMATION = {
  pt: "Você tem certeza de que deseja expirar esta proposta?",
  es: "¿Está seguro de que desea expirar esta propuesta?",
  en: "Are you sure you want to expire this proposal?",
};

export const EXPIRE = {
  pt: "Expirar",
  es: "Expirar",
  en: "Expire",
};

export const CANCEL = {
  pt: "Cancelar",
  es: "Cancelar",
  en: "Cancel",
};
