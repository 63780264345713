export const STATE = {
  pt: "Estado",
  es: "Estado",
  en: "State",
};

export const LOADING = {
  pt: "Carregando...",
  es: "Cargando...",
  en: "Loading...",
};

export const SELECT_STATE = {
  pt: "Nenhum estado informado",
  es: "No se informó ningún estado",
  en: "No state informed",
};

export const CITY = {
  pt: "Cidade",
  es: "Ciudad",
  en: "City",
};

export const SELECT_CITY = {
  pt: "Nenhuma cidade informada",
  es: "Ninguna ciudad reportada",
  en: "No city informed",
};

export const PROVINCE = {
  pt: "Província",
  en: "Province",
  es: "Provincia",
};

export const SELECT_PROVINCE = {
  pt: "Sem província informada",
  en: "No province informed",
  es: "Ninguna provincia reportada",
};

export const OOPS = {
  pt: "Ops...",
  es: "Epa",
  en: "Ops",
};

export const ERROR_GET_STATES = {
  pt: "Erro ao buscar a lista de estados",
  en: "Error getting states list",
  es: "Error al obtener la lista de estados",
};

export const ERROR_GET_CITIES = {
  pt: "Erro ao buscar a lista de cidades",
  en: "Error getting cities list",
  es: "Error al obtener la lista de ciudades",
};

export const REQUIRED_FIELD = {
  pt: "Campo obrigatório",
  es: "Campo obligatorio",
  en: "Mandatory field",
};
