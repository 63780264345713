const PERCENTAGE_DIGITS = 2;

export const percentage =
  (locale) =>
  (value, digits = PERCENTAGE_DIGITS) =>
    value.toLocaleString(locale, {
      style: "percent",
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    });
