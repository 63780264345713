import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, Form, notification, Radio, Row, Select } from "antd";
import apiOcpp from "../../../../services/apiOcpp";
import { useFreire } from "../../../../utils/freireContext";
import * as texts from "../../locales";

const { Option } = Select;

const ChangeAvailability = ({
  stationId = "",
  connectors = [],
  redirectTo = () => {},
  form,
}) => {
  useEffect(() => {
    form.resetFields(["connectorId"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectors]);

  const { getFieldDecorator } = form;
  const [loading, setLoading] = useState(false);
  const { freire } = useFreire();

  const send = () => {
    form.validateFields(async (err, payload) => {
      if (err) {
        return;
      }

      try {
        setLoading(true);
        const { data } = await apiOcpp.post(
          `/ocpp16/changeAvailability/${stationId}`,
          {
            connectorId: payload.connectorId,
            type: payload.type,
          }
        );

        setLoading(false);

        if (data && data.result) {
          if (data.result === "Accepted") {
            notification.success({
              message: freire(texts.ALL_RIGHT),
              description: `${freire(texts.STATION_RESPONSE)} '${data.result}'`,
            });
            redirectTo("/dashboard/ocpp/stations");
          } else {
            notification.error({
              message: freire(texts.OOPS),
              description: `${freire(texts.STATION_RESPONSE)} '${data.result}'`,
            });
          }
        } else {
          throw new Error();
        }
      } catch (error) {
        setLoading(false);
        notification.error({
          message: freire(texts.OOPS),
          description: freire(texts.VERIFY_AGAIN),
        });
      }
    });
  };

  return (
    <Fragment>
      <Form layout="vertical" wrapperCol={{ span: 24 }}>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item label={freire(texts.CONNECTOR_ID)}>
              {getFieldDecorator("connectorId", {
                rules: [
                  {
                    required: true,
                    message: freire(texts.REQUIRED),
                  },
                ],
              })(
                <Select
                  disabled={!stationId}
                  placeholder={freire(texts.SELECT_CONNECTOR_ID)}
                >
                  <Option value={0}>{freire(texts.ALL_IDS)}</Option>
                  {connectors.map((connector) => (
                    <Option
                      key={connector.connectorId}
                      value={connector.connectorId}
                    >
                      ID {connector.connectorId}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item label={freire(texts.AVAILABLE_TYPE)}>
              {getFieldDecorator("type", {
                rules: [
                  {
                    required: true,
                    message: freire(texts.REQUIRED),
                  },
                ],
              })(
                <Radio.Group>
                  <Radio.Button value="Inoperative">
                    {freire(texts.INOPERATIVE)}
                  </Radio.Button>
                  <Radio.Button value="Operative">
                    {freire(texts.OPERATIVE)}
                  </Radio.Button>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        <Button
          disabled={!stationId || loading}
          loading={loading}
          onClick={send}
          type="primary"
        >
          <span>
            {freire(texts.EXECUTE)}{" "}
            <span style={{ fontWeight: "bold" }}> ChangeAvailability</span>
          </span>
        </Button>
      </div>
    </Fragment>
  );
};

const PageChangeAvailability = Form.create({ name: "inputs" })(
  ChangeAvailability
);

export default PageChangeAvailability;
