import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
} from "antd";
import { WrappedFormUtils } from "antd/lib/form/Form";
import React, { useState } from "react";
import * as texts from "../../locales";
import { useFreire } from "../../../../utils/freireContext";
import apiOcpp from "../../../../services/apiOcpp";
import { GetCompositeScheduleResponse } from "./types";

type FormProps = {
  connectorId: number;
  duration: number;
};

type Props = {
  connectors: any[];
  form: WrappedFormUtils<FormProps>;
  stationId: string;
};

const GetCompositeSchedule: React.FC<Props> = (props: Props) => {
  const { form } = props;

  const { freire } = useFreire();
  const [loading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<Record<string, any>>();

  const sendCommand = () => {
    form.validateFields(async (error, { connectorId, duration }) => {
      if (error) return;
      setLoading(true);

      try {
        const { data } = await apiOcpp.post<GetCompositeScheduleResponse>(
          `/ocpp16/getCompositeSchedule/${props.stationId}`,
          {
            connectorId: connectorId,
            duration: duration,
          }
        );

        setLoading(false);

        if (data?.status === "Accepted") {
          notification.success({
            message: freire(texts.ALL_RIGHT),
            description: `${freire(texts.STATION_RESPONSE)} '${data.status}'`,
          });
          setResponse(data);
          return;
        }

        throw new Error();
      } catch (error) {
        setLoading(false);
        notification.error({
          message: freire(texts.OOPS),
          description: freire(texts.VERIFY_AGAIN),
        });
      }
    });
  };

  return (
    <>
      <Form>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item label={freire(texts.CONNECTOR_ID)}>
              {form.getFieldDecorator("connectorId", {
                rules: [{ required: true, message: freire(texts.REQUIRED) }],
              })(
                <Select
                  disabled={!props.stationId}
                  placeholder={freire(texts.SELECT_CONNECTOR_ID)}
                >
                  {props.connectors?.map((connector) => (
                    <Select.Option
                      key={connector.connectorId}
                      value={connector.connectorId}
                    >
                      ID {connector.connectorId}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item label={freire(texts.DURATION)}>
              {form.getFieldDecorator("duration", {
                rules: [{ required: true, message: freire(texts.REQUIRED) }],
              })(
                <Input
                  type="number"
                  placeholder={freire(texts.SPECIFY_SCHEDULE_DURATION)}
                ></Input>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Button
          disabled={!props.stationId || loading}
          loading={loading}
          onClick={sendCommand}
          type="primary"
        >
          {freire(texts.EXECUTE)}
          &nbsp;
          <span style={{ fontWeight: "bold" }}>GetCompositeSchedule</span>
        </Button>
      </div>
      <Modal title="CompositeSchedule" visible={!!response} footer={null} onCancel={() => setResponse(undefined)}>
        <pre>{`${JSON.stringify(response, null, 2)}`}</pre>
      </Modal>
    </>
  );
};

const PageGetCompositeSchedule = Form.create({
  name: "get-composite-schedule",
})(GetCompositeSchedule);

export default PageGetCompositeSchedule;
