import React, { Fragment, useMemo } from "react";
import { Form, Col, Row, Radio } from "antd";
import { Show } from "../../../../../contexts/PermissionContext";
import { useFreire } from "../../../../../utils/freireContext";
import * as texts from "../../../locales";
import DynamicCompanyForm, {
  validPlaceTypes,
} from "../../../DynamicCompanyForm";
import { COUPON_TYPE } from "../../../../../services/utils";
import { useNewCampaignContext } from "../../context";
import MyDivider from "../../../../../components/MyDivider";
import { BRAND } from '../../../../../config';

const { FIXED_VALUE, CREDIT } = COUPON_TYPE;

const ValidPlaces = ({ currency, sameCompany = false, hideAllNetwork = false, showDivider = true }) => {
  const { freire } = useFreire();
  const { form, selectedCompany, allCompanies, allStations } =
    useNewCampaignContext();

  const { getFieldDecorator } = form;

  const { benefit, validPlace } = form.getFieldsValue();

  /**
   * Para campanhas com desconto específico na moeda, as empresas serão filtradas para ser possível
   * selecionar apenas empresas que tem o mesmo tipo de moeda da empresa dona da campanha ('selectedCompany')
   */
  const selectedCurrency = currency ?? selectedCompany?.currency;
  const companiesFilteredByCurrency = useMemo(() => {
    if (sameCompany || [FIXED_VALUE, CREDIT].includes(benefit?.type)) {
      return allCompanies.filter(
        (company) => company.currency === selectedCurrency
      );
    }

    return allCompanies;
  }, [sameCompany, benefit?.type, selectedCurrency, allCompanies]);

  return (
    <Fragment>
      <Row gutter={[16, 0]}>
        <Col span={18}>
          <Form.Item label={null}>
            {getFieldDecorator("validPlace", {
              initialValue: validPlaceTypes.ALL_COMPANIES,
            })(
              <Radio.Group buttonStyle="solid">
                {/* {!hideAllNetwork && ( */}
                  <Show when={"set-coupons-all-stations"}>
                    <Radio
                      className="verticalRadio"
                      value={validPlaceTypes.ALL_NETWORK}
                      disabled={hideAllNetwork}
                    >
                      {freire(texts.ALL_NETWORK_COMPANIES_AND_STATIONS)}
                    </Radio>
                  </Show>
                {/* )} */}
                {/* {!selectedCompany?._id === BRAND && ( */}
                  <Radio
                    className="verticalRadio"
                    value={validPlaceTypes.ALL_COMPANIES}
                    disabled={selectedCompany?._id === BRAND}
                  >
                    <span>
                      {selectedCompany?.holding
                        ? freire(texts.ALL_COMPANIES_AND_STATIONS)
                        : freire(texts.ALL_STATIONS)}
                      {selectedCompany && (
                        <>
                          {' '}
                          <strong>{selectedCompany.companyName}</strong>
                        </>
                      )}
                    </span>
                  </Radio>
                {/* )} */}
                <Radio
                  className="verticalRadio"
                  value={validPlaceTypes.SPECIFIC_STATIONS}
                >
                  {selectedCompany?.holding
                    ? freire(texts.SPECIFIC_COMPANIES_AND_STATIONS)
                    : freire(texts.SPECIFIC_STATIONS)}
                </Radio>
              </Radio.Group>
            )}
          </Form.Item>
        </Col>
      </Row>
      {/* <div style={{ marginTop: 32, marginBottom: 32, color: "#0022AA" }}>
        <pre>{JSON.stringify({ currency, selectedCompanyCurrency: selectedCompany?.currency ?? 'vazio aaaaaa', selectedCurrency, companiesFilteredByCurrencyLength: companiesFilteredByCurrency.length }, undefined, 2)}</pre>
      </div> */}

      {validPlace === validPlaceTypes.SPECIFIC_STATIONS && (
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <DynamicCompanyForm
              form={form}
              allCompanies={companiesFilteredByCurrency}
              allStations={allStations}
              selectedCompany={selectedCompany}
            />
          </Col>
        </Row>
      )}

      {showDivider && <MyDivider />}
    </Fragment>
  );
};

export default ValidPlaces;
