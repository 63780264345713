import type { Abort } from "./abortable-promise";
import { AbortablePromise } from "./abortable-promise";

export const sleep = (ms: number): AbortablePromise => {
  let timeoutId: number | undefined;

  const abort: Abort = (): void => {
    if (timeoutId !== undefined) {
      timeoutId = undefined;
      clearTimeout(timeoutId);
    }
  };

  return new AbortablePromise((resolve): void => {
    const handler = (): void => {
      timeoutId = undefined;
      resolve();
    };

    timeoutId = setTimeout(handler, ms) as unknown as number;
  }, abort);
};
