import React from "react";
import { Tooltip, message } from "antd";
import copy from "copy-to-clipboard";
import { useFreire } from "../../utils/freireContext";
import * as texts from "./locales";

const EMPTY_TEXT: string = "----";

type CopyProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLSpanElement>,
  HTMLSpanElement
> & {
  children?: number | string;
  value?: string;
};

export const Copy: React.FC<CopyProps> = React.memo(
  ({ children, className, onClick, value, ...props }) => {
    const { freire } = useFreire();

    // prevents rendering `0` as '----'
    // converts '' to '----'
    const text =
      typeof children === "number"
        ? children.toString()
        : children || EMPTY_TEXT;

    const copyText: React.MouseEventHandler<HTMLSpanElement> = (event) => {
      copy(value ?? text);
      message.info(freire(texts.COPIED));
      onClick?.(event);
    };

    return text === EMPTY_TEXT ? (
      <>{text}</>
    ) : (
      <Tooltip placement="right" title={freire(texts.COPY)}>
        <span
          className={`copyText${className ? ` ${className}` : ""}`}
          onClick={copyText}
          {...props}
        >
          {text}
        </span>
      </Tooltip>
    );
  }
);
