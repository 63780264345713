import React, { Fragment, useMemo } from "react";
import {
  InputNumber,
  Input,
  Form,
  Col,
  Row,
  Icon,
  Tooltip,
  Radio,
  Switch,
  Alert,
} from "antd";
import { COUPON_CODE_TYPE, COUPON_TYPE, COUPONS_USAGE_AMOUNT_RULES } from "../../../../../services/utils";
import { useFreire } from "../../../../../utils/freireContext";
import * as texts from "../../../locales";
import { formatters } from "../../../../../utils/formatters";
import { useNewCampaignContext } from "../../context";
import MyDivider from "../../../../../components/MyDivider";
import { formatCurrency } from "../../../utils/currencyFormatter";
import CampaignUsageLimitPerPeriodRules from "../CampaignLimitPerPeriod";
import { ELIGIBLE_USERS_TO_USE_COUPON_TYPE } from "../EligibleUsers";

const { FREE, PERCENTAGE, FIXED_VALUE, CREDIT } = COUPON_TYPE;
const { EQUALS, DIFFERENT } = COUPON_CODE_TYPE;

const CouponCampaignRules = ({ showDivider = true }) => {
  const { freire } = useFreire();
  const { form, selectedCompany } = useNewCampaignContext();

  const requiredFieldRule = {
    required: true,
    message: freire(texts.REQUIRED_FIELD),
  };

  const { getFieldDecorator, getFieldValue } = form;

  const isAnyoneEligibleUsersType = getFieldValue('eligibleUsersType') === ELIGIBLE_USERS_TO_USE_COUPON_TYPE.ANYONE

  const { benefit: campaignBenefit } = form.getFieldsValue();

  const {
    couponDuration: campaignCouponDuration,
    codeType,
    usageAmountRule,
    hasPercentageBalanceLimitPerCharge,
    benefit = {
      type: campaignBenefit?.type ?? FREE,
      value: campaignBenefit?.value ?? "0,00",
    },
    onlyUsersWithoutCharge,
  } = form.getFieldsValue();

  const accumulateOnRenewal = form.getFieldValue("accumulateOnRenewal");

  const {
    renewalTime = {
      enabled: false,
      unit: undefined,
      value: 1,
    },
  } = form.getFieldsValue() || {};

  const unitDescription = useMemo(() => {
    const { unit, value } = renewalTime;

    if (unit === "D") {
      return value > 1 ? freire(texts.DAYS) : freire(texts.DAY).toLowerCase();
    }

    if (unit === "M") {
      return value > 1
        ? freire(texts.MONTHS)
        : freire(texts.MONTH).toLowerCase();
    }

    return "";
  }, [renewalTime, freire]);

  const {
    couponDuration = {
      enabled: campaignCouponDuration?.enabled ?? false,
      unit: campaignCouponDuration?.unit ?? undefined,
      value: campaignCouponDuration?.value ?? 1,
    },
  } = form.getFieldsValue();

  return (
    <Fragment>
      <span style={{ color: "#fd5876", fontWeight: "bold" }}>
        {freire(texts.CAMPAIGN_RULES)}
      </span>
      <Row gutter={[16, 0]}>
        <Col span={8}>
          <Form.Item label={freire(texts.BENEFIT)}>
            {getFieldDecorator("benefit.type", {
              initialValue: benefit.type,
              rules: [requiredFieldRule],
            })(
              <Radio.Group
                onChange={() => form.resetFields(["companies"])}
                buttonStyle="solid"
                className="customRadioGroup"
              >
                <Radio.Button value={FREE}>
                  {freire(texts.DESCRIPTION_FREE)}
                </Radio.Button>
                <Radio.Button value={PERCENTAGE}>
                  {freire(texts.DESCRIPTION_DISCOUNT_PERCENT)}
                </Radio.Button>
                <Radio.Button value={FIXED_VALUE}>
                  {freire(texts.DISCOUNT_FIXED)}
                </Radio.Button>
                <Radio.Button value={CREDIT}>
                  {freire(texts.DESCRIPTION_CREDIT)}
                </Radio.Button>
              </Radio.Group>
            )}
          </Form.Item>
        </Col>
        {[PERCENTAGE, FIXED_VALUE, CREDIT].includes(benefit.type) && (
          <Col span={8} style={{ marginLeft: "50px" }}>
            <Form.Item
              labelAlign="left"
              label={
                benefit.type === PERCENTAGE
                  ? freire(texts.VALUE_PERCENT)
                  : benefit.type === FIXED_VALUE
                  ? freire(
                      texts.VALUE_FIXED(
                        formatters.currency(
                          freire.userLanguage,
                          selectedCompany?.currency
                        )(null)
                      )
                    )
                  : freire(
                      texts.VALUE_CREDIT(
                        formatters.currency(
                          freire.userLanguage,
                          selectedCompany?.currency
                        )(null)
                      )
                    )
              }
            >
              {getFieldDecorator("benefit.value", {
                initialValue: (() => {
                  const benefitValue = benefit?.value?.toString() ?? "0,00";
                  if (benefit.type === PERCENTAGE) {
                    if (parseFloat(benefitValue * 100) / 100 >= 100) {
                      return "100,00";
                    }

                    return (parseFloat(benefitValue) * 100).toString();
                  }

                  return formatCurrency(benefitValue ? benefitValue : "");
                })(),
                normalize: (input) => {
                  input = String(input);
                  let value = input ? input.replace(/[.,]/g, "") : 0;

                  if (benefit.type === PERCENTAGE) {
                    if (parseFloat(value) / 100 >= 100) {
                      return "100,00";
                    }
                  }

                  return formatCurrency(input ? input : "");
                },
                rules: [requiredFieldRule],
              })(<Input />)}
            </Form.Item>
          </Col>
        )}
      </Row>

      {isAnyoneEligibleUsersType && (
        <Row gutter={[16, 0]} id="customRow">
          <Col span={8}>
            <Form.Item labelAlign="left" label={freire(texts.QTD_COUPONS)}>
              {getFieldDecorator("amountOfCoupons", {
                ...(isAnyoneEligibleUsersType && { rules: [requiredFieldRule] }),
              })(
                <InputNumber
                  min={1}
                  style={{ width: "100%" }}
                  placeholder={freire(texts.EX_PARAM("50"))}
                />
              )}
            </Form.Item>
          </Col>
          {benefit.type !== CREDIT && (
            <Col span={8}>
              <Form.Item
                label={
                  <span>
                    <span>{freire(texts.COD_TYPE)}</span>{" "}
                    <Tooltip title={freire(texts.QUESTION_COD_TYPE_DESCRIPTION)}>
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator("codeType", {
                  ...(isAnyoneEligibleUsersType && { rules: [requiredFieldRule] }),
                })(
                  <Radio.Group className="customRadioGroup" buttonStyle="solid">
                    <Radio.Button value={EQUALS}>
                      {freire(texts.EQUALS)}
                    </Radio.Button>
                    <Radio.Button value={DIFFERENT}>
                      {freire(texts.DIFFERENT)}
                    </Radio.Button>
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>
          )}
          {(codeType || benefit.type === CREDIT) && (
            <Col span={8}>
              <Form.Item
                label={
                  codeType === EQUALS ? (
                    freire(texts.COD)
                  ) : (
                    <span>
                      <span>{freire(texts.PREFIX)}</span>{" "}
                      <Tooltip title={freire(texts.QUESTION_PREFIX_DESCRIPTION)}>
                        <Icon type="question-circle-o" />
                      </Tooltip>
                    </span>
                  )
                }
              >
                {getFieldDecorator("cod", {
                  normalize: (input) => (input ? input.toUpperCase() : ""),
                  rules: [
                    {
                      pattern: /^[A-Z\d-]+$/,
                      message: freire(texts.COD_ERROR_LETTER),
                    },
                    {
                      min: 4,
                      message: freire(texts.COD_ERROR_MIN),
                    },
                    requiredFieldRule,
                  ],
                })(
                  <Input
                    placeholder={freire(
                      texts.EX_PARAM(codeType === EQUALS ? "TUPINATAL" : "TUPI")
                    )}
                  />
                )}
              </Form.Item>
            </Col>
          )}
        </Row>
      )}
      
      <Row>
        <Col span={16}>
          <Form.Item
            labelAlign="left"
            label={
              <span style={{ color: "#000000d9" }}>
                <span>{freire(texts.QUESTION_LIMITE_USE_COUPON)}</span>{" "}
                <Tooltip
                  title={freire(texts.QUESTION_LIMITE_USE_COUPON_DESCRIPTION)}
                >
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator("usageAmountRule", {
              rules: [requiredFieldRule],
              initialValue: usageAmountRule ?? COUPONS_USAGE_AMOUNT_RULES.FIRST_CHARGE,
            })(
              <Radio.Group className="customRadioGroup" buttonStyle="solid">
                <Radio.Button value={COUPONS_USAGE_AMOUNT_RULES.FIRST_CHARGE}>
                  {freire(texts.ONLY_FIRST_CHARGE)}
                </Radio.Button>
                <Radio.Button value={COUPONS_USAGE_AMOUNT_RULES.UNLIMITED}>
                  {freire(texts.UNLIMITED)}
                </Radio.Button>
                <Radio.Button value={COUPONS_USAGE_AMOUNT_RULES.LIMITED}>
                  {freire(texts.LIMITED)}
                </Radio.Button>
              </Radio.Group>
            )}
          </Form.Item>
        </Col>
        {usageAmountRule !== undefined && usageAmountRule === COUPONS_USAGE_AMOUNT_RULES.LIMITED && (
          <Col span={8}>
            <Form.Item
              labelAlign="left"
              label={freire(texts.DEFINE_LIMIT_USE_COUPON)}
              style={{ marginLeft: "16px" }}
            >
              {getFieldDecorator("usageLimitPerCoupon", {
                rules: [requiredFieldRule],
              })(
                <InputNumber
                  min={1}
                  style={{ width: "100%" }}
                  placeholder={freire(texts.EX_PARAM("3"))}
                />
              )}
            </Form.Item>
          </Col>
        )}
      </Row>

      {!onlyUsersWithoutCharge && (
        <CampaignUsageLimitPerPeriodRules />
      )}

      {benefit.type === CREDIT && (
        <Row gutter={[16, 0]}>
          <Col span={8}>
            <Form.Item label={freire(texts.HAS_DISCOUNT_PERCENTAGE_LIMIT)}>
              {getFieldDecorator("hasPercentageBalanceLimitPerCharge", {
                initialValue: false,
                rules: [requiredFieldRule],
              })(
                <Radio.Group
                  buttonStyle="solid"
                  className="customRadioGroup"
                >
                  <Radio.Button value={false}>
                    {freire(texts.NO_LIMIT)}
                  </Radio.Button>
                  <Radio.Button value={true}>
                    {freire(texts.LIMITED)}
                  </Radio.Button>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
          {hasPercentageBalanceLimitPerCharge && (
            <Col span={8} style={{ marginLeft: "50px" }}>
              <Form.Item
              labelAlign="left"
              label={`${freire(texts.MAX_PERCENTAGE)} (%)`}
            >
              {getFieldDecorator("percentageBalanceLimitPerCharge", {
                initialValue: 0,
                normalize: (input) => {
                    input = String(input);
                    return formatCurrency(input ? input : "")
                  },
                  rules: [requiredFieldRule],
                })(<Input />)}
              </Form.Item>
            </Col>
          )}
        </Row>
      )}

      <Row gutter={[16, 0]}>
        <Col span={16}>
          <Form.Item
            labelAlign="left"
            className="custom-form-item"
            label={freire(texts.COUPON_EXPIRE)}
          >
            {getFieldDecorator("couponDuration.enabled", {
              valuePropName: "checked",
              initialValue: couponDuration.enabled,
            })(
              <Switch
                checkedChildren={freire(texts.YES)}
                unCheckedChildren={freire(texts.NO)}
              />
            )}
          </Form.Item>
        </Col>
      </Row>

      {couponDuration.enabled && (
        <Row>
          <Col span={5}>
            <Form.Item label={freire(texts.UNIT_TYPE)}>
              {getFieldDecorator("couponDuration.unit", {
                ...(couponDuration.enabled && { rules: [requiredFieldRule] }),
              })(
                <Radio.Group
                  disabled={!couponDuration.enabled}
                  className="customRadioGroup"
                  buttonStyle="solid"
                >
                  <Radio.Button value={"D"}>{freire(texts.DAY)}</Radio.Button>
                  <Radio.Button value={"M"}>{freire(texts.MONTH)}</Radio.Button>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
          <Col span={5} style={{ marginLeft: "20px" }}>
            <Form.Item label={freire(texts.BENEFIT_DURATION)}>
              <div style={{ position: "relative", width: "100%" }}>
                {getFieldDecorator("couponDuration.value", {
                  ...(couponDuration.enabled && { rules: [requiredFieldRule] }),
                  initialValue: couponDuration.value,
                })(
                  <InputNumber
                    disabled={!couponDuration.enabled || !couponDuration.unit}
                    min={1}
                    max={couponDuration.unit === "D" ? 31 : 12}
                    style={{ width: "100%", paddingRight: 80 }}
                  />
                )}
                <span
                  style={{
                    position: "absolute",
                    right: 32,
                    opacity: couponDuration.enabled ? 1 : 0.5,
                  }}
                >
                  {unitDescription}
                </span>
              </div>
            </Form.Item>
          </Col>
        </Row>
      )}

      {couponDuration.enabled && (
        <Row gutter={[16, 0]}>
          <Col span={20}>
            <Alert
              description={
                <span style={{ whiteSpace: "pre-line" }}>
                  {freire(texts.COUPON_EXPIRE_DESCRIPTION)}
                </span>
              }
              type="warning"
              showIcon
              style={{ marginBottom: 25 }}
            />
          </Col>
        </Row>
      )}
      
      <Row gutter={[16, 0]}>
        <Col span={8}>
          <Form.Item labelAlign="left" label={freire(texts.RECURRENCE)}>
            {getFieldDecorator("renewalTime.enabled", {
              initialValue: renewalTime.enabled ? "monthlyRecurring" : false,
              rules: [requiredFieldRule],
            })(
              <Radio.Group
                className="customRadioGroup campaignQuota"
                buttonStyle="solid"
                onChange={() =>
                  form.setFieldsValue({ accumulateOnRenewal: false })
                }
              >
                <Radio.Button value={false}>
                  {freire(texts.UNIQUE_VALUE)}
                </Radio.Button>
                <Radio.Button value={"monthlyRecurring"}>
                  {freire(texts.RENOVATION_QUOTA)}{" "}
                  <Tooltip title={freire(texts.MAINTAINING_RENEWAL)}>
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </Radio.Button>
              </Radio.Group>
            )}
          </Form.Item>
        </Col>
        {renewalTime.enabled === "monthlyRecurring" && (
          <>
            <Col span={5}>
              <Form.Item label={freire(texts.UNIT_TYPE)}>
                {getFieldDecorator("renewalTime.unit", {
                  rules: [requiredFieldRule],
                })(
                  <Radio.Group className="customRadioGroup" buttonStyle="solid">
                    <Radio.Button value={"D"}>{freire(texts.DAY)}</Radio.Button>
                    <Radio.Button value={"M"}>
                      {freire(texts.MONTH)}
                    </Radio.Button>
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label={freire(texts.DURATION)}>
                <div style={{ position: "relative", width: "100%" }}>
                  {getFieldDecorator("renewalTime.value", {
                    rules: [requiredFieldRule],
                    initialValue: renewalTime.value,
                  })(
                    <InputNumber
                      min={1}
                      max={renewalTime.unit === "D" ? 31 : 12}
                      style={{ width: "100%", paddingRight: 80 }}
                    />
                  )}
                  <span
                    style={{
                      position: "absolute",
                      right: 32,
                      opacity: renewalTime.enabled ? 1 : 0.5,
                    }}
                  >
                    {unitDescription}
                  </span>
                </div>
              </Form.Item>
            </Col>
          </>
        )}
      </Row>
      <Row>
        {renewalTime.enabled === "monthlyRecurring" && (
          <Col span={5}>
            <Form.Item
              className="custom-form-item"
              labelAlign="right"
              label={freire(texts.VALUE_ACCUMULATE_TO_NEXT_PERIOD)}
            >
              {getFieldDecorator("accumulateOnRenewal", {
                valuePropName: "checked",
                initialValue: accumulateOnRenewal ? true : false,
              })(
                <Switch
                  checkedChildren={freire(texts.YES)}
                  unCheckedChildren={freire(texts.NO)}
                  disabled={renewalTime.enabled !== "monthlyRecurring"}
                />
              )}
            </Form.Item>
          </Col>
        )}
      </Row>
      {(codeType === COUPON_CODE_TYPE.DIFFERENT || !isAnyoneEligibleUsersType) && usageAmountRule !== COUPONS_USAGE_AMOUNT_RULES.FIRST_CHARGE && (
        <Row>
          <Col span={8}>
            <Form.Item
              className="custom-form-item"
              labelAlign="right"
              label={freire(texts.EACH_USER_CAN_LINK_MORE_THAN_ONE_CODE)}
            >
              {getFieldDecorator("couponsMultipleLink", {
                valuePropName: "checked",
                initialValue: false,
              })(
                <Switch
                  checkedChildren={freire(texts.YES)}
                  unCheckedChildren={freire(texts.NO)}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      )}
      {showDivider && <MyDivider />}
    </Fragment>
  );
};

export default CouponCampaignRules;
