import React from "react";

// adapted from https://assets.freshping.io/static/media/pause.d5cd0055.svg
export const Error = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1">
      <g transform="translate(-547.000000, -431.000000)">
        <g transform="translate(547.000000, 431.000000)">
          <circle fill="currentColor" cx="50%" cy="50%" r="9" />
          <g
            transform="translate(6.000000, 6.000000)"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.6"
          >
            <polyline
              transform="translate(2.987798, 2.945455) scale(-1, -1) rotate(-45.000000) translate(-2.987798, -2.945455)"
              points="-6.39052447e-13 -8.43390004e-13 0.883538278 0.871016614 2.27275329 2.24054342 5.97559632 5.89090909"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
