import { useContext } from "react";
import context from "./context";
import checkRole from "./hasPermission";

const useAccess = () => {
  const { permissions, resources, isLoaded, define } = useContext(context);

  const hasPermission = (role, opts = {}) => {
    return checkRole(permissions, resources, role, opts);
  };

  return { isLoaded, hasPermission, define };
};

export default useAccess;
