import React, { useState, useEffect } from "react";
import { Button, Icon, Modal, Input } from "antd";
import { useFreire } from "../../../../../utils/freireContext";
import * as texts from "../../../locales";
import { TicketIcon } from "../../../../../assets/icons";
import styles from "../styles.css";

const ModalCouponUser = ({ visible, loading, coupon, onCancel, onConfirm }) => {
  const { freire } = useFreire();
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (!visible) {
      setEmail("");
    }
  }, [visible]);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleClearEmail = () => {
    setEmail("");
  };

  const handleConfirm = () => {
    if (coupon && coupon._id && email) {
      onConfirm(coupon._id, email);
    }
  };

  return (
    <Modal
      centered
      width={"20%"}
      style={{ minWidth: 400 }}
      footer={null}
      closable={false}
      visible={visible}
      onCancel={handleClearEmail}
    >
      <span className="modalCouponTitle">
        <div type="user-add" />
        <span>{freire(texts.ADD_COUPONS_USER).toUpperCase()}</span>
      </span>

      <div className="modalCouponUserCard">
        <div className={styles.modalCouponUserCardInfo}>
          <Icon
            component={TicketIcon}
            className={styles.modalCouponUserCardIcon}
          />
          <span style={{ marginLeft: 8 }}>{coupon?.cod}</span>
        </div>

        <div
          className={styles.modalCouponUserCardInfo}
          style={{ marginTop: 20 }}
        >
          <span style={{ color: "red" }}>*</span>
          <span className={styles.modalName}> {freire(texts.ENTER_EMAIL)}</span>
        </div>

        <div style={{ marginBottom: 0 }}>
          <Input
            value={email}
            onChange={handleEmailChange}
            style={{ marginTop: 8, marginBottom: 8 }}
            placeholder={freire(texts.ENTER_EMAIL_HERE)}
          />
        </div>
      </div>

      <div
        className={styles.modalCouponUserFooter}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Button
          style={{ width: "48%", marginRight: "4%" }}
          disabled={loading}
          onClick={onCancel}
        >
          {freire(texts.CANCEL)}
        </Button>
        <Button
          loading={loading}
          type="danger"
          onClick={handleConfirm}
          style={{ width: "48%", background: "#1890FF", border: "#1890FF" }}
        >
          {freire(texts.ADD_COUPONS_USERS)}
        </Button>
      </div>
    </Modal>
  );
};

export default ModalCouponUser;
