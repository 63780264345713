import React from "react";
import { useFreire } from "../../utils/freireContext";
import * as texts from "./locales";
import { Button } from "antd";
import { ILanguageTranslation } from "../../interfaces/languages";

type Props = {
  loading: boolean;
  onClick: () => void;
  btnTitle?: ILanguageTranslation;
};

const ButtonCsvDownload = ({ loading, onClick, btnTitle }: Props) => {
  const { freire } = useFreire();

  return (
    <Button size="large" loading={loading} icon="download" onClick={onClick}>
      {freire(btnTitle ?? texts.DOWNLOAD_CSV)}
    </Button>
  );
};

export default ButtonCsvDownload;
