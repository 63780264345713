import React, { useEffect, useState } from "react";
import {
  Button,
  Select,
  Input,
  Form,
  Col,
  Row,
  Icon,
  Steps,
  notification,
  Upload,
  Popover,
} from "antd";
import * as XLSX from "xlsx";
import languageEncoding from "detect-file-encoding-and-language";
import { withRouter } from "react-router-dom";

import "./index.css";
import api from "../../../services/api";
import rfidCsvExample from "../../../assets/rfidCsvExample.png";
import { getCompanyCurrentUser } from "../../../services/auth";
import { Show } from "../../../contexts/PermissionContext";
import { useFreire } from "../../../utils/freireContext";
import * as texts from "../locales";
const { Step } = Steps;
const { Option } = Select;

const NewRfidCampaign = ({ form, history }) => {
  const [loadingSave, setLoadingSave] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [uploadCodList, setUploadCodList] = useState([]);
  const { freire } = useFreire();

  useEffect(() => {
    setCompanys();
  }, []);

  const setCompanys = async () => {
    try {
      // const company = await getCompanyCurrentUser();
      const { data: companies } = await api.get("company");
      setCompanies(companies);
    } catch (e) {}
  };

  const onClickSave = () => {
    form.validateFields(async (err, payload) => {
      if (err) {
        return;
      }

      if (!payload.company) {
        const companyUser = await getCompanyCurrentUser();
        payload.company = companyUser.id;
      }
      try {
        setLoadingSave(true);

        const data = {
          name: payload.name,
          company: payload.company,
          clientName: payload.clientName,
          tags: uploadCodList,
        };

        await api.post("rfid-campaigns", data);

        notification.success({
          message: freire(texts.ALL_RIGHT),
          description: freire(texts.CAMPAIGN_CREATED),
        });
        setLoadingSave(false);

        history.push("/dashboard/rfid-campaigns");
      } catch (error) {
        setLoadingSave(false);

        const {
          response: {
            data: { message },
          },
        } = error;

        notification.error({
          message: freire(texts.WENT_WONG),
          description: message || error.message,
        });
      }
    });
  };

  const onUpload = async ({ onSuccess, file }) => {
    new Promise((resolve) => resolve()).then(() => onSuccess({}, file));
  };

  const checkFileType = (file) => {
    if (file.type !== "text/csv") {
      notification.error({
        message: freire(texts.OOPS),
        description: freire(texts.ONLY_CSV_FILE_ALLOWED),
      });
      return false;
    }

    return true;
  };

  const getFileInfoEnconding = async (file) => {
    try {
      const fileInfo = await languageEncoding(file);

      return fileInfo?.encoding || "utf-8";
    } catch (error) {
      return "utf-8";
    }
  };

  async function readFile(file) {
    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const { result } = event.target;

        const readedData = XLSX.read(result, { type: "string" });

        const sheetName = readedData.SheetNames[0];
        const sheetData = readedData.Sheets[sheetName];

        /* Convert array to json*/
        const sheetDataParsed = XLSX.utils.sheet_to_json(sheetData, {
          header: 1,
          defval: "",
          raw: false
        });

        if (sheetDataParsed?.length > 0 && sheetDataParsed[0][0] === "RFID") {
          const list = sheetDataParsed.slice(1).map((row) => row[0]);

          setUploadCodList(list);
        } else {
          notification.error({
            message: freire(texts.OOPS),
            description: freire(texts.SHEET_WRONG),
          });
          form.resetFields(["uploadField"]);
        }
      } catch (error) {
        notification.error({
          message: freire(texts.OOPS),
          description: freire(texts.ERROR_SHEET_PROCESS),
        });
      }
    };

    const fileEncoding = await getFileInfoEnconding(file);

    reader.readAsText(file, fileEncoding);
  }

  const getValueFromEvent = (event) => {
    if (!checkFileType(event.file)) return [];

    if (event?.file?.status === "done") {
      readFile(event.file.originFileObj);
    }

    return event?.fileList || [];
  };

  const { uploadField } = form.getFieldsValue();

  return (
    <div className="container">
      <div className="content">
        <Row gutter={[16, 0]}>
          <Col span={6}>
            <Steps current={1} size="small" direction="vertical">
              <Step
                icon={<Icon type="profile" />}
                title={freire(texts.NEW_CAMPAIGN)}
                description={freire(texts.CREATE_CAMPAIGN_RFID)}
              />
            </Steps>
          </Col>

          <Col span={18}>
            <Form
              id="form_new_rfid_campaign"
              hideRequiredMark
              colon={false}
              wrapperCol={{ span: 24 }}
            >
              <Row gutter={[16, 0]}>
                <Show when={"edit-rfid-company"}>
                  <Col span={12}>
                    <Form.Item label={freire(texts.COMPANY)}>
                      {form.getFieldDecorator("company", {
                        rules: [
                          {
                            required: true,
                            message: freire(texts.REQUIRED_FIELD),
                          },
                        ],
                      })(
                        <Select placeholder={freire(texts.COMPANY)}>
                          {companies.map((element) => {
                            return (
                              <Option key={element._id} value={element._id}>
                                {element.companyName}
                              </Option>
                            );
                          })}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Show>
              </Row>
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span>
                        {freire(texts.CLIENT)}{" "}
                        <span style={{ fontSize: 13, fontWeight: "normal" }}>
                          {freire(texts.OPTIONAL)}
                        </span>
                      </span>
                    }
                  >
                    {form.getFieldDecorator("clientName")(
                      <Input placeholder={freire(texts.INFORM_CLIENT_NAME)} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <Form.Item label={freire(texts.CAMPAIGN_NAME)}>
                    {form.getFieldDecorator("name", {
                      rules: [
                        {
                          required: true,
                          message: freire(texts.REQUIRED_FIELD),
                        },
                      ],
                    })(<Input placeholder={freire(texts.CAMPAIGN_NAME_EX)} />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Col span={15}>
                  <span className="labelUpload">
                    {freire(texts.CODS_IMPORT)} <br />
                    <span className="descriptionLabelUpload">
                      {freire(texts.UPLOAD_DESCRIPTION)}
                      {"("}
                      <Popover
                        placement="right"
                        title={freire(texts.SHEET_RFID_EX)}
                        trigger="hover"
                        content={
                          <div style={{ width: 300 }}>
                            <img
                              style={{ width: "100%", marginTop: 20 }}
                              src={rfidCsvExample}
                              alt="info"
                            />
                          </div>
                        }
                      >
                        <span style={{ textDecoration: "underline" }}>
                          {freire(texts.EXAMPLE)}
                        </span>
                      </Popover>
                      {")."}
                    </span>
                  </span>
                  <Form.Item>
                    {form.getFieldDecorator("uploadField", {
                      rules: [
                        {
                          required: true,
                          message: freire(texts.REQUIRED_FIELD),
                        },
                      ],
                      valuePropName: "fileList",
                      getValueFromEvent: getValueFromEvent,
                    })(
                      <Upload
                        style={{ marginTop: 0 }}
                        customRequest={onUpload}
                        disabled={loadingSave}
                        multiple={false}
                      >
                        {!uploadField || uploadField?.length === 0 ? (
                          <Button icon="paper-clip">
                            {freire(texts.SHEET_IMPORT)}
                          </Button>
                        ) : null}
                      </Upload>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Col span={15}>
                  <div className="footer">
                    <Button
                      style={{ marginRight: 46 }}
                      onClick={() => history.push("/dashboard/rfid-campaigns")}
                      type="default"
                    >
                      {freire(texts.CANCEL)}
                    </Button>
                    <Button
                      loading={loadingSave}
                      onClick={onClickSave}
                      type="primary"
                    >
                      {freire(texts.SAVE)}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const PageNewRfidCampaign = Form.create({ name: "inputs" })(NewRfidCampaign);
export default withRouter(PageNewRfidCampaign);
