import api from "../services/api";

export const getEstados = async () => {
  const { data: estados } = await api.get("/ibge/estados");

  return estados;
};

export const getMunicipiosByEstado = async (state: string) => {
  const { data: municipios } = await api.get(
    `/ibge/estados/${state}/municipios`
  );

  return municipios;
};
