
// adapted from antd
export const Ticket = ({ color = "#1890FF" }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 71 63"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M47.4584 62.5868H19.5417C7.23046 62.5868 3.48962 58.596 3.48962 45.4619V43.9728C3.48962 42.7517 4.43879 41.7391 5.58337 41.7391C8.26337 41.7391 10.4688 39.3863 10.4688 36.5272C10.4688 33.6681 8.26337 31.3153 5.58337 31.3153C4.43879 31.3153 3.48962 30.3027 3.48962 29.0816V27.5925C3.48962 14.4585 7.23046 10.4676 19.5417 10.4676H47.4584C59.7696 10.4676 63.5105 14.4585 63.5105 27.5925V30.5707C63.5105 31.7918 62.5613 32.8044 61.4167 32.8044C58.7367 32.8044 56.5313 35.1572 56.5313 38.0163C56.5313 40.8754 58.7367 43.2282 61.4167 43.2282C62.5613 43.2282 63.5105 44.2408 63.5105 45.4619C63.5105 58.596 59.7696 62.5868 47.4584 62.5868ZM7.67712 45.9384C7.73296 56.1836 9.71504 58.1194 19.5417 58.1194H47.4584C56.7825 58.1194 59.0438 56.3623 59.295 47.4276C55.303 46.415 52.3438 42.573 52.3438 38.0163C52.3438 33.4596 55.3309 29.6177 59.323 28.6051V27.5925C59.323 16.9602 57.4525 14.935 47.4584 14.935H19.5417C9.71504 14.935 7.73296 16.8708 7.67712 27.116C11.6692 28.1286 14.6563 31.9705 14.6563 36.5272C14.6563 41.0839 11.6692 44.9258 7.67712 45.9384Z" />
    <path d="M27.9166 22.3806C26.772 22.3806 25.8229 21.368 25.8229 20.1469V12.7013C25.8229 11.4802 26.772 10.4676 27.9166 10.4676C29.0612 10.4676 30.0104 11.4802 30.0104 12.7013V20.1469C30.0104 21.368 29.0612 22.3806 27.9166 22.3806Z" />
    <path d="M27.9166 44.2111C26.772 44.2111 25.8229 43.1985 25.8229 41.9774V31.0473C25.8229 29.8262 26.772 28.8136 27.9166 28.8136C29.0612 28.8136 30.0104 29.8262 30.0104 31.0473V41.9774C30.0104 43.2282 29.0612 44.2111 27.9166 44.2111Z" />
    <path d="M27.9166 62.5868C26.772 62.5868 25.8229 61.5742 25.8229 60.3531V52.9075C25.8229 51.6864 26.772 50.6738 27.9166 50.6738C29.0612 50.6738 30.0104 51.6864 30.0104 52.9075V60.3531C30.0104 61.5742 29.0612 62.5868 27.9166 62.5868Z" />
  </svg>
);

export const TicketTag = ({ color = "#1890FF" }) => (
  <svg
    width="1.5em"
    height="1.5em"
    viewBox="0 0 71 63"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M47.4584 62.5868H19.5417C7.23046 62.5868 3.48962 58.596 3.48962 45.4619V43.9728C3.48962 42.7517 4.43879 41.7391 5.58337 41.7391C8.26337 41.7391 10.4688 39.3863 10.4688 36.5272C10.4688 33.6681 8.26337 31.3153 5.58337 31.3153C4.43879 31.3153 3.48962 30.3027 3.48962 29.0816V27.5925C3.48962 14.4585 7.23046 10.4676 19.5417 10.4676H47.4584C59.7696 10.4676 63.5105 14.4585 63.5105 27.5925V30.5707C63.5105 31.7918 62.5613 32.8044 61.4167 32.8044C58.7367 32.8044 56.5313 35.1572 56.5313 38.0163C56.5313 40.8754 58.7367 43.2282 61.4167 43.2282C62.5613 43.2282 63.5105 44.2408 63.5105 45.4619C63.5105 58.596 59.7696 62.5868 47.4584 62.5868ZM7.67712 45.9384C7.73296 56.1836 9.71504 58.1194 19.5417 58.1194H47.4584C56.7825 58.1194 59.0438 56.3623 59.295 47.4276C55.303 46.415 52.3438 42.573 52.3438 38.0163C52.3438 33.4596 55.3309 29.6177 59.323 28.6051V27.5925C59.323 16.9602 57.4525 14.935 47.4584 14.935H19.5417C9.71504 14.935 7.73296 16.8708 7.67712 27.116C11.6692 28.1286 14.6563 31.9705 14.6563 36.5272C14.6563 41.0839 11.6692 44.9258 7.67712 45.9384Z" />
    <path d="M27.9166 22.3806C26.772 22.3806 25.8229 21.368 25.8229 20.1469V12.7013C25.8229 11.4802 26.772 10.4676 27.9166 10.4676C29.0612 10.4676 30.0104 11.4802 30.0104 12.7013V20.1469C30.0104 21.368 29.0612 22.3806 27.9166 22.3806Z" />
    <path d="M27.9166 44.2111C26.772 44.2111 25.8229 43.1985 25.8229 41.9774V31.0473C25.8229 29.8262 26.772 28.8136 27.9166 28.8136C29.0612 28.8136 30.0104 29.8262 30.0104 31.0473V41.9774C30.0104 43.2282 29.0612 44.2111 27.9166 44.2111Z" />
    <path d="M27.9166 62.5868C26.772 62.5868 25.8229 61.5742 25.8229 60.3531V52.9075C25.8229 51.6864 26.772 50.6738 27.9166 50.6738C29.0612 50.6738 30.0104 51.6864 30.0104 52.9075V60.3531C30.0104 61.5742 29.0612 62.5868 27.9166 62.5868Z" />
  </svg>
);


export const CouponIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.25 2.75L10.9571 1.45711C10.5666 1.06658 9.93342 1.06658 9.54289 1.45711L1.45711 9.54289C1.06658 9.93342 1.06658 10.5666 1.45711 10.9571L2.75 12.25L3.46798 12.3153C4.5836 12.4167 5.39838 13.4146 5.27468 14.5279L5.25 14.75L7.04289 16.5429C7.43342 16.9334 8.06658 16.9334 8.45711 16.5429L16.5429 8.45711C16.9334 8.06658 16.9334 7.43342 16.5429 7.04289L14.75 5.25L14.3901 5.28271C13.1463 5.39579 12.1042 4.35373 12.2173 3.10986L12.25 2.75Z"
        stroke="#747277"
        strokeWidth="1.5"
      />
      <path
        d="M4.5 7.5L10.5 13.5"
        stroke="#747277"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 2"
      />
    </svg>
  );
};
