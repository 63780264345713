import {
  Button,
  Col,
  Drawer,
  Form,
  Icon,
  Input,
  notification,
  Popconfirm,
  Radio,
  Row,
  Select,
  Switch,
  Table,
  Tag,
  Tooltip,
} from "antd";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Show from "../../../contexts/PermissionContext/Show";
import api from "../../../services/api";
import "./index.css";
import { FreireContext } from "../../../utils/freireContext";
import * as texts from "../locales";
import { sorter } from "../../../utils/sorter";

const { Option } = Select;

class AuditStations extends Component {
  static contextType = FreireContext;

  state = {
    loadingTable: false,
    visibleDrawer: false,
    visibleEdit: false,
    currentStation: {},
    currentStationEdit: {},
    data: [],
    tablePage: 1,
  };

  componentDidMount() {
    this.getStationsAudit();
  }

  async getStationsAudit(params = {}) {
    this.setState({ loadingTable: true });

    const { data } = await api.get(`stations_audit`, { params });

    this.setState({ data, loadingTable: false });
  }

  openDrawer = (currentStation) => {
    this.setState({
      visibleDrawer: true,
      currentStation,
      currentStationEdit: JSON.parse(JSON.stringify(currentStation)),
    });
  };

  closeDrawer = () => {
    this.setState({ visibleDrawer: false });
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.filter();
    }
  };

  onSalveStationAudit = async () => {
    const { freire } = this.context;

    try {
      let { currentStationEdit } = this.state;

      this.setState({ loadingSave: true });

      if (currentStationEdit._id) {
        await api.put(
          `stations_audit/${currentStationEdit._id}`,
          currentStationEdit
        );

        this.setState({
          visibleEdit: false,
          visibleDrawer: false,
          loadingSave: false,
        });
        notification.success({
          message: freire(texts.ALL_RIGHT),
          description: freire(texts.STATION_EDITED),
        });

        this.getStationsAudit();
      }
    } catch (error) {
      const {
        response: {
          data: { message },
        },
      } = error;

      this.setState({ loadingSave: false });

      notification.error({
        message: freire(texts.WENT_WRONG),
        description: message || error.message,
      });
    }
  };

  onAprovedAudit = async (data) => {
    const { freire } = this.context;

    try {
      if (data._id) {
        await api.post(`stations_audit_aproved/${data._id}`);

        notification.success({
          message: freire(texts.ALL_RIGHT),
          description: freire(texts.STATION_AUDITED),
        });

        this.getStationsAudit();
      }
    } catch (error) {
      const {
        response: {
          data: { message },
        },
      } = error;

      notification.error({
        message: freire(texts.WENT_WRONG),
        description: message || error.message,
      });
    }
  };

  onDeleteAudit = async (data) => {
    const { freire } = this.context;

    try {
      if (data._id) {
        await api.delete(`stations_audit/${data._id}`);

        notification.success({
          message: freire(texts.ALL_RIGHT),
          description: freire(texts.AUDIT_DELETED),
        });

        this.getStationsAudit();
      }
    } catch (error) {
      const {
        response: {
          data: { message },
        },
      } = error;

      notification.error({
        message: freire(texts.WENT_WRONG),
        description: message || error.message,
      });
    }
  };

  renderValue(key) {
    const { freire } = this.context;

    let { currentStation } = this.state;

    if (key === "position") {
      return (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://www.google.com/maps/search/?api=1&query=${currentStation.lat},${currentStation.lng}`}
        >
          {freire(texts.OPEN_IN_GOOGLE_MAPS)}
        </a>
      );
    } else if (key === "plugs") {
      return currentStation.plugs && currentStation.plugs.length ? (
        currentStation.plugs.map((plug, i) => <Tag key={i}>{plug}</Tag>)
      ) : (
        <span style={{ fontWeight: "bold" }}>----</span>
      );
    } else if (key === "freeParking") {
      return (
        <span style={{ fontWeight: "bold" }}>
          {currentStation.freeParking ? freire(texts.YES) : freire(texts.NO)}
        </span>
      );
    } else {
      return (
        <span style={{ fontWeight: "bold" }}>
          {currentStation[key] || "----"}
        </span>
      );
    }
  }

  filter = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.getStationsAudit(values);
      }
    });

    this.setState({ tablePage: 1 });
  };

  clearFilters = () => {
    this.props.form.resetFields();
    this.getStationsAudit();
  };

  getDataToShow = () => {
    const { freire } = this.context;
    return [
      { label: freire(texts.NAME), key: "name" },
      { label: freire(texts.ADDRESS), key: "address" },
      { label: freire(texts.LOCALIZATION), key: "position" },
      { label: freire(texts.PHONE), key: "phone" },
      { label: freire(texts.BUSINESS_HOURS), key: "businessHours" },
      { label: freire(texts.PLUGS), key: "plugs" },
      { label: freire(texts.FREE_PARKING), key: "freeParking" },
    ];
  };

  getColumns = () => {
    const { freire } = this.context;

    return [
      {
        title: freire(texts.NAME),
        dataIndex: "name",
        key: "name",
      },
      {
        title: freire(texts.ADDRESS),
        dataIndex: "address",
        key: "address",
      },
      {
        title: freire(texts.PLUGS),
        dataIndex: "plugs",
        key: "plugs",
        render: (plugs) => (
          <span>
            {plugs.map((plug, i) => (
              <Tag key={i}>{plug}</Tag>
            ))}
          </span>
        ),
      },
      {
        title: freire(texts.AUDITED),
        dataIndex: "audited",
        key: "audited",
        render: (text, data) => (
          <span>{data.audited ? freire(texts.YES) : freire(texts.NO)}</span>
        ),
      },
      {
        title: freire(texts.ACTIONS),
        key: "acoes",
        render: (text, data) => (
          <>
            {!data.audited && (
              <Show when={"edit-audit"}>
                <Tooltip
                  placement="top"
                  title={`${freire(texts.SHOW_DETAILS)} | ${freire(
                    texts.EDIT
                  )}`}
                >
                  <Button
                    shape="circle"
                    size="small"
                    ghost
                    type="primary"
                    style={{ marginRight: 8 }}
                    onClick={() => this.openDrawer(data)}
                  >
                    <Icon type="edit" />
                  </Button>
                </Tooltip>
              </Show>
            )}

            <Show when={"delete-audit"}>
              <Tooltip placement="top" title={freire(texts.DISCART_DELETE)}>
                <Popconfirm
                  placement="top"
                  title={freire(texts.CONFIRM_DISCART)}
                  okText={freire(texts.YES)}
                  cancelText={freire(texts.NO)}
                  onConfirm={() => this.onDeleteAudit(data)}
                >
                  <Button
                    shape="circle"
                    size="small"
                    ghost
                    type="danger"
                    style={{ marginRight: 8 }}
                  >
                    <Icon type="delete" />
                  </Button>
                </Popconfirm>
              </Tooltip>
            </Show>

            {!data.audited && (
              <Show when={"approve-audit"}>
                <Tooltip placement="top" title={freire(texts.APPROVE)}>
                  <Popconfirm
                    placement="top"
                    title={freire(texts.CONFIRM_APPROVE)}
                    okText={freire(texts.YES)}
                    cancelText={freire(texts.NO)}
                    onConfirm={() => this.onAprovedAudit(data)}
                  >
                    <Button
                      shape="circle"
                      size="small"
                      ghost
                      type="primary"
                      className="success"
                    >
                      <Icon type="check" />
                    </Button>
                  </Popconfirm>
                </Tooltip>
              </Show>
            )}
          </>
        ),
      },
    ];
  };

  plugs = ["Tipo 2", "CCS 2", "CHAdeMO", "Tipo 1", "GB/T", "Tesla"];

  render() {
    const { getFieldDecorator } = this.props.form;
    const { freire } = this.context;

    let {
      loadingTable,
      loadingSave,
      data,
      visibleDrawer,
      visibleEdit,
      currentStation,
      currentStationEdit,
    } = this.state;

    const dataToShow = this.getDataToShow();
    const columns = this.getColumns();

    return (
      <div className="container">
        <Breadcrumbs
          breadcrumbs={[
            freire(texts.ADMIN),
            freire(texts.STATIONS),
            freire(texts.AUDITS),
          ]}
        />
        <div className="filter">
          <Form wrapperCol={{ span: 24 }}>
            <Form.Item>
              <div style={{ flexDirection: "row", display: "flex" }}>
                {getFieldDecorator("name")(
                  <Input
                    className="firstInput"
                    placeholder={freire(texts.SEARCH_BY_NAME)}
                    onKeyDown={this.handleKeyPress}
                    onChange={(e) => {
                      e.target.value = e.target.value.trim();
                    }}
                  />
                )}
                <Button
                  icon="sync"
                  className="buttonSearch"
                  onClick={this.filter}
                >
                  {freire(texts.UPDATE)}
                </Button>
              </div>
            </Form.Item>

            <Row gutter={[16, 8]}>
              <Col span={6}>
                <Form.Item label={freire(texts.ADDRESS)}>
                  {getFieldDecorator("address")(
                    <Input
                      placeholder={freire(texts.ADDRESS)}
                      onKeyDown={this.handleKeyPress}
                      onChange={(e) => {
                        e.target.value = e.target.value.trim();
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label={freire(texts.AUDITED)}>
                  {getFieldDecorator("audited")(
                    <Radio.Group buttonStyle="solid">
                      <Radio.Button value={true}>
                        {freire(texts.YES)}
                      </Radio.Button>
                      <Radio.Button value={undefined}>---</Radio.Button>
                      <Radio.Button value={false}>
                        {freire(texts.NO)}
                      </Radio.Button>
                    </Radio.Group>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <div className="footer">
            <Button style={{ marginRight: 10 }} onClick={this.clearFilters}>
              {freire(texts.CLEAR)}
            </Button>
            <Button onClick={this.filter} type="primary">
              {freire(texts.FILTER)}
            </Button>
          </div>
        </div>

        <Table
          style={{ backgroundColor: "white" }}
          loading={loadingTable}
          dataSource={data}
          rowKey={(stationData) => stationData._id}
          columns={columns}
          pagination={{
            current: this.state.tablePage,
            onChange: (page) => this.setState({ tablePage: page }),
          }}
        />

        <Drawer
          title={freire(texts.AUDIT_STATION)}
          width={500}
          onClose={this.closeDrawer}
          visible={visibleDrawer}
          bodyStyle={{ paddingBottom: 80 }}
        >
          {visibleEdit ? (
            <Form layout="vertical" hideRequiredMark>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label={freire(texts.NAME)}>
                    <Input
                      placeholder={freire(texts.NAME)}
                      value={currentStationEdit.name}
                      onChange={(value) => {
                        currentStationEdit.name = value.target.value;
                        this.setState({ currentStationEdit });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label={freire(texts.ADDRESS)}>
                    <Input
                      placeholder={freire(texts.ADDRESS)}
                      value={currentStationEdit.address}
                      onChange={(value) => {
                        currentStationEdit.address = value.target.value;
                        this.setState({ currentStationEdit });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label={freire(texts.LATITUDE)}>
                    <Input
                      placeholder={freire(texts.LATITUDE)}
                      value={currentStationEdit.lat}
                      onChange={(value) => {
                        currentStationEdit.lat = value.target.value;
                        currentStationEdit.position.lat = value.target.value;
                        this.setState({ currentStationEdit });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={freire(texts.LONGITUDE)}>
                    <Input
                      placeholder={freire(texts.LONGITUDE)}
                      value={currentStationEdit.lng}
                      onChange={(value) => {
                        currentStationEdit.lng = value.target.value;
                        currentStationEdit.position.lng = value.target.value;
                        this.setState({ currentStationEdit });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label={freire(texts.BUSINESS_HOURS)}>
                    <Input
                      placeholder={freire(texts.BUSINESS_HOURS)}
                      value={currentStationEdit.businessHours}
                      onChange={(value) => {
                        currentStationEdit.businessHours = value.target.value;
                        this.setState({ currentStationEdit });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={freire(texts.PHONE)}>
                    <Input
                      placeholder={freire(texts.PHONE)}
                      value={currentStationEdit.phone}
                      onChange={(value) => {
                        currentStationEdit.phone = value.target.value;
                        this.setState({ currentStationEdit });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label={freire(texts.PLUGS)}>
                    <Select
                      mode="multiple"
                      placeholder={freire(texts.SELECT_PLUGS)}
                      optionLabelProp="label"
                      defaultValue={currentStationEdit.plugs}
                      onChange={(plugs) => {
                        currentStationEdit.plugs = plugs;
                        this.setState({ currentStationEdit });
                      }}
                    >
                      {sorter(this.plugs).map((plug) => (
                        <Option key={plug} value={plug} label={plug}>
                          {plug}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={freire(texts.FREE_PARKING)}>
                    <Switch
                      checked={currentStationEdit.freeParking}
                      onChange={(value) => {
                        currentStationEdit.freeParking = value;
                        this.setState({ currentStationEdit });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          ) : (
            <div className="containerEdit">
              {dataToShow.map((data, i) => (
                <Row
                  key={i}
                  style={{
                    padding: "20px 10px",
                    borderBottom:
                      i + 1 !== dataToShow.length && "1px solid #dadada",
                  }}
                >
                  <Col span={10}>
                    <span>{data.label}</span>
                  </Col>
                  <Col span={14}>{this.renderValue(data.key)}</Col>
                </Row>
              ))}
            </div>
          )}

          <div
            style={{
              width: "100%",
              marginTop: 10,
              backgroundColor: "#FFF",
              textAlign: "right",
            }}
          >
            {visibleEdit ? (
              <>
                <Button
                  disabled={loadingSave}
                  onClick={() =>
                    this.setState({
                      visibleEdit: false,
                      currentStationEdit: {},
                    })
                  }
                  style={{ marginRight: 10 }}
                >
                  {freire(texts.CANCEL)}
                </Button>

                <Button
                  loading={loadingSave}
                  onClick={this.onSalveStationAudit}
                  type="primary"
                >
                  {freire(texts.SAVE)}
                </Button>
              </>
            ) : (
              <>
                <Button onClick={this.closeDrawer} style={{ marginRight: 10 }}>
                  {freire(texts.CLOSE)}
                </Button>

                <Button
                  onClick={() =>
                    this.setState({
                      visibleEdit: true,
                      currentStationEdit: JSON.parse(
                        JSON.stringify(currentStation)
                      ),
                    })
                  }
                  type="primary"
                >
                  {freire(texts.EDIT)}
                </Button>
              </>
            )}
          </div>
        </Drawer>
      </div>
    );
  }
}

const PageAuditStations = Form.create({ name: "filters" })(AuditStations);
export default withRouter(PageAuditStations);
