import { Button, Drawer } from "antd";
import * as texts from "../../locales";
import InfoRow from "../../../../components/InfoRow";

interface IStationsProps {
  closeDrawer: () => void;
  visibleStationsDrawer: boolean;
  freire: any;
  stations: any;
}

const Stations = ({
  closeDrawer,
  visibleStationsDrawer,
  freire,
  stations,
}: IStationsProps) => {
  return (
    <Drawer
      title={freire(texts.STATIONS_LIST_TITLE)}
      width={500}
      onClose={closeDrawer}
      visible={visibleStationsDrawer}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <div>
        {stations.length === 0 && (
          <div>
            <b>{freire(texts.NO_STATIONS)}</b>
          </div>
        )}
        {stations.map((station: any) => {
          return (
            <div key={station.id}>
              <InfoRow
                labelSpan={7}
                valueSpan={17}
                label={`${freire(texts.NAME)}:`}
                value={station?.name}
                extraItems=""
                bold={false}
                bordered={false}
              />
              <InfoRow
                labelSpan={7}
                valueSpan={17}
                label="stationID"
                value={station?.stationID}
                extraItems=""
                bold={false}
                allowCopy
                bordered={true}
              />
            </div>
          );
        })}
      </div>
      <div
        style={{
          position: "absolute",
          right: 0,
          bottom: 0,
          width: "100%",
          borderTop: "1px solid #e9e9e9",
          padding: "10px 16px",
          background: "#fff",
          textAlign: "right",
        }}
      >
        <Button onClick={closeDrawer} style={{ marginRight: 8 }}>
          {freire(texts.CANCEL)}
        </Button>
      </div>
    </Drawer>
  );
};

export default Stations;
