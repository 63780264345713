export const GROUP = {
  pt: "Grupo",
  es: "Grupo",
  en: "Group",
};

export const EMAIL = {
  pt: "E-mails",
  es: "Correos electrónicos",
  en: "Emails",
};

export const STATIONS = {
  pt: "Estações",
  es: "Estaciones",
  en: "Stations",
};

export const ACTIONS = {
  pt: "Ações",
  es: "Acciones",
  en: "Actions",
};

export const SHOW_DETAILS_EDIT = {
  pt: "Ver detalhes | Editar",
  es: "Ver detalles | Editar",
  en: "View details | Edit",
};

export const DELETE = {
  pt: "Deletar",
  es: "Borrar",
  en: "Delete",
};

export const CONFIRM_DELETE = {
  pt: "Deseja realmente DELETAR?",
  es: "¿Desea realmente BORRAR?",
  en: "Do you really want to DELETE?",
};

export const YES = {
  pt: "Sim",
  es: "Sí",
  en: "Yes",
};

export const NO = {
  pt: "Não",
  es: "No",
  en: "No",
};

export const MORE_STATIONS = (NUMBER) => ({
  pt: `... mais ${NUMBER} estações`,
  es: `... más ${NUMBER} estaciones`,
  en: `... more ${NUMBER} stations`,
});

export const ALL_RIGHT = {
  pt: "Tudo certo!",
  es: "¡Está bien!",
  en: "All set!",
};

export const GROUP_EDITED_SUCCESS = {
  pt: "Grupo editado com sucesso!",
  es: "¡Grupo editado con éxito!",
  en: "Group successfully edited!",
};

export const OOPS = {
  pt: "Ops...",
  es: "Epa...",
  en: "Ops...",
};

export const ERROR_EDIT_GROUP = {
  pt: "Erro ao editar grupo!",
  es: "¡Error al editar grupo!",
  en: "Error editing group!",
};

export const GROUP_DELETED_SUCCESS = {
  pt: "Grupo deletado com sucesso!",
  es: "¡Grupo eliminado con éxito!",
  en: "Group successfully deleted!",
};

export const ERROR_DELETE_GROUP = {
  pt: "Erro ao deletar grupo",
  es: "Error al eliminar grupo",
  en: "Error deleting group",
};

export const ERROR_GET_PERMISSIONS_GROUPS = {
  pt: "Erro ao buscar grupos de permissões",
  es: "Error al buscar grupos de permisos",
  en: "Error searching permission groups",
};

export const ERROR_GET_STATIONS = {
  pt: "Erro ao buscar as estações",
  es: "Error al buscar las estaciones",
  en: "Error searching stations",
};

export const ADMIN = {
  pt: "Admin",
  es: "Admin",
  en: "Admin",
};

export const GROUPS = {
  pt: "Grupos",
  es: "Grupos",
  en: "Groups",
};

export const PERMISSIONS_GROUPS_LISTING = {
  pt: "Listagem - Grupos de permissões",
  es: "Listado - Grupos de permisos",
  en: "Listing - Permissions groups",
};

export const SEARCH_BY_GROUP_NAME = {
  pt: "Pesquise pelo nome do grupo...",
  es: "Busque por el nombre del grupo...",
  en: "Search by group name...",
};

export const SEARCH = {
  pt: "Pesquisar",
  es: "Buscar",
  en: "Search",
};

export const NEW = {
  pt: "Novo",
  es: "Nuevo",
  en: "New",
};

export const CLEAR = {
  pt: "Limpar",
  es: "Limpiar",
  en: "Clear",
};

export const FILTER = {
  pt: "Filtrar",
  es: "Filtrar",
  en: "Filter",
};

export const GROUP_CREATED_SUCCESS = {
  pt: "Grupo criado com sucesso!",
  es: "¡Grupo creado con éxito!",
  en: "Group successfully created!",
};

export const WENT_WONG = {
  pt: "Algo deu errado!",
  es: "¡Algo salió mal!",
  en: "Something went wrong!",
};

export const EDIT_GROUP = {
  pt: "Editar Grupo",
  es: "Editar grupo",
  en: "Edit Group",
};

export const NEW_PERMISSION_GROUP = {
  pt: "Novo grupo de alertas",
  es: "Nuevo grupo de alertas",
  en: "New alert group",
};

export const CREATE_ALERT_GROUP = {
  pt: "Crie um grupo de alertas",
  es: "Crear un grupo de alertas",
  en: "Create an alert group",
};

export const GROUP_NAME = {
  pt: "Nome do Grupo",
  es: "Nombre del Grupo",
  en: "Group name",
};

export const REQUIRED_FIELD = {
  pt: "Campo obrigatório",
  es: "Campo obligatorio",
  en: "Mandatory field",
};

export const GROUP_NAME_EX = {
  pt: "Ex.: Condomínio Abc",
  es: "p.ej.: Condominio Abc",
  en: "Example: Condomínio Abc",
};

export const EMAIL_LISTING = {
  pt: "Lista de e-mail",
  es: "Lista de correo electrónico",
  en: "Email list",
};

export const INPUT_EMAILS = {
  pt: "Digite os e-mails",
  es: "Escriba los correos electrónicos",
  en: "Enter e-mails",
};

export const INFORM_VALID_EMAIL = {
  pt: "Informe um e-mail válido",
  es: "Introduzca un correo electrónico válido",
  en: "Inform a valid e-mail.",
};

export const SELECT_STATIONS = {
  pt: "Selecione as estações",
  es: "Seleccione las estaciones",
  en: "Select stations",
};

export const SELECT_ALL = (NUMBER) => ({
  pt: `Selecionar todas (${NUMBER})`,
  es: `Seleccionar todas (${NUMBER})`,
  en: `Select all (${NUMBER})`,
});

export const CANCEL = {
  pt: "Cancelar",
  es: "Cancelar",
  en: "Cancel",
};

export const SAVE = {
  pt: "Salvar",
  es: "Guardar",
  en: "Save",
};
