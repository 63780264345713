import React, { Component, Fragment } from "react";
import {
  Button,
  Input,
  Form,
  Col,
  Row,
  Icon,
  Steps,
  Upload,
  message,
  notification,
  DatePicker,
  InputNumber,
  Alert,
  Radio,
} from "antd";
import moment from "moment-timezone";
import { withRouter } from "react-router-dom";
import api from "../../../services/api";
import "./index.css";
import firebase from "../../../services/firebaseConfig";
import { FreireContext } from "../../../utils/freireContext";
import * as texts from "../locales";
const { Step } = Steps;

class NewPublicity extends Component {
  static contextType = FreireContext;

  state = {
    loadingSave: false,
    img: null,
  };

  async getAspectRatio(imgSrc) {
    const res = await new Promise((resolve, reject) => {
      const img = new Image();
      img.src = imgSrc;

      img.onload = function () {
        resolve(this.width / this.height);
      };
      img.onerror = function () {
        reject(0);
      };
    });

    return Number(res.toFixed(2));
  }

  onUpload = async ({ onProgress, onError, onSuccess, file }) => {
    var storageRef = firebase.storage().ref(file.name);
    var taskUpload = storageRef.put(file);

    taskUpload.on(
      "state_changed",
      (snapshot) => {
        var percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        onProgress({ percent });
      },
      (e) => {
        onError(e);
      },
      () => {
        taskUpload.snapshot.ref.getDownloadURL().then(async (url) => {
          // try {
          //   const aspect = await this.getAspectRatio(url);
          //   console.log("aspect ->", aspect);
          // } catch (error) {
          //   console.log("aspect ->", error);
          // }

          this.setState({ img: { url, aspectRatio: 2, name: file.name } });
          onSuccess(null, url);
        });
      }
    );
  };

  onChange = (info) => {
    const { freire } = this.context;

    if (!this.verifyUpload(info.file)) {
      message.error(freire(texts.ONLY_JPG_PNG_CAN_UPLOAD));
    }

    if (info.file.status === "done") {
      message.success(freire(texts.UPLOAD_SUCCESS));
    } else if (info.file.status === "error") {
      message.error(freire(texts.ERROR_UPLOAD));
    }
  };

  verifyUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    return isJpgOrPng;
  };

  salvar = () => {
    const { freire } = this.context;

    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }

      delete values.upload;
      let payload = {
        ...values,
        img: this.state.img,
      };

      this.setState({ loadingSave: true }, async () => {
        try {
          await api.post("publicity", payload);

          this.setState({ loadingSave: false });

          notification.success({
            message: freire(texts.ALL_RIGHT),
            description: freire(texts.PUBLICITY_CREATED_SUCCESS),
          });

          this.props.history.push("/dashboard/advertising");
        } catch (error) {
          this.setState({ loadingSave: false });

          const {
            response: {
              data: { message },
            },
          } = error;

          notification.error({
            message: freire(texts.WENT_WONG),
            description: message || error.message,
          });
        }
      });
    });
  };

  required = (check = true) => {
    const { freire } = this.context;

    return {
      required: check,
      message: freire(texts.REQUIRED_FIELD),
    };
  };

  validateDate = (date, msg) => {
    const { freire } = this.context;

    return {
      validator: async (rule, value, callback) => {
        try {
          if (value && date.isAfter(value)) {
            throw new Error(msg || freire(texts.INSET_DATE_IN_FUTURE));
          }
        } catch (err) {
          callback(err);
        }
      },
    };
  };

  disabledDate = (current, date) => {
    return current && current < date.subtract(1, "day").endOf("day");
  };

  showTime = () => {
    return {
      defaultValue: moment("09:00:00", "HH:mm:ss"),
    };
  };

  getValueFromEvent = (event) => {
    if (!this.verifyUpload(event.file)) return [];

    if (event && event.file && event.file.status === "done") {
      return [
        {
          ...event.file,
          url: event.file.xhr,
          thumbUrl: event.file.xhr,
        },
      ];
    }

    return event && event.fileList;
  };

  getIntervalDates = () => {
    const { startDate, endDate, intervalDays, sendMode } =
      this.props.form.getFieldsValue();

    if (startDate) {
      if (sendMode === 1) {
        return [moment(startDate)];
      }

      if (sendMode === 2 && endDate && intervalDays && intervalDays >= 5) {
        var qtyInterval = Math.floor(
          moment(endDate).diff(moment(startDate), "days") / intervalDays
        );

        var list = [];

        list.push(moment(startDate));
        for (let i = 1; i <= qtyInterval; i++) {
          list.push(moment(startDate).add(intervalDays * i, "days"));
        }

        return list;
      }
    }

    return [];
  };

  render() {
    const { getFieldDecorator, getFieldsValue } = this.props.form;
    const { loadingSave } = this.state;
    const { startDate, endDate, sendMode, upload } = getFieldsValue();
    const intervalDates = this.getIntervalDates();
    const { freire } = this.context;

    return (
      <div className="container">
        <div className="content">
          <Row>
            <Col span={4}>
              <Steps
                current={1}
                size="small"
                onChange={this.onChange}
                direction="vertical"
              >
                <Step
                  icon={<Icon type="profile" />}
                  title={freire(texts.PUBLICITY)}
                  description={freire(texts.ADD_PUBLICITY)}
                />
              </Steps>
            </Col>

            <Col span={20}>
              <Form layout="vertical">
                <Row gutter={[16, 0]}>
                  <Col span={12}>
                    <Form.Item label={freire(texts.NAME)}>
                      {getFieldDecorator("name", {
                        rules: [this.required()],
                      })(
                        <Input
                          disabled={loadingSave}
                          placeholder={freire(texts.NAME_EX)}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={freire(texts.IMAGE)}>
                      {getFieldDecorator("upload", {
                        rules: [this.required()],
                        valuePropName: "fileList",
                        getValueFromEvent: this.getValueFromEvent,
                      })(
                        <Upload
                          style={{ marginTop: 0 }}
                          listType="picture"
                          customRequest={this.onUpload}
                          beforeUpload={this.verifyUpload}
                          onChange={this.onChange}
                          onRemove={() => this.setState({ img: null })}
                          disabled={loadingSave}
                          multiple={false}
                        >
                          {(!upload || upload.length < 1) && (
                            <Button
                              className="btn-upload"
                              size="large"
                              icon={"upload"}
                              disabled={loadingSave}
                            >
                              <span>{freire(texts.DRAG_HERE)}</span>
                            </Button>
                          )}
                        </Upload>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label={freire(texts.ACTION_URL)}>
                      {getFieldDecorator("urlAction")(
                        <Input
                          disabled={loadingSave}
                          placeholder={freire(texts.ACTION_URL_EX)}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label={freire(texts.SHOW_CAMPAIGN)}>
                      {getFieldDecorator("sendMode", {
                        rules: [this.required()],
                      })(
                        <Radio.Group
                          onChange={() =>
                            this.props.form.resetFields([
                              "endDate",
                              "intervalDays",
                            ])
                          }
                          buttonStyle="solid"
                        >
                          <Radio className="radioVertical" value={1}>
                            {freire(texts.ONLY_ONE_TIME)}
                          </Radio>
                          <Radio className="radioVertical" value={2}>
                            {freire(texts.IN_PERIOD)}
                          </Radio>
                        </Radio.Group>
                      )}
                    </Form.Item>
                  </Col>
                  {sendMode && (
                    <Col span={12}>
                      <Form.Item
                        label={freire(texts.DATE_TIME_TO_SHOW(sendMode))}
                      >
                        {getFieldDecorator("startDate", {
                          rules: [this.required(), this.validateDate(moment())],
                        })(
                          <DatePicker
                            style={{ width: "100%" }}
                            placeholder={freire(texts.SELECT_DATE_TIME)}
                            format="L LTS"
                            disabledDate={(current) =>
                              this.disabledDate(current, moment())
                            }
                            showTime={this.showTime()}
                            onChange={() => {
                              this.props.form.resetFields([
                                "endDate",
                                "intervalDays",
                              ]);
                            }}
                            disabled={loadingSave}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  {sendMode === 2 && (
                    <Fragment>
                      <Col span={12}>
                        <Form.Item label={freire(texts.END_DATE_TIME)}>
                          {getFieldDecorator("endDate", {
                            rules: [
                              this.required(),
                              this.validateDate(
                                moment(startDate),
                                freire(texts.INSERT_DATE_TIME_TO_SEND)
                              ),
                            ],
                          })(
                            <DatePicker
                              style={{ width: "100%" }}
                              placeholder={freire(texts.SELECT_DATE_TIME)}
                              format="L LTS"
                              disabledDate={(current) =>
                                this.disabledDate(current, moment(startDate))
                              }
                              showTime={this.showTime()}
                              disabled={!startDate || loadingSave}
                            />
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label={freire(texts.INTERVAL_DAYS)}>
                          {getFieldDecorator("intervalDays", {
                            rules: [
                              this.required(),
                              {
                                validator: async (rule, value, callback) => {
                                  if (value && value < 5) {
                                    callback(freire(texts.FIVE_DAYS_MINIMUM));
                                  }
                                },
                              },
                            ],
                          })(
                            <InputNumber
                              style={{ width: "100%" }}
                              disabled={!endDate || loadingSave}
                              placeholder={freire(texts.INFORM_QTD_DAYS)}
                            />
                          )}
                        </Form.Item>
                      </Col>
                    </Fragment>
                  )}
                </Row>
              </Form>

              {intervalDates.length > 0 && (
                <Alert
                  style={{ marginBottom: 25 }}
                  message={freire(texts.CAMPAIGN_RESUME_SENDS)}
                  description={
                    <div style={{ paddingLeft: 20 }}>
                      {intervalDates.map((date, index) => {
                        return (
                          <div key={date}>
                            {index + 1}º: {date.format("L LT")} (
                            {date.format("dddd")})
                          </div>
                        );
                      })}
                    </div>
                  }
                  type="info"
                  showIcon
                />
              )}

              <div className="footer">
                <Button
                  style={{ marginRight: 20 }}
                  type="default"
                  disabled={loadingSave}
                  onClick={() =>
                    this.props.history.push("/dashboard/publicity")
                  }
                >
                  {freire(texts.CANCEL)}
                </Button>
                <Button
                  loading={this.state.loadingSave}
                  onClick={this.salvar}
                  type="primary"
                >
                  {freire(texts.SAVE)}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const PageNewPublicity = Form.create({ name: "inputs" })(NewPublicity);
export default withRouter(PageNewPublicity);
