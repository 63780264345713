import React, {
  forwardRef,
  Fragment,
  Ref,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Button, Divider, Icon, Input, Select } from "antd";
import { SelectProps } from "antd/lib/select";
import * as texts from "./locales";
import { useFreire } from "../../utils/freireContext";

type SelectWithNewItemProps = SelectProps & {
  onAddNewItem: (itemName: string) => void;
  inputPlaceholder?: string;
};

const SelectWithNewItem: React.FC<SelectWithNewItemProps> = forwardRef(
  ({ onAddNewItem, inputPlaceholder, ...props }, ref: Ref<Select>) => {
    const { freire } = useFreire();

    const [isOpened, setIsOpened] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const selectRef = useRef<Select>(null);
    const inputRef = useRef<Input>(null);

    useImperativeHandle(ref, () => selectRef.current!, []);

    const setName = (name: string) => {
      if (inputRef.current) {
        inputRef.current.setValue(name);
      }
    };

    const onSelect = () => {
      setName("");
      setIsOpened(false);
    };

    const handlerOnAddNewItem = useCallback(() => {
      const formattedName = (inputRef.current?.state?.value ?? "").trim();

      if (!formattedName) return;

      onAddNewItem(formattedName);

      setName("");
      setIsOpened(false);
    }, [onAddNewItem]);

    const onDropdownVisibleChange = useCallback(
      (visible: boolean) => {
        if (!visible && isFocused) return;

        setName("");
        setIsOpened(visible);
      },
      [isFocused]
    );

    const dropdownRender = useCallback(
      (menu: React.ReactNode) => (
        <Fragment>
          {menu}

          <Divider style={{ margin: "4px 0" }} />

          <div
            style={{
              padding: "8px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Input
              ref={inputRef}
              onFocus={() => setIsFocused(true)}
              onBlur={() => {
                setIsFocused(false);
                selectRef.current?.focus();
              }}
              placeholder={
                inputPlaceholder || freire(texts.DEFAULT_INPUT_PLACEHOLDER)
              }
              onKeyDown={(event) => event.stopPropagation()}
            />
            <Button style={{ marginLeft: 8 }} onClick={handlerOnAddNewItem}>
              <Icon type="plus" /> Add
            </Button>
          </div>
        </Fragment>
      ),
      [freire, handlerOnAddNewItem, inputPlaceholder]
    );

    return (
      <Select
        ref={selectRef}
        open={isOpened}
        allowClear
        showSearch
        optionFilterProp="children"
        onSelect={onSelect}
        onDropdownVisibleChange={onDropdownVisibleChange}
        dropdownRender={dropdownRender}
        {...props}
      >
        {props.children}
      </Select>
    );
  }
);

export default SelectWithNewItem;
