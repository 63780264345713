import { Progress } from "antd"
import moment from "moment"
import "./index.css"
import { useFreire } from "../../../../utils/freireContext";
import * as texts from "../../locales"
interface IDateProps{
	createdAt: string;
	data: {
		expireDate: string;
	}
}

export default function DateComponent({createdAt, data}: IDateProps) {
	const { freire } = useFreire();

	const now = Date.now()
	const percent = 100.0 * moment(now).diff(moment(createdAt)) / moment(data.expireDate).diff(moment(createdAt))

	const rest = moment(data.expireDate).fromNow()
	const restDays = Math.abs(moment(now).diff(data.expireDate, "days"))

	let isExpired = false
	let isCloseExpire = false

	if(moment(now) > moment(data.expireDate)){
		isExpired = true
	}
	if(restDays <= 15){
		isCloseExpire = true
	}

	return (
	<div className="dateContainer"> 
		<div className="dateFormatContainer">
			<div className="formatContainer">
				<span>{moment(createdAt).format("DD/MM/YYYY")}</span>
				<span className="hourText">{moment(createdAt).format("HH:mm")}</span>
			</div>
			<div className="formatContainer">
				<span><b>{moment(data.expireDate).format("DD/MM/YYYY")}</b></span>
				<span className="hourExpireText">{moment(data.expireDate).format("HH:mm")}</span>
			</div>
		</div>
		<div>
			<div>
				<Progress percent={percent} showInfo={false} status="normal" strokeColor="#CECECD"/>
			</div>
		</div>
		<div className="dateTextContainer">
			{isExpired && <span className="expiredText">{freire(texts.EXPIRE)}</span> }
			{!isExpired && !isCloseExpire && <span>{rest}</span>}
			{isCloseExpire && <span>{rest}</span>}
		</div>
	</div>)
}