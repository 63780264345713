export const ALL_RIGHT = {
  pt: "Tudo certo!",
  es: "¡Muy bien!",
  en: "All set!",
};

export const PLUG_EDITED_SUCESS = {
  pt: "Plug editado com sucesso!",
  es: "¡Conector editado con éxito!",
  en: "Plug successfully edited!",
};

export const OOPS = {
  pt: "Ops...",
  es: "Epa...",
  en: "Ops...",
};

export const PLUG_CREATED_SUCCESS = {
  pt: "Plug criado com sucesso!",
  es: "¡Conector creado con éxito!",
  en: "Plug successfully created!",
};

export const ERROR_EDIT_PLUG = {
  pt: "Erro ao editar plug!",
  es: "¡Error al editar el conector!",
  en: "Error editing plug!",
};

export const ERROR_GET_PLUGS = {
  pt: "Erro ao buscar plugs",
  es: "Error al buscar conectores",
  en: "Error searching plugs",
};

export const WENT_WONG = {
  pt: "Algo deu errado!",
  es: "¡Algo salió mal!",
  en: "Something went wrong!",
};

export const NEW_PLUG = {
  pt: "Novo plug",
  es: "Nuevo conector",
  en: "New Plug",
};

export const NEW_PLUG_DESCRIPTION = {
  pt: "Crie um plug para ser atribuído a uma estação",
  es: "Crear un conector para asignar a una estación",
  en: "Create a plug to be assigned to a station",
};

export const ADMIN = {
  pt: "Admin",
  es: "Admin",
  en: "Admin",
};

export const MANAGEMENT = {
  pt: "Administração",
  es: "Administración",
  en: "Administration",
};

export const PLUGS = {
  pt: "Plugs",
  es: "Conectores",
  en: "Plugs",
};

export const SEARCH_BY_PLUG_NAME = {
  pt: "Pesquise pelo nome do plug...",
  es: "Busque por el nombre del conector...",
  en: "Search by plug name...",
};

export const SEARCH = {
  pt: "Pesquisar",
  es: "Buscar",
  en: "Search",
};

export const NEW = {
  pt: "Novo",
  es: "Nuevo",
  en: "New",
};

export const CLEAR = {
  pt: "Limpar",
  es: "Limpiar",
  en: "Clear",
};

export const FILTER = {
  pt: "Filtrar",
  es: "Filtrar",
  en: "Filter",
};

export const NAME = {
  pt: "Nome",
  es: "Nombre",
  en: "Name",
};

export const ICON = {
  pt: "Ícone",
  es: "Icono",
  en: "Icon",
};

export const ERROR_LOAD_IMAGES = {
  pt: "Erro ao carregar imagem. Tente abrir o link.",
  es: "Error al cargar imagen. Intente abrir el enlace.",
  en: "Error loading image. Try opening the link.",
};

export const ACTIONS = {
  pt: "Ações",
  es: "Acciones",
  en: "Actions",
};

export const EDIT = {
  pt: "Editar",
  es: "Editar",
  en: "Edit",
};

export const EDIT_PLUG = {
  pt: "Editar Plug",
  es: "Editar Conector",
  en: "Edit Plug",
};

export const PLUG_NAME = {
  pt: "Nome do Plug",
  es: "Nombre del conector",
  en: "Plug Name",
};

export const REQUIRED_FIELD = {
  pt: "Campo obrigatório",
  es: "Campo obligatorio",
  en: "Mandatory field",
};

export const PLUG_NAME_EX = {
  pt: "Ex.: Tipo 2",
  es: "p.ej.: Tipo 2",
  en: "Example: Type 2",
};

export const ICON_EXAMPLE = {
  pt: "Ex.: https://www.example.com/image.png",
  es: "p.ej.: https://www.example.com/image.png",
  en: "Example: https://www.example.com/image.png",
};

export const CANCEL = {
  pt: "Cancelar",
  es: "Cancelar",
  en: "Cancel",
};

export const SAVE = {
  pt: "Salvar",
  es: "Guardar",
  en: "Save",
};
