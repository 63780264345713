import React from "react";

// adapted from antd
export const Copy = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 0.5H2C0.9 0.5 0 1.4 0 2.5V16.5H2V2.5H15V0.5ZM18 4.5H6C4.9 4.5 4 5.4 4 6.5V18.5C4 19.6 4.9 20.5 6 20.5H18C19.1 20.5 20 19.6 20 18.5V6.5C20 5.4 19.1 4.5 18 4.5ZM18 18.5H6V6.5H18V18.5Z"
      fill="#747277"
    />
  </svg>
);
