import React from "react";
import SimpleTable from "./SimpleTable";
import { Link } from "react-router-dom";
import { useFreire } from "../../utils/freireContext";
import * as texts from "./locales";

interface IEnergyRemunerationTable {
	data: [];
}

const EnergyRemunerationTable: React.FC<IEnergyRemunerationTable> = ({
	data,
}) => {
	const { freire } = useFreire();
	return (
		<SimpleTable
			columns={[
				{
					title: freire(texts.RECIPIENT),
					dataIndex: "receptor",
					key: "receptor",
				},
				{
					title: freire(texts.PERCENTAGE),
					dataIndex: "percentage",
					key: "percentage",
				},
			]}
			dataSource={data}
			endAdornment={
				<div style={{ marginTop: "0.5rem" }}>
					<span>
						{freire(texts.PAYMENT_SPLIT_INSTRUCTION)}
						<a href="##">
							<Link to="/dashboard/companys">{freire(texts.EDIT_INFO)}</Link>
						</a>
					</span>
				</div>
			}
		/>
	);
};

export default EnergyRemunerationTable;
