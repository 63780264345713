import React from "react";
import { Button, Divider, Icon, Modal } from "antd";
import { useFreire } from "../../../../../utils/freireContext";
import * as texts from "../../../locales";
import { TicketIcon } from "../../../../../assets/icons";

const ModalRemoveUser = ({ visible, loading, coupon, onCancel, onConfirm }) => {
  const { freire } = useFreire();

  return (
    <Modal
      centered
      width={"50%"}
      className="modalRemoveUser"
      footer={null}
      closable={false}
      visible={visible}
    >
      <span className="modalRemoveUserTitle">
        <Icon
          type="user-delete"
          style={{ color: "#1890FF", fontSize: 24, marginRight: 8 }}
        />
        {freire(texts.REMOVE_USER_COUPON).toUpperCase()}
      </span>

      <Divider />

      <div className="modalRemoveUserCard">
        <div className="modalRemoveUserCardInfo" style={{ marginBottom: 10 }}>
          <Icon component={TicketIcon} className="modalRemoveUserCardIcon" />
          <span>{coupon?.cod}</span>
        </div>

        <div className="modalRemoveUserCardInfo">
          <Icon type="user" className="modalRemoveUserCardIcon" />
          <span>{coupon?.user?.email}</span>
        </div>
      </div>

      <span>{freire(texts.CONFIRM_REMOVE_USER_COUPON)}</span>

      <div className="modalRemoveUserFooter">
        <Button
          loading={loading}
          style={{ backgroundColor: "#F40000", flex: 1 }}
          type="danger"
          onClick={onConfirm}
        >
          {freire(texts.REMOVE)}
        </Button>
        <div style={{ width: 24 }} />
        <Button style={{ flex: 1 }} disabled={loading} onClick={onCancel}>
          {freire(texts.CANCEL)}
        </Button>
      </div>
    </Modal>
  );
};

export default ModalRemoveUser;
