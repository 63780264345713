import * as texts from "../locales";

export const generateFields = (
  companyOptions: { label: string; value: string }[],
  onChangeCompany: () => void,
  freire: any
) => [
  {
    isMainFilter: true,
    inputSearch: {
      label: freire(texts.NAME),
      placeholder: freire(texts.SEARCH_BY_NAME),
    },
    buttonNew: {
      permission: "",
    },
  },
  {
    label: freire(texts.COMPANY),
    name: "company_id",
    component: "tupi.select",
    isMultiple: true,
    placeholder: freire(texts.SELECT_COMPANY),
    options: companyOptions,
    onChange: onChangeCompany,
    colSpan: 8,
  },
];
