import { TimezoneProps } from "../interfaces";
import { Button, Icon, Select } from "antd";
import { useFreire } from "../../../utils/freireContext";
import { useContext } from "react";
import * as texts from "../locales";
import moment from "moment";
import "../index.css";
import { BusinessHourContext } from "../index";

export default function Timezone({ timezones, timezone }: TimezoneProps) {
  const { freire } = useFreire();

  const { loading, onChangeTimezone, disabled } =
    useContext(BusinessHourContext);

  return (
    <div className="timezoneContainer">
      <span className="titleTimezone">{freire(texts.TIMEZONE)}</span>
      <div className="rowTimezone">
        <Button
          disabled={loading || disabled}
          type="dashed"
          className="timezoneButton"
          onClick={() => {
            onChangeTimezone(moment.tz.guess());
          }}
        >
          <Icon type="environment" />
          <span>{freire(texts.USE_LOCATION)}</span>
        </Button>
        <Select
          disabled={loading || disabled}
          showSearch
          placeholder="Select a timezone"
          optionFilterProp="fullName"
          autoClearSearchValue
          value={timezone}
          filterOption={(input, option) => {
            return (
              String(option?.props?.value)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            );
          }}
          onChange={(value: string) => onChangeTimezone(value)}
        >
          {timezones.map((tz, i) => (
            <Select.Option key={tz} value={tz}>
              {" "}
              {tz}
            </Select.Option>
          ))}
        </Select>
      </div>
    </div>
  );
}
