import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Icon,
  Input,
  Modal,
  Row,
  Select,
  Switch,
} from "antd";
import api from "../../../services/api";
import { useFreire } from "../../../utils/freireContext";
import * as texts from "../locales";

function ModalMakeAdmin({
  form,

  visible = false,
  loading = false,
  onClose = () => {},
  onSave = () => {},
}) {
  const [companies, setCompanies] = useState([]);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const { freire } = useFreire();

  useEffect(() => {
    async function getCompanies() {
      try {
        setLoadingCompanies(true);

        const { data: companies } = await api.get("company");

        setCompanies(companies);
      } catch (error) {
      } finally {
        setLoadingCompanies(false);
      }
    }

    if (visible) {
      getCompanies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const handlerSave = () => {
    form.validateFields(async (err, payload) => {
      if (err) return;

      const [companyID, companyName] = payload.companyInfo?.split("/");
      payload = { ...payload, companyID, companyName };

      delete payload.companyInfo;

      onSave(payload, () => form.resetFields());
    });
  };

  const handlerClose = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      width="50%"
      title={freire(texts.MAKE_USER_ADMIN)}
      visible={visible}
      closable={false}
      footer={[
        <Button
          disabled={loading}
          key="cancel"
          onClick={handlerClose}
          style={{ marginRight: 15 }}
        >
          {freire(texts.CANCEL)}
        </Button>,
        <Button
          loading={loading}
          key="save"
          type="primary"
          onClick={handlerSave}
        >
          {freire(texts.SAVE)}
        </Button>,
      ]}
    >
      <Form>
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Form.Item label={freire(texts.E_MAIL)}>
              {form.getFieldDecorator("email", {
                rules: [
                  { required: true, message: freire(texts.REQUIRED_FIELD) },
                ],
              })(
                <Input
                  style={{ width: "100%" }}
                  prefix={
                    <Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="exemplo@email.com"
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item label={freire(texts.COMPANY)}>
              {form.getFieldDecorator("companyInfo", {
                rules: [
                  {
                    required: true,
                    message: freire(texts.REQUIRED_FIELD),
                  },
                ],
              })(
                <Select
                  showSearch
                  loading={loadingCompanies}
                  disabled={loading}
                  placeholder={freire(texts.SELECT_COMPANY)}
                >
                  {companies.map((company) => {
                    return (
                      <Select.Option
                        key={company._id}
                        value={`${company._id}/${company.companyName}`}
                      >
                        {company.companyName}
                      </Select.Option>
                    );
                  })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={freire(texts.ADMIN)}>
              {form.getFieldDecorator("admin", {
                initialValue: true,
                valuePropName: "checked",
                rules: [
                  {
                    required: true,
                    message: freire(texts.REQUIRED_FIELD),
                  },
                ],
              })(
                <Switch
                  disabled
                  checkedChildren={freire(texts.YES)}
                  unCheckedChildren={freire(texts.NO)}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

const ComponentModalMakeAdmin = Form.create({ name: "inputs" })(ModalMakeAdmin);
export default ComponentModalMakeAdmin;
