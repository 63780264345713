import useAccess from "./useAccess";

const withPermission = (Component) =>
  function WithPermission(props) {
    const { hasPermission } = useAccess();

    return <Component {...props} hasPermission={hasPermission} />;
  };

export default withPermission;
