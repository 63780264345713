import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Modal,
  notification,
  Popover,
  Row,
  Table,
} from "antd";
import copy from "copy-to-clipboard";
import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import apiOcpp from "../../../services/apiOcpp";
import "./index.css";
// import EditDrawer from "./EditDrawer";
import moment from "moment-timezone";
import dots from "../../../assets/dots.png";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { Show } from "../../../contexts/PermissionContext";
import { FreireContext } from "../../../utils/freireContext";
import * as texts from "../locales";
import withPermission from "../../../contexts/PermissionContext/withPermission";
import { formatters } from "../../../utils/formatters";
import { sorter } from "../../../utils/sorter";

const { RangePicker } = DatePicker;

class ListOcppStations extends Component {
  static contextType = FreireContext;

  state = {
    loadingTable: false,
    dateFiltrada: [],
    stationId: "",
    tablePage: 1,
  };

  getColumns = () => {
    const { freire } = this.context;

    return [
      {
        title: freire(texts.IDENTIFICATION),
        dataIndex: "stationId",
        key: "stationId",
        render: (stationId, data, index) => {
          return (
            <div style={{ flexDirection: "column" }}>
              <div className="stationName">{data.name || ""}</div>
              <div className="stationId">{stationId}</div>
            </div>
          );
        },
      },
      {
        title: freire(texts.STATUS),
        dataIndex: "connectors",
        key: "connectors",
        render: (connectors, data, index) => {
          return (
            <div style={{ flexDirection: "column" }}>
              {data.disconnectionTimestamp ? (
                <div style={{ marginTop: 8, marginBottom: 8 }}>
                  <div className="viewStatus">
                    <div className="disconnected" />
                    {freire(texts.DISCONNECTED)}
                  </div>
                  <div className="disconnectionTime">
                    {freire(
                      texts.THERE_IS(
                        formatters.duration(freire)(
                          moment().diff(data.disconnectionTimestamp)
                        )
                      )
                    )}
                  </div>
                </div>
              ) : connectors.length > 0 ? (
                connectors.map((connector) => (
                  <div
                    key={connector.connectorId}
                    style={{ marginTop: 8, marginBottom: 8 }}
                  >
                    {this.statusRender(connector)}
                  </div>
                ))
              ) : (
                "----"
              )}
            </div>
          );
        },
      },
      {
        title: freire(texts.LAST_HEARBEAT),
        dataIndex: "lastHeartbeat",
        key: "lastHeartbeat",
        render: (lastHeartbeat, data) => {
          return (
            <div style={{ flexDirection: "column" }}>
              <div>
                {lastHeartbeat ? moment(lastHeartbeat).format("L LTS") : "----"}
              </div>
            </div>
          );
        },
      },
      {
        title: freire(texts.CONNECTION_TIME),
        dataIndex: "connectionTimestamp",
        key: "connectionTimestamp",
        render: (connectionTimestamp, data) => {
          return (
            <div style={{ flexDirection: "column" }}>
              <div className="connectionTime">
                {formatters.duration(freire)(
                  moment().diff(connectionTimestamp)
                )}
              </div>
              <div className="connectionDate">
                {connectionTimestamp && moment(connectionTimestamp).calendar()}
              </div>
            </div>
          );
        },
      },
      {
        title: freire(texts.CHARGEPOINT_VENDOR),
        dataIndex: "chargePointVendor",
        key: "chargePointVendor",
      },
      {
        title: freire(texts.MODEL),
        dataIndex: "chargePointModel",
        key: "chargePointModel",
        render: (chargePointModel = "", data) => {
          if (chargePointModel?.length > 10) {
            return (
              <Popover
                placement="top"
                trigger="hover"
                title={"Ver mais"}
                content={<span>{chargePointModel}</span>}
              >
                <span style={{ cursor: "pointer" }}>
                  {`${chargePointModel.slice(0, 10)}...`}
                </span>
              </Popover>
            );
          }

          return chargePointModel;
        },
      },
      {
        title: freire(texts.FIRMWARE),
        dataIndex: "firmwareVersion",
        key: "firmwareVersion",
        render: (firmwareVersion = "", data) => {
          if (firmwareVersion?.length > 10) {
            return (
              <Popover
                placement="top"
                trigger="hover"
                title={"Ver mais"}
                content={<span>{firmwareVersion}</span>}
              >
                <span style={{ cursor: "pointer" }}>
                  {`${firmwareVersion.slice(0, 10)}...`}
                </span>
              </Popover>
            );
          }

          return firmwareVersion;
        },
      },
      {
        title: freire(texts.ACTIONS),
        key: "acoes",
        render: (text, data) => (
          <Show when={"execute-reboot-station"}>
            <Dropdown overlay={this.actions(data)}>
              <div>
                <img src={dots} style={{ width: 21 }} alt="" />
              </div>
            </Dropdown>
          </Show>
        ),
      },
    ];
  };

  componentDidMount() {
    this.getStations();
  }

  async getStations(params = {}) {
    const { freire } = this.context;

    try {
      this.setState({ loadingTable: true });

      const { data } = await apiOcpp.get(`stations`, { params });

      this.setState({ dateFiltrada: sorter(data) });
    } catch (error) {
      notification.error({
        message: freire(texts.OOPS),
        description: freire(texts.ERROR_GET_STATIONS),
      });
    } finally {
      this.setState({ loadingTable: false });
    }
  }

  filtroDate = async (nome) => {
    // filtro
  };

  filter = async () => {
    this.setState({ loadingTable: true });
    await this.props.form.validateFields(async (err, values) => {
      let params = {};
      let { dateTime } = values;
      if (dateTime && dateTime.length > 0) {
        let startDate = dateTime[0]
          .utcOffset(0)
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .toJSON();

        let endDate = dateTime[1]
          .utcOffset(0)
          .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
          .toJSON();

        params["startDate"] = startDate;
        params["endDate"] = endDate;
      }

      if (!values.stationIdOrName) values.stationIdOrName = "";

      if (!err) {
        const { data } = await apiOcpp.get(
          `stations?stationIdOrName=${values.stationIdOrName}`,
          { params }
        );
        this.setState({
          dateFiltrada: sorter(data),
          loadingTable: false,
          tablePage: 1,
        });
      }
    });
  };

  statusRender(connector) {
    const { freire } = this.context;

    const { lastStatus, lastStatusTimestamp, connectorId } = connector;

    if (lastStatus === "Available") {
      return (
        <div key={connectorId} className="viewStatus">
          <div className="available" />
          {freire(texts.AVAILABLE)} {`ID ${connectorId}`}
        </div>
      );
    } else if (lastStatus === "Charging") {
      return (
        <div key={connectorId} className="viewStatus">
          <div className="charging" />
          {freire(texts.CHARGING)} {`ID ${connectorId}`}
        </div>
      );
    } else if (lastStatus === "Faulted") {
      return (
        <div key={connectorId} className="viewStatus">
          <div className="faulted" />
          {freire(texts.FAULTED)} {`ID ${connectorId}`}
        </div>
      );
    } else if (["SuspendedEV", "SuspendedEVSE"].includes(lastStatus)) {
      return (
        <Fragment>
          <div key={connectorId} className="viewStatus">
            <div className="suspendedEV" />
            {freire(texts.SUSPENDEDEV)} {`ID ${connectorId}`}
          </div>

          {lastStatusTimestamp && (
            <div className="suspendedTime">
              {freire(
                texts.THERE_IS(
                  formatters.duration(freire)(
                    moment().diff(lastStatusTimestamp)
                  )
                )
              )}
            </div>
          )}
        </Fragment>
      );
    } else if (lastStatus === "Preparing") {
      return (
        <div key={connectorId} className="viewStatus">
          <div className="preparing" />
          {freire(texts.PREPARING)} {`ID ${connectorId}`}
        </div>
      );
    } else if (lastStatus === "Finishing") {
      return (
        <div key={connectorId} className="viewStatus">
          <div className="finishing" />
          {freire(texts.FINISHING)} {`ID ${connectorId}`}
        </div>
      );
    } else if (lastStatus === "Unavailable") {
      return (
        <div key={connectorId} className="viewStatus">
          <div className="unavailable" />
          {freire(texts.UNAVAILABLE)} {`ID ${connectorId}`}
        </div>
      );
    } else {
      return "----";
    }
  }

  actions = ({ stationId, authKeyHash }) => {
    const { hasPermission } = this.props;
    const { freire } = this.context;

    const canRebootStation = hasPermission("execute-reboot-station");
    const canCreateStationAuth = hasPermission("create-station-auth");
    const canCopyStationAuth = hasPermission("copy-station-auth");
    const canDeleteStationAuth = hasPermission("delete-station-auth");

    return (
      <Menu>
        {canRebootStation && (
          <Menu.Item onClick={() => this.showConfirm(stationId, "Soft")}>
            <span>{freire(texts.SOFT_REBOOT)}</span>
          </Menu.Item>
        )}

        {canRebootStation && (
          <Menu.Item onClick={() => this.showConfirm(stationId, "Hard")}>
            <span>{freire(texts.HARD_REBOOT)}</span>
          </Menu.Item>
        )}

        {canCreateStationAuth && !authKeyHash && (
          <Menu.Item onClick={() => this.createHashKey(stationId)}>
            <span>{freire(texts.CREATE_HASH_KEY)}</span>
          </Menu.Item>
        )}

        {canCopyStationAuth && authKeyHash && (
          <Menu.Item onClick={() => this.getHashKey(stationId)}>
            <span>{freire(texts.COPY_HASH_KEY)}</span>
          </Menu.Item>
        )}

        {canDeleteStationAuth && authKeyHash && (
          <Menu.Item onClick={() => this.deleteHashKey(stationId)}>
            <span>{freire(texts.DELETE_HASH_KEY)}</span>
          </Menu.Item>
        )}
      </Menu>
    );
  };

  async createHashKey(stationId) {
    const { freire } = this.context;

    Modal.confirm({
      title: freire(texts.ATTENTION),
      content: freire(texts.CONFIRM_CREATE_HASH_KEY_DESCRIPTION),
      okText: freire(texts.CONFIRM_CREATE_HASH_KEY_OK_TEXT),
      onOk: async () => {
        try {
          this.setState({ loadingTable: true });

          const {
            data: { hash },
          } = await apiOcpp.put(`station/${stationId}/create-hash`);

          copy(String(hash));
          message.success(freire(texts.SUCCESS_CREATE_HASH_KEY(stationId)));

          await this.getStations();
        } catch (error) {
          this.setState({ loadingTable: false });
          const description =
            error?.response?.data?.msg || freire(texts.OPERATION_ERROR);

          notification.error({ message: "Ops...", description });
        }
      },
    });
  }

  async getHashKey(stationId) {
    const { freire } = this.context;

    try {
      this.setState({ loadingTable: true });

      const {
        data: { hash },
      } = await apiOcpp.get(`station/${stationId}/show-hash`);

      copy(String(hash));
      message.success(freire(texts.SUCCESS_COPY_HASH_KEY(stationId)));
    } catch (error) {
      const description =
        error?.response?.data?.msg || freire(texts.OPERATION_ERROR);

      notification.error({ message: "Ops...", description });
    } finally {
      this.setState({ loadingTable: false });
    }
  }

  async deleteHashKey(stationId) {
    const { freire } = this.context;

    Modal.confirm({
      title: freire(texts.ATTENTION),
      content: freire(texts.CONFIRM_DELETE_HASH_KEY_DESCRIPTION),
      onOk: async () => {
        try {
          this.setState({ loadingTable: true });

          await apiOcpp.delete(`station/${stationId}/delete-hash`);

          message.success(freire(texts.SUCCESS_DELETE_HASH_KEY(stationId)));

          await this.getStations();
        } catch (error) {
          this.setState({ loadingTable: false });

          const description =
            error?.response?.data?.msg || freire(texts.OPERATION_ERROR);

          notification.error({ message: "Ops...", description });
        }
      },
      okType: "danger",
      okText: freire(texts.CONFIRM_DELETE_HASH_KEY_OK_TEXT),
    });
  }

  actions = ({ stationId, authKeyHash }) => {
    const { hasPermission } = this.props;

    const canRebootStation = hasPermission("execute-reboot-station");
    const canCreateStationAuth = hasPermission("create-station-auth");
    const canCopyStationAuth = hasPermission("copy-station-auth");
    const canDeleteStationAuth = hasPermission("delete-station-auth");

    return (
      <Menu>
        {canRebootStation && (
          <Menu.Item onClick={() => this.showConfirm(stationId, "Soft")}>
            <span>Soft reboot</span>
          </Menu.Item>
        )}

        {canRebootStation && (
          <Menu.Item onClick={() => this.showConfirm(stationId, "Hard")}>
            <span>Hard reboot</span>
          </Menu.Item>
        )}

        {canCreateStationAuth && !authKeyHash && (
          <Menu.Item onClick={() => this.createHashKey(stationId)}>
            <span>Criar senha para autenticação</span>
          </Menu.Item>
        )}

        {canCopyStationAuth && authKeyHash && (
          <Menu.Item onClick={() => this.getHashKey(stationId)}>
            <span>Copiar senha de autenticação</span>
          </Menu.Item>
        )}

        {canDeleteStationAuth && authKeyHash && (
          <Menu.Item onClick={() => this.deleteHashKey(stationId)}>
            <span>Remover autenticação</span>
          </Menu.Item>
        )}
      </Menu>
    );
  };

  timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  async showConfirm(stationId, type) {
    const { freire } = this.context;

    Modal.confirm({
      title: freire(texts.CONFIRM_SEND_RESET) + type,
      content: freire(texts.CONFIRM_SEND_RESET_DESCRIPTION),
      onOk: async () => {
        try {
          const { data } = await apiOcpp.post(`ocpp16/reset/${stationId}`, {
            type,
          });
          if (data.result === "Accepted") {
            notification.success({
              message: freire(texts.ALL_RIGHT),
              description: `${freire(texts.STATION_RESPONSE)} '${data.result}'`,
            });
            await this.timeout(4000);

            this.getStations();
          } else {
            notification.error({
              message: freire(texts.OOPS),
              description: `${freire(texts.STATION_RESPONSE)} '${data.result}'`,
            });
          }
        } catch (error) {
          notification.error({
            message: freire(texts.OOPS),
            description: freire(texts.UNEXPECTED),
          });
        }
      },
    });
  }

  clearFilters = () => {
    this.props.form.resetFields();
    this.filter();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { dateFiltrada, loadingTable } = this.state;
    const { freire } = this.context;

    const columns = this.getColumns();

    return (
      <div className="container">
        <Breadcrumbs
          breadcrumbs={[
            freire(texts.ADMIN),
            freire(texts.OCPP),
            freire(texts.STATIONS),
          ]}
        />
        <div className="filter">
          <Form wrapperCol={{ span: 24 }}>
            <Form.Item>
              <Row gutter={[16, 8]}>
                <Col span={6}>
                  <Form.Item label={freire(texts.SEARCH_BY_STATION_ID)}>
                    {getFieldDecorator("stationIdOrName")(
                      <Input
                        placeholder={freire(texts.SEARCH_BY_STATION_ID_EX)}
                        onPressEnter={this.filter}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={freire(texts.PERIOD_HEARTBEAT)}>
                    {getFieldDecorator("dateTime")(
                      <RangePicker
                        format="L"
                        placeholder={[
                          freire(texts.START_DATE),
                          freire(texts.FINAL_DATE),
                        ]}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Form>

          <div className="footer">
            <div style={{ paddingTop: 5 }}>
              {freire(texts.SHOWING)}{" "}
              <span className="stationsDisplayed">
                {dateFiltrada.length} {freire(texts.STATIONS)}{" "}
              </span>
            </div>
            <div>
              <Button style={{ marginRight: 10 }} onClick={this.clearFilters}>
                {freire(texts.CLEAN)}
              </Button>
              <Button onClick={this.filter} type="primary">
                {freire(texts.FILTER)}
              </Button>
            </div>
          </div>
        </div>

        <Table
          style={{ backgroundColor: "white" }}
          scroll={{ x: 1300 }}
          tableLayout="auto"
          loading={loadingTable}
          rowKey={(data) => data.id}
          dataSource={dateFiltrada}
          columns={columns}
          pagination={{
            current: this.state.tablePage,
            onChange: (page) => this.setState({ tablePage: page }),
          }}
        />
      </div>
    );
  }
}

const PageListOcppStations = Form.create({ name: "filters" })(ListOcppStations);

export default withPermission(withRouter(PageListOcppStations));
