import React, { useState, useContext, useRef } from "react";
import { Row, Col, Form, Button, Input, TimePicker, Icon } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { FreireContext } from "../../utils/freireContext";
import {
  ADD_STATION_PRICE_PER_HOUR,
  PRICE_PER_HOUR_VALUE,
  OF,
  TO,
  DEFAULT_PRICE_PER_HOUR_VALUE,
} from "../../locales";
import moment from "moment";
import { formatarMoeda } from "../FormatingUtils";
import {
  validateValue,
  validateEndTime,
  validateStartTime,
} from "./validators";

type TStationPricePerHourProps = {
  isVisible: boolean;
  loading: boolean;
  forms: FormComponentProps["form"];
  selectedCompanyCurrency: string;
  showDefaultValue?: boolean;
};

export const StationPricePerHour = ({
  isVisible,
  loading,
  forms,
  selectedCompanyCurrency,
  showDefaultValue = false,
}: TStationPricePerHourProps) => {
  const { freire } = useContext(FreireContext);
  const { getFieldDecorator, getFieldValue, setFieldsValue } = forms;

  const [inputPositions, setInputPositions] = useState([0]);
  const inputPositionsCount = useRef(0);

  const addInput = () => {
    setInputPositions((prev) => [...prev, ++inputPositionsCount.current]);
  };

  const removeInput = (position: number) => () => {
    setInputPositions((prev) => prev.filter((p) => p !== position));
    setFieldsValue({
      [`price_per_hour[${position}].value`]: undefined,
      [`price_per_hour[${position}].start_time`]: undefined,
      [`price_per_hour[${position}].end_time`]: undefined,
    });
  };

  return (
    <Row style={!isVisible ? { display: "none" } : {}}>
      <Row gutter={24} style={showDefaultValue ? {} : { display: "none" }}>
        <Col span={12}>
          <Form.Item
            label={freire(
              DEFAULT_PRICE_PER_HOUR_VALUE(
                getFieldValue("paymentCharge.method"),
                selectedCompanyCurrency
              )
            )}
          >
            {getFieldDecorator("price_per_hour_default_value", {
              initialValue: "0,00",
              normalize: (input: string) => {
                return input ? formatarMoeda(String(input)) : "0,00";
              },
              rules:
                isVisible && showDefaultValue
                  ? [
                      {
                        validator: (rule, value, callback) =>
                          validateValue(value, callback, freire),
                        required: true,
                      },
                    ]
                  : [],
            })(<Input disabled={loading} />)}
          </Form.Item>
        </Col>
      </Row>
      {inputPositions.map((position) => {
        const VALUE_INPUT_NAME = `price_per_hour[${position}].value`;
        const START_TIME_INPUT_NAME = `price_per_hour[${position}].start_time`;
        const END_TIME_INPUT_NAME = `price_per_hour[${position}].end_time`;
        const TIMEZONE_INPUT_NAME = `price_per_hour[${position}].timezone`;

        return (
          <Row
            gutter={24}
            key={`${VALUE_INPUT_NAME}-${START_TIME_INPUT_NAME}-${END_TIME_INPUT_NAME}-${position}`}
            type="flex"
            align="middle"
          >
            <Col span={6}>
              <Form.Item
                label={freire(PRICE_PER_HOUR_VALUE(selectedCompanyCurrency))}
              >
                {getFieldDecorator(VALUE_INPUT_NAME, {
                  initialValue: "0,00",
                  normalize: (input: string) => {
                    return input ? formatarMoeda(String(input)) : "0,00";
                  },
                  rules: isVisible
                    ? [
                        {
                          validator: (rule, value, callback) =>
                            validateValue(value, callback, freire),
                        },
                      ]
                    : [],
                })(<Input disabled={loading} />)}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label={freire(OF)}>
                {getFieldDecorator(START_TIME_INPUT_NAME, {
                  initialValue: moment("00:00", "HH:mm"),
                  rules: isVisible
                    ? [
                        {
                          validator: (rule, value, callback) =>
                            validateStartTime(
                              getFieldValue(END_TIME_INPUT_NAME),
                              value,
                              callback,
                              freire
                            ),
                        },
                      ]
                    : [],
                })(
                  <TimePicker
                    style={{ width: "100%" }}
                    disabled={loading}
                    format="HH:mm"
                    onOpenChange={() =>
                      forms.validateFields([END_TIME_INPUT_NAME])
                    }
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label={freire(TO)}>
                {getFieldDecorator(END_TIME_INPUT_NAME, {
                  initialValue: moment("22:00", "HH:mm"),
                  rules: isVisible
                    ? [
                        {
                          validator: (rule, value, callback) =>
                            validateEndTime(
                              getFieldValue(START_TIME_INPUT_NAME),
                              value,
                              callback,
                              freire
                            ),
                        },
                      ]
                    : [],
                })(
                  <TimePicker
                    style={{ width: "100%" }}
                    disabled={loading}
                    format="HH:mm"
                    onOpenChange={() =>
                      forms.validateFields([START_TIME_INPUT_NAME])
                    }
                  />
                )}
              </Form.Item>
            </Col>
            <Col style={{ display: "none" }}>
              <Form.Item>
                {getFieldDecorator(TIMEZONE_INPUT_NAME, {
                  initialValue: moment.tz.guess(),
                })(<Input disabled={loading} />)}
              </Form.Item>
            </Col>
            {position > 0 && (
              <Col>
                <Icon
                  type="delete"
                  style={{ verticalAlign: "middle" }}
                  onClick={removeInput(position)}
                />
              </Col>
            )}
          </Row>
        );
      })}
      <Button
        style={{ width: "100%", textAlign: "left" }}
        type="dashed"
        icon="plus"
        size="large"
        onClick={addInput}
      >
        {freire(ADD_STATION_PRICE_PER_HOUR)}
      </Button>
    </Row>
  );
};
