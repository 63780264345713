import React, { useMemo } from "react";

import "./styles.css";
import { formatters } from "../../utils/formatters";
import { useFreire } from "../../utils/freireContext";

const CampaignBalance = ({
  textLeft,
  textRight,
  currency,
  total,
  remaining,
}) => {
  const { freire } = useFreire();

  const consumed = total - remaining;

  const percentageAvailable = useMemo(
    () => (consumed / total) * 100,
    [total, consumed]
  );

  return (
    <div>
      <div className="balanceContainerValues">
        <div className="balanceColumn">
          <span className="balanceText">{textLeft}</span>
          <span className="balanceValue" style={{ color: "#1890FF" }}>
            {formatters.currency(freire.userLanguage, currency)(consumed / 100)}
          </span>
        </div>
        <div className="balanceColumn">
          <span className="balanceText">{textRight}</span>
          <span className="balanceValue" style={{ color: "#595959" }}>
            {formatters.currency(
              freire.userLanguage,
              currency
            )(remaining / 100)}
          </span>
        </div>
      </div>

      <div className="balanceContainerProgress">
        <div
          style={{ width: `${percentageAvailable}%` }}
          className="balanceProgressBarLeft"
        />

        <div className="balanceProgressBarRight" />
      </div>
    </div>
  );
};

export default CampaignBalance;
