import React from "react";

interface ITitleTopicEditDrawer {
  title: string;
}

const TitleTopicEditDrawer: React.FC<ITitleTopicEditDrawer> = ({ title }) => {
  return (
    <div style={{ marginBottom: 24 }}>
      <span style={{ color: "#fd5876", fontWeight: "bold" }}>{title}</span>
    </div>
  );
};

export default TitleTopicEditDrawer;
