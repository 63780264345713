import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, Form, notification, Row, Select, Input } from "antd";
import apiOcpp from "../../../../services/apiOcpp";
import { useFreire } from "../../../../utils/freireContext";
import * as texts from "../../locales";
import api from "../../../../services/api";
import { TRANSACTION_VALIDATION_ERROR } from "../../../../constants/transaction-validation-error";

const { Option } = Select;

const ID_TAG = "TUPI_ADMIN_TAG";

const RemoteStartTransaction = ({
  stationId = "",
  connectors = [],
  redirectTo = () => {},
  form,
}) => {
  useEffect(() => {
    form.resetFields(["connectorId"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectors]);

  const { getFieldDecorator } = form;
  const [loading, setLoading] = useState(false);
  const { freire } = useFreire();

  const send = () => {
    form.validateFields(async (err, payload) => {
      if (err) return;

      const connectorId = payload.connectorId || 1;

      try {
        const remoteChargeEmail = payload.remoteChargeEmail?.trim();
        const remoteChargeCouponCod = payload.remoteChargeCouponCod?.trim();

        if (!remoteChargeEmail) {
          notification.error({
            message: freire(texts.OOPS),
            description: freire(texts.REMOTE_CHARGE_EMAIL_REQUIRED),
          });
          return;
        }

        setLoading(true);

        const { data: user } = await api.get(
          `admin/user/email/${remoteChargeEmail}`
        );

        if (!user) {
          notification.error({
            message: freire(texts.OOPS),
            description: freire(
              texts.REMOTE_CHARGE_USER_NOT_FOUND(remoteChargeEmail)
            ),
          });
          setLoading(false);
          return;
        }

        const { data: remoteStart } = await apiOcpp.post(
          `/ocpp16/remoteStartTransaction/${stationId}`,
          { connectorId, userToken: user.userID }
        );

        if (!remoteStart?.result) {
          throw new Error();
        }

        if (remoteStart.result === "Accepted") {
          const { data: createRemoteChargeResp } = await api.post(
            "/admin/create-remote-charge",
            {
              transactionID: remoteStart.transactionID,
              email: remoteChargeEmail,
              couponCod: remoteChargeCouponCod,
            }
          );

          const success = createRemoteChargeResp?.success ?? false;
          const [chargerHistory] = createRemoteChargeResp?.chargerHistory ?? [];

          var description = freire(texts.CHARGE_STARTED);
          if (remoteStart.transactionID) {
            description += ` ID: ${remoteStart.transactionID}`;
          }
          if (success && chargerHistory) {
            description += ` ${freire(texts.ATTACH_REMOTE_CHARGE_SUCCESS)} ${
              chargerHistory.userEmail
            }`;
            if (chargerHistory.stopDateTime == null) {
              description += ` ${freire(
                texts.CHARGE_HISTORY_CURRENTLY_CHARGING
              )}`;
            }
          } else {
            description += ` ${freire(texts.ATTACH_REMOTE_CHARGE_FAILED)}`;
          }
          notification.success({
            message: freire(texts.ALL_RIGHT),
            description,
          });
          redirectTo(
            `/dashboard/ocpp/transactions/${remoteStart.transactionID}`
          );
        } else {
          notification.error({
            message: freire(texts.OOPS),
            description: `${freire(texts.STATION_RESPONSE)}: '${
              remoteStart.result
            }'`,
          });
        }
      } catch (error) {
        setLoading(false);
        console.error("Error on RemoteStartTransaction", error);

        if (
          error?.response?.data?.validationError ===
          TRANSACTION_VALIDATION_ERROR.PENDING_TRANSACTION
        ) {
          notification.error({
            duration: 10,
            message: freire(texts.TRANSACTION_PENDING_ERROR_TITLE),
            description: freire(
              texts.TRANSACTION_PENDING_ERROR_MESSAGE(connectorId, stationId)
            ),
          });
          return;
        }

        notification.error({
          message: freire(texts.OOPS),
          description: freire(texts.VERIFY_AGAIN),
        });
      } finally {
        setLoading(false);
      }
    });
  };

  return (
    <Fragment>
      <Form layout="vertical" wrapperCol={{ span: 24 }}>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item label={freire(texts.CONNECTOR_ID)}>
              {getFieldDecorator("connectorId", {
                rules: [
                  {
                    required: true,
                    message: freire(texts.REQUIRED),
                  },
                ],
              })(
                <Select
                  disabled={!stationId}
                  placeholder={freire(texts.SELECT_CONNECTOR_ID)}
                >
                  {connectors.map((connector) => (
                    <Option
                      key={connector.connectorId}
                      value={connector.connectorId}
                    >
                      ID {connector.connectorId}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item label={freire(texts.TAG_OCPP)}>
              {getFieldDecorator("idTag", {
                initialValue: ID_TAG,
                rules: [
                  {
                    required: true,
                    message: freire(texts.REQUIRED),
                  },
                ],
              })(
                <Select placeholder={freire(texts.SELECT_TAG_OCPP)} disabled>
                  <Option value={ID_TAG}>{ID_TAG}</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item label={freire(texts.REMOTE_CHARGE_EMAIL)}>
              {getFieldDecorator("remoteChargeEmail", {
                rules: [
                  {
                    required: true,
                    message: freire(texts.REQUIRED),
                  },
                ],
              })(<Input />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item label={freire(texts.REMOTE_CHARGE_COUPON)}>
              {getFieldDecorator("remoteChargeCouponCod")(<Input />)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        <Button
          disabled={!stationId || loading}
          loading={loading}
          onClick={send}
          type="primary"
        >
          <span>
            {freire(texts.EXECUTE)}{" "}
            <span style={{ fontWeight: "bold" }}> RemoteStartTransaction</span>
          </span>
        </Button>
      </div>
    </Fragment>
  );
};

const PageRemoteStartTransaction = Form.create({ name: "inputs" })(
  RemoteStartTransaction
);

export default PageRemoteStartTransaction;
