export const formatarMoeda = (valor: any) => {
    valor = valor.replace(/\D/g, "");
    valor = String(Number(valor));
    var len = valor.length;
    if (len === 1) valor = valor.replace(/(\d)/, "0,0$1");
    else if (len === 2) valor = valor.replace(/(\d)/, "0,$1");
    else if (len > 2) {
      valor = valor.replace(/(\d{2})$/, ",$1");
      if (len > 5) {
        var x = len - 5,
          er = new RegExp("(\\d{" + x + "})(\\d)");
        valor = valor.replace(er, "$1.$2");
      }
    }
    return valor;
  };

