import React, { forwardRef, useState } from "react";
import { Form, Select, notification } from "antd";
import * as texts from "./locales";
import { WrappedFormUtils } from "antd/lib/form/Form";
import { SelectProps } from "antd/lib/select";
import { ILanguageTranslation } from "../../interfaces/languages";
import { useFreire } from "../../utils/freireContext";
import * as requests from "../../requests";
import { useOnMount, useOnUpdate } from "../../hooks";
import CountryFlag from "./CountryFlag";
import { Country as ICountry } from "../../interfaces/country";

const { Option } = Select;
interface ISelectCountry {
  label?: ILanguageTranslation;
  form?: WrappedFormUtils<any>;
  initialValue?: string;
  disabled: boolean;
  showWarn: boolean;
  onSelect?: (countryCode: string, country: ICountry) => void;
  dispatchFirstSelect?: boolean;
  countries?: ICountry[];
  loading?: boolean;
  isRequired?: boolean;
}

const SelectCountry: React.FC<ISelectCountry> = ({
  label,
  form,
  initialValue,
  disabled,
  showWarn = true,
  onSelect,
  dispatchFirstSelect,
  countries,
  loading,
  isRequired = true,
}) => {
  const { freire } = useFreire();

  const [loadingFetchCountries, setLoadingFetchCountries] = useState(false);
  const [countriesList, setCountriesList] = useState<ICountry[]>(
    countries ?? []
  );

  const _setCountriesList = (countriesList: ICountry[]) => {
    setCountriesList(countriesList);
    if (initialValue && dispatchFirstSelect) {
      const country = countriesList.find((country) => country.code === initialValue)!;
      onSelect?.(country.code, country);
    }
  };

  const fetchCountries = async () => {
    try {
      form?.resetFields(["country"]);
      setLoadingFetchCountries(true);

      const countriesFetched = await requests.getRecommendedCountries();

      _setCountriesList(countriesFetched);
    } catch (error) {
      notification.error({
        message: freire(texts.OOPS),
        description: freire(texts.ERROR_LIST_COUNTRIES),
      });
    } finally {
      setLoadingFetchCountries(false);
    }
  };

  useOnMount(() => {
    if (!countries) {
      fetchCountries();
    }
  });

  useOnUpdate(() => {
    if (countries) {
      _setCountriesList(countries);
    }
  }, [countries]);

  const isLoading = loading || loadingFetchCountries;

  const SelectInput = forwardRef<Select, SelectProps>((props, ref) => (
    <Select
      ref={ref}
      placeholder={
        isLoading
          ? freire(texts.LOADING)
          : freire(texts.SELECT_COUNTRY_PLACEHOLDER)
      }
      disabled={isLoading || disabled}
      loading={isLoading}
      showSearch
      optionFilterProp="children"
      onSelect={(value) => (onSelect ? onSelect(value as string, countriesList.find((country) => country.code === value)!) : {})}
      {...props}
    >
      {countriesList.map((country) => (
        <Option key={country.code} value={country.code}>
          <CountryFlag disabled={disabled} country={country} />
        </Option>
      ))}
    </Select>
  ));

  return (
    <Form.Item
      label={freire(label ?? texts.SELECT_COUNTRY_LABEL)}
      {...(showWarn && { extra: freire(texts.SELECT_COUNTRY_WARN) })}
    >
      {/**
       * O drawer de edição de empresa não utiliza o form do Antd
       * TODO: Remover adaptação quando o drawer de edição utilizar o form do Antd
       */}
      {form ? (
        form.getFieldDecorator("country", {
          initialValue: isLoading ? undefined : initialValue,
          rules: [{ required: isRequired, message: freire(texts.REQUIRED) }],
        })(<SelectInput />)
      ) : (
        <SelectInput value={isLoading ? undefined : initialValue} />
      )}
    </Form.Item>
  );
};

export default SelectCountry;
