import { useCallback, useEffect, useState } from "react";
import Breadcrumbs from "../../../components/Breadcrumbs";
import GenerateFilter from "../../../components/GenerateFilter";
import TablePagination, {
  paginationInitConfig,
} from "../../../components/TablePagination";
import { Button, notification, Form } from "antd";
import * as texts from "../locales";
import { useFreire } from "../../../utils/freireContext";
import { sorter } from "../../../utils/sorter";
import api from "../../../services/api";
import * as requests from "../../../requests/index";
import { Country as ICountry } from "../../../interfaces/country";
import { ICompany } from "../../../interfaces/company";
import { ILocation } from "../../../interfaces/location";
import getColumns from "./components/Columns";
import { generateFields } from "./fields";
import withPermission from "../../../contexts/PermissionContext/withPermission";
import StationsDrawer from "./components/StationsDrawer";
import EditLocationDrawer from "./components/EditLocationDrawer";

function ListLocations(props: any) {
  const { freire } = useFreire();

  const [pagination, setPagination] = useState(paginationInitConfig);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [loadingDrawer, setLoadingDrawer] = useState(false);
  const [locations, setLocations] = useState<ILocation[]>();
  const [companies, setCompanies] = useState<ICompany[]>();
  const [companyOptions, setCompanyOptions] = useState([]);
  const [countries, setCountries] = useState<ICountry[]>();
  const [stations, setStations] = useState([]);
  const [visibleStationsDrawer, setVisibleStationsDrawer] = useState(false);
  const [visibleLocationEditDrawer, setVisibleLocationEditDrawer] =
    useState(false);
  const [location, setLocation] = useState<ILocation | null>(null);

  const goNewLocation = () => {
    props.history.push("/dashboard/new_location");
  };

  const getLocations = useCallback(
    async (params = {}) => {
      setLoadingLocations(true);
      try {
        const { data: locations } = await api.get("locations", {
          params,
        });
        setLocations(locations);
      } catch (error) {
        notification.error({
          message: freire(texts.OOPS),
          description: freire(texts.ERROR_LIST_LOCATIONS),
        });
      } finally {
        setLoadingLocations(false);
      }
    },
    [freire]
  );

  const getCompanies = useCallback(async () => {
    try {
      const { data: companiesData } = await api.get("company");
      const companies = sorter(companiesData, "companyName");

      const companiesNotHolding = companies.filter(
        (company: ICompany) => !company.holding
      );
      const options = companies.map((company: ICompany) => ({
        label: company.companyName,
        value: company._id,
      }));

      setCompanies(companiesNotHolding);
      setCompanyOptions(options);
    } catch (error) {
      notification.error({
        message: freire(texts.OOPS),
        description: freire(texts.ERROR_LIST_COMPANIES),
      });
    }
  }, [freire]);

  const getCountries = useCallback(async () => {
    try {
      const countries = await requests.getRecommendedCountries();
      setCountries(countries);
    } catch (error) {
      notification.error({
        message: freire(texts.OOPS),
        description: freire(texts.ERROR_LIST_COUNTRIES),
      });
    }
  }, [freire]);

  useEffect(() => {
    getLocations();
    getCompanies();
    getCountries();
  }, [getLocations, getCompanies, getCountries]);

  const onChangeCompany = () => {
    props.form.resetFields();
  };

  const onFilter = () => {
    props.form.validateFields(async (err: any, values: any) => {
      if (!err) {
        getLocations({ ...values });
      }
    });
  };

  const updateLocation = async (data: ILocation) => {
    setLoadingDrawer(true);

    try {
      await api.put(`locations/${location?.id}`, data);

      setLoadingDrawer(false);
      setVisibleLocationEditDrawer(false);

      notification.success({
        message: freire(texts.ALL_RIGHT),
        description: freire(texts.LOCATION_EDITED_SUCCESS),
      });

      getLocations();
    } catch (error: any) {
      const message = error?.response?.data?.message;

      notification.error({
        message: freire(texts.WENT_WRONG),
        description: message || error.message,
      });
      setLoadingDrawer(false);
    }
  };

  const onDeleteLocation = async (location: ILocation) => {
    try {
      if (location.id) {
        await api.delete(`locations/${location.id}`);

        notification.success({
          message: freire(texts.ALL_RIGHT),
          description: freire(texts.LOCATION_DELETED),
        });

        getLocations();
      }
    } catch (error: any) {
      const message = error?.response?.data?.message;

      notification.error({
        message: freire(texts.WENT_WRONG),
        description: message || error.message,
      });
    }
  };

  const openStationsDrawer = async (location: any) => {
    setVisibleStationsDrawer(true);

    const { data: stations } = await api.get(
      `locations/${location.id}/stations`
    );

    setStations(stations);
  };

  const openLocationEditDrawer = async (location: any) => {
    setLocation(location);
    setVisibleLocationEditDrawer(true);
  };

  const closeDrawer = () => {
    setVisibleStationsDrawer(false);
    setVisibleLocationEditDrawer(false);
  };

  const columns = getColumns({
    companies: companies!,
    countries: countries!,
    freire,
    openStationsDrawer,
    openLocationEditDrawer,
    onDeleteLocation,
  });

  const filteredFilds = generateFields(companyOptions, onChangeCompany, freire);

  return (
    <div className="container">
      <div className="header">
        <Breadcrumbs
          breadcrumbs={[
            freire(texts.ADMIN),
            freire(texts.STATIONS),
            freire(texts.LOCATIONS),
          ]}
        />
        <Button
          className="buttonAddLocation"
          type="primary"
          onClick={goNewLocation}
        >
          {freire(texts.NEW_LOCATION)}
        </Button>
      </div>

      <StationsDrawer
        closeDrawer={closeDrawer}
        visibleStationsDrawer={visibleStationsDrawer}
        freire={freire}
        stations={stations}
      />

      {visibleLocationEditDrawer && (
        <EditLocationDrawer
          visible={visibleLocationEditDrawer}
          freire={freire}
          location={location}
          closeDrawer={closeDrawer}
          onSave={updateLocation}
          loading={loadingDrawer}
        />
      )}

      <GenerateFilter
        form={props.form}
        fields={filteredFilds}
        handleClear={() => {
          props.form.resetFields();
        }}
        handleFilter={onFilter}
      />
      <TablePagination
        style={{ backgroundColor: "white" }}
        scroll={{ x: 1300 }}
        tableLayout="auto"
        loading={loadingLocations}
        current={pagination.page}
        rowKey={(data: any) => data._id}
        dataSource={locations}
        columns={columns}
        onPagination={(pagination: any) => setPagination(pagination)}
        bodyStyle={undefined}
      />
    </div>
  );
}

const PageListLocations = Form.create({ name: "filters" })(ListLocations);

export default withPermission(PageListLocations);
