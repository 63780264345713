import React from "react";
import { getStatusInfo } from "../../../services/utils";

import styles from "./styles.module.css";

function DotStatus({ status }) {
  if (!status) return null;

  if (["Unavailable", "Disabled", "Test", "Faulted"].includes(status)) {
    return (
      <div
        className={styles.dotUnavailable}
        style={{
          borderColor: getStatusInfo(status).color,
        }}
      >
        <div
          className={styles.lineDotUnavailable}
          style={{
            backgroundColor: getStatusInfo(status).color,
          }}
        />
      </div>
    );
  }

  return (
    <div
      className={styles.dot}
      style={{
        backgroundColor: getStatusInfo(status).color,
      }}
    />
  );
}

export default DotStatus;
