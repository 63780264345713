import React, { useCallback, useState } from "react";
import { Button, Input, notification } from "antd";
import "./styles.css";
import { paginationInitConfig } from "../../../../components/TablePagination";
import { useOnMount, useOnUnmount, useOnUpdate } from "../../../../hooks";
import * as texts from "../../locales";
import { useFreire } from "../../../../utils/freireContext";
import api from "../../../../services/api";
import { debounce } from "../../../../utils/debounce";
import ResumeTable from "./ResumeTable";
import CouponsTable from "./CouponsTable";
import ModalRemoveUser from "./ModalRemoveUser";
import ModalCouponUser from "./ModalCouponUser";
import CouponsCsv from "./CouponsCsv";
import useDialog from "../../../../hooks/use-dialog";
import ModalAddCoupon from "../../ModalAddCoupon/ModalAddCoupon";
import { autoLinkCouponType } from "../../NewCoupons/Forms/EligibleUsers";

const ERROR_CODES_MSG = {
  "coupon-in-use": texts.COUPON_IN_USE,
  "same-campaign-found": texts.COUPON_FROM_SAME_CAMPAIGN_FOUND,
};

const CouponsViewer = ({ match, history }) => {
  const { freire } = useFreire();
  const {
    isVisible: isVisibleAddCoupon,
    handleClose: handleCloseAddCoupon,
    handleOpen: handleOpenAddCoupon,
  } = useDialog();

  const [filterParams, setFilterParams] = useState({
    ...paginationInitConfig,
    cod: "",
  });

  const [loadingTableResume, setLoadingTableResume] = useState(true);
  const [campaignResume, setCampaignResume] = useState(null);

  const [loadingTableCoupons, setLoadingTableCoupons] = useState(true);
  const [couponsList, setCouponsList] = useState([]);
  const [totalCoupons, setTotalCoupons] = useState(0);

  const [visibleModalRemoveUser, setVisibleModalRemoveUser] = useState(false);
  const [couponToRemoveUser, setCouponToRemoveUser] = useState("");
  const [loadingRemoveUser, setLoadingRemoveUser] = useState(false);

  const [visibleModalCouponUser, setVisibleModalCouponUser] = useState(false);
  const [couponToCouponUser, setCouponToCouponUser] = useState("");
  const [loadingCouponUser, setLoadingCouponUser] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filterWithDebounce = useCallback(
    debounce((cod) => {
      setFilterParams((prevState) => ({ ...prevState, page: 1, cod }));
    }, 500),
    []
  );

  const onChangeInputSearch = useCallback(
    (event) => {
      filterWithDebounce(event.target.value);
    },
    [filterWithDebounce]
  );

  const showModalRemoveUser = (coupon) => {
    setCouponToRemoveUser(coupon);
    setVisibleModalRemoveUser(true);
  };

  const onCancelRemoveUser = () => {
    setVisibleModalRemoveUser(false);
  };

  const onConfirmRemoveUser = async () => {
    try {
      setLoadingRemoveUser(true);
      await api.put(`campaign/${campaignId}/coupon/${couponToRemoveUser._id}`);

      notification.success({
        message: freire(texts.ALL_RIGHT),
        description: freire(texts.SUCCESS_REMOVE_USER_COUPON),
      });

      setVisibleModalRemoveUser(false);

      reloadCoupons();
    } catch (error) {
      notification.error({
        message: freire(texts.OOPS),
        description: freire(texts.ERROR_REMOVE_USER_COUPON),
      });
    } finally {
      setLoadingRemoveUser(false);
    }
  };

  const showModalCouponUser = (couponID) => {
    setCouponToCouponUser(couponID);
    setVisibleModalCouponUser(true);
  };

  const onCancelCouponUser = () => {
    setVisibleModalCouponUser(false);
  };

  const formatErrorMsg = (error) => {
    const errorCode = error?.response?.data?.errorCode;
    if (ERROR_CODES_MSG[errorCode]) return freire(ERROR_CODES_MSG[errorCode]);

    const errorMessage = error?.response?.data?.message;
    if (errorMessage) return errorMessage;

    return freire(texts.ERROR_COUPON_USER);
  };

  const onConfirmCouponUser = async (couponId, email) => {
    try {
      setLoadingCouponUser(true);
      await api.patch(`coupon/${couponId}/assign-user`, { email });

      notification.success({
        message: freire(texts.ALL_RIGHT),
        description: freire(texts.SUCCESS_COUPON_USER),
      });

      setVisibleModalCouponUser(false);

      reloadCoupons();
    } catch (error) {
      const errorMessage = formatErrorMsg(error);

      notification.error({
        message: freire(texts.OOPS),
        description: errorMessage,
      });
    } finally {
      setLoadingCouponUser(false);
    }
  };

  const loadCampaignResume = async () => {
    try {
      const { campaignId } = match.params;

      const { data: resume } = await api.get(`campaign/${campaignId}/summary`);

      setCampaignResume(resume);
    } catch (error) {
      notification.error({
        message: freire(texts.OOPS),
        description: freire(texts.ERROR_GET_CAMPAIGN_RESUME),
      });
    } finally {
      setLoadingTableResume(false);
    }
  };

  const loadCoupons = async () => {
    try {
      const { campaignId } = match.params;

      const {
        data: { docs, totalDocs },
      } = await api.get(`campaign/${campaignId}/coupons`, {
        params: filterParams,
      });

      setCouponsList(docs);
      setTotalCoupons(totalDocs);
    } catch (error) {
      notification.error({
        message: freire(texts.OOPS),
        description: freire(texts.ERROR_GET_CAMPAIGNS_COUPONS),
      });
    } finally {
      setLoadingTableCoupons(false);
    }
  };

  const onDisable = async (coupon) => {
    try {
      await api.patch(`coupon/${coupon._id}/disable`);
      notification.success({
        message: freire(texts.ALL_RIGHT),
        description: freire(texts.SUCCESS_DISABLE_COUPON),
      });
      reloadCoupons();
    } catch (error) {
      notification.error({
        message: freire(texts.OOPS),
        description: freire(texts.ERROR_DISABLE_COUPON),
      });
    }
  };

  const campaignId = match?.params?.campaignId;

  const load = async () => {
    await loadCampaignResume();

    loadCoupons();
  };

  const reloadCoupons = () => {
    setLoadingTableCoupons(true);

    loadCoupons();
  };

  useOnMount(load);

  useOnUpdate(reloadCoupons, [filterParams]);

  useOnUnmount(filterWithDebounce.cancel);

  return (
    <div style={{ padding: "16px 6px" }}>
      <Button
        type="primary"
        size="small"
        icon="left"
        onClick={() => history.goBack()}
      >
        {freire(texts.GO_BACK)}
      </Button>

      <div className="campaignResume">
        <span className="campaignResumeTitle">
          {freire(texts.CAMPAIGN_RESUME)}
          {campaignResume ? ` (${campaignResume.name})` : ""}
        </span>
        <ResumeTable
          loading={loadingTableResume}
          campaignResume={campaignResume}
        />

        <div className="couponsFilterAndCsv">
          <div>
            <div className="couponsFilterText">{freire(texts.FILTER)}</div>
            <Input
              placeholder={freire(texts.FILTER_COD)}
              className="couponsFilterInput"
              onChange={onChangeInputSearch}
              allowClear
            />
          </div>

          {campaignResume?.autoLinkCouponType !== autoLinkCouponType.FULL_REGISTER && (
            <span className="addCouponButton" onClick={handleOpenAddCoupon}>
              {freire(texts.ADD_COUPON_TO_CAMPAIGN)}
            </span>
          )}
        </div>
      </div>

      <div style={{ marginLeft: 16, marginBottom: 8 }}>
        <CouponsCsv campaignResume={campaignResume} />
      </div>

      <CouponsTable
        loading={loadingTableCoupons}
        couponsList={couponsList}
        campaignResume={campaignResume}
        currentPage={filterParams.page}
        totalCoupons={totalCoupons}
        onPagination={(pagination) =>
          setFilterParams((prevState) => ({ ...prevState, ...pagination }))
        }
        actions={{
          onRemoveUser: showModalRemoveUser,
          onDisable,
          onCouponUser: showModalCouponUser,
        }}
      />

      <ModalRemoveUser
        visible={visibleModalRemoveUser}
        loading={loadingRemoveUser}
        coupon={couponToRemoveUser}
        onCancel={onCancelRemoveUser}
        onConfirm={onConfirmRemoveUser}
      />
      <ModalAddCoupon
        visible={isVisibleAddCoupon}
        campaignId={campaignId}
        onClose={handleCloseAddCoupon}
        onSuccess={load}
        codeType={campaignResume?.autoLinkCouponType}
      />
      <ModalCouponUser
        visible={visibleModalCouponUser}
        loading={loadingCouponUser}
        coupon={couponToCouponUser}
        onCancel={onCancelCouponUser}
        onConfirm={onConfirmCouponUser}
      />
    </div>
  );
};

export default CouponsViewer;
