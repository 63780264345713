import { COUNTRY_BR } from "../constants/countries";
import { IAddress } from "../interfaces/address";

export function getCountryCode(country: string): string {
  const countryCode = country === COUNTRY_BR ? "BRA" : "ARG";

  return countryCode
}

export function getStateAndCity(country: string, address: IAddress): {state: string, city: string} {
  let city
  let state

  if (country === COUNTRY_BR) {
    city = address.city
    state = address.state
  } else {
    city = address.city
    state = address.province
  }

  return {state, city}
}