import React, { useEffect, useState, useRef } from "react";
import { Form, Radio, Select } from "antd";
import * as texts from "./locales";
import TupiSelect from "../../components/TupiSelect";
import { useOnUpdate } from "../../hooks";
import { useFreire } from "../../utils/freireContext";
import "./NewCoupons/index.css";

export const validPlaceTypes = {
  ALL_NETWORK: "allNetwork",
  ALL_COMPANIES: "allCompanies",
  SPECIFIC_STATIONS: "specificStations",
};

function DynamicCompanyForm({
  form,
  allCompanies = [],
  allStations = [],
  campaignToEdit,
  selectedCompany,
}) {
  const { freire } = useFreire();

  const [inputPositions, setInputPositions] = useState([0]);
  const isFirstInputPositionsUpdate = useRef(true);
  const inputPositionsCount = useRef(0);

  const { companies = [] } = form.getFieldsValue();

  const requiredFieldRule = {
    required: true,
    message: freire(texts.REQUIRED_FIELD),
  };

  /**
   * Esse hook só é utilizado quando tiver pelo EditDrawer da campanha de cupons
   * onde será passado o atributo 'campaignToEdit'
   */
  useOnUpdate(() => {
    const values = form.getFieldsValue();

    if (campaignToEdit && isFirstInputPositionsUpdate.current) {
      let validPlace = "";
      let companies = [];

      const availableCompanies = campaignToEdit.availableCompanies;

      if (availableCompanies.length === 0) {
        validPlace = validPlaceTypes.ALL_NETWORK;
        companies = [
          {
            companyId: campaignToEdit.company._id,
            stationIds: [],
            specificStations: false,
          },
        ];
      } else if (
        availableCompanies.length === 1 &&
        availableCompanies[0].companyId === campaignToEdit.company._id &&
        availableCompanies[0].stationIds.length === 0
      ) {
        validPlace = validPlaceTypes.ALL_COMPANIES;
        companies = [
          {
            companyId: availableCompanies[0].companyId,
            stationIds: [],
            specificStations: false,
          },
        ];
      } else {
        validPlace = validPlaceTypes.SPECIFIC_STATIONS;
        companies = (availableCompanies ?? []).map((availableCompany) => ({
          companyId: availableCompany.companyId,
          stationIds: availableCompany.stationIds,
          specificStations: availableCompany.stationIds.length !== 0,
        }));
      }

      form.setFieldsValue({ validPlace, companies });

      isFirstInputPositionsUpdate.current = false;
    } else {
      form.setFieldsValue(values);
    }
  }, [inputPositions]);

  /**
   * Esse hook só é utilizado quando tiver pelo EditDrawer da campanha de cupons
   * onde será passado o atributo 'campaignToEdit'
   */
  useEffect(() => {
    if (campaignToEdit) {
      const amountInputs = campaignToEdit.availableCompanies.length || 1;

      const positions = Array.from(Array(amountInputs).keys());

      setInputPositions(positions);

      inputPositionsCount.current = positions[positions.length - 1] || 0;

      isFirstInputPositionsUpdate.current = true;
    }
  }, [campaignToEdit]);

  const getSelectedCompanyIds = () =>
    companies
      .filter((company) => company !== undefined)
      .map(({ companyId }) => companyId);

  const getCompanyById = (companyId) =>
    allCompanies.find((company) => company._id === companyId);

  const addInput = () => {
    setInputPositions((prev) => [...prev, ++inputPositionsCount.current]);
  };

  const removeInput = (position) => () => {
    setInputPositions((prev) => prev.filter((p) => p !== position));
  };

  const filterCompanyOptions = (allCompanies, selectedCompaniesWithoutCurrent) => {
    let companyOptions;
    const userHasOnlyOneCompany = allCompanies.length === 1;
    
    companyOptions = allCompanies
      .filter(
        (company) => !selectedCompaniesWithoutCurrent.includes(company._id)
      )
      .map((company) => ({
        label: company.companyName,
        value: company._id,
      }));

    if (!userHasOnlyOneCompany) {
      return companyOptions.filter((company) => company._id !== selectedCompany?._id)
    }

    return companyOptions;
  }

  const selectedCompanyIds = getSelectedCompanyIds();

  return (
    <div style={{ paddingBottom: 28 }}>
      {inputPositions.map((position, index) => {
        const COMPANY_ID_INPUT_NAME = `companies[${position}].companyId`;
        const STATION_IDS_INPUT_NAME = `companies[${position}].stationIds`;
        const SPECIFIC_STATIONS_INPUT_NAME = `companies[${position}].specificStations`;

        const currentCompanyId = form.getFieldValue(COMPANY_ID_INPUT_NAME);
        const isSpecificStations = form.getFieldValue(
          SPECIFIC_STATIONS_INPUT_NAME
        );

        const currentCompany = getCompanyById(currentCompanyId);

        const selectedCompaniesWithoutCurrent = selectedCompanyIds.filter(
          (companyId) => companyId !== currentCompanyId
        );

        const companyOptions = filterCompanyOptions(allCompanies, selectedCompaniesWithoutCurrent);

        const stationsOptions = allStations
          .filter((station) => station.companyID === currentCompanyId)
          .map((station) => ({
            label: `(${station.stationID}) ${station.name} - ${station.address} )`,
            value: station._id,
          }));

        const showStationIdsInput = currentCompanyId && isSpecificStations;

        return (
          <div
            key={`${COMPANY_ID_INPUT_NAME}-${STATION_IDS_INPUT_NAME}`}
            className="companyForm"
            style={{ padding: index > 0 ? "0 16px 16px" : "0 16px" }}
          >
            <Form.Item label={freire(texts.COMPANY)}>
              {form.getFieldDecorator(COMPANY_ID_INPUT_NAME, {
                rules: [requiredFieldRule],
              })(
                <Select
                  showSearch
                  optionFilterProp="label"
                  placeholder={freire(texts.SELECT_COMPANY)}
                  onChange={() => {
                    form.resetFields([STATION_IDS_INPUT_NAME]);
                  }}
                >
                  {companyOptions.map((option) => (
                    <Select.Option
                      label={option.label}
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item style={{ display: currentCompany ? "block" : "none" }}>
              {form.getFieldDecorator(SPECIFIC_STATIONS_INPUT_NAME, {
                initialValue: false,
              })(
                <Radio.Group buttonStyle="solid">
                  <Radio className="verticalRadio" value={false}>
                    <span>
                      {currentCompany?.holding
                        ? freire(texts.ALL_COMPANIES_AND_STATIONS)
                        : freire(texts.ALL_STATIONS)}
                      {currentCompany && (
                        <strong> {currentCompany.companyName}</strong>
                      )}
                    </span>
                  </Radio>
                  <Radio
                    disabled={currentCompany?.holding}
                    className="verticalRadio"
                    value={true}
                  >
                    {freire(texts.SPECIFIC_STATIONS)}
                  </Radio>
                </Radio.Group>
              )}
            </Form.Item>
            <Form.Item
              label={freire(texts.STATIONS_LABEL)}
              style={{
                display: showStationIdsInput ? "block" : "none",
              }}
            >
              {form.getFieldDecorator(STATION_IDS_INPUT_NAME, {
                rules: [
                  {
                    ...requiredFieldRule,
                    required: showStationIdsInput,
                  },
                ],
              })(
                <TupiSelect
                  form={form}
                  fieldName={STATION_IDS_INPUT_NAME}
                  className="customSelectTags"
                  enableAllSelect
                  multiple
                  maxTagCount={20}
                  maxTagTextLength={200}
                  showMaxTagPlaceholder={false}
                  placeholder={freire(texts.STATIONS_PLACEHOLDER)}
                  options={stationsOptions}
                  disabled={!currentCompanyId}
                />
              )}
            </Form.Item>

            <span className="btnRemoveCompany" onClick={removeInput(position)}>
              - {freire(texts.DELETE_COMPANY)}
            </span>
          </div>
        );
      })}
      {inputPositions.length !== allCompanies.length && (
        <span className="btnAddCompany" onClick={addInput}>
          + {freire(texts.ADD_OTHER_COMPANY)}
        </span>
      )}
    </div>
  );
}

export default DynamicCompanyForm;