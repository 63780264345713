import { ILanguageTranslation } from "../../interfaces/languages";

export const SELECT_COUNTRY_PLACEHOLDER: ILanguageTranslation = {
  pt: "Selecione o país",
  es: "Seleccione el país",
  en: "Select the country",
};

export const LOADING: ILanguageTranslation = {
  pt: "Carregando...",
  es: "Cargando...",
  en: "Loading...",
};

export const SELECT_COUNTRY_LABEL: ILanguageTranslation = {
  pt: "País de atuação",
  es: "País de operación",
  en: "Country of operation",
};

export const SELECT_COUNTRY_WARN: ILanguageTranslation = {
  pt: "Atenção: esse campo não poderá ser alterado após a criação da empresa.",
  es: "Atención: este campo no podrá ser modificado después de la creación de la empresa.",
  en: "Attention: this field cannot be changed after the company is created.",
};

export const REQUIRED: ILanguageTranslation = {
  pt: "Campo obrigatório",
  es: "Campo obligatorio",
  en: "Mandatory field",
};

export const ERROR_LIST_COUNTRIES: ILanguageTranslation = {
  pt: "Não foi possível carregar a lista de países",
  es: "No se pudo cargar la lista de países",
  en: "Failed to load the country list",
};

export const OOPS: ILanguageTranslation = {
  pt: "Ops...",
  es: "Epa...",
  en: "Ops...",
};
