export const getStationsOptionsBySelectedCompanies = (
  allSelectStations,
  selectedCompanies
) => {
  let options = allSelectStations;

  if (selectedCompanies.length > 0 && !selectedCompanies.includes("all")) {
    const stationsFiltreds = allSelectStations.filter(({ companyID }) =>
      selectedCompanies.includes(companyID)
    );
    options = stationsFiltreds;
  }

  return options.map((station) => ({
    label: `(${station.stationID}) ${station.name} - ${station.address} )`,
    value: station.stationID,
  }));
};
