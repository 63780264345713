import { Switch } from "antd";
import { useFreire } from "../../../utils/freireContext";
import { useContext } from "react";
import * as texts from "../locales";
import "../index.css";
import { BusinessHourContext } from "../index";

export default function Options() {
  const { freire } = useFreire();
  const { loading, isSemiPublic, onChangeWeekToSemiPublic, disabled } =
    useContext(BusinessHourContext);

  return (
    <div className="switchSemiPublicContainer">
      <div className="switchSemiPublicRow">
        <Switch
          disabled={loading || disabled}
          size="small"
          checked={isSemiPublic}
          onChange={(value) => onChangeWeekToSemiPublic(value)}
        />
        <span className="switchSemiPublicLabel">
          {freire(texts.SEMI_PUBLIC_STATION)}
        </span>
      </div>
      <p className="switchSemiPublicInfo">
        {freire(texts.SEMI_PUBLIC_STATION_DESCRIPTION)}
      </p>
    </div>
  );
}
