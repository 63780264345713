import React, { useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Table,
  notification,
  Icon,
  message,
  Tooltip,
} from "antd";
import { CSVLink } from "react-csv";
import copy from "copy-to-clipboard";
import moment from "moment-timezone";

import api from "../../../services/api";
import { useFreire } from "../../../utils/freireContext";
import * as texts from "../locales";

const { Column } = Table;

const RfidTagsDrawer = ({ visible, onClose, campaign }) => {
  const [loading, setLoading] = useState(false);
  const [rfidTags, setRfidTags] = useState([]);
  const { freire } = useFreire();

  useEffect(() => {
    if (visible) {
      getRfidTags();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const getRfidTags = async () => {
    try {
      setLoading(true);

      const { data: rfidTags } = await api.get(
        `rfid-campaign/${campaign._id}/rfidTags`
      );

      setRfidTags(rfidTags);
    } catch (error) {
      notification.error({
        message: freire(texts.OOPS),
        description: freire(texts.ERROR_GET_RIFID_CAMPAIGN),
      });
    } finally {
      setLoading(false);
    }
  };

  const closeDrawer = () => {
    setRfidTags([]);
    onClose();
  };

  return (
    <Drawer
      title={campaign.name}
      width={"50%"}
      onClose={closeDrawer}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <div style={{ paddingBottom: 25 }}>
        <CSVLink
          style={{ color: "#1890ff", 
          cursor: "pointer", 
          paddingLeft: 10, 
          fontSize: 17, 
          border: "1px solid #1890ff", 
          borderRadius: "6px",
          padding: "8px 16px",
          display: "inline-block"
        }}
          filename={freire(
            texts.CAMPAIGN_CSV_FILE_NAME(
              campaign.name,
              moment().format(freire(texts.DATE_TIME))
            )
          )}
          className="btn btn-primary"
          data={rfidTags.map((rfidTag) => {
            return {
              ...rfidTag,
              lastUse: rfidTag.lastUse
                ? moment(rfidTag.lastUse).format("L LTS")
                : "----",
              enabled: rfidTag.enabled ? freire(texts.YES) : freire(texts.NO),
              user:
                rfidTag?.user?.email || rfidTag?.user?.displayName || "----",
            };
          })}
          headers={[
            { label: freire(texts.CODE), key: "cod" },
            { label: freire(texts.ACTIVE), key: "enabled" },
            { label: freire(texts.QTD_USED), key: "amountUsage" },
            { label: freire(texts.LAST_USE), key: "lastUse" },
            { label: freire(texts.USER), key: "user" },
          ]}
        >
          {freire(texts.DOWNLOAD_CSV)}
          &nbsp;&nbsp;
          <Icon type="download" />
        </CSVLink>
      </div>
      <Table loading={loading} dataSource={rfidTags}>
        <Column
          title={freire(texts.CODE)}
          dataIndex="cod"
          key="cod"
          render={(cod, data) => (
            <Tooltip placement="right" title={freire(texts.COPY)}>
              <span
                style={{
                  textDecoration: data.enabled ? "none" : "line-through",
                  fontWeight: 600,
                  cursor: "pointer",
                }}
                onClick={() => {
                  copy(String(cod));
                  message.info(freire(texts.COPY_SUCCESS));
                }}
              >
                {cod}
              </span>
            </Tooltip>
          )}
        />
        <Column
          title={freire(texts.ACTIVE)}
          dataIndex="enabled"
          key="enabled"
          render={(enabled, tag) => (
            <Icon type={enabled && tag?.user ? "check" : "close"} />
          )}
        />
        <Column
          title={freire(texts.QTD_USED)}
          dataIndex="amountUsage"
          key="amountUsage"
        />
        <Column
          title={freire(texts.LAST_USE)}
          dataIndex="lastUse"
          key="lastUse"
          render={(lastUse) => (
            <span>{lastUse ? moment(lastUse).format("L LTS") : "----"}</span>
          )}
        />
        <Column
          title={freire(texts.USER)}
          dataIndex="user"
          key="user"
          render={(user) =>
            user ? (
              <span>
                {user.displayName && (
                  <span>
                    {user.displayName}
                    <br />
                  </span>
                )}
                {user?.email || ""}
              </span>
            ) : (
              "----"
            )
          }
        />
      </Table>

      <div
        style={{
          position: "absolute",
          right: 0,
          bottom: 0,
          width: "100%",
          borderTop: "1px solid #e9e9e9",
          padding: "10px 16px",
          background: "#fff",
          textAlign: "right",
        }}
      >
        <Button onClick={closeDrawer} style={{ marginRight: 8 }}>
          {freire(texts.CLOSE)}
        </Button>
      </div>
    </Drawer>
  );
};

export default RfidTagsDrawer;
