import { Icon, Input } from "antd";
import React from "react";
import { v4 as uuid } from "uuid";
import { useFreire } from "../../utils/freireContext";
import SimpleTable from "./SimpleTable";
import * as texts from "./locales";
import { formatters } from "../../utils/formatters";

const AntdIcon: any = Icon;

export type TRemunerationMedia = {
  id: string;
  receiver: string;
  percentage: number;
};

interface IRemunerationMediaTable {
  data: Array<TRemunerationMedia>;
  updateData(data: Array<TRemunerationMedia>): void;
  disabled?: boolean;
}

const RemunerationMediaTable: React.FC<IRemunerationMediaTable> = ({
  data,
  updateData,
  disabled,
}) => {
  const handleDeleteRow = (id: string) => {
    updateData(data.filter((item) => item.id !== id));
  };

  const handleChange = (
    id: string,
    text: string,
    key: "receiver" | "percentage"
  ) => {
    data.forEach((item, i, array) => {
      if (item.id === id) {
        item.receiver = key === "receiver" ? text : item.receiver;
        item.percentage = key === "percentage" ? Number(text) : item.percentage;
        updateData(array);
      }
    });
  };

  const dataSource = data.map(({ id, receiver, percentage }) => ({
    key: id,
    receptor: (
      <Input
        disabled={disabled}
        defaultValue={receiver}
        onChange={(e) => handleChange(id, e.target.value, "receiver")}
      />
    ),
    percentage: (
      <Input
        disabled={disabled}
        type="number"
        onChange={(e) => handleChange(id, e.target.value, "percentage")}
        addonAfter="%"
        min={0}
        max={100}
        defaultValue={percentage}
        style={{ maxWidth: "6.27rem" }}
      />
    ),
    trash: (
      <div
        style={{ color: "#FF4D4F", cursor: "pointer" }}
        onClick={() => handleDeleteRow(id)}
      >
        <AntdIcon type="delete" />
      </div>
    ),
  }));

  const handleAddNewRow = () => {
    updateData([...data, { id: uuid(), receiver: "", percentage: 0 }]);
  };
  const total = data.reduce(
    (accumulator, currentValue) => accumulator + currentValue.percentage,
    0
  );
  const { freire } = useFreire();

  return (
    <SimpleTable
      columns={[
        {
          title: freire(texts.RECIPIENT),
          dataIndex: "receptor",
          key: "receptor",
        },
        {
          title: freire(texts.PERCENTAGE),
          dataIndex: "percentage",
          key: "percentage",
        },
        {
          title: freire(texts.DELETE),
          dataIndex: "trash",
          key: "trash",
        },
      ]}
      dataSource={[
        ...dataSource,
        {
          key: uuid(),
          receptor: (
            <a href="##" onClick={handleAddNewRow}>
              {freire(texts.ADD_RECIPITER)}
            </a>
          ),
          percentage: `${freire(texts.TOTAL)}: ${formatters.percentage(
            freire.userLanguage
          )(total / 100)}`,
        },
      ]}
    />
  );
};

export default RemunerationMediaTable;
