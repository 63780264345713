import { PrivateHourProps } from "../interfaces";
import { Button, TimePicker } from "antd";
import { useFreire } from "../../../utils/freireContext";
import { useContext } from "react";
import * as texts from "../locales";
import moment from "moment";
import "../index.css";
import { BusinessHourContext } from "../index";

export default function PrivateHour({
  hour,
  weekDay,
  hourIndex,
}: PrivateHourProps) {
  const { freire } = useFreire();

  const {
    loading,
    onChangeInitialOnOptionalHour,
    onChangeFinalOnOptionalHour,
    onDeleteOptionalHourInDay,
    disabled,
  } = useContext(BusinessHourContext);
  return (
    <div className="hourContainer">
      <span className="hourFirstText">{freire(texts.OF)}</span>
      <TimePicker
        disabled={loading || disabled}
        className="privateHourTimePicker"
        allowClear={false}
        format="HH:mm"
        value={moment(hour.initial, "HH:mm")}
        onChange={(value) =>
          onChangeInitialOnOptionalHour(value, weekDay, hourIndex)
        }
      />
      <span className="hourSecondText">{freire(texts.TO)}</span>
      <TimePicker
        disabled={loading || disabled}
        className="privateHourTimePicker"
        allowClear={false}
        format="HH:mm"
        value={moment(hour.final, "HH:mm")}
        onChange={(value) =>
          onChangeFinalOnOptionalHour(value, weekDay, hourIndex)
        }
      />
      <Button
        disabled={loading || disabled}
        icon="delete"
        className="buttonDeleteHour"
        onClick={() => onDeleteOptionalHourInDay(weekDay, hourIndex)}
      />
    </div>
  );
}
