import React, { Component } from "react";
import { Button, Drawer, Row, Col, Card, Statistic, Icon } from "antd";
import api from "../../../services/api";
import { CSVLink } from "react-csv";
import Countdown from "antd/lib/statistic/Countdown";
import moment from "moment-timezone";
import { FreireContext } from "../../../utils/freireContext";
import * as texts from "../locales";
export default class StatisticsDrawer extends Component {
  static contextType = FreireContext;

  state = {
    nextRunAt: "",
    loadingNextRunAt: false,
    publicity: null,
    loadingPublicity: false,
  };

  async getNextRunAt() {
    this.setState({ loadingNextRunAt: true }, async () => {
      try {
        const { data: nextRunAt } = await api.get(
          `/publicity/${this.props.publicityID}/nextRunAt`
        );

        this.setState({ nextRunAt, loadingNextRunAt: false });
      } catch (error) {
        this.setState({ nextRunAt: "", loadingNextRunAt: false });
      }
    });
  }

  async getPublicity() {
    this.setState({ loadingPublicity: true }, async () => {
      try {
        const { data: publicity } = await api.get(
          `/publicity/${this.props.publicityID}`
        );

        this.setState({ publicity, loadingPublicity: false });
      } catch (error) {
        this.setState({ publicity: null, loadingPublicity: false });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.visible !== prevProps.visible) {
      if (this.props.visible) {
        this.setState({ nextRunAt: "", publicity: null });
        this.getPublicity();
        this.getNextRunAt();
      }
    }
  }

  render() {
    const { visible, publicityName, onClose } = this.props;
    const { nextRunAt, loadingNextRunAt, publicity, loadingPublicity } =
      this.state;
    const { freire } = this.context;

    return (
      <Drawer
        visible={visible}
        title={publicityName}
        width={600}
        onClose={() => onClose()}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <div style={{ display: "none" }}>
          {publicity && (
            <CSVLink
              filename={freire(texts.CAMPAIGN_CSV_FILE_NAME(publicityName))}
              className="btn btn-primary"
              data={[publicity]}
              id="downloadExcel"
              headers={[
                { label: freire(texts.VIEWS), key: "analytics.viewBanner" },
                {
                  label: freire(texts.URL_CLICKS),
                  key: "analytics.openUrlAction",
                },
              ]}
            />
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Button
            onClick={() => {
              var link = document.getElementById("downloadExcel");
              if (link) link.click();
            }}
            style={{ marginRight: 20 }}
            icon="download"
          >
            {freire(texts.DOWNLOAD_IN_CSV)}
          </Button>
          <Button
            onClick={() => {
              this.getPublicity();
              this.getNextRunAt();
            }}
            icon="sync"
          >
            {freire(texts.UPDATE)}
          </Button>
        </div>
        <div style={{ marginTop: 20 }}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Card>
                <Statistic
                  title={freire(texts.VIEWS)}
                  value={
                    loadingPublicity
                      ? freire(texts.LOADING)
                      : publicity && publicity.analytics
                      ? publicity.analytics.viewBanner
                      : "----"
                  }
                  prefix={
                    <Icon style={{ fontSize: 20, marginRight: 4 }} type="eye" />
                  }
                />
              </Card>
            </Col>
            <Col span={12}>
              <Card>
                <Statistic
                  title={freire(texts.ACTION_URL_CLICK)}
                  value={
                    loadingPublicity
                      ? freire(texts.LOADING)
                      : publicity && publicity.urlAction && publicity.analytics
                      ? publicity.analytics.openUrlAction
                      : "----"
                  }
                  prefix={
                    <Icon
                      style={{ fontSize: 20, marginRight: 4 }}
                      type="link"
                    />
                  }
                />
              </Card>
            </Col>
            <Col span={24}>
              <Card>
                <Statistic
                  title={freire(texts.NEXT_UPLOAD)}
                  value={
                    loadingNextRunAt
                      ? freire(texts.LOADING)
                      : nextRunAt
                      ? moment(nextRunAt).format("L LTS")
                      : "----"
                  }
                  suffix={
                    nextRunAt ? `(${moment(nextRunAt).format("dddd")})` : ""
                  }
                  prefix={
                    <Icon
                      style={{ fontSize: 20, marginRight: 4 }}
                      type="calendar"
                    />
                  }
                />
              </Card>
            </Col>
            <Col span={24}>
              <Card>
                <Countdown
                  title={freire(texts.REMAINING_TIME_TO_NEXT)}
                  value={moment(nextRunAt).add(5, "seconds")}
                  format={
                    loadingNextRunAt
                      ? `[${freire(texts.LOADING)}]`
                      : nextRunAt
                      ? "D[d] H[h] m[m] s[s]"
                      : "[----]"
                  }
                  onFinish={() => this.getNextRunAt()}
                  // valueStyle={{ color: "#3f8600" }}
                  prefix={
                    <Icon
                      style={{ fontSize: 20, marginRight: 4 }}
                      type="clock-circle"
                    />
                  }
                />
              </Card>
            </Col>
          </Row>
        </div>
        <div
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            width: "100%",
            borderTop: "1px solid #e9e9e9",
            padding: "10px 16px",
            background: "#fff",
            textAlign: "right",
          }}
        >
          <Button onClick={() => onClose()} style={{ marginRight: 8 }}>
            {freire(texts.CLOSE)}
          </Button>
        </div>
      </Drawer>
    );
  }
}
