import {
  Button,
  Col,
  Form,
  Icon,
  Pagination,
  Popover,
  Row,
  Select,
  Tabs,
  TreeSelect,
} from "antd";
import axios from "axios";
import moment from "moment-timezone";
import React, {
  forwardRef,
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { ErrorIcon, SuccessIcon, WarningIcon } from "../../../assets/icons";
import DotStatus from "../../../components/StatusWithDot/DotStatus";
import { useAccess } from "../../../contexts/PermissionContext";
import { BlankSpace } from "../../../components/BlankSpace";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { useMountUnmount, useOnUpdate } from "../../../hooks";
import api from "../../../services/api";
import { getTreeData } from "../../../services/utils";
import { formatters } from "../../../utils/formatters";
import { sleep } from "../../../utils/sleep";
import { CardAvailability } from "./CardAvailability";
import "./styles.css";
import { useFreire } from "../../../utils/freireContext";
import * as texts from "../locales";

const { Option } = Select;

/** availability version control */
const REVISION = 1;

/** 92% (8%) */
const SYSTEM_AVAILABILITY_PERCENTAGE_RED = 0.92;
/** 98% (2%) */
const SYSTEM_AVAILABILITY_PERCENTAGE_YELLOW = 0.98;

/** 70% (30%) */
const AVERAGE_AVAILABILITY_PERCENTAGE_RED = 0.7;
/** 80% (20%) */
const AVERAGE_AVAILABILITY_PERCENTAGE_YELLOW = 0.8;

/** 80% (20%) */
const MINIMUM_AVAILABILITY_PERCENTAGE = AVERAGE_AVAILABILITY_PERCENTAGE_YELLOW;

const SKELETON_AVAILABILITIES = 4;

/** pagination page sizes */
const PAGE_SIZES = [10, 25, 50, 100];

/** 25 */
const DEFAULT_PAGE_SIZE = PAGE_SIZES[1];

const AUTO_UPDATE_ENABLED = true;
const AUTO_UPDATE_INTERVAL = "PT2M";

// TODO: subscribe no history para corrigir <Link /> e redirects @ sidebar

// TODO: maybe disable filters if user only has access to one company and/or station

const { REACT_APP_FRESHPING_IDS } = process.env;
const freshpingIds =
  REACT_APP_FRESHPING_IDS === undefined
    ? []
    : JSON.parse(REACT_APP_FRESHPING_IDS);

const { REACT_APP_IS_DEVELOPMENT } = process.env;
const isDevelopment = REACT_APP_IS_DEVELOPMENT === "true";

const autoUpdateInterval = moment.duration(AUTO_UPDATE_INTERVAL).valueOf();

const pageSizeOptions = PAGE_SIZES.map((pageSize) => pageSize.toString());

const AVAILABILITIES_COLORS = {
  GREEN: "#029B2D", // GREEN: "#6FCF8A",
  YELLOW: "#FCD429",
  RED: "#f40000", // RED: "#FF8585",
  PURPLE: "#CE93D8",
};

const getTabs = (freire) => [
  {
    duration: "PT12H",
    title: freire(texts.LAST_12_HOURS),
    qtyBlocks: 48,
    fullDayBlocks: false,
  },
  {
    duration: "PT24H",
    title: freire(texts.LAST_24_HOURS),
    qtyBlocks: 48,
    fullDayBlocks: false,
  },
  {
    duration: "P7D",
    title: freire(texts.LAST_7_DAYS),
    qtyBlocks: 56,
    fullDayBlocks: false,
  },
  {
    duration: "P30D",
    title: freire(texts.LAST_30_DAYS),
    qtyBlocks: 30,
    fullDayBlocks: true,
  },
  {
    duration: "P60D",
    title: freire(texts.LAST_60_DAYS),
    qtyBlocks: 60,
    fullDayBlocks: true,
  },
  {
    duration: "P90D",
    title: freire(texts.LAST_90_DAYS),
    qtyBlocks: 90,
    fullDayBlocks: true,
  },
];

const CONNECTIONS = [
  {
    key: "all",
    text: texts.DEFAULT_CONNECTION,
    dotStatusIcon: "Loading",
  },
  {
    key: "connected",
    text: texts.CONNECTED_CONNECTION,
    dotStatusIcon: "Available",
  },
  {
    key: "disconnected",
    text: texts.DISCONNECTED_CONNECTION,
    dotStatusIcon: "Unavailable",
  },
];

const [DEFAULT_CONNECTION, CONNECTED_CONNECTION, DISCONNECTED_CONNECTION] =
  CONNECTIONS.map((connection) => connection.key);

const isCriticalAvailability = (availability) => availability.critical;

const filterAvailabilities = (availabilities, connection) => {
  // TODO: move to utils
  const not =
    (fn) =>
    (...args) =>
      !fn(...args);

  const disconnected = (availabilityData) => availabilityData.disconnected;

  switch (connection) {
    default:
    case DEFAULT_CONNECTION: {
      return availabilities;
    }
    case CONNECTED_CONNECTION: {
      return availabilities.filter(not(disconnected));
    }
    case DISCONNECTED_CONNECTION: {
      return availabilities.filter(disconnected);
    }
  }
};

const SystemAvailabilityCard = memo(
  forwardRef(
    (
      {
        duration,
        // TODO: check if 'whenLoading' triggers rerender
        whenLoading = "loading...",
      },
      ref
    ) => {
      const [loading, setLoading] = useState(true);
      const [error, setError] = useState(null);
      const [availability, setAvailability] = useState(null);
      const { freire } = useFreire();
      const loadController = useRef(null);

      // TODO: loadAvailability method
      const load = async () => {
        const controller = new AbortController();
        try {
          loadController.current = controller;
          let freshpingMsList;
          const hours = moment.duration(duration).asHours();
          try {
            freshpingMsList = await Promise.all(
              freshpingIds.map(async (freshpingId) => {
                const response = await axios.get(
                  `https://api.freshping.io/v1/public-check-stats-reports/${freshpingId}`,
                  {
                    signal: controller.signal,
                    timeout: 10_000,
                    transitional: {
                      // TODO: in every api
                      clarifyTimeoutError: true,
                    },
                    params: {
                      for_hours: hours,
                    },
                  }
                );

                return response.data.duration_seconds_total_downtime * 1000;
              })
            );
          } catch (error) {
            if (error.code !== "ERR_CANCELED") {
              // TODO: check also if it is not an axios error
              // TODO?: early return
              controller.abort();
            }
            throw error;
          }

          const reducedFreshpingMs =
            freshpingMsList.reduce(
              (summed, freshpingMs) => summed + freshpingMs,
              0
            ) / freshpingMsList.length;

          const percentage =
            1 - reducedFreshpingMs / moment.duration(duration).valueOf();

          await sleep(isDevelopment ? 1000 : 500);

          setError(null);
          setAvailability({
            duration: reducedFreshpingMs,
            percentage,
          });
        } catch (error) {
          if (error.code === "ERR_CANCELED") {
            return;
          }
          console.error("SystemAvailability.load", error);
          setError(error);
        } finally {
          if (loadController.current !== controller) {
            return;
          }
          loadController.current = null;
          setLoading(false);
        }
      };

      const unload = () => {
        loadController.current?.abort();
        loadController.current = null;
      };

      const reload = useCallback(() => {
        setLoading(true);
        unload();
        load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      useMountUnmount(load, unload);

      useOnUpdate(reload, [duration]);

      if (ref !== null) {
        ref.current = {
          reload,
        };
      }

      if (loading) {
        return whenLoading;
      }

      if (error) {
        return (
          <div className="system-availability">
            <span
              style={{
                fontSize: 18,
                lineHeight: "20px",
                color: AVAILABILITIES_COLORS.RED,
                fontWeight: 600,
              }}
            >
              <Icon component={ErrorIcon} style={{ marginRight: 10 }} />
              {formatters.percentage(freire.userLanguage)(0)}
            </span>
            <BlankSpace />
            {/* TODO: review double span */}
            <span style={{ marginLeft: 8 }}>
              <span
                className="noselect"
                style={{
                  textDecoration: "underline",
                  textTransform: "lowercase",
                  color: AVAILABILITIES_COLORS.RED,
                  cursor: "pointer",
                }}
                onClick={reload}
              >
                {freire(texts.TRY_AGAIN)}
              </span>
            </span>
          </div>
        );
      }

      let color;
      let icon;

      if (availability.percentage < 0) {
        color = AVAILABILITIES_COLORS.PURPLE;
        icon = WarningIcon;
      } else if (availability.percentage < SYSTEM_AVAILABILITY_PERCENTAGE_RED) {
        color = AVAILABILITIES_COLORS.RED;
        icon = WarningIcon;
      } else if (
        availability.percentage < SYSTEM_AVAILABILITY_PERCENTAGE_YELLOW
      ) {
        color = AVAILABILITIES_COLORS.YELLOW;
        icon = WarningIcon;
      } else if (availability.percentage <= 1) {
        color = AVAILABILITIES_COLORS.GREEN;
        icon = SuccessIcon;
      } else {
        color = AVAILABILITIES_COLORS.PURPLE;
        icon = WarningIcon;
      }

      return (
        <div className="system-availability">
          <Popover
            content={
              <div className="contentPopover">
                <div className="times">
                  <div className="time">
                    <div className="squareColor green" />
                    <span>
                      {formatters.durationOneDayTweak(freire)(
                        moment
                          .duration(duration)
                          .subtract(availability.duration)
                          .valueOf()
                      )}
                    </span>
                  </div>
                  <div className="time">
                    <div className="squareColor red" />
                    <span>
                      {formatters.durationOneDayTweak(freire)(
                        availability.duration
                      )}
                    </span>
                  </div>
                </div>
              </div>
            }
          >
            <span
              style={{
                fontSize: 18,
                lineHeight: "20px",
                color,
                fontWeight: 600,
              }}
            >
              <Icon component={icon} style={{ marginRight: 10 }} />
              {formatters.percentage(freire.userLanguage)(
                availability.percentage
              )}
            </span>
          </Popover>
          <BlankSpace />
          <span style={{ marginLeft: 8 }}>
            <Link
              style={{
                textDecoration: "underline",
                textTransform: "lowercase",
              }}
              to={`/dashboard/availability_system`}
            >
              {freire(texts.SHOW_DETAILS)}
            </Link>
          </span>
        </div>
      );
    }
  )
);

const StationsAvailabilityCard = memo(
  ({
    availabilities,
    hasError,
    loading,
    // TODO: check if 'whenLoading' triggers rerender
    whenLoading = "loading...",
  }) => {
    const { freire } = useFreire();

    if (hasError || loading) {
      return whenLoading;
    }

    const availabilityPercentage =
      availabilities.reduce(
        (summed, availability) => summed + availability.availability,
        0
      ) / availabilities.length;

    let color;
    let icon;

    if (availabilityPercentage < 0) {
      color = AVAILABILITIES_COLORS.PURPLE;
      icon = WarningIcon;
    } else if (availabilityPercentage < AVERAGE_AVAILABILITY_PERCENTAGE_RED) {
      color = AVAILABILITIES_COLORS.RED;
      icon = WarningIcon;
    } else if (
      availabilityPercentage < AVERAGE_AVAILABILITY_PERCENTAGE_YELLOW
    ) {
      color = AVAILABILITIES_COLORS.YELLOW;
      icon = WarningIcon;
    } else if (availabilityPercentage <= 1) {
      color = AVAILABILITIES_COLORS.GREEN;
      icon = SuccessIcon;
    } else {
      color = AVAILABILITIES_COLORS.PURPLE;
      icon = WarningIcon;
    }

    // TODO?: Popover com blocos e texto "média de disponibilidade das estações"

    return (
      <div className="system-availability">
        <span
          style={{
            fontSize: 18,
            lineHeight: "20px",
            color,
            fontWeight: 600,
          }}
        >
          <Icon component={icon} style={{ marginRight: 10 }} />
          {formatters.percentage(freire.userLanguage)(availabilityPercentage)}
        </span>
      </div>
    );
  }
);

const AvailabilityCards = memo(
  ({
    canViewDetailed,
    connection,
    currentTab,
    filteredAvailabilities,
    freire,
    freireUserLanguage, // to force rerender when the user changes the language
    hasAnotherDay,
    maxDifferenceInDaysLength,
    maxBlockDuration,
    maxOfflineTime,
    pageSize,
    resetFilter,
    resetFilterText,
    setPageSize,
  }) => {
    const FIRST_PAGE = 1;
    const [page, setPage] = useState(FIRST_PAGE);

    const onPageChange = useCallback((page) => {
      setPage(page);
    }, []);

    const onPageSizeChange = useCallback((_page, pageSize) => {
      setPageSize(pageSize);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const paginatedAvailabilities = useMemo(
      () =>
        filteredAvailabilities.slice((page - 1) * pageSize, page * pageSize),
      [filteredAvailabilities, page, pageSize]
    );

    const hasZeroPaginatedAvailabilities = paginatedAvailabilities.length === 0;

    const showTotal = useCallback(
      (total, [firstRange, secondRange]) => (
        <span>{freire(texts.OF_STATIONS(firstRange, secondRange, total))}</span>
      ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    const goToFirstPage = () => {
      setPage(FIRST_PAGE);
    };

    const goToFirstPageIfNeeded = () => {
      // TODO: check instead if there is now less pages than the ones in previous state
      if (hasZeroPaginatedAvailabilities && page !== FIRST_PAGE) {
        goToFirstPage();
      }
    };

    useOnUpdate(goToFirstPage, [connection]);
    useOnUpdate(goToFirstPageIfNeeded, [paginatedAvailabilities]);

    const error = new Error();
    error.message = freire(
      texts.ERROR_NO_AVAILABILITY(
        connection === DEFAULT_CONNECTION
          ? undefined
          : freire(
              CONNECTIONS.find(
                (_connection) => _connection.key === connection
              ).text(1)
            ).toLowerCase()
      )
    );

    return (
      <>
        {hasZeroPaginatedAvailabilities ? (
          <div style={{ position: "relative" }}>
            <CardAvailability
              canViewDetailed={canViewDetailed}
              currentTab={currentTab}
              freire={freire}
              freireUserLanguage={freireUserLanguage}
              skeleton
              stillSkeleton
            />
            <div
              style={{
                background: "rgba(0, 0, 0, 0.12)",
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <span style={{ paddingBottom: 4 }}>
                <Icon component={WarningIcon} style={{ marginRight: 8 }} />
                {formatters.phrase(error.message)}
              </span>
              <Button onClick={resetFilter}>
                <Icon type="minus" style={{ marginRight: 2 }} />
                {freire(resetFilterText)}
              </Button>
            </div>
          </div>
        ) : (
          <>
            {paginatedAvailabilities.map((availabilityData) => (
              <CardAvailability
                key={`${availabilityData.stationID}`}
                canViewDetailed={canViewDetailed}
                connectionLogs={availabilityData.connectionLogs}
                businessConnectionLogs={availabilityData.businessConnectionLogs}
                critical={availabilityData.critical}
                currentTab={currentTab}
                companyName={availabilityData.companyName}
                freire={freire}
                freireUserLanguage={freireUserLanguage}
                stationID={availabilityData.stationID}
                stationName={availabilityData.stationName}
                disconnected={availabilityData.disconnected}
                availability={availabilityData.availability}
                offlineDuration={availabilityData.offlineDuration}
                blocks={availabilityData.blocks}
                hasAnotherDay={hasAnotherDay}
                maxDifferenceInDaysLength={maxDifferenceInDaysLength}
                maxBlockDuration={maxBlockDuration}
                maxOfflineTime={maxOfflineTime}
              />
            ))}
            <div
              style={{ display: "flex", justifyContent: "end", marginTop: 16 }}
            >
              <Pagination
                current={page}
                // TODO?: disabled
                onChange={onPageChange}
                total={filteredAvailabilities.length}
                showSizeChanger
                // TODO?: hideOnSinglePage={
                //   pageSize === DEFAULT_PAGE_SIZE &&
                //   availabilities.length < pageSize
                // }
                style={{ marginRight: -8 }}
                showTotal={showTotal}
                pageSize={pageSize}
                pageSizeOptions={pageSizeOptions}
                onShowSizeChange={onPageSizeChange}
              />
            </div>
          </>
        )}
      </>
    );
  }
);

const ConnectionOptionContent = memo(
  ({ availabilities, connection, freire, hasError, loading }) => (
    <>
      <span
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <DotStatus status={connection.dotStatusIcon} />
        {freire(connection.text())}
        {!loading &&
          !hasError &&
          ` (${filterAvailabilities(availabilities, connection.key).length})`}
      </span>
    </>
  )
);

const AvailabilityStationsReal = memo(({ form }) => {
  const { hasPermission } = useAccess();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [stations, setStations] = useState(null);
  const [availabilitiesData, setAvailabilitiesData] = useState(null);
  const lastAvailabilitiesData = useRef(availabilitiesData);
  const { freire } = useFreire();
  const loadController = useRef(null);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [connection, setConnection] = useState(DEFAULT_CONNECTION);

  const { current: resetFilters } = useRef(() => {
    setSelectedCompanies((companies) => {
      if (companies.length === 0) {
        return companies;
      }
      const defaultCompanies = [];
      form.setFieldsValue({ companies: defaultCompanies });
      return defaultCompanies;
    });

    setSelectedStations((stations) => {
      if (stations.length === 0) {
        return stations;
      }
      const defaultStations = [];
      form.setFieldsValue({ stations: defaultStations });
      return defaultStations;
    });

    resetConnection();
  });

  const { current: resetConnection } = useRef(() => {
    setConnection(DEFAULT_CONNECTION);
  });

  const updateTimer = useRef(null);

  // TODO: stop using form
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedStations, setSelectedStations] = useState([]);

  const load = async (silent = false) => {
    const controller = new AbortController();
    try {
      loadController.current = controller;

      if (companies === null) {
        await loadCompanies(controller.signal);
      }

      if (stations === null) {
        await loadStations(controller.signal);
      }

      await loadAvailabilities(controller.signal);

      if (isDevelopment) {
        await sleep(2000);
      }

      if (!silent) {
        setError(null);
      }
    } catch (error) {
      if (error.code === "ERR_CANCELED") {
        return;
      }
      console.error("AvailabilityStationsReal.load", error);
      if (!silent) {
        setError(error);
      }
    } finally {
      if (loadController.current !== controller) {
        return;
      }
      loadController.current = null;

      if (AUTO_UPDATE_ENABLED) {
        // TODO: mostrar pro gabriel
        updateTimer.current = setTimeout(() => {
          silentReload();
          updateTimer.current = null;
        }, autoUpdateInterval);
      }

      setLoading(false);
    }
  };

  const loadCompanies = async (signal) => {
    try {
      const response = await api.get("company", { signal });

      // const { data: companies } = response;
      const { data: companies2 } = response;
      const companies = companies2.filter(
        () => true
        // (companies) => companies._id === "62f55c0a12dfba214513c577" // droga raia
      );

      const sortedCompanies = companies.sort((a, b) =>
        a.companyName.localeCompare(b.companyName)
      );

      setCompanies(sortedCompanies);
    } catch (error) {
      // TODO: early return
      if (error.code !== "ERR_CANCELED") {
        console.error("AvailabilityStationsReal.loadCompanies", error);
        error.message = freire(texts.ERROR_FIND_COMPANY);
      }
      throw error;
    }
  };

  const loadStations = async (signal) => {
    try {
      const response = await api.get("select/connected_stations", {
        signal,
        params: {
          disabled: false,
          test: false,
          centralSystem: true,
          companies: selectedCompanies,
        },
      });

      const { data: stations } = response;

      const sortedStations = stations.sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      setStations(sortedStations);
    } catch (error) {
      // TODO: early return
      if (error.code !== "ERR_CANCELED") {
        console.error("AvailabilityStationsReal.loadStations", error);
        error.message = freire(texts.ERROR_FIND_STATIONS);
      }
      throw error;
    }
  };

  const loadAvailabilities = async (signal) => {
    try {
      const response = await api.post(
        "availability_stations_real",
        {
          periodTypeDuration: currentTab.duration,
          company_ids: selectedCompanies,
          station_ids: selectedStations,
          revision: REVISION,
        },
        {
          signal,
        }
      );

      const {
        data: { availabilities, period, maxBlockDuration },
      } = response;

      const updatedAt = moment();

      const criticalAvailabilitiesLength = availabilities.filter(
        isCriticalAvailability
      ).length;

      const availabilitiesData = {
        availabilities,
        criticalAvailabilitiesLength,
        updatedAt,
        period,
        maxBlockDuration,
      };

      setAvailabilitiesData(availabilitiesData);
      lastAvailabilitiesData.current = availabilitiesData;
    } catch (error) {
      // TODO: early return
      if (error.code !== "ERR_CANCELED") {
        console.error("AvailabilityStationsReal.loadAvailabilities", error);
        error.message = freire(texts.ERROR_GET_DATA);
      }
      throw error;
    }
  };

  const unload = () => {
    if (loadController.current) {
      loadController.current.abort();
      loadController.current = null;
    }

    if (updateTimer.current !== null) {
      clearTimeout(updateTimer.current);
      updateTimer.current = null;
    }
  };

  const freireUserLanguage = freire.userLanguage;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tabs = useMemo(() => getTabs(freire), [freireUserLanguage]);

  const [defaultTab] = tabs;

  const [currentTab, setCurrentTab] = useState(defaultTab);

  // TODO: check memoize
  const silentReload = () => {
    unload();
    load(true);
  };

  const reload = useCallback(() => {
    setLoading(true);
    unload();
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, selectedCompanies, selectedStations]);

  useMountUnmount(load, unload);

  useOnUpdate(reload, [currentTab, selectedCompanies, selectedStations]);

  const systemAvailabilityRef = useRef(null);

  const formatPeriodDate = (date) => {
    const _date = moment(date).format("L");

    if (currentTab.fullDayBlocks) {
      return _date;
    }

    return `${_date} - ${moment(date).format("LT")}`;
  };

  // TODO: memoize
  // do not use default values as they are null here
  const handlerTags = ({ value, list, label = "" }) => {
    const { length: valueLength } = value;
    const { length: listLength } = list;
    const lowerCaseLabel = label.toLowerCase();

    return {
      maxTagCount: valueLength === 1 ? 1 : 0,
      maxTagPlaceholder: (omittedValues) => {
        if (valueLength === listLength) {
          return freire(texts.ALL(listLength, lowerCaseLabel));
        }

        if (valueLength > 1) {
          return `${valueLength} ${lowerCaseLabel}`;
        }

        return `+ ${(omittedValues ?? []).length || 0} ...`;
      },
    };
  };

  const safeCompanies = companies ?? [];
  const safeStations = stations ?? [];
  const selectableStations =
    selectedCompanies.length === 0
      ? safeStations
      : safeStations.filter((station) =>
          selectedCompanies.includes(station.companyID)
        );

  const companyTags = useMemo(
    () =>
      handlerTags({
        value: selectedCompanies,
        list: safeCompanies,
        label: freire(texts.COMPANIES),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [safeCompanies, selectedCompanies]
  );

  const stationTags = useMemo(
    () =>
      handlerTags({
        value: selectedStations,
        list: selectableStations,
        label: freire(texts.STATIONS),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stations, selectedStations]
  );

  const companyTreeData = useMemo(
    () =>
      getTreeData({
        key: "all",
        title: freire(texts.SELECT_ALL_COMPANIES(safeCompanies.length)),
        children: safeCompanies.map((company) => ({
          key: company._id,
          title: company.companyName,
          value: company._id,
        })),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companies]
  );

  const stationTreeData = useMemo(
    () =>
      getTreeData({
        key: "all",
        title: freire(texts.SELECT_ALL_STATIONS(selectableStations.length)),
        children: selectableStations.map((station) => ({
          key: station._id,
          title: station.name,
          value: station._id,
        })),
        // TODO?: group by company
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companies, selectedCompanies, selectableStations]
  );

  const hasError = error !== null;

  const onReload = reload;

  // TODO: review
  // const onReload = useCallback(() => {
  //   reload();
  //   systemAvailabilityRef.current.reload();
  // }, [reload]);

  const canViewDetailed = useMemo(
    () => hasPermission("show-station-availability-detailed"),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    _availabilitiesData,
    hasAnotherDay,
    hasZeroAvailabilityData,
    maxDifferenceInDaysLength,
    maxBlockDuration,
    maxOfflineTime,
  } = useMemo(() => {
    if (availabilitiesData === null) {
      return {};
    }

    const { availabilities, maxBlockDuration } = availabilitiesData;
    const _availabilitiesData = availabilities.map((availabilityData) => {
      const disconnected = availabilityData.currentConnectionLog !== null;
      return {
        ...availabilityData,
        disconnected,
        connectionLogs: disconnected
          ? [
              ...availabilityData.connectionLogs,
              {
                _id: "current",
                ...availabilityData.currentConnectionLog,
              },
            ]
          : availabilityData.connectionLogs,
      };
    });

    const hasZeroAvailabilityData = _availabilitiesData.length === 0;

    const mapConnectionLogs = (connectionLogs) =>
      connectionLogs.map((connectionLog) => {
        const disconnectedAt = // TODO: remove this ternary
          connectionLog.disconnectedAt === undefined
            ? undefined
            : moment(connectionLog.disconnectedAt);
        const connectedAt =
          connectionLog.connectedAt === undefined
            ? undefined
            : moment(connectionLog.connectedAt);

        const UNIT_OF_TIME = "day";

        const _disconnectedAtDay = (
          disconnectedAt?.clone() ?? moment()
        ).startOf(UNIT_OF_TIME);
        const _connectedAtDay = (connectedAt?.clone() ?? moment()).startOf(
          UNIT_OF_TIME
        );

        const differenceInDays = _connectedAtDay.diff(
          _disconnectedAtDay,
          UNIT_OF_TIME
        );
        const differenceInDaysLength = differenceInDays.toString().length;

        return {
          ...connectionLog,
          disconnectedAt,
          connectedAt,
          differenceInDays,
          differenceInDaysLength,
        };
      });

    // TODO: using this twice
    const isAnotherDay = (connectionLog) =>
      connectionLog.differenceInDays !== 0;
    const connectionLogsWithAnotherDay = _availabilitiesData
      .map((availability) => mapConnectionLogs(availability.connectionLogs))
      .flat()
      .filter(isAnotherDay);
    const hasAnotherDay = connectionLogsWithAnotherDay.length !== 0;
    const maxDifferenceInDaysLength = Math.max(
      ...connectionLogsWithAnotherDay.map(
        (connectionLog) => connectionLog.differenceInDaysLength
      )
    );

    const maxOfflineTime = _availabilitiesData.reduce(
      (max, availability) =>
        formatters.durationOneDayTweak(freire)(availability.offlineDuration)
          .length > max.length
          ? formatters.durationOneDayTweak(freire)(availability.offlineDuration)
          : max,
      ""
    );

    return {
      _availabilitiesData,
      hasAnotherDay,
      hasZeroAvailabilityData,
      maxDifferenceInDaysLength,
      maxBlockDuration,
      maxOfflineTime,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availabilitiesData]);

  const {
    filteredAvailabilities,
    filteredCriticalAvailabilitiesLength,
    hasZeroFilteredAvailabilities,
  } = useMemo(() => {
    if (_availabilitiesData === undefined) {
      return {};
    }

    const filteredAvailabilities = filterAvailabilities(
      _availabilitiesData,
      connection
    );

    const filteredCriticalAvailabilities = filteredAvailabilities.filter(
      isCriticalAvailability
    );

    const filteredCriticalAvailabilitiesLength =
      filteredCriticalAvailabilities.length;

    const hasZeroFilteredAvailabilities = filteredAvailabilities.length === 0;

    return {
      filteredAvailabilities,
      filteredCriticalAvailabilitiesLength,
      hasZeroFilteredAvailabilities,
    };
  }, [_availabilitiesData, connection]);

  const onCompaniesChange = useCallback(
    (companies) => {
      const stations = [];
      form.setFieldsValue({ companies, stations });
      setSelectedCompanies(companies);
      // TODO: only change stations when needed (when a selected station from a selected company has been removed)
      setSelectedStations(stations);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onStationsChange = useCallback(
    (stations) => {
      form.setFieldsValue({ stations });
      setSelectedStations(stations);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onTabChange = useCallback(
    (activeKey) => {
      const currentTab = tabs.find((tab) => tab.duration === activeKey);
      setCurrentTab(currentTab);
    },
    [tabs]
  );

  const onConnectionChange = useCallback(
    (connection) =>
      connection === undefined ? resetConnection() : setConnection(connection),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className="container">
      <Breadcrumbs
        breadcrumbs={[
          freire(texts.ADMIN),
          freire(texts.AVAILABILITY),
          freire(texts.STATIONS_REAL),
        ]}
      />
      <div className="cardContainerAvailability">
        <div className="filter">
          <Row gutter={[16, 0]}>
            <Col span={24}>
              <Form id="form_availability_stations" wrapperCol={{ span: 24 }}>
                <Row gutter={[16, 0]}>
                  <Col span={9} lg={10}>
                    <Form.Item
                      label={freire(texts.COMPANIES)}
                      style={{ marginBottom: 12 }}
                    >
                      {/* TODO: check if 'form' triggers rerender */}
                      {form.getFieldDecorator("companies")(
                        <TreeSelect
                          {...companyTags}
                          allowClear
                          treeCheckable
                          style={{ width: "100%" }}
                          treeNodeFilterProp="title"
                          showCheckedStrategy={TreeSelect.SHOW_CHILD}
                          disabled={companies === null}
                          searchPlaceholder={freire(texts.SELECT_COMPANIES)}
                          dropdownStyle={{ maxHeight: 220, overflow: "auto" }}
                          treeDefaultExpandAll
                          treeData={companyTreeData}
                          onChange={onCompaniesChange}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={9} lg={10}>
                    <Form.Item
                      label={freire(texts.STATIONS)}
                      style={{ marginBottom: 12 }}
                    >
                      {/* TODO: check if 'form' triggers rerender */}
                      {form.getFieldDecorator("stations")(
                        <TreeSelect
                          {...stationTags}
                          allowClear
                          multiple
                          treeCheckable
                          style={{ width: "100%" }}
                          treeNodeFilterProp="title"
                          showCheckedStrategy={TreeSelect.SHOW_CHILD}
                          disabled={stations === null}
                          searchPlaceholder={freire(texts.SELECT_STATIONS)}
                          dropdownStyle={{ maxHeight: 220, overflow: "auto" }}
                          treeDefaultExpandAll
                          treeData={stationTreeData}
                          onChange={onStationsChange}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={6} lg={4}>
                    <Form.Item
                      label={freire(texts.CONNECTION)}
                      style={{ marginBottom: 12 }}
                    >
                      <Select
                        allowClear={connection !== DEFAULT_CONNECTION}
                        value={connection}
                        onChange={onConnectionChange}
                      >
                        {CONNECTIONS.map((connection) => (
                          <Option key={connection} value={connection.key}>
                            <ConnectionOptionContent
                              availabilities={_availabilitiesData}
                              connection={connection}
                              freire={freire}
                              hasError={hasError}
                              loading={loading}
                            />
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="textLastUpdate">
              {hasError || loading ? (
                <span style={{ visibility: "hidden" }}>
                  {freire(texts.LAST_UPDATE_LOADING)}
                </span>
              ) : (
                <span>
                  {freire(
                    texts.LAST_UPDATE_REAL(
                      availabilitiesData.updatedAt.format("L"),
                      availabilitiesData.updatedAt.format("LT")
                    )
                  )}
                </span>
              )}
            </div>
            <div>
              <Button
                onClick={resetFilters}
                disabled={
                  selectedCompanies.length === 0 &&
                  selectedStations.length === 0 &&
                  connection === DEFAULT_CONNECTION
                }
                style={{ marginLeft: 32 }}
              >
                <Icon type="minus" style={{ marginRight: 2 }} />
                {freire(texts.RESET_FILTERS)}
              </Button>
              <Button
                onClick={onReload}
                disabled={loading}
                style={{ marginLeft: 16 }}
              >
                <Icon type="reload" style={{ marginRight: 2 }} spin={loading} />
                {freire(texts.UPDATE)}
              </Button>
            </div>
          </div>
        </div>

        <Tabs
          type="card"
          activeKey={currentTab.duration}
          onChange={onTabChange}
          tabBarGutter={0}
          destroyInactiveTabPane
        >
          {tabs.map((tab) => (
            <Tabs.TabPane key={tab.duration} tab={tab.title}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: 16,
                }}
              >
                <div className="containerInfoTab">
                  {hasError || loading ? (
                    <div
                      className="blockcontainer"
                      style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        className="containerPeriod"
                        style={{ minWidth: 300 }}
                      >
                        <div
                          className={`textPeriod skeleton${
                            !loading && hasError ? " still" : ""
                          }`}
                          style={{ width: tab.fullDayBlocks ? 70 : 130 }}
                        />
                        <Icon type="right" />
                        <div
                          className={`textPeriod skeleton${
                            !loading && hasError ? " still" : ""
                          }`}
                          style={{ width: tab.fullDayBlocks ? 70 : 130 }}
                        />
                      </div>
                      {(lastAvailabilitiesData.current !== null ||
                        lastAvailabilitiesData.current
                          ?.criticalAvailabilitiesLength !== 0) && (
                        <div
                          style={{
                            marginTop: 14,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            className={`system-availability-2 skeleton${
                              !loading && hasError ? " still" : ""
                            }`}
                            style={{ width: 250 }}
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div
                      className="blockcontainer"
                      style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        className="containerPeriod"
                        style={{ minWidth: 300 }}
                      >
                        <span
                          className="textPeriod"
                          style={{ fontWeight: 600 }}
                        >
                          {formatPeriodDate(availabilitiesData.period.start)}
                        </span>
                        <Icon type="right" />
                        <span
                          className="textPeriod"
                          style={{ fontWeight: 600 }}
                        >
                          {formatPeriodDate(availabilitiesData.period.end)}
                        </span>
                      </div>
                      {filteredCriticalAvailabilitiesLength > 0 && (
                        <div
                          style={{
                            marginTop: 10,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Popover
                            content={freire(
                              texts.LOW_AVAILABILITY_DESCRIPTION(
                                formatters.percentage(freireUserLanguage)(
                                  MINIMUM_AVAILABILITY_PERCENTAGE,
                                  0
                                )
                              )
                            )}
                          >

                            <span className="textCritical"
                              dangerouslySetInnerHTML={
                                { __html: freire(
                                  texts.LOW_AVAILABILITY_REAL(
                                    filteredCriticalAvailabilitiesLength
                                  )
                                )}
                              } >
                            </span> 
                          </Popover>
                        </div>
                      )}
                      {!loading && hasZeroAvailabilityData && (
                        <div
                          style={{
                            marginTop: 14,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            className="system-availability-2 skeleton still"
                            style={{ width: 250 }}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {canViewDetailed && (
                  <div className="containerInfoTab">
                    <div
                      className="blockcontainer"
                      style={{
                        minWidth: 220,
                      }}
                    >
                      <div
                        className="containerPeriod"
                        style={{ justifyContent: "start" }}
                      >
                        <span
                          className="textPeriod"
                          style={{ fontWeight: 600 }}
                          dangerouslySetInnerHTML={{ __html: freire(texts.STATIONS_AVAILABILITY_REAL) }}
                        ></span>
                      </div>
                      <div style={{ marginTop: 9 }}>
                        <StationsAvailabilityCard
                          availabilities={filteredAvailabilities}
                          // TODO: check loading || hasError
                          hasError={
                            hasError ||
                            hasZeroAvailabilityData ||
                            hasZeroFilteredAvailabilities
                          }
                          loading={loading}
                          whenLoading={
                            <div
                              style={{
                                display: "flex",
                                alignItems: "end",
                              }}
                            >
                              <div
                                className={`system-availability skeleton${
                                  !loading &&
                                  (hasError ||
                                    hasZeroAvailabilityData ||
                                    hasZeroFilteredAvailabilities)
                                    ? " still"
                                    : ""
                                }`}
                                style={{ width: 21, marginRight: 10 }}
                              />
                              <div
                                className={`system-availability skeleton${
                                  !loading &&
                                  (hasError ||
                                    hasZeroAvailabilityData ||
                                    hasZeroFilteredAvailabilities)
                                    ? " still"
                                    : ""
                                }`}
                                style={{ width: 70 }}
                              />
                            </div>
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="containerInfoTab">
                  <div
                    className="blockcontainer"
                    style={{
                      minWidth: 230,
                    }}
                  >
                    <div
                      className="containerPeriod"
                      style={{ justifyContent: "start" }}
                    >
                      <span className="textPeriod" style={{ fontWeight: 600 }}>
                        {freire(texts.SYSTEM_AVAILABILITY)}
                      </span>
                    </div>
                    <div style={{ marginTop: 9 }}>
                      <SystemAvailabilityCard
                        ref={systemAvailabilityRef}
                        duration={currentTab.duration}
                        whenLoading={
                          <div
                            style={{
                              display: "flex",
                              alignItems: "end",
                              marginTop: 11,
                            }}
                          >
                            <div
                              className="system-availability skeleton"
                              style={{ width: 21, marginRight: 10 }}
                            />
                            <div
                              className="system-availability skeleton"
                              style={{ width: 70 }}
                            />
                            <div
                              className="system-availability-2 skeleton"
                              style={{ marginLeft: 8, width: 80 }}
                            />
                          </div>
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="availabilities-container">
                {hasError ? (
                  <div style={{ position: "relative" }}>
                    <CardAvailability
                      canViewDetailed={canViewDetailed}
                      currentTab={currentTab}
                      freire={freire}
                      freireUserLanguage={freireUserLanguage}
                      skeleton
                      stillSkeleton={!loading}
                    />
                    {!loading && (
                      <div
                        style={{
                          background: "rgba(0, 0, 0, 0.12)",
                          width: "100%",
                          height: "100%",
                          position: "absolute",
                          top: 0,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <span style={{ paddingBottom: 4 }}>
                          <Icon
                            component={WarningIcon}
                            style={{ marginRight: 8 }}
                          />
                          {formatters.phrase(error.message)}
                        </span>
                        <Button onClick={onReload} disabled={loading}>
                          {/* TODO: testar ícone */}
                          {freire(texts.TRY_AGAIN)}
                        </Button>
                      </div>
                    )}
                  </div>
                ) : loading ? (
                  <>
                    {Array.from(
                      {
                        length: Math.min(
                          SKELETON_AVAILABILITIES,
                          ...(lastAvailabilitiesData.current?.availabilities
                            .length > 0
                            ? [
                                lastAvailabilitiesData.current.availabilities
                                  .length,
                              ]
                            : [])
                        ),
                      },
                      (_, index) => (
                        <CardAvailability
                          key={`skeleton-${index}`}
                          canViewDetailed={canViewDetailed}
                          currentTab={currentTab}
                          freire={freire}
                          freireUserLanguage={freireUserLanguage}
                          skeleton
                        />
                      )
                    )}
                  </>
                ) : (
                  <AvailabilityCards
                    canViewDetailed={canViewDetailed}
                    connection={connection}
                    currentTab={currentTab}
                    filteredAvailabilities={filteredAvailabilities}
                    freire={freire}
                    freireUserLanguage={freireUserLanguage}
                    hasAnotherDay={hasAnotherDay}
                    maxDifferenceInDaysLength={maxDifferenceInDaysLength}
                    maxBlockDuration={maxBlockDuration}
                    maxOfflineTime={maxOfflineTime}
                    pageSize={pageSize}
                    resetFilter={
                      connection === DEFAULT_CONNECTION
                        ? resetFilters
                        : resetConnection
                    }
                    resetFilterText={
                      connection === DEFAULT_CONNECTION
                        ? texts.RESET_FILTERS
                        : texts.RESET_FILTER
                    }
                    setPageSize={setPageSize}
                  />
                )}
              </div>
            </Tabs.TabPane>
          ))}
        </Tabs>
      </div>
    </div>
  );
});

export default Form.create({ name: "filters" })(AvailabilityStationsReal);
