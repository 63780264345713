import React, { useState } from "react";
import { Dropdown, Icon, Menu } from "antd";
import { useAccess } from "../../contexts/PermissionContext";

import "./styles.css";

const Dot = ({ dropdownVisible }) => (
  <div
    className="dropdownActionsDot"
    style={{ backgroundColor: dropdownVisible ? "#ffffff" : "#595959" }}
  />
);

const DropdownActions = ({ actions = [] }) => {
  const { hasPermission } = useAccess();
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const filteredActions = actions.filter(
    (action) =>
      action && (!action.permission || hasPermission(action.permission))
  );

  if (filteredActions.length === 0) {
    return null;
  }

  return (
    <div style={{ display: "inline-flex" }}>
      <Dropdown
        onVisibleChange={setDropdownVisible}
        overlay={
          <Menu>
            {filteredActions.map((action) => (
              <Menu.Item
                key={action.title}
                onClick={() => {
                  setDropdownVisible(false);
                  action.onClick();
                }}
              >
                <span style={{ color: "#1890FF" }}>
                  <Icon type={action.icon} style={{ fontSize: 15 }} />
                  {action.title}
                </span>
              </Menu.Item>
            ))}
          </Menu>
        }
      >
        <div
          className="dropdownActions"
          style={{
            backgroundColor: dropdownVisible ? "#1890ff" : "transparent",
          }}
        >
          <Dot dropdownVisible={dropdownVisible} />
          <Dot dropdownVisible={dropdownVisible} />
          <Dot dropdownVisible={dropdownVisible} />
        </div>
      </Dropdown>
    </div>
  );
};

export default DropdownActions;
