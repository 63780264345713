import type { UnionToArray } from "../utils/types";
import type { Brand, BrandAlias } from "./brand";
import { BRAND, BRAND_ALIAS } from "./brand";
import type { WhiteLabelAlias } from "./white-labels";
import { WHITE_LABELS } from "./white-labels";

export type AppAlias = BrandAlias | WhiteLabelAlias;

export type App = Brand & {
  qrCodeLink: string;
};

export type Apps = Record<AppAlias, App>;

const toQrCode = (app: Brand): string => {
  const subdomain = app.deepLinkSubdomain ?? app.alias;
  return `https://${subdomain}.tupinambaenergia.com.br/qr-code`;
};

export const apps = [BRAND, ...WHITE_LABELS].reduce<Apps>(
  (apps, app) => ({
    ...apps,
    [app.alias]: {
      ...app,
      qrCodeLink: toQrCode(app),
    },
  }),
  {} as Apps
);

export const appAliases = Object.keys(apps) as UnionToArray<AppAlias>;

export const getStrictAppByAlias = (alias: string): App | null =>
  ((apps as any)[alias] as App | undefined) ?? null;

export const getAppByAlias = (alias: string): App =>
  getStrictAppByAlias(alias) ?? apps[BRAND_ALIAS];
