import { duration, durationOneDayTweak } from "./date";
import { id } from "./id";
import { phrase } from "./phrase";
import { percentage } from "./percentage";
import { currency, getCurrencySymbol } from "./currency";
import { phone } from "./phone";
import { formatAddress } from "./address";

export const formatters = {
  duration,
  durationOneDayTweak,
  id,
  phrase,
  percentage,
  currency,
  getCurrencySymbol,
  phone,
  formatAddress
};
