import React, { forwardRef, useState } from "react";
import { Select } from "antd";
import * as texts from "./locales";
import { useFreire } from "../../utils/freireContext";
import { useOnUpdate } from "../../hooks";
import { ITupiSelectProps } from "../../interfaces/tupiSelect";

const TupiMultiSelect = forwardRef(
  (
    {
      options,
      enableAllSelect,
      onChange,
      maxTagCount = 1,
      maxTagTextLength = 15,
      form,
      fieldName,
      showMaxTagPlaceholder = true,
      ...props
    }: ITupiSelectProps,
    ref: any
  ) => {
    const { freire } = useFreire();
    const [selectedAll, setSelectedAll] = useState(false);

    const fieldValue = form?.getFieldValue(fieldName);

    useOnUpdate(() => {
      if (!fieldValue && selectedAll) {
        setSelectedAll(false);
      }
    }, [fieldValue]);

    const handleChange = (values: any) => {
      if (onChange) onChange(values);

      if (values.includes("all")) {
        if (selectedAll) {
          form?.resetFields([fieldName]);
        } else {
          form?.setFieldsValue({
            [fieldName]: options.map(({ value }) => value),
          });
        }
        setSelectedAll((prev) => !prev);
      } else {
        setSelectedAll(values.length === options.length);
      }
    };

    return (
      <Select
        {...props}
        onChange={handleChange}
        ref={ref}
        mode="multiple"
        showSearch
        allowClear
        optionFilterProp="label"
        autoClearSearchValue={false}
        maxTagTextLength={maxTagTextLength}
        maxTagCount={selectedAll && showMaxTagPlaceholder ? 0 : maxTagCount}
        maxTagPlaceholder={
          selectedAll && showMaxTagPlaceholder ? freire(texts.ALL) : undefined
        }
      >
        {enableAllSelect && options.length > 0 && (
          <Select.Option
            label={freire(texts.TOGGLE_SELECTION)}
            value="all"
            style={{ borderBottom: "1px solid #D4D4D3" }}
          >
            {freire(texts.TOGGLE_SELECTION)}
          </Select.Option>
        )}

        {options.map((option) => (
          <Select.Option
            label={option.label}
            key={option.value}
            value={option.value}
          >
            {option.label}
          </Select.Option>
        ))}
      </Select>
    );
  }
);



const TupiSingleSelect = forwardRef(({ options, ...props }: ITupiSelectProps, ref: any) => (
  <Select {...props} showSearch allowClear optionFilterProp="label" ref={ref}>
    {options.map((option) => (
      <Select.Option
        label={option.label}
        key={option.value}
        value={option.value}
      >
        {option.label}
      </Select.Option>
    ))}
  </Select>
));



const TupiSelect = forwardRef(({ multiple, ...props }: ITupiSelectProps, ref: any) => {
  return multiple ? (
    <TupiMultiSelect {...props} ref={ref} />
  ) : (
    <TupiSingleSelect {...props} ref={ref} />
  );
});

export default TupiSelect;
