import React from "react";
import { Button, Divider, Icon, Modal } from "antd";
import * as texts from './locales';
import './styles.css';


const ModalExpireProposal = ({ visible, proposalId, onCancel, onConfirm }) => {
  // TODO - internacionalizacao dos textos
  const lang = 'pt';

  return (
    <Modal
      className="modalExpireProposal"
      centered
      width={"50%"}
      footer={null}
      closable={false}
      visible={visible}
      onCancel={onCancel}
    >
      <span className="modalExpireProposalTitle">
        <Icon
          type="delete"
          style={{ color: "#1890FF", fontSize: 24, marginRight: 8 }}
        />
        {texts.EXPIRE_PROPOSAL_TITLE[lang]}
      </span>

      <Divider />


      <div className="modalExpireProposalCard">
        <div className="modalExpireProposalCardInfo" style={{ marginBottom: 10 }}>
          <Icon type="file" className="modalExpireProposalCardIcon" />
          <span>{texts.PROPOSAL_ID[lang]} {proposalId}</span>
        </div>
      </div>

      <span>{texts.EXPIRE_PROPOSAL_CONFIRMATION[lang]}</span>

      <div className="modalExpireProposalFooter">
        <Button
          style={{ backgroundColor: "#F40000", flex: 1 }}
          type="danger"
          onClick={onConfirm}
        >
          {texts.EXPIRE[lang]}
        </Button>
        <div style={{ width: 24 }} />
        <Button style={{ flex: 1 }} onClick={onCancel}>
          {texts.CANCEL[lang]}
        </Button>
      </div>
    </Modal>
  );
};

export default ModalExpireProposal;
