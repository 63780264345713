import React, { Component } from "react";
import {
  Button,
  Select,
  Input,
  Form,
  Col,
  Row,
  Icon,
  Steps,
  notification,
  message,
  TreeSelect,
} from "antd";
import { withRouter } from "react-router-dom";
import { validate as isValidEmail } from "email-validator";
import api from "../../../services/api";
import "./index.css";
import { getTreeData } from "../../../services/utils";
import { FreireContext } from "../../../utils/freireContext";
import * as texts from "../locales";
const { Step } = Steps;

class NewReportGroup extends Component {
  static contextType = FreireContext;

  state = {
    loadingSave: false,
    loadingStations: false,
    stations: [],
  };

  getStations() {
    const { freire } = this.context;

    this.setState({ loadingStations: true, stations: [] }, async () => {
      try {
        const { data } = await api.get(`select/connected_stations`);
        this.setState({
          loadingStations: false,
          stations: data,
        });
      } catch (error) {
        message.error(freire(texts.ERROR_GET_STATIONS));
        this.setState({
          loadingStations: false,
        });
      }
    });
  }

  save = () => {
    const { freire } = this.context;

    this.props.form.validateFields(async (err, payload) => {
      if (err) {
        return;
      }

      try {
        this.setState({ loadingSave: true });

        await api.post("report_groups", payload);

        notification.success({
          message: freire(texts.ALL_RIGHT),
          description: freire(texts.GROUP_CREATED_SUCCESS),
        });
        this.setState({ loadingSave: false });

        this.props.history.push("/dashboard/report_groups");
      } catch (error) {
        this.setState({ loadingSave: false });

        const {
          response: {
            data: { message },
          },
        } = error;

        notification.error({
          message: freire(texts.WENT_WONG),
          description: message || error.message,
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { loadingSave, loadingStations, stations } = this.state;
    const { freire } = this.context;

    return (
      <div className="container">
        <div className="content">
          <Row gutter={[16, 0]}>
            <Col span={6}>
              <Steps current={1} size="small" direction="vertical">
                <Step
                  icon={<Icon type="profile" />}
                  title={freire(texts.NEW_REPORT_GROUP)}
                  description={freire(texts.CREATE_REPORT_GROUP)}
                />
              </Steps>
            </Col>

            <Col span={18}>
              <Form wrapperCol={{ span: 24 }}>
                <Row gutter={[16, 0]}>
                  <Col span={12}>
                    <Form.Item label={freire(texts.GROUP_NAME)}>
                      {getFieldDecorator("name", {
                        rules: [
                          {
                            required: true,
                            message: freire(texts.REQUIRED_FIELD),
                          },
                        ],
                      })(
                        <Input
                          disabled={loadingSave}
                          placeholder={freire(texts.GROUP_NAME_EX)}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={freire(texts.EMAIL_LIST)}>
                      {getFieldDecorator("emails", {
                        rules: [
                          {
                            required: true,
                            message: freire(texts.REQUIRED_FIELD),
                          },
                        ],
                      })(
                        <Select
                          mode="tags"
                          disabled={loadingSave}
                          style={{ width: "100%" }}
                          placeholder={freire(texts.INPUT_EMAILS)}
                          onSelect={(value) => {
                            if (!isValidEmail(value)) {
                              message.warning(freire(texts.INFORM_VALID_EMAIL));
                              var emails =
                                this.props.form.getFieldValue("emails");

                              const index = emails.indexOf(value);
                              if (index > -1) {
                                emails.splice(index, 1);
                                this.props.form.setFieldsValue({ emails });
                              }
                            }
                          }}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label={freire(texts.STATIONS)}>
                      {getFieldDecorator("stations", {
                        rules: [
                          {
                            required: true,
                            message: freire(texts.REQUIRED_FIELD),
                          },
                        ],
                      })(
                        <TreeSelect
                          multiple
                          treeCheckable
                          treeNodeFilterProp="title"
                          showCheckedStrategy={TreeSelect.SHOW_CHILD}
                          disabled={loadingSave || loadingStations}
                          searchPlaceholder={freire(texts.SELECT_STATIONS)}
                          dropdownStyle={{ maxHeight: 220 }}
                          treeData={getTreeData({
                            title: freire(texts.SELECT_ALL(stations.length)),
                            loading: loadingStations,
                            children: stations.map((station) => ({
                              title: `${station.name} (${station.stationID})`,
                              key: station._id,
                              value: station.stationID,
                            })),
                          })}
                          onDropdownVisibleChange={(opened) => {
                            if (opened && stations.length === 0) {
                              this.getStations();
                            }
                          }}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Form>

              <div className="footer">
                <Button
                  style={{ marginRight: 20 }}
                  onClick={() =>
                    this.props.history.push("/dashboard/report_groups")
                  }
                  type="default"
                  disabled={loadingSave}
                >
                  {freire(texts.CANCEL)}
                </Button>
                <Button
                  loading={loadingSave}
                  onClick={this.save}
                  type="primary"
                >
                  {freire(texts.SAVE)}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const PageNewCoupons = Form.create({ name: "inputs" })(NewReportGroup);
export default withRouter(PageNewCoupons);
