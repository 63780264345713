import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, Form, notification, Row, Select } from "antd";
import apiOcpp from "../../../../services/apiOcpp";
import { useFreire } from "../../../../utils/freireContext";
import * as texts from "../../locales";

const { Option } = Select;

const RemoteStopTransaction = ({
  stationId = "",
  connectors = [],
  redirectTo = () => {},
  form,
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingTransactions, setLoadingTransactions] = useState(false);
  const [activeTransactions, setActiveTransactions] = useState([]);
  const { getFieldDecorator } = form;
  const { freire } = useFreire();

  useEffect(() => {
    if (stationId) {
      form.resetFields(["transactionId"]);
      getActiveTransactions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stationId]);

  const getActiveTransactions = async () => {
    try {
      setActiveTransactions([]);
      setLoadingTransactions(true);

      const { data } = await apiOcpp.get(`station/${stationId}/transactionsId`);

      setLoadingTransactions(false);
      setActiveTransactions(data);
    } catch (error) {
      setLoadingTransactions(false);
    }
  };

  const send = () => {
    form.validateFields(async (err, payload) => {
      if (err) {
        return;
      }

      try {
        setLoading(true);
        const { data } = await apiOcpp.post(
          `/ocpp16/remoteStopTransaction/${stationId}/transaction/${payload.transactionId}`
        );

        setLoading(false);

        if (data && data.result) {
          if (data.result === "Accepted") {
            notification.success({
              message: freire(texts.ALL_RIGHT),
              description: `${freire(texts.STATION_RESPONSE)} '${data.result}'`,
            });
            redirectTo("/dashboard/ocpp/stations");
          } else {
            notification.error({
              message: freire(texts.OOPS),
              description: `${freire(texts.STATION_RESPONSE)} '${data.result}'`,
            });
          }
        } else {
          throw new Error();
        }
      } catch (error) {
        setLoading(false);
        notification.error({
          message: freire(texts.OOPS),
          description: freire(texts.VERIFY_AGAIN),
        });
      }
    });
  };

  return (
    <Fragment>
      <Form layout="vertical" wrapperCol={{ span: 24 }}>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item label={freire(texts.ACTIVE_TRANSACTION_ID)}>
              {getFieldDecorator("transactionId", {
                rules: [
                  {
                    required: true,
                    message: freire(texts.REQUIRED),
                  },
                ],
              })(
                <Select
                  loading={loadingTransactions}
                  placeholder={freire(texts.SELECT_TRANSACTION)}
                >
                  {activeTransactions.map((tr) => (
                    <Option key={tr.id} value={tr.id}>
                      ID: <span style={{ fontWeight: "bold" }}>{tr.id}</span>
                      <span style={{ fontSize: 12.5, marginLeft: 7 }}>
                        (Conector: {tr.connectorId})
                      </span>
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        <Button
          disabled={!stationId || loading}
          loading={loading}
          onClick={send}
          type="primary"
        >
          <span>
            {freire(texts.EXECUTE)}{" "}
            <span style={{ fontWeight: "bold" }}> RemoteStopTransaction</span>
          </span>
        </Button>
      </div>
    </Fragment>
  );
};

const PageRemoteStopTransaction = Form.create({ name: "inputs" })(
  RemoteStopTransaction
);

export default PageRemoteStopTransaction;
