export const ADMIN = {
    pt: "Admin",
    es: "Admin",
    en: "Admin",
  };

export const PAINEL_OKR = {
    pt: "Painel OKR",
    en: "OKR panel",
    es: "Panel OKR",
};

