export const ADMIN = {
  pt: "Admin",
  es: "Admin",
  en: "Admin",
};

export const PROPOSAL = {
  pt: "Propostas",
  es: "Propuestas",
  en: "Proposals",
};

export const CREATION = {
  pt: "Criação",
  es: "Creación",
  en: "Creation",
};

export const LIST = {
  pt: "Lista",
  es: "Lista",
  en: "List",
};

export const EDIT = {
  pt: "Editar",
  es: "Editar",
  en: "Edit",
}
