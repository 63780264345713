import React, { memo, useMemo } from "react";
import { Icon } from "antd";
import moment from "moment-timezone";

import { BlockColor } from "./BlockColor";
import DotStatus from "../../../../components/StatusWithDot/DotStatus";
import { BlankSpace } from "../../../../components/BlankSpace";
import { useToggle } from "../../../../hooks";
import { formatters } from "../../../../utils/formatters";

import "./styles.css";
import * as texts from "../../locales";

// TODO: using this twice
const isAnotherDay = (connectionLog) => connectionLog.differenceInDays !== 0;

const ZendeskTicketLink = memo(({ ticketID }) => (
  <a
    style={{ textDecoration: "underline" }}
    rel="noopener noreferrer"
    target="_blank"
    href={`https://tupinambaenergiahelp.zendesk.com/agent/tickets/${ticketID}`}
  >
    Ticket #{ticketID}
  </a>
));

const Disconnection = memo(
  ({
    connectionLog,
    hasInvisibleSpace,
    canViewDetailed,
    maxDifferenceInDaysLength,
    freire,
  }) => (
    <div
      style={{ ...(connectionLog.duration === 0 && { fontWeight: "bold" }) }}
    >
      <span>{connectionLog.disconnectedAt.format("L")}</span>
      <BlankSpace />
      <span style={{ marginLeft: 16 }}>
        {connectionLog.disconnectedAt.format("LT")}
        {connectionLog.connectedAt === undefined
          ? ""
          : ` - ${connectionLog.connectedAt.format("LT")}`}
        {hasInvisibleSpace && (
          <span
            {...{
              ...(!isAnotherDay(connectionLog) && {
                style: {
                  visibility: "hidden",
                },
              }),
            }}
          >
            +{connectionLog.differenceInDays}
            {maxDifferenceInDaysLength -
              connectionLog.differenceInDaysLength !==
              0 && (
              <span style={{ visibility: "hidden", whiteSpace: "pre" }}>
                {" ".repeat(
                  (maxDifferenceInDaysLength -
                    connectionLog.differenceInDaysLength) *
                    2
                )}
              </span>
            )}
          </span>
        )}
        {connectionLog.connectedAt === undefined && (
          <span style={{ visibility: "hidden", whiteSpace: "pre" }}>
            {" - 00:00"}
          </span>
        )}
      </span>
      {/* TODO: is it really needed? */}
      <BlankSpace />
      <span
        style={{
          marginLeft: 16,
          ...(connectionLog.duration === 0 && {
            color: "#CE93D8",
            textDecoration: "underline",
          }),
        }}
      >
        ({formatters.durationOneDayTweak(freire)(connectionLog.duration)})
      </span>
      {connectionLog.duration === 0 && (
        <>
          <BlankSpace />
          <span
            style={{
              marginLeft: 16,
              color: "#CE93D8",
            }}
          >
            {connectionLog._id}
          </span>
        </>
      )}
      {canViewDetailed && typeof connectionLog.zendeskTicketID === "number" && (
        <>
          <BlankSpace />
          <span style={{ marginLeft: 16 }}>
            <ZendeskTicketLink ticketID={connectionLog.zendeskTicketID} />
          </span>
        </>
      )}
    </div>
  )
);

const Disconnections = memo(
  ({
    connectionLogs,
    canViewDetailed,
    hasAnotherDay,
    maxDifferenceInDaysLength,
    freire,
  }) => {
    if (connectionLogs.length === 0) {
      return (
        <div className="disconnections">
          <div style={{ fontWeight: "bold" }}>
            {freire(texts.NO_DISCONNECT)}
          </div>
        </div>
      );
    }

    const _connectionLogs = connectionLogs.map((connectionLog) => {
      const disconnectedAt = moment(connectionLog.disconnectedAt);
      const connectedAt =
        connectionLog.connectedAt === undefined
          ? undefined
          : moment(connectionLog.connectedAt);

      const UNIT_OF_TIME = "day";

      const _disconnectedAtDay = (disconnectedAt?.clone() ?? moment()).startOf(
        UNIT_OF_TIME
      );
      const _connectedAtDay = (connectedAt?.clone() ?? moment()).startOf(
        UNIT_OF_TIME
      );

      const differenceInDays = _connectedAtDay.diff(
        _disconnectedAtDay,
        UNIT_OF_TIME
      );
      const differenceInDaysLength = differenceInDays.toString().length;

      return {
        ...connectionLog,
        disconnectedAt,
        connectedAt,
        differenceInDays,
        differenceInDaysLength,
      };
    });

    return (
      <div className="disconnections">
        <div style={{ marginBottom: 8, fontWeight: "bold" }}>
          {freire(texts.DISCONNECTIONS(_connectionLogs.length))}
        </div>
        <div className="disconnections-list">
          {_connectionLogs.map((connectionLog) => (
            <Disconnection
              key={connectionLog._id}
              connectionLog={connectionLog}
              hasInvisibleSpace={hasAnotherDay}
              maxDifferenceInDaysLength={maxDifferenceInDaysLength}
              canViewDetailed={canViewDetailed}
              freire={freire}
            />
          ))}
        </div>
      </div>
    );
  }
);

export const CardAvailability = memo(
  ({
    canViewDetailed,
    connectionLogs = [],
    critical,
    currentTab,
    companyName,
    freire,
    freireUserLanguage, // to force rerender when the user changes the language
    stationID,
    stationName,
    disconnected,
    availability,
    hasAnotherDay,
    maxDifferenceInDaysLength,
    maxBlockDuration = 0,
    maxOfflineTime,
    offlineDuration,
    blocks,
    skeleton,
    stillSkeleton,
  }) => {
    const [opened, toggleOpened] = useToggle();

    const _maxBlockDuration = useMemo(
      () => moment.duration(maxBlockDuration),
      [maxBlockDuration]
    );

    return (
      <div
        className="container"
        style={{ backgroundColor: critical ? "#FFE4E4" : "#FAFAFA" }}
      >
        {skeleton ? (
          <div className="header">
            <div className="infoHeader">
              <span
                className={`labelHeader skeleton${
                  stillSkeleton ? ` still` : ""
                }`}
                style={{ width: 130 }}
              />
              <div
                className="textHeader"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {canViewDetailed && <DotStatus status="Loading" />}
                <span
                  className={`textHeader skeleton${
                    stillSkeleton ? ` still` : ""
                  } noMargin`}
                  style={{ width: canViewDetailed ? 280 : 290 }}
                />
              </div>
            </div>
            <div className="infoHeader">
              <span className="labelHeader" style={{ width: 100 }}>
                {freire(texts.AVAILABILITY)}
              </span>
              <span
                className={`textHeader skeleton${
                  stillSkeleton ? ` still` : ""
                } lowMargin`}
                style={{ width: 75 }}
              />
            </div>
            <div className="infoHeader">
              <span className="labelHeader" style={{ width: 48 }}>
                {freire(texts.DISCONNECTIONS_ALT)}
              </span>
              <span
                className={`textHeader skeleton${
                  stillSkeleton ? ` still` : ""
                } lowMargin`}
                style={{ width: 32 }}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div className="infoHeader">
                <span className="labelHeader" style={{ width: 110 }}>
                  {freire(texts.OFFLINE_TIME)}
                </span>
                <span
                  className={`textHeader skeleton${
                    stillSkeleton ? ` still` : ""
                  } lowMargin`}
                  style={{ width: 80 }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
              >
                <div
                  className={`viewDisconnections skeleton${
                    stillSkeleton ? ` still` : ""
                  }`}
                  style={{ width: 80 }}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="header">
            <div className="infoHeader">
              <span className="labelHeader">
                {companyName} - {stationID}
              </span>
              <span
                className="textHeader"
                style={{
                  fontWeight: 700,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {canViewDetailed && (
                  <DotStatus
                    status={disconnected ? "Unavailable" : "Available"}
                  />
                )}
                {stationName}
              </span>
            </div>
            <div className="infoHeader">
              <span className="labelHeader" style={{ minWidth: 100 }}>
                {freire(texts.AVAILABILITY)}
              </span>
              <span className="textHeader">
                {formatters.percentage(freireUserLanguage)(availability)}
              </span>
            </div>
            <div className="infoHeader">
              <span className="labelHeader" style={{ minWidth: 48 }}>
                {freire(texts.DISCONNECTIONS_ALT)}
              </span>
              <span className="textHeader">{connectionLogs.length}</span>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div className="infoHeader">
                {/* TODO: rever esses widths no skeleton */}
                <span className="labelHeader" style={{ minWidth: 110 }}>
                  {freire(texts.OFFLINE_TIME)}
                </span>
                <span className="textHeader">
                  {formatters.durationOneDayTweak(freire)(offlineDuration)}
                  <span
                    style={{
                      visibility: "hidden",
                      height: 0,
                      display: "block",
                    }}
                  >
                    {maxOfflineTime}
                  </span>
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column-reverse",
                  paddingBottom: 6,
                  width: 80,
                }}
              >
                <span
                  className="viewDisconnections noselect"
                  onClick={toggleOpened}
                >
                  {freire(texts.SHOW_DETAILS)}
                  {opened ? (
                    <Icon
                      type="up"
                      style={{ marginLeft: 6, fontSize: "85%" }}
                    />
                  ) : (
                    <Icon
                      type="down"
                      style={{ marginLeft: 6, fontSize: "85%" }}
                    />
                  )}
                </span>
              </div>
            </div>
          </div>
        )}

        <div style={{ width: "min-content" }}>
          {skeleton ? (
            <div
              className={`containerBlock skeleton${
                stillSkeleton ? ` still` : ""
              }`}
              style={{ width: currentTab.qtyBlocks * (6 + 5) - 5 }}
            />
          ) : (
            <div className="containerBlock">
              {blocks.map((block, index) => (
                <BlockColor
                  key={index}
                  currentTab={currentTab}
                  block={block}
                  maxOfflineTime={_maxBlockDuration}
                  freire={freire}
                />
              ))}
            </div>
          )}
          <div className="containerTimeline">
            <span className="textTimeline">
              {formatters.durationOneDayTweak(freire)(
                currentTab.duration,
                undefined,
                undefined,
                { months: false }
              )}{" "}
              {freire(texts.AGO)}
            </span>
            <div className="dividerTimeline" />
            <span className="textTimeline">{freire(texts.NOW)}</span>
          </div>
        </div>
        {opened && (
          <div style={{ display: "flex", flexDirection: "column-reverse" }}>
            <Disconnections
              connectionLogs={connectionLogs}
              canViewDetailed={canViewDetailed}
              hasAnotherDay={hasAnotherDay}
              maxDifferenceInDaysLength={maxDifferenceInDaysLength}
              freire={freire}
            />
          </div>
        )}
      </div>
    );
  }
);
