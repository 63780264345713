import { DayProps } from "../interfaces";
import { Button, Icon, Switch } from "antd";
import { useFreire } from "../../../utils/freireContext";
import { useContext } from "react";
import * as texts from "../locales";
import "../index.css";
import { BusinessHourContext, is24Hours } from "../index";
import Hour from "./Hour";
import SemiPublicHours from "./semiPublicHours";

export default function Day({ day, weekDay, dayName }: DayProps) {
  const { freire } = useFreire();

  const {
    loading,
    isSemiPublic,
    onCopyDayInWeek,
    onChangeDayInTwentyFourHours,
    disabled,
  } = useContext(BusinessHourContext);

  return (
    <div className="dayContainer">
      <div className="rowTitleDay">
        <span className="titleNameDay">{dayName}</span>
        <Button
          disabled={loading || disabled}
          className="titleButtonDay"
          onClick={() => onCopyDayInWeek(day)}
        >
          <Icon type="copy" className="titleButtonDayIcon" />
          <span className="titleButtonDayText">
            {freire(texts.COPY_IN_ALL)}
          </span>
        </Button>
      </div>
      {isSemiPublic && (
        <div>
          <SemiPublicHours day={day} weekDay={weekDay} />
        </div>
      )}
      {!isSemiPublic && (
        <>
          <div className="rowTwentyFourHours">
            <Switch
              disabled={loading || disabled}
              size="small"
              checked={is24Hours(day)}
              onChange={(value) => onChangeDayInTwentyFourHours(value, weekDay)}
            />
            <span style={{ marginLeft: 10 }}>
              {freire(texts.TWENTY_FOUR_HOURS)}
            </span>
          </div>
          <div className="dayColumnHours">
            {is24Hours(day)
              ? null
              : day.hour.map((hour, index) => (
                  <Hour
                    key={index}
                    hour={hour}
                    weekDay={weekDay}
                    hourIndex={index}
                    hourLength={day.hour.length}
                  />
                ))}
          </div>
        </>
      )}
    </div>
  );
}
