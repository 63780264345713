import React, { Component } from "react";
import {
  Button,
  Input,
  Form,
  Col,
  Row,
  Icon,
  Steps,
  notification,
  Select,
  Tooltip,
  Switch,
} from "antd";
import { withRouter } from "react-router-dom";
import api from "../../../services/api";
import "./index.css";
import { FreireContext } from "../../../utils/freireContext";
import * as texts from "../locales";

const { Step } = Steps;
const { Option } = Select;

class NewRecipient extends Component {
  static contextType = FreireContext;

  state = {
    loadingSave: false,
  };

  salvar = () => {
    const { freire } = this.context;
    this.props.form.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      try {
        this.setState({ loadingSave: true });
        values.automatic_anticipation_enabled =
          values.automatic_anticipation_enabled?.toString();
        values.transfer_enabled = values.transfer_enabled?.toString();

        await api.post("recipient", values);

        this.setState({ loadingSave: false });

        notification.success({
          message: freire(texts.ALL_RIGHT),
          description: freire(texts.RECIPIENT_ADD_SUCCESS),
        });

        this.props.history.push("/dashboard/recipients");
      } catch (error) {
        const {
          response: {
            data: { message },
          },
        } = error;

        this.setState({ loadingSave: false });
        notification.error({
          message: freire(texts.WEN_WONG),
          description: message || error.message,
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { freire } = this.context;

    return (
      <div className="container">
        <div className="content">
          <Row>
            <Col span={4}>
              <Steps
                current={1}
                size="small"
                onChange={this.onChange}
                direction="vertical"
              >
                <Step
                  icon={<Icon type="profile" />}
                  title={freire(texts.RECIPIENT)}
                  description={freire(texts.DATA_OF_RECEIPT)}
                />
              </Steps>
            </Col>

            <Col span={20}>
              <Form wrapperCol={{ span: 24 }}>
                <Row gutter={[16, 0]}>
                  <Col span={12}>
                    <Form.Item label={freire(texts.RECEIVER_NAME)}>
                      {getFieldDecorator("recipientName", {
                        rules: [
                          {
                            required: true,
                            message: freire(texts.REQUIRED),
                          },
                        ],
                      })(<Input placeholder="" />)}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={
                        <Tooltip title={freire(texts.ANTICIPABLE_DESCRIPTION)}>
                          {"anticipatable_volume_percentage "}
                          <Icon type="question-circle-o" />
                        </Tooltip>
                      }
                    >
                      {getFieldDecorator("anticipatable_volume_percentage", {
                        initialValue: "0",
                        rules: [
                          {
                            required: true,
                            message: freire(texts.REQUIRED),
                          },
                        ],
                      })(<Input placeholder="" />)}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={
                        <Tooltip
                          title={freire(
                            texts.AUTOMATIC_ANTICIPATION_DESCRIPTION
                          )}
                        >
                          {"automatic_anticipation_enabled "}
                          <Icon type="question-circle-o" />
                        </Tooltip>
                      }
                    >
                      {getFieldDecorator("automatic_anticipation_enabled", {
                        initialValue: false,
                        valuePropName: "checked",
                        rules: [
                          {
                            required: true,
                            message: freire(texts.REQUIRED),
                          },
                        ],
                      })(
                        <Switch
                          checkedChildren={freire(texts.YES)}
                          unCheckedChildren={freire(texts.NO)}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={
                        <Tooltip title={freire(texts.TRANSFER_DAY_DESCRIPTION)}>
                          {"transfer_day "}
                          <Icon type="question-circle-o" />
                        </Tooltip>
                      }
                    >
                      {getFieldDecorator("transfer_day", {
                        initialValue: "0",

                        rules: [
                          {
                            required: true,
                            message: freire(texts.REQUIRED),
                          },
                        ],
                      })(<Input placeholder="" />)}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={
                        <Tooltip title={freire(texts.TRANSFER_ENABLED)}>
                          {"transfer_enabled "}
                          <Icon type="question-circle-o" />
                        </Tooltip>
                      }
                    >
                      {getFieldDecorator("transfer_enabled", {
                        initialValue: false,
                        valuePropName: "checked",
                        rules: [
                          {
                            required: true,
                            message: freire(texts.REQUIRED),
                          },
                        ],
                      })(
                        <Switch
                          checkedChildren={freire(texts.YES)}
                          unCheckedChildren={freire(texts.NO)}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 0]}>
                  <Col span={12}>
                    <Form.Item label={freire(texts.BANK_CODE)}>
                      {getFieldDecorator("bank_account.bank_code", {
                        rules: [
                          {
                            required: true,
                            message: freire(texts.REQUIRED),
                          },
                        ],
                      })(<Input placeholder="" />)}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={freire(texts.AGENCY)}>
                      {getFieldDecorator("bank_account.agencia", {
                        rules: [
                          {
                            required: true,
                            message: freire(texts.REQUIRED),
                          },
                        ],
                      })(<Input placeholder="" />)}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={freire(texts.AGENCY_CHECK_DIGIT)}>
                      {getFieldDecorator("bank_account.agencia_dv", {
                        rules: [
                          {
                            message: freire(texts.REQUIRED),
                          },
                        ],
                      })(<Input placeholder="" />)}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={freire(texts.ACCOUNT_NUMBER)}>
                      {getFieldDecorator("bank_account.conta", {
                        rules: [
                          {
                            required: true,
                            message: freire(texts.REQUIRED),
                          },
                        ],
                      })(<Input placeholder="" />)}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={freire(texts.ACCOUNT_TYPE)}>
                      {getFieldDecorator("bank_account.type", {
                        initialValue: "conta_corrente",
                        rules: [
                          {
                            required: true,
                            message: freire(texts.REQUIRED),
                          },
                        ],
                      })(
                        <Select>
                          <Option value="conta_corrente">
                            {freire(texts.CHECKING_ACCOUNT)}
                          </Option>
                          <Option value="conta_poupanca">
                            {freire(texts.SAVING_ACCOUNT)}
                          </Option>
                          <Option value="conta_corrente_conjunta">
                            {freire(texts.JOINT_CHECKING_ACCOUNT)}
                          </Option>
                          <Option value="conta_poupanca_conjunta">
                            {freire(texts.JOINT_SAVING_ACCOUNT)}
                          </Option>
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={freire(texts.ACCOUNT_VERIFICATION_DIGIT)}>
                      {getFieldDecorator("bank_account.conta_dv", {
                        rules: [
                          {
                            required: true,
                            message: freire(texts.REQUIRED),
                          },
                        ],
                      })(<Input placeholder="" />)}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={freire(texts.ACCOUNT_CPF_OR_CNPJ)}>
                      {getFieldDecorator("bank_account.document_number", {
                        rules: [
                          {
                            required: true,
                            message: freire(texts.REQUIRED),
                          },
                        ],
                      })(<Input placeholder="" />)}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={freire(texts.FULL_NAME_BUSINESS_NAME)}>
                      {getFieldDecorator("bank_account.legal_name", {
                        rules: [
                          {
                            required: true,
                            message: freire(texts.REQUIRED),
                          },
                        ],
                      })(<Input placeholder="" />)}
                    </Form.Item>
                  </Col>
                </Row>
              </Form>

              <div className="footer">
                <Button
                  loading={this.state.loadingSave}
                  onClick={this.salvar}
                  type="primary"
                >
                  {freire(texts.SAVE)}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const PageNewRecipient = Form.create({ name: "inputs" })(NewRecipient);
export default withRouter(PageNewRecipient);
