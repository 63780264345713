import React, { Fragment, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Form,
  Modal,
  notification,
  Row,
  Select,
  Table,
} from "antd";
import apiOcpp from "../../../../services/apiOcpp";
import { useFreire } from "../../../../utils/freireContext";
import * as texts from "../../locales";

const { Option } = Select;
const { Column } = Table;

const GetConfiguration = ({ stationId = "", form }) => {
  const { getFieldDecorator } = form;
  const [loading, setLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [configurationKey, setConfigurationKey] = useState([]);
  const [unknownKey, setUnknownKey] = useState([]);
  const { freire } = useFreire();

  const configsKey = [
    {
      name: "ConnectionTimeOut",
      type: "integer",
      description: freire(texts.SECONDS),
    },
    {
      name: "ResetRetries",
      type: "integer",
      description: freire(texts.QTD_OF_TIMES),
    },
    {
      name: "BlinkRepeat",
      type: "integer",
      description: freire(texts.QTD_OF_TIMES),
    },
    {
      name: "LightIntensity",
      type: "integer",
      description: freire(texts.PERCENTAGEM),
    },
    {
      name: "MeterValueSampleInterval",
      type: "integer",
      description: freire(texts.SECONDS),
    },
    {
      name: "ClockAlignedDataInterval",
      type: "integer",
      description: freire(texts.SECONDS),
    },
    {
      name: "MeterValuesSampledData",
      type: "string",
      description: freire(texts.COMMA_SEPARATED_LIST),
    },
    {
      name: "MeterValuesAlignedData",
      type: "string",
      description: freire(texts.COMMA_SEPARATED_LIST),
    },
    {
      name: "StopTxnSampledData",
      type: "string",
      description: freire(texts.COMMA_SEPARATED_LIST),
    },
    {
      name: "StopTxnAlignedData",
      type: "string",
      description: freire(texts.COMMA_SEPARATED_LIST),
    },
    {
      name: "HeartbeatInterval",
      type: "integer",
      description: freire(texts.SECONDS),
    },
    {
      name: "AllowOfflineTxForUnknownId",
      type: "boolean",
      description: freire(texts.BOOLEANO),
    },
    {
      name: "AuthorizationCacheEnabled",
      type: "boolean",
      description: freire(texts.BOOLEANO),
    },
    {
      name: "AuthorizeRemoteTxRequests",
      type: "boolean",
      description: freire(texts.BOOLEANO),
    },
    {
      name: "ConnectorPhaseRotation",
      type: "string",
      description: freire(texts.COMMA_SEPARATED_LIST),
    },
    {
      name: "ConnectorPhaseRotationMaxLength",
      type: "integer",
      description: freire(texts.INTEGER),
    },
    {
      name: "GetConfigurationMaxKeys",
      type: "integer",
      description: freire(texts.INTEGER),
    },
    {
      name: "LocalAuthorizeOffline",
      type: "boolean",
      description: freire(texts.BOOLEANO),
    },
    {
      name: "LocalPreAuthorize",
      type: "boolean",
      description: freire(texts.BOOLEANO),
    },
    {
      name: "MaxEnergyOnInvalidId",
      type: "integer",
      description: freire(texts.IN_KH),
    },
    {
      name: "MeterValuesAlignedDataMaxLength",
      type: "integer",
      description: freire(texts.INTEGER),
    },
    {
      name: "MeterValuesSampledDataMaxLength",
      type: "integer",
      description: freire(texts.INTEGER),
    },
    {
      name: "MinimumStatusDuration",
      type: "integer",
      description: freire(texts.SECONDS),
    },
    {
      name: "NumberOfConnectors",
      type: "integer",
      description: freire(texts.INTEGER),
    },
    {
      name: "StopTransactionOnEVSideDisconnect",
      type: "boolean",
      description: freire(texts.BOOLEANO),
    },
    {
      name: "StopTransactionOnInvalidId",
      type: "boolean",
      description: freire(texts.BOOLEANO),
    },
    {
      name: "StopTxnAlignedDataMaxLength",
      type: "integer",
      description: freire(texts.INTEGER),
    },
    {
      name: "StopTxnSampledDataMaxLength",
      type: "integer",
      description: freire(texts.INTEGER),
    },
    {
      name: "SupportedFeatureProfiles",
      type: "string",
      description: freire(texts.COMMA_SEPARATED_LIST),
    },
    {
      name: "SupportedFeatureProfilesMaxLength",
      type: "integer",
      description: freire(texts.INTEGER),
    },
    {
      name: "TransactionMessageAttempts",
      type: "integer",
      description: freire(texts.QTD_OF_TIMES),
    },
    {
      name: "TransactionMessageRetryInterval",
      type: "integer",
      description: freire(texts.SECONDS),
    },
    {
      name: "UnlockConnectorOnEVSideDisconnect",
      type: "boolean",
      description: freire(texts.BOOLEANO),
    },
    {
      name: "WebSocketPingInterval",
      type: "integer",
      description: freire(texts.SECONDS),
    },
    {
      name: "LocalAuthListEnabled",
      type: "boolean",
      description: freire(texts.BOOLEANO),
    },
    {
      name: "LocalAuthListMaxLength",
      type: "integer",
      description: freire(texts.INTEGER),
    },
    {
      name: "SendLocalListMaxLength",
      type: "integer",
      description: freire(texts.INTEGER),
    },
    {
      name: "ReserveConnectorZeroSupported",
      type: "boolean",
      description: freire(texts.BOOLEANO),
    },
    {
      name: "ChargeProfileMaxStackLevel",
      type: "integer",
      description: freire(texts.INTEGER),
    },
    {
      name: "ChargingScheduleAllowedChargingRateUnit",
      type: "string",
      description: freire(texts.COMMA_SEPARATED_LIST),
    },
    {
      name: "ChargingScheduleMaxPeriods",
      type: "integer",
      description: freire(texts.INTEGER),
    },
    {
      name: "ConnectorSwitch3to1PhaseSupported",
      type: "boolean",
      description: freire(texts.BOOLEANO),
    },
    {
      name: "MaxChargingProfilesInstalled",
      type: "integer",
      description: freire(texts.INTEGER),
    },
  ];

  const send = () => {
    form.validateFields(async (err, payload) => {
      if (err) {
        return;
      }

      try {
        setLoading(true);
        setConfigurationKey([]);
        setUnknownKey([]);

        const { data: result } = await apiOcpp.post(
          `/ocpp16/getConfiguration/${stationId}`,
          { key: payload.key }
        );

        if (result) {
          if (result.configurationKey) {
            setConfigurationKey(result.configurationKey);
          }
          if (result.unknownKey) {
            setUnknownKey(result.unknownKey);
          }

          setVisibleModal(true);
        } else {
          throw new Error();
        }
      } catch (error) {
        notification.error({
          message: freire(texts.OOPS),
          description: freire(texts.VERIFY_AGAIN),
        });
      } finally {
        setLoading(false);
      }
    });
  };

  return (
    <Fragment>
      <Modal
        width={"70%"}
        title={`Get Configuration (${stationId})`}
        centered
        maskClosable={false}
        closable={false}
        visible={visibleModal}
        footer={[
          <Button key="back" onClick={() => setVisibleModal(false)}>
            {freire(texts.CLOSE)}
          </Button>,
        ]}
      >
        {unknownKey && unknownKey.length > 0 ? (
          <Alert
            showIcon
            style={{ marginBottom: 24 }}
            message={freire(texts.CONFIGURATION_NOT_FINDED)}
            description={unknownKey.join(", ")}
            type="warning"
          />
        ) : null}
        <Table
          scroll={{ y: "60vh" }}
          pagination={false}
          size="middle"
          dataSource={configurationKey}
        >
          <Column
            title={freire(texts.CONFIGURATION)}
            dataIndex="key"
            key="key"
          />
          <Column
            align="center"
            title={freire(texts.VALUE)}
            dataIndex="value"
            key="value"
            render={(value) => (
              <span>{value !== undefined ? String(value) : ""}</span>
            )}
          />
          <Column
            align="center"
            title={freire(texts.READ_ONLY_QUESTION)}
            dataIndex="readonly"
            key="readonly"
            render={(readonly) => (
              <span>{readonly !== undefined ? String(readonly) : ""}</span>
            )}
          />
        </Table>
      </Modal>

      <Row gutter={[16, 24]}>
        <Col span={24}>
          <Alert
            message={freire(texts.NO_CONFIGURATION_ALERT)}
            type="info"
            showIcon
          />
        </Col>
      </Row>
      <Form layout="vertical" wrapperCol={{ span: 24 }}>
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Form.Item label={freire(texts.CONFIGURATION)}>
              {getFieldDecorator("key")(
                <Select
                  showSearch
                  allowClear
                  mode="tags"
                  tokenSeparators={[","]}
                  optionLabelProp="label"
                  placeholder={freire(texts.SELECT_ONE_OR_MORE_CONFIGURATION)}
                  filterOption={(input, option) => {
                    return (
                      option.props.value
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  {configsKey.map((config) => (
                    <Option
                      label={config.name}
                      key={config.name}
                      value={config.name}
                    >
                      {config.name}
                      <br />
                      <span style={{ fontSize: 12.5, opacity: 0.6 }}>
                        ({config.description})
                      </span>
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        <Button
          disabled={!stationId || loading}
          loading={loading}
          onClick={send}
          type="primary"
        >
          <span>
            {freire(texts.EXECUTE)}{" "}
            <span style={{ fontWeight: "bold" }}> GetConfiguration</span>
          </span>
        </Button>
      </div>
    </Fragment>
  );
};

const PageGetConfiguration = Form.create({ name: "inputs" })(GetConfiguration);

export default PageGetConfiguration;
