import React, { Fragment, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  notification,
  Row,
} from "antd";
import moment from "moment-timezone";
import apiOcpp from "../../../../services/apiOcpp";
import { useFreire } from "../../../../utils/freireContext";
import * as texts from "../../locales";

const UpdateFirmware = ({ stationId = "", redirectTo = () => {}, form }) => {
  const { getFieldDecorator } = form;
  const [loading, setLoading] = useState(false);
  const { freire } = useFreire();

  const handlerErrorMsg = (error) => {
    let errorTitle = freire(texts.OOPS);
    let errorMessage = freire(texts.VERIFY_AGAIN);

    try {
      if (error?.response?.data) {
        const { errorTupi, title, message } = error.response.data;

        if (errorTupi && message) {
          errorMessage = message;

          if (title) errorTitle = title;
        }
      }
    } catch (error) {}

    return [errorTitle, errorMessage];
  };

  const send = () => {
    form.validateFields(async (err, payload) => {
      if (err) {
        return;
      }

      // console.log("payload ->", payload);
      // return;

      try {
        setLoading(true);
        const { data } = await apiOcpp.post(
          `/ocpp16/updateFirmware/${stationId}`,
          payload
        );

        setLoading(false);

        if (data && data.result) {
          if (data.result === "Accepted") {
            notification.success({
              message: freire(texts.ALL_RIGHT),
              description: `${freire(texts.STATION_RESPONSE)} '${data.result}'`,
            });
            notification.success({
              message: freire(texts.ALL_RIGHT),
              description: freire(texts.FALLOW_LOGS),
              duration: 10,
            });
            redirectTo("/dashboard/ocpp/logs");
          } else {
            notification.error({
              message: freire(texts.OOPS),
              description: `${freire(texts.STATION_RESPONSE)} '${data.result}'`,
            });
          }
        } else {
          throw new Error();
        }
      } catch (error) {
        setLoading(false);
        const [message, description] = handlerErrorMsg(error);
        notification.error({ message, description });
      }
    });
  };

  return (
    <Fragment>
      <Form layout="vertical" wrapperCol={{ span: 24 }}>
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Form.Item label={freire(texts.URL_TO_DOWNLOAD_FIRMWARE)}>
              {getFieldDecorator("location", {
                rules: [
                  {
                    required: true,
                    message: freire(texts.REQUIRED),
                  },
                ],
              })(
                <Input
                  placeholder={freire(texts.URL_TO_DOWNLOAD_FIRMWARE_EX)}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Form.Item label={freire(texts.DATE_TIME_TO_ATT)}>
              {getFieldDecorator("retrieveDate", {
                rules: [
                  {
                    required: true,
                    message: freire(texts.REQUIRED),
                  },
                  {
                    validator: async (rule, value, callback) => {
                      try {
                        if (value && moment().isAfter(value)) {
                          throw new Error(
                            freire(texts.INSERT_DATE_TIME_IN_FUTURE)
                          );
                        }
                      } catch (err) {
                        callback(err);
                      }
                    },
                  },
                ],
              })(
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder={freire(texts.SELECT_DATE_TIME)}
                  format="L LT"
                  disabledDate={(current) =>
                    current &&
                    current < moment().subtract(1, "day").endOf("day")
                  }
                  showTime={{
                    format: "HH:mm",
                    defaultValue: moment().endOf("minute"),
                  }}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Form.Item label={freire(texts.NUMBER_OF_ATTEMPTS)}>
              {getFieldDecorator("retries")(
                <InputNumber
                  style={{ width: "100%" }}
                  min={1}
                  placeholder={freire(texts.NUMBER_EX)}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Form.Item label={freire(texts.INTERVAL_IN_SECONDS_ATTEMPTS)}>
              {getFieldDecorator("retryInterval")(
                <InputNumber
                  style={{ width: "100%" }}
                  min={1}
                  placeholder={freire(texts.NUMBER_EX)}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        <Button
          disabled={!stationId || loading}
          loading={loading}
          onClick={send}
          type="primary"
        >
          <span>
            {freire(texts.EXECUTE)}{" "}
            <span style={{ fontWeight: "bold" }}> UpdateFirmware</span>
          </span>
        </Button>
      </div>
    </Fragment>
  );
};

const PageUpdateFirmware = Form.create({ name: "inputs" })(UpdateFirmware);

export default PageUpdateFirmware;
