import React from "react";
import { CSVLink } from "react-csv";

import * as texts from "./locales";
import { useFreire } from "../../utils/freireContext";

const CsvDownload = ({
  fileName,
  linkText,
  loading,
  csvLinkRef,
  onClick,
  headers,
  data,
}) => {
  const { freire } = useFreire();

  return (
    <div style={{ alignSelf: "center" }}>
      <div style={{ display: "none" }}>
        <CSVLink
          ref={(ref) => (csvLinkRef.current = ref)}
          filename={fileName}
          headers={headers}
          data={data}
        />
      </div>

      {loading ? (
        <span>{freire(texts.LOADING_CSV)}...</span>
      ) : (
        <span
          style={{
            color: "#1890ff",
            cursor: "pointer",
            textDecorationLine: "underline",
          }}
          onClick={onClick}
        >
          {linkText}
        </span>
      )}
    </div>
  );
};

export default CsvDownload;
