import React, { useState } from "react";
import { Modal, Button, Input, InputNumber, notification, Upload, Icon } from "antd";
import api from "../../../services/api";
import { useFreire } from "../../../utils/freireContext";
import * as texts from "../locales";
import * as XLSX from "xlsx";
import { autoLinkCouponType } from "../NewCoupons/Forms/EligibleUsers";
import { wordsToExcludeFromCouponSheet } from "../utils";

const { Dragger } = Upload;

const MAX_QUANTITY = 20;
const ModalAddCoupon = ({ visible, onClose, campaignId, onSuccess, codeType }) => {
  const [showQuantityInput, setShowQuantityInput] = useState(true);
  const [quantity, setQuantity] = useState(0);
  const [customCoupons, setCustomCoupons] = useState([""]);
  const [sheetFileList, setSheetFileList] = useState([]);

  const { freire } = useFreire();

  const couponCodeType = codeType === autoLinkCouponType.CHASSI ? freire(texts.CHASSI) : codeType === autoLinkCouponType.EMAIL ? freire(texts.EMAIL) : freire(texts.COUPONS);

  const okCoupons = customCoupons.filter((code) => code !== "");
  const [loading, setLoading] = useState(false);

  const resetState = (showQuantityInput = true) => {
    setShowQuantityInput(showQuantityInput);
    setQuantity(0);
    setCustomCoupons([""]);
    setSheetFileList([]);
  };

  const handleModalClose = () => {
    resetState();
    onClose();
  };

  const handleAdd = async () => {
    if (!campaignId) {
      return;
    }
    setLoading(true);
    try {
      let body;
      if (showQuantityInput && quantity > 0) {
        body = {
          codQuantity: quantity,
        };
      } else if (okCoupons.length > 0) {
        setCustomCoupons(okCoupons);
        body = {
          customCoupons: okCoupons,
        };
      }

      if (!body) {
        const error = new Error("Adicione pelo menos 1 cupom");
        error.showErrorMessage = true;
        throw error;
      }

      await api.post(`/campaign/${campaignId}/coupon`, body);

      notification.success({
        message: "Sucesso",
        description: `Cupom adicionado com sucesso!`,
      });
      onSuccess?.();
    } catch (error) {
      notification.error({
        message: "Erro",
        description:
          error?.response?.data?.message ||
          (error.showErrorMessage
            ? error.message
            : "Falha ao adicionar cupom."),
      });
    }
    setLoading(false);
    handleModalClose();
  };

  const handleByQuantity = () => {
    resetState(true);
  };

  const handleCustomCode = () => {
    resetState(false);
  };

  const handleQuantityChange = (quantity) => {
    const _quantity =
      typeof quantity === "number"
        ? Math.min(Math.max(0, quantity), MAX_QUANTITY)
        : 0;
    setQuantity(_quantity);
  };

  const handleCodeChange = (index, value) => {
    setCustomCoupons((customCoupons) => {
      const updatedCodes = [...customCoupons];
      updatedCodes[index] = value.toUpperCase();
      return updatedCodes;
    });
  };

  const splitMultipleCodes = () => {
    setCustomCoupons((customCoupons) => {
      return customCoupons
        .map((customCoupon) => {
          return customCoupon
            .replace(/,{2,}/g, ",")
            .replace(/^,/g, "")
            .replace(/,$/g, "")
            .split(",");
        })
        .flat()
        .map((code) => code.trim());
    });
  };

  const addCustomCode = () => {
    splitMultipleCodes();
    setCustomCoupons((customCoupons) => {
      const updatedCodes = [...customCoupons, ""];
      return updatedCodes;
    });
  };

  const removeCustomCode = (index) => {
    setCustomCoupons((customCoupons) => {
      const updatedCodes = [...customCoupons];
      if (updatedCodes.length !== 1) {
        updatedCodes.splice(index, 1);
      }
      return updatedCodes;
    });
  };

  const canAddCustomCode = customCoupons.length < MAX_QUANTITY;

  const handleCodeEnter = (index, e) => {
    if (e.key === "Enter") {
      splitMultipleCodes();
      if (canAddCustomCode && index === customCoupons.length - 1) {
        addCustomCode();
      }
    }
  };

  const handleAddCouponsSheet = async (file) => {
    setSheetFileList([...sheetFileList ,file]);

    const data = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = (e) => resolve(e.target.result);
      reader.onerror = (e) => reject(e.target.error);
    });

    const workbook = XLSX.read(data, { type: "binary" });
    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];
    const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

    const coupons = [...new Set(sheetData
      .map((row) => {
        if (codeType === autoLinkCouponType.CHASSI) {
          return String(row[0]).toUpperCase().replace(/[^a-zA-Z0-9]/g, '').trim();
        } else {
          return String(row[0]).toUpperCase().trim();
        }
      })
      .filter((cod) => !cod.match(new RegExp('undefined', 'i')))
      .filter((cod) => {
        if (codeType === autoLinkCouponType.CHASSI) {
          return !new RegExp(wordsToExcludeFromCouponSheet.join('|'), 'gi').test(cod);
        }

        if (codeType === autoLinkCouponType.EMAIL) {
          return new RegExp('@', 'gi').test(cod);
        }

        return true;
      }))];

    if (coupons.length > 0) {
      setCustomCoupons(coupons);
    }
  }

  return (
    <Modal
      centered
      width={"30%"}
      maxWidth={700}
      visible={visible}
      onCancel={handleModalClose}
      maskClosable={true}
      maskStyle={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      bodyStyle={{ padding: "20px" }}
      footer={null}
    >
      <div
        style={{
          color: "red",
          textTransform: "uppercase",
          fontWeight: "bold",
          marginBottom: "20px",
          textAlign: "start",
        }}
      >
        {freire(texts.ADD_COUPONS)}
      </div>
      {codeType === autoLinkCouponType.CHASSI || codeType === autoLinkCouponType.EMAIL ? (
        <>
          <label>* {freire(texts.ADD_COUPONS_SHEET(couponCodeType))}</label>
          <Dragger
            name="file"
            multiple={false}
            beforeUpload={(file) => {
              handleAddCouponsSheet(file);
              return false;
            }}
            fileList={sheetFileList}
            >
            <p className="ant-upload-text">
              <Icon type="upload" />
              {freire(texts.CLICK_OR_DRAG_HERE)}
            </p>
          </Dragger>
        </>
      ) : (
        <>
          <div style={{ marginBottom: "20px", textAlign: "center" }}>
            <Button
              style={{
                background: showQuantityInput ? "#1890FF" : "white",
                color: showQuantityInput ? "white" : "black",
                width: "45%",
                height: "40px",
              }}
              onClick={handleByQuantity}
              disabled={loading}
            >
              {freire(texts.PER_QUANTITY)}
            </Button>
            <Button
              style={{
                background: !showQuantityInput ? "#1890FF" : "white",
                color: !showQuantityInput ? "white" : "black",
                width: "50%",
                height: "40px",
                marginLeft: "10px",
              }}
              onClick={handleCustomCode}
              disabled={loading}
            >
              {freire(texts.CUSTOM_CODE)}
            </Button>
          </div>
          {showQuantityInput ? (
            <div style={{ marginBottom: "20px", marginLeft: "14px" }}>
              <span style={{ color: "red" }}>*</span>{" "}
              {freire(texts.REPORT_QUANTITY)}:
              {/* <div>
                <pre>{JSON.stringify({ quantity }, undefined, 2)}</pre>
              </div> */}
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <InputNumber
                  type="number"
                  min={0}
                  max={MAX_QUANTITY}
                  value={quantity === 0 ? "" : quantity.toString()}
                  onChange={handleQuantityChange}
                  onPressEnter={handleAdd}
                  formatter={() => (quantity === 0 ? "" : quantity.toString())}
                  placeholder="Ex. 50"
                  style={{ width: "40%" }}
                  autoFocus
                  disabled={loading}
                />
              </div>
            </div>
          ) : (
            <div style={{ marginBottom: "20px", marginLeft: "14px" }}>
              <span>
                <span style={{ color: "red" }}>*</span>{" "}
                {freire(texts.CODES_SPLITED_BY_COMMA)}:
              </span>
              {/* <div>
                <pre>
                  {JSON.stringify({ customCoupons, okCoupons }, undefined, 2)}
                </pre>
              </div> */}
              {customCoupons.map((code, index, array) => (
                <div key={index} style={{ marginTop: "10px", display: "flex" }}>
                  <Input
                    value={code}
                    onChange={(e) => handleCodeChange(index, e.target.value)}
                    onBlur={splitMultipleCodes}
                    onKeyDown={(e) => handleCodeEnter(index, e)}
                    style={{ width: "90%" }}
                    placeholder="Ex. TUPINATAL"
                    addonBefore={index + 1}
                    autoFocus
                  />
                  <Button
                    style={{ marginLeft: "8px" }}
                    onClick={() => removeCustomCode(index)}
                    type="danger"
                    icon="delete"
                    disabled={array.length === 1 || loading}
                  />
                </div>
              ))}

              <Button
                type="link"
                onClick={addCustomCode}
                style={{ color: "#1890FF", textDecoration: "underline" }}
                disabled={!canAddCustomCode || loading}
              >
                + {freire(texts.INCLUDE_NEW_CODE)}
              </Button>
            </div>
          )}
        </>
      )}
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Button
          onClick={handleModalClose}
          style={{ marginRight: "10px", width: "120px", height: "30px" }}
        >
          {freire(texts.CANCEL)}
        </Button>
        <Button
          type="primary"
          onClick={handleAdd}
          style={{ width: "120px", height: "30px" }}
          disabled={loading}
        >
          {freire(texts.ADD)}
        </Button>
      </div>
    </Modal>
  );
};

export default ModalAddCoupon;
