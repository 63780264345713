export const ALL_RIGHT = {
  pt: "Tudo certo!",
  es: "¡Muy bien!",
  en: "All set!",
};

export const CAMPAIGN_EDITED_SUCCESS = {
  pt: "Campanha editada com sucesso!",
  es: "¡Campaña editada con éxito!",
  en: "Campaign successfully edited!",
};

export const OOPS = {
  pt: "Ops...",
  es: "Epa...",
  en: "Ops...",
};

export const ERROR_EDIT_CAMPAIGN = {
  pt: "Erro ao editar campanha",
  es: "Error en la edición de la campaña",
  en: "Error editing campaign",
};

export const PUBLICITY_DELETED = {
  pt: "Publicidade deletada.",
  es: "Publicidad eliminada.",
  en: "Deleted publicity.",
};

export const WENT_WONG = {
  pt: "Algo deu errado!",
  es: "¡Algo salió mal!",
  en: "Something went wrong!",
};

export const ADMIN = {
  pt: "Admin",
  es: "Administración",
  en: "Admin",
};

export const PUBLICITY = {
  pt: "Publicidade",
  es: "Publicidad",
  en: "Publicity",
};

export const ADD_PUBLICITY = {
  pt: "Adicione uma publicidade",
  es: "Añadir publicidad",
  en: "Add a publicity",
};

export const SEARCH_BY_NAME = {
  pt: "Pesquise por nome...",
  es: "Buscar por nombre...",
  en: "Search by name...",
};

export const SEARCH = {
  pt: "Pesquisar",
  es: "Buscar",
  en: "Search",
};

export const NEW = {
  pt: "Novo",
  es: "Nuevo",
  en: "New",
};

export const ENABLED = {
  pt: "Habilitada",
  es: "Activado",
  en: "Enabled",
};

export const YES = {
  pt: "SIM",
  es: "SI",
  en: "YES",
};

export const NO = {
  pt: "NÃO",
  es: "NO",
  en: "NO",
};

export const CLEAR = {
  pt: "Limpar",
  es: "Borrar",
  en: "Clear",
};

export const FILTER = {
  pt: "Filtrar",
  es: "Filtro",
  en: "Filter",
};

export const NAME = {
  pt: "Nome",
  es: "Nombre",
  en: "Name",
};

export const NAME_EX = {
  pt: "Nome da campanha",
  es: "Nombre de la campaña",
  en: "Campaign name",
};

export const REQUIRED_FIELD = {
  pt: "Campo obrigatório",
  es: "Campo obligatorio",
  en: "Mandatory field",
};

export const IMAGE = {
  pt: "Imagem",
  es: "Imagen",
  en: "Image",
};

export const DRAG_HERE = {
  pt: "Clique ou arraste a imagem até aqui",
  es: "Haga clic o arrastre la imagen hasta aquí",
  en: "Click or drag the image here",
};

export const ERROR_LOAD_IMAGE = {
  pt: "Erro ao carregar imagem. Tente abrir o link.",
  es: "Error al cargar la imagen. Intenta abrir el enlace.",
  en: "Error loading image. Try to open the link.",
};

export const IMAGE_CAMPAING = {
  pt: "Imagem da campanha",
  es: "Imagen de la campaña",
  en: "Campaign image",
};

export const ACTION_URL = {
  pt: "URL de Ação",
  es: "URL de acción",
  en: "Action URL",
};

export const ACTION_URL_EX = {
  pt: "Ex.: https://www.site.com/campanha",
  es: "p.ej.: https://www.site.com/campana",
  en: "Example: https://www.website.com/campaign",
};

export const START_DATE_TIME = {
  pt: "Data/hora de início",
  es: "Fecha/hora de inicio",
  en: "Start date/time",
};

export const END_DATE_TIME = {
  pt: "Data/hora de término",
  es: "Fecha/hora de finalización",
  en: "End date/time",
};

export const INTERVAL_DAYS = {
  pt: "Repete a cada:",
  es: "Repite cada vez:",
  en: "Repeat every:",
};

export const INFORM_QTD_DAYS = {
  pt: "Informe a quantidade em dias",
  es: "Informar la cantidad en días",
  en: "Inform quantity days",
};

export const CAMPAIGN_RESUME_SENDS = {
  pt: "Resumo dos envios da campanha",
  es: "Resumen de los envíos de la campaña",
  en: "Summary of campaign submissions",
};

export const FIVE_DAYS_MINIMUM = {
  pt: "* Deve ser pelo menos 5 dias.",
  es: "* Debe ser 5 días como mínimo.",
  en: "* Must be at least 5 days.",
};

export const INSERT_DATE_TIME_TO_SEND = {
  pt: "Insira uma data/hora após a data de envio",
  es: "Introduzca una fecha/hora posterior a la fecha de envío",
  en: "Insert a date/time after the submission date",
};

export const ONLY_ONE_TIME = {
  pt: "Somente uma vez",
  es: "Sólo una vez",
  en: "Only once",
};

export const IN_PERIOD = {
  pt: "Durante um período",
  es: "Durante un periodo",
  en: "During a period",
};

export const SHOW_CAMPAIGN = {
  pt: "Exibir campanha",
  es: "Mostrar campaña",
  en: "Show campaign",
};

export const ACTIONS = {
  pt: "Ações",
  es: "Acciones",
  en: "Actions",
};

export const SHOW_DETAILS_EDIT = {
  pt: "Ver detalhes | Editar",
  es: "Ver detalles | Editar",
  en: "See details | Edit",
};

export const SHOW_STATS = {
  pt: "Ver estatísticas",
  es: "Ver estadísticas",
  en: "See statistics",
};

export const DELETE = {
  pt: "Deletar",
  es: "Borrar",
  en: "Delete",
};

export const CONFIRM_DELETE = {
  pt: "Deseja realmente DELETAR?",
  es: "¿Realmente desea BORRAR?",
  en: "Do you really want to DELETE?",
};

export const EDIT_PUBLICITY_CAMPAIGN = {
  pt: "Editar campanha de publicidade",
  es: "Editar campaña de publicidad",
  en: "Edit publicity campaign",
};

export const CANCEL = {
  pt: "Cancelar",
  es: "Cancelar",
  en: "Cancel",
};

export const SAVE = {
  pt: "Salvar",
  es: "Guardar",
  en: "Save",
};

export const VIEWS = {
  pt: "Exibições",
  es: "Exposiciones",
  en: "Views",
};

export const URL_CLICKS = {
  pt: "Cliques (URL)",
  es: "Clics (URL)",
  en: "Clicks (URL)",
};

export const CAMPAIGN_CSV_FILE_NAME = (publicityName) => ({
  pt: `${publicityName || "campanha"}.csv`,
  es: `${publicityName || "campaña"}.csv`,
  en: `${publicityName || "campaign"}.csv`,
});

export const DOWNLOAD_IN_CSV = {
  pt: "Baixar em Excel",
  es: "Descargar en Excel",
  en: "Download in Excel",
};

export const UPDATE = {
  pt: "Atualizar",
  es: "Actualización",
  en: "Update",
};

export const LOADING = {
  pt: "Carregando ...",
  es: "Cargando ...",
  en: "Charging...",
};

export const ACTION_URL_CLICK = {
  pt: "Cliques na URL de ação",
  es: "Clics en la URL de acción",
  en: "Action URL Clicks",
};

export const NEXT_UPLOAD = {
  pt: "Próximo envio",
  es: "Próximo envío",
  en: "Next submission",
};

export const REMAINING_TIME_TO_NEXT = {
  pt: "Tempo restante para o próximo envio",
  es: "Tiempo restante para el próximo envío",
  en: "Remaining time to the next submission",
};

export const CLOSE = {
  pt: "Fechar",
  es: "Cerrar",
  en: "Close",
};

export const ONLY_JPG_PNG_CAN_UPLOAD = {
  pt: "Você só pode fazer upload de imagens do tipo JPG ou PNG!",
  es: "¡Solo puedes subir imágenes de tipo JPG o PNG!",
  en: "You may only upload images of JPG or PNG type!",
};

export const UPLOAD_SUCCESS = {
  pt: "Upload concluido!",
  es: "¡Carga completa!",
  en: "Concluded upload!",
};

export const ERROR_UPLOAD = {
  pt: "Erro ao fazer upload",
  es: "Error al cargar",
  en: "Error uploading",
};

export const PUBLICITY_CREATED_SUCCESS = {
  pt: "Publicidade criada com sucesso!",
  es: "¡Publicidad creada con éxito!",
  en: "Publicity successfully created!",
};

export const INSET_DATE_IN_FUTURE = {
  pt: "Insira uma data/hora no futuro",
  es: "Introduzca una fecha/hora en el futuro",
  en: "Insert a date/time in future",
};

export const DATE_TIME_TO_SHOW = (sendMode) => ({
  pt: `Data/hora ${sendMode === 1 ? "para exibir" : "de início"}`,
  es: `Fecha/hora ${sendMode === 1 ? "para exhibir" : "de inicio"}`,
  en: `Date/time ${sendMode === 1 ? "para exibir" : "de início"}`,
});

export const SELECT_DATE_TIME = {
  pt: "Selecione data/hora",
  es: "Seleccione la fecha/hora",
  en: "Select date/time",
};
