import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Radio,
  Row,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import Breadcrumbs from "../../../components/Breadcrumbs";
import TablePagination, {
  paginationInitConfig,
} from "../../../components/TablePagination";
import { Show } from "../../../contexts/PermissionContext";
import api from "../../../services/api";
import EditDrawer from "./EditDrawer";
import "./index.css";
import { getInitialColumns } from "./initiallState";
import RfidTagsDrawer from "./RfidTagsDrawer";
import { useFreire } from "../../../utils/freireContext";
import * as texts from "../locales";
import { useOnUpdate } from "../../../hooks";

const { Option } = Select;

const ListRfidCampaigns = ({ form, history }) => {
  const { freire } = useFreire();

  const [currentCampaign, setCurrentCampaign] = useState({});

  const [loadingTable, setLoadingTable] = useState(false);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [loadingDrawer, setLoadingDrawer] = useState(false);

  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [visibleRfidTagsDrawer, setVisibleRfidTagsDrawer] = useState(false);

  const [companies, setCompanies] = useState([]);
  const [columns] = useState(
    getInitialColumns({
      handleClickDelete: (rfidCampaign) => deleteRfidCampaign(rfidCampaign),
      handleClickEdit: (rfidCampaign) => {
        setVisibleDrawer(true);
        setCurrentCampaign(rfidCampaign);
      },
      handleClickTagRFID: (rfidCampaign) => {
        setVisibleRfidTagsDrawer(true);
        setCurrentCampaign(rfidCampaign);
      },
      freire,
    })
  );

  const [campaignsList, setCampaignsList] = useState([]);
  const [totalCampaigns, setTotalCampaigns] = useState(0);
  const [pagination, setPagination] = useState(paginationInitConfig);

  useOnUpdate(() => {
    filter(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  useEffect(() => {
    getCampaigns();
    getCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateRfidCampaign = async (data) => {
    try {
      setLoadingDrawer(true);

      await api.put(`rfid-campaign/${currentCampaign._id}`, data);

      setLoadingDrawer(false);
      setVisibleDrawer(false);

      notification.success({
        message: freire(texts.ALL_RIGHT),
        description: freire(texts.CAMPAIGN_EDITED),
      });

      getCampaigns();
    } catch (error) {
      notification.error({
        message: freire(texts.OOPS),
        description: freire(texts.ERROR_EDITED_CAMPAIGN),
      });
      setLoadingDrawer(false);
    }
  };

  const deleteRfidCampaign = async (campaign) => {
    try {
      setLoadingTable(true);

      await api.delete(`rfid-campaign/${campaign._id}`);

      setLoadingTable(false);

      notification.success({
        message: freire(texts.ALL_RIGHT),
        description: freire(texts.CAMPAIGN_DELETED_SUCCESS),
      });

      getCampaigns();
    } catch (error) {
      notification.error({
        message: freire(texts.OOPS),
        description: freire(texts.ERROR_DELETE_CAMPAIGN),
      });
      setLoadingTable(false);
    }
  };

  const getCompanies = async () => {
    try {
      setLoadingCompanies(true);

      const { data: companies } = await api.get("company");

      setCompanies(companies);
    } catch (error) {
      setCompanies([]);
    } finally {
      setLoadingCompanies(false);
    }
  };

  const getCampaigns = async () => {
    try {
      setLoadingTable(true);
      const {
        data: { docs, totalDocs },
      } = await api.get(`rfid-campaigns`, {
        params: {
          ...form.getFieldsValue(),
          ...pagination,
        },
      });

      setCampaignsList(docs);
      setTotalCampaigns(totalDocs);
    } catch (error) {
      notification.error({
        message: freire(texts.OOPS),
        description: freire(texts.ERROR_GET_CAMPAIGNS),
      });
    } finally {
      setLoadingTable(false);
    }
  };

  const filter = (byButton = true) => {
    form.validateFields((err, values) => {
      if (!values.name) values.name = undefined;

      if (!err) {
        getCampaigns(values);
      }

      if (byButton) {
        setPagination((prevState) => ({ ...prevState, page: 1 }));
      }
    });
  };

  const clearFilters = () => {
    form.resetFields();
    setCampaignsList([]);
    filter();
  };

  const goNew = () => {
    history.push("/dashboard/new-rfid-campaign");
  };

  return (
    <div className="container">
      <Breadcrumbs
        breadcrumbs={[freire(texts.ADMIN), freire(texts.TAGS_RFID)]}
      />
      <div className="filter">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            filter();
          }}
          wrapperCol={{ span: 24 }}
        >
          <Form.Item>
            <div style={{ flexDirection: "row", display: "flex" }}>
              {form.getFieldDecorator("name")(
                <Input
                  onSubmit={filter}
                  className="firstInput"
                  placeholder={freire(texts.SEARCH_CAMPAIGN)}
                />
              )}
              <Button icon="search" className="buttonSearch" onClick={filter}>
                {freire(texts.SEARCH)}
              </Button>
              <Show when={"add-tags-rfid"}>
                <Button
                  icon="plus"
                  className="buttonNew"
                  onClick={goNew}
                  type="primary"
                >
                  {freire(texts.NEW)}
                </Button>
              </Show>
            </div>
          </Form.Item>

          <Row gutter={[16, 8]}>
            <Show when={"filter-by-company-rfid-tags"}>
              <Col span={6}>
                <Form.Item label={freire(texts.COMPANY)}>
                  {form.getFieldDecorator("company")(
                    <Select
                      loading={loadingCompanies}
                      onFocus={() => getCompanies()}
                      placeholder={freire(texts.SELECT_COMPANY)}
                      allowClear
                    >
                      {companies.map((company) => {
                        return (
                          <Option key={company._id} value={company._id}>
                            {company.companyName}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Show>
            <Col span={6}>
              <Form.Item label={freire(texts.CLIENT)}>
                {form.getFieldDecorator("clientName")(
                  <Input placeholder={freire(texts.INFORM_CLIENT_NAME)} />
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={freire(texts.SHOW)}>
                {form.getFieldDecorator("enabled")(
                  <Radio.Group buttonStyle="outline">
                    <Radio.Button value={undefined}>
                      {freire(texts.ALL)}
                    </Radio.Button>
                    <Radio.Button value={true}>
                      {freire(texts.ACTIVED)}
                    </Radio.Button>
                    <Radio.Button value={false}>
                      {freire(texts.DESACTIVED)}
                    </Radio.Button>
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <div className="footer">
          <Button style={{ marginRight: 10 }} onClick={clearFilters}>
            {freire(texts.CLEAR)}
          </Button>
          <Button onClick={filter} type="primary">
            {freire(texts.FILTER)}
          </Button>
        </div>
      </div>

      <TablePagination
        style={{ backgroundColor: "white" }}
        loading={loadingTable}
        rowKey={(data) => data._id}
        dataSource={campaignsList}
        columns={columns}
        total={totalCampaigns}
        onPagination={(pagination) => {
          setPagination(pagination);
        }}
        scroll={{ x: 1300 }}
        tableLayout="auto"
        current={pagination.page}
      />

      <EditDrawer
        visible={visibleDrawer}
        loading={loadingDrawer}
        onClose={() => setVisibleDrawer(false)}
        onSave={(values) => updateRfidCampaign(values)}
        campaign={currentCampaign}
        companies={companies}
      />

      <RfidTagsDrawer
        visible={visibleRfidTagsDrawer}
        onClose={() => setVisibleRfidTagsDrawer(false)}
        campaign={currentCampaign}
      />
    </div>
  );
};
const PageListRfidCampaigns = Form.create({ name: "filters" })(
  ListRfidCampaigns
);
export default withRouter(PageListRfidCampaigns);
