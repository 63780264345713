import React from "react";

export const ProfileIcon = ({ color = "#1890FF" }) => (
  <svg
    width="1.8em"
    height="1.8em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1331 9.68333C10.1081 9.68333 10.0915 9.68333 10.0665 9.68333C10.0248 9.675 9.96647 9.675 9.91647 9.68333C7.4998 9.60833 5.6748 7.70833 5.6748 5.36666C5.6748 2.98333 7.61647 1.04166 9.9998 1.04166C12.3831 1.04166 14.3248 2.98333 14.3248 5.36666C14.3165 7.70833 12.4831 9.60833 10.1581 9.68333C10.1498 9.68333 10.1415 9.68333 10.1331 9.68333ZM9.9998 2.29166C8.30814 2.29166 6.92481 3.675 6.92481 5.36666C6.92481 7.03333 8.2248 8.375 9.88314 8.43333C9.9248 8.425 10.0415 8.425 10.1498 8.43333C11.7831 8.35833 13.0665 7.01666 13.0748 5.36666C13.0748 3.675 11.6915 2.29166 9.9998 2.29166Z"
      fill="#037ACF"
    />
    <path
      d="M10.1413 18.7917C8.50801 18.7917 6.86634 18.375 5.62467 17.5417C4.46634 16.775 3.83301 15.725 3.83301 14.5833C3.83301 13.4417 4.46634 12.3833 5.62467 11.6083C8.12467 9.95 12.1747 9.95 14.658 11.6083C15.808 12.375 16.4497 13.425 16.4497 14.5667C16.4497 15.7083 15.8163 16.7667 14.658 17.5417C13.408 18.375 11.7747 18.7917 10.1413 18.7917ZM6.31634 12.6583C5.51634 13.1917 5.08301 13.875 5.08301 14.5917C5.08301 15.3 5.52467 15.9833 6.31634 16.5083C8.39134 17.9 11.8913 17.9 13.9663 16.5083C14.7663 15.975 15.1997 15.2917 15.1997 14.575C15.1997 13.8667 14.758 13.1833 13.9663 12.6583C11.8913 11.275 8.39134 11.275 6.31634 12.6583Z"
      fill="#037ACF"
    />
  </svg>
);

export const ProfileRemove = ({ color = "#1890FF" }) => (
  <svg
    width="2em"
    height="2em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.5002 19.75H17.5002C17.0902 19.75 16.7502 19.41 16.7502 19C16.7502 18.59 17.0902 18.25 17.5002 18.25H21.5002C21.9102 18.25 22.2502 18.59 22.2502 19C22.2502 19.41 21.9102 19.75 21.5002 19.75Z"
      fill="#1890FF"
    />
    <path
      d="M14.15 11.62C14.12 11.62 14.1 11.62 14.07 11.62C14.01 11.61 13.95 11.62 13.89 11.62C10.99 11.53 8.80005 9.25 8.80005 6.44C8.80005 3.58 11.13 1.25 13.99 1.25C16.85 1.25 19.18 3.58 19.18 6.44C19.17 9.25 16.97 11.53 14.18 11.62C14.17 11.62 14.16 11.62 14.15 11.62ZM13.99 2.75C11.96 2.75 10.3 4.41 10.3 6.44C10.3 8.44 11.86 10.05 13.85 10.12C13.91 10.11 14.04 10.11 14.17 10.12C16.13 10.03 17.67 8.42 17.68 6.44C17.68 4.41 16.02 2.75 13.99 2.75Z"
      fill="#1890FF"
    />
    <path
      d="M13.9902 22.56C11.9502 22.56 10.0202 22.03 8.56016 21.05C7.17016 20.12 6.41016 18.85 6.41016 17.48C6.41016 16.11 7.18016 14.85 8.56016 13.93C11.5502 11.93 16.4102 11.93 19.4002 13.93C19.7402 14.16 19.8402 14.63 19.6102 14.97C19.3802 15.32 18.9102 15.41 18.5702 15.18C16.0802 13.52 11.8802 13.52 9.39016 15.18C8.43016 15.82 7.91016 16.63 7.91016 17.48C7.91016 18.33 8.43016 19.16 9.39016 19.8C10.6002 20.61 12.2302 21.05 13.9802 21.05C14.3902 21.05 14.7302 21.39 14.7302 21.8C14.7302 22.21 14.4002 22.56 13.9902 22.56Z"
      fill="#1890FF"
    />
  </svg>
);
