import React from "react";

export const ExitIcon = ({ color = "#1890FF", width = "1em", height = "1em" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_530_154)">
      <path
        d="M11.0398 9.28658H10.3192C10.263 9.28658 10.2161 9.33346 10.2161 9.38971V10.2174H1.78262V1.78256H10.2174V2.61024C10.2174 2.66649 10.2643 2.71337 10.3206 2.71337H11.0411C11.0974 2.71337 11.1442 2.66783 11.1442 2.61024V1.26828C11.1442 1.0406 10.9607 0.857117 10.7331 0.857117H1.26834C1.04066 0.857117 0.857178 1.0406 0.857178 1.26828V10.7317C0.857178 10.9593 1.04066 11.1428 1.26834 11.1428H10.7317C10.9594 11.1428 11.1429 10.9593 11.1429 10.7317V9.38971C11.1429 9.33212 11.096 9.28658 11.0398 9.28658ZM11.2889 5.9156L9.38843 4.4156C9.31745 4.35935 9.21432 4.41024 9.21432 4.49997V5.51783H5.00896C4.95004 5.51783 4.90182 5.56605 4.90182 5.62497V6.37497C4.90182 6.4339 4.95004 6.48212 5.00896 6.48212H9.21432V7.49997C9.21432 7.58971 9.31878 7.6406 9.38843 7.58435L11.2889 6.08435C11.3017 6.07433 11.312 6.06152 11.3192 6.0469C11.3263 6.03228 11.33 6.01624 11.33 5.99997C11.33 5.98371 11.3263 5.96766 11.3192 5.95305C11.312 5.93843 11.3017 5.92562 11.2889 5.9156Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_530_154">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
