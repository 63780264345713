import React from "react";
import {
  IdleChargePeriodDefault,
  WayToChargeIdleFee,
} from "../../constants/station";
import { Button, Col, Form, Icon, Input, Row, Switch } from "antd";
import { formatarMoeda } from "../FormatingUtils";
import { disabledIdleFee } from "../../pages/Stations/BatchOperation";

interface IdleFeeBatchOperationProps {
  keepIdleFeeValue: boolean;
  idleFee: any;
  getFieldDecorator: any;
  forms: any;
  loading: boolean;
  handleIdleFeeChange: (keepIdleFeeValue: boolean) => void;
  texts: any;
  freire: any;
}

export const IdleFeeBatchOperation: React.FC<IdleFeeBatchOperationProps> = ({
  keepIdleFeeValue,
  idleFee,
  getFieldDecorator,
  loading,
  handleIdleFeeChange,
  texts,
  freire,
  forms,
}) => {
  return (
    <div className="margin-container-style">
      <>
        {!keepIdleFeeValue && (
          <span className="update-confirm">
            <Icon
              type="warning"
              style={{
                color: "#FCD429",
                fontSize: 20,
                paddingLeft: 5,
              }}
            />
            {freire(texts.IDLE_WARNING)}
          </span>
        )}
        <div className="container-contents">
          <span style={{ color: "#434341", fontWeight: "bold" }}>
            {freire(texts.IDLE_FEE)}
          </span>
          <div className="keep-btn-change-btn">
            <Button
              type={keepIdleFeeValue ? "primary" : "default"}
              style={{
                fontWeight: "bold",
                color: keepIdleFeeValue ? "#FFFFFF" : "#747277",
              }}
              onClick={() => handleIdleFeeChange(true)}
            >
              {freire(texts.BUTTON_KEEP)}
            </Button>
            <Button
              type={!keepIdleFeeValue ? "primary" : "default"}
              style={{
                fontWeight: "bold",
                color: !keepIdleFeeValue ? "#FFFFFF" : "#747277",
              }}
              onClick={() => handleIdleFeeChange(false)}
            >
              {freire(texts.BUTTON_CHANGE)}
            </Button>
          </div>
        </div>
        <div
          style={{
            display: !keepIdleFeeValue ? "block" : "none",
          }}
        >
          <Row gutter={24} style={{ marginTop: 8 }}>
            <Col span={24}>
              <Form.Item label="">
                {getFieldDecorator("idleFee.enabled", {
                  valuePropName: "checked",
                })(
                  <Switch
                    disabled={loading}
                    checkedChildren={freire(texts.YES)}
                    unCheckedChildren={freire(texts.NO)}
                    onChange={(event) => {
                      if (!event) {
                        forms.setFieldsValue(disabledIdleFee);
                      }
                      forms.setFieldsValue({
                        idleFee: {
                          chargePeriod: IdleChargePeriodDefault,
                          wayToCharge: WayToChargeIdleFee.PER_DURATION,
                        },
                      });
                    }}
                  />
                )}
                <span style={{ marginLeft: 10 }}>
                  {freire(texts.ACCEPT_IDLE_FEE)}
                </span>
              </Form.Item>
            </Col>
          </Row>

          <Row
            gutter={24}
            style={idleFee?.enabled ? { marginTop: 8 } : { display: "none" }}
          >
            <Col span={8}>
              <Form.Item label={freire(texts.VALUE_PER_MINUTE)}>
                {getFieldDecorator("idleFee.value", {
                  normalize: (input: string) => {
                    return input ? formatarMoeda(input) : "0,00";
                  },
                })(<Input type="text" placeholder={freire(texts.VALUE_EX)} />)}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={freire(texts.GRACE_PERIOD)}>
                {getFieldDecorator("idleFee.gracePeriod")(
                  <Input
                    type="number"
                    min="0"
                    placeholder={freire(texts.GRACE_PERIOD_MINUTES)}
                  />
                )}
              </Form.Item>
              <Form.Item style={{ display: "none" }}>
                {getFieldDecorator("idleFee.chargePeriod")(<Input />)}
              </Form.Item>
              <Form.Item style={{ display: "none" }}>
                {getFieldDecorator("idleFee.wayToCharge")(<Input />)}
              </Form.Item>
            </Col>
          </Row>
        </div>
      </>
    </div>
  );
};
