import { Button, Col, Form, List, Row, Select } from "antd";
import moment from "moment-timezone";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import socket from "socket.io-client";
import "./index.css";

import Breadcrumbs from "../../../components/Breadcrumbs";
import apiLogs from "../../../services/apiLogs";
import apiOcpp from "../../../services/apiOcpp";
import { FreireContext } from "../../../utils/freireContext";
import * as texts from "../locales";
import withPermission from "../../../contexts/PermissionContext/withPermission";

const { Option } = Select;

class ListLogs extends Component {
  static contextType = FreireContext;

  state = {
    loadingStations: false,
    logs: [],
    stations: [],
    selectedStationID: "",
    dates: [],
    date: "",
    loading: false,
    isConnected: false,
  };

  enterFunction = (event) => {
    if (event.keyCode === 13) {
      const logs = this.state.logs;
      if (logs.length > 0) {
        logs.push("");
        this.setState({ logs }, () => {
          this.goToEndScroll();
        });
      }
    }
  };

  componentDidMount() {
    document.addEventListener("keydown", this.enterFunction, false);
  }

  componentWillUnmount() {
    this.unsubscribeLogs();
    document.removeEventListener("keydown", this.enterFunction, false);
  }

  subscribeToLogs = async (eventName) => {
    this.io = socket(apiLogs.defaults.baseURL);

    const res = await new Promise((resolve, reject) => {
      this.io.on("connect", (data) => {
        console.log("connect", this.io.id);
        this.setState({ isConnected: true });
        resolve(this.io.id);
      });

      this.io.on("connect_error", (data) => {
        console.log("connect_error", data);
        this.setState({ isConnected: false });
        reject(null);
      });
    });

    this.io.on("disconnect", (data) => {
      console.log("disconnect", data);
      this.setState({ isConnected: false });
    });

    this.io.on(eventName, (log) => {
      const scroll = this.isEndOfScroll();

      const logs = this.state.logs;
      logs.push(log);
      this.setState({ logs }, () => {
        if (scroll) this.goToEndScroll();
      });
    });

    return res;
  };

  unsubscribeLogs = () => {
    if (this.io) {
      this.io.disconnect();
    }
  };

  async getStations() {
    const { hasPermission } = this.props;

    this.setState({ loadingStations: true }, async () => {
      try {
        const { data: stations } = await apiOcpp.get(`stations/select`);

        const canViewUnknownLogs = hasPermission("show-unknown-station-logs");

        const unknownStation = {
          id: -1,
          name: "Não identificado",
          stationId: "UNKNOWN",
        };

        this.setState({
          stations: [
            ...stations,
            ...(canViewUnknownLogs ? [unknownStation] : []),
          ],
        });
      } catch (error) {
      } finally {
        this.setState({ loadingStations: false });
      }
    });
  }

  selectStation = async (stationID) => {
    const { data } = await apiLogs.get(`stationLogs/${stationID}`);
    this.setState({
      dates: data,
      selectedStationID: stationID,
    });
  };

  selectDate = async (date) => {
    try {
      const { selectedStationID } = this.state;

      this.setState({ loading: true });

      const params = {};
      // Verifica se a data é hoje
      if (moment().isSame(moment(date, "DD-MM-YYYY"), "day")) {
        const socketId = await this.subscribeToLogs(
          `${selectedStationID}/${date}`
        );
        if (socketId) {
          params.socketId = socketId;
        }
      } else {
        this.unsubscribeLogs();
      }

      const { data } = await apiLogs.get(
        `stationLogs/${selectedStationID}/${date}`,
        {
          params,
        }
      );

      const scroll = this.isEndOfScroll();

      this.setState(
        {
          logs: data,
          date,
          loading: false,
        },
        () => {
          if (scroll) this.goToEndScroll();
        }
      );
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  };

  isEndOfScroll() {
    const list = document.getElementById("myList");
    if (list.scrollHeight - list.clientHeight === list.scrollTop) {
      return true;
    }

    return false;
  }

  goToEndScroll() {
    const list = document.getElementById("myList");
    list.scrollTop = list.scrollHeight;
  }

  goFullScreen() {
    var elem = document.getElementById("myList");

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  }

  renderDate = (date) => {
    if (moment().isSame(moment(date, "DD-MM-YYYY"), "day")) {
      if (this.state.isConnected) {
        return (
          <span>
            Hoje{" "}
            <span style={{ color: "green", fontWeight: "bold" }}>
              (Conectado)
            </span>
          </span>
        );
      }

      return "Hoje";
    }
    return date;
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      dates,
      logs,
      stations,
      selectedStationID,
      loading,
      loadingStations,
    } = this.state;

    const { freire } = this.context;

    return (
      <div className="containerLogs">
        <Breadcrumbs
          breadcrumbs={[
            freire(texts.ADMIN),
            freire(texts.OCPP),
            freire(texts.LOGS),
          ]}
        />
        <div className="filter">
          <Form wrapperCol={{ span: 24 }}>
            <Form.Item>
              <Row gutter={[16, 8]}>
                <Col span={12}>
                  <Form.Item label={freire(texts.STATION)}>
                    {getFieldDecorator("stationId")(
                      <Select
                        showSearch
                        allowClear
                        loading={loadingStations}
                        placeholder={
                          loadingStations
                            ? freire(texts.CHARGING)
                            : freire(texts.SELECT_STATION)
                        }
                        optionFilterProp="label"
                        onChange={(stationID) => {
                          this.selectStation(stationID);
                          this.props.form.resetFields(["date"]);
                          this.setState({ logs: [] });
                          this.unsubscribeLogs();
                        }}
                        onDropdownVisibleChange={(opened) => {
                          if (opened && stations.length === 0) {
                            this.getStations();
                          }
                        }}
                      >
                        {stations.map((station) => {
                          return (
                            <Option
                              label={`${station.stationId} (${station.name})`}
                              key={station.stationId}
                              value={station.stationId}
                            >
                              {station.stationId}{" "}
                              <span style={{ fontSize: 12, color: "#878787" }}>
                                ({station.name})
                              </span>
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={freire(texts.DAY_REFERRING)}>
                    {getFieldDecorator("date")(
                      <Select
                        ref={(ref) => (this.selectLogDt = ref)}
                        disabled={selectedStationID === ""}
                        showSearch
                        onChange={(date) => {
                          this.selectDate(date);
                          if (this.selectLogDt) this.selectLogDt.blur();
                        }}
                        placeholder={freire(texts.SELECT_DATE)}
                      >
                        {dates.map((date) => {
                          return (
                            <Option key={date} value={date}>
                              {this.renderDate(date)}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </div>

        {logs.length > 0 ? (
          <div style={{ position: "relative" }}>
            <Button
              style={{ position: "absolute", top: 0, right: 15, zIndex: 9999 }}
              icon="fullscreen"
              onClick={this.goFullScreen}
            >
              {freire(texts.FULLSCREEN)}
            </Button>
          </div>
        ) : null}

        <div id="myList" style={{ overflowY: "scroll", position: "relative" }}>
          <List
            bordered
            style={{ backgroundColor: "#FFF" }}
            size="small"
            dataSource={logs}
            loading={loading}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />
        </div>
      </div>
    );
  }
}

const PageListLogs = Form.create({ name: "filters" })(ListLogs);

export default withPermission(withRouter(PageListLogs));
