import axios from "axios";
import { setTokenRequestInterceptor } from "./interceptors";

const apiOcpp = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/proxy-ocpp/api`,
});

apiOcpp.interceptors.request.use(setTokenRequestInterceptor);

export default apiOcpp;
