import * as texts from "./locales";

const generateFields = ({
  companyOptions,
  onChangeCompany,
  stationsOptions,
  freire,
}) => [
  {
    label: freire(texts.COMPANIES),
    name: "company_ids",
    component: "tupi.select",
    isMultiple: true,
    placeholder: freire(texts.COMPANIES_PLACEHOLDER),
    options: companyOptions,
    onChange: onChangeCompany,
    colSpan: 8,
  },
  {
    label: freire(texts.STATIONS),
    name: "station_ids",
    component: "tupi.select",
    isMultiple: true,
    placeholder: freire(texts.STATIONS_PLACEHOLDER),
    options: stationsOptions,
    colSpan: 14,
    help: freire(texts.HELP),
  },

  {
    label: freire(texts.CONNECTION),
    name: "disabled",
    component: "tupi.radioGroup",
    options: [
      { value: undefined, label: freire(texts.CONNECTION_LABEL_ALL) },
      { value: false, label: freire(texts.CONNECTION_LABEL_ACTIVE) },
      { value: true, label: freire(texts.CONNECTION_LABEL_DISABLED) },
    ],
    colSpan: 7,
  },
  {
    label: freire(texts.VISIBILITY),
    name: "visibility",
    initialValue: "all",
    component: "tupi.radioGroup",
    options: [
      { value: undefined, label: freire(texts.VISIBILITY_LABEL_ALL) },
      { value: "groups", label: freire(texts.VISIBILITY_LABEL_GROUPS) },
      { value: "none", label: freire(texts.VISIBILITY_LABEL_HIDDEN) },
    ],
    when: "filter-by-visibility-station",
    colSpan: 6,
  },
];

export default generateFields;
