import { Col, Icon, Popover, Row } from "antd";

export default function TextToolTip({ title, icon, info }) {
  return (
    <Popover
      zIndex={9999}
      placement="right"
      title={
        <span style={{ fontWeight: "bold", fontSize: 16 }}>
          {title}
        </span>
      }
      trigger="hover"
      content={Info({info: info})}
    >
      <Icon
        type={icon}
        style={{ marginLeft: 5 }}
      />
    </Popover>
  );
}

const Info = ({info}) =>{
  return (
    <Col style={{ width: 300, zIndex: 9999 }}>
      {info.map((item) => {
        const hasName = !!item?.name;
        const hasTitle = !!item?.title;
        const hasNameOrTitle = hasName || hasTitle;

        return(
        <Row style={{ marginTop: 10 }}>
          {hasName && (
            <span style={{ fontWeight: "bold", color: "black" }}>
              {item.name}
            </span>
          )}
          {hasTitle && (
            <span style={{ color: "black" }}>{item.title}</span>
          )}
          {hasNameOrTitle && <br />}
          {item?.info && <div style={{ color: "black" }}>{item.info}</div>}
        </Row>
      )})}
    </Col>
  )
}