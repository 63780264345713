import {
  Button,
  Card,
  Dropdown,
  Form,
  Icon,
  Input,
  notification,
  Popconfirm,
  Table,
  Tag,
  Tooltip,
} from "antd";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Show from "../../../contexts/PermissionContext/Show";
import api from "../../../services/api";
import EditDrawer from "./EditDrawer";
import "./index.css";
import { FreireContext } from "../../../utils/freireContext";
import * as texts from "../locales";
import { sorter } from "../../../utils/sorter";

class ListVisibilityGroup extends Component {
  static contextType = FreireContext;

  state = {
    currentGroup: {},
    loadingTable: false,
    loadingSave: false,
    visibleDrawer: false,
    loadingDrawer: false,
    dateFiltrada: [],
    tablePage: 1,
  };

  getColumns = () => {
    const { freire } = this.context;

    return [
      {
        title: freire(texts.GROUP),
        dataIndex: "name",
        key: "name",
      },
      {
        title: freire(texts.EMAIL),
        dataIndex: "email",
        key: "email",
        width: "25%",
      },
      {
        title: freire(texts.STATIONS),
        dataIndex: "stations",
        key: "stations",
        width: "25%",
        render: (stations) => this.renderStations(stations),
      },
      {
        title: freire(texts.ACTIONS),
        key: "acoes",
        width: 100,
        render: (group, data) => (
          <>
            <Show when={"edit-visibility-group"}>
              <Tooltip placement="top" title={freire(texts.EDIT)}>
                <Button
                  shape="circle"
                  size="small"
                  ghost
                  type="primary"
                  style={{ marginRight: 8 }}
                  onClick={() =>
                    this.setState({
                      visibleDrawer: true,
                      currentGroup: group,
                    })
                  }
                >
                  <Icon type="edit" />
                </Button>
              </Tooltip>
            </Show>

            <Show when={"delete-visibility-group"}>
              <Tooltip placement="top" title={freire(texts.DELETE)}>
                <Popconfirm
                  placement="top"
                  title={freire(texts.CONFIRM_DELETE)}
                  okText={freire(texts.YES)}
                  cancelText={freire(texts.NO)}
                  onConfirm={() => this.deleteGroup(group)}
                >
                  <Button
                    style={{ marginRight: 8 }}
                    shape="circle"
                    size="small"
                    ghost
                    type="danger"
                  >
                    <Icon type="delete" />
                  </Button>
                </Popconfirm>
              </Tooltip>
            </Show>
          </>
        ),
      },
    ];
  };

  componentDidMount() {
    this.getReportGroups();
  }

  renderTags = (tags, color = "blue") => {
    return tags.map((tag) => (
      <Tag className="tagStations" color={color} key={tag}>
        {tag}
      </Tag>
    ));
  };

  renderStations = (stations = []) => {
    const { freire } = this.context;

    let display = 4;

    let displayList = stations;
    let overlayList = [];

    // Só deve exibir o 'overlay' se a qtd. de itens 'para ocultar' for maior
    // ou igual a 2 para evitar de exibir o 'overlay' com menos de 2 itens
    if (stations.length - display >= 2) {
      displayList = stations.slice(0, display);
      overlayList = stations.slice(display, stations.length);
    }

    return (
      <span>
        {this.renderTags(displayList)}
        {overlayList.length > 0 ? (
          <Dropdown
            overlay={
              <div>
                <Card className="cardOverlay">
                  {this.renderTags(overlayList)}
                </Card>
              </div>
            }
          >
            <div>{freire(texts.MORE_STATIONS(overlayList.length))}</div>
          </Dropdown>
        ) : null}
      </span>
    );
  };

  async updateGroup(data) {
    const { freire } = this.context;

    this.setState({ loadingDrawer: true }, async () => {
      try {
        data._id = this.state.currentGroup._id;
        await api.put(`visibilityGroup`, data);

        this.setState({ loadingDrawer: false, visibleDrawer: false });

        notification.success({
          message: freire(texts.ALL_RIGHT),
          description: freire(texts.GROUP_EDITED_SUCCESS),
        });

        this.getReportGroups();
      } catch (error) {
        notification.error({
          message: freire(texts.OOPS),
          description: freire(texts.ERROR_EDIT_GROUPS),
        });
        this.setState({ loadingDrawer: false });
      }
    });
  }

  async deleteGroup(group) {
    // this.setState({ loadingTable: true }, async () => {
    //   try {
    //     await api.delete(`report_group/${group._id}`);
    //     this.setState({ loadingTable: false });
    //     notification.success({
    //       message: "Tudo certo",
    //       description: "Grupo deletado com sucesso!",
    //     });
    //     this.getReportGroups();
    //   } catch (error) {
    //     notification.error({
    //       message: "Ops...",
    //       description: "Erro ao deletar grupo",
    //     });
    //     this.setState({ loadingTable: false });
    //   }
    // });
  }

  async getReportGroups(params = {}) {
    const { freire } = this.context;

    this.setState({ loadingTable: true }, async () => {
      try {
        const { data } = await api.get(`visibilityGroup`, { params });
        this.setState({
          dateFiltrada: sorter(data),
          loadingTable: false,
        });
      } catch (error) {
        notification.error({
          message: freire(texts.OOPS),
          description: freire(texts.ERROR_GET_VISIBILITY_GROUPS),
        });
        this.setState({ loadingTable: false });
      }
    });
  }

  filter = () => {
    this.props.form.validateFields((err, values) => {
      if (!values.name) values.name = undefined;

      if (!err) {
        this.getReportGroups(values);
      }
    });

    this.setState({ tablePage: 1 });
  };

  clearFilters = () => {
    this.props.form.resetFields();
    this.setState({ dateFiltrada: [] });
    this.getReportGroups();
  };

  goNew = () => {
    this.props.history.push("/dashboard/new_visibility_group");
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      visibleDrawer,
      loadingDrawer,
      currentGroup,
      loadingTable,
      dateFiltrada,
    } = this.state;
    const { freire } = this.context;

    const columns = this.getColumns();

    return (
      <div className="container">
        <Breadcrumbs
          breadcrumbs={[
            freire(texts.ADMIN),
            freire(texts.GROUPS),
            freire(texts.LISTING_VISIBILITY_GROUP),
          ]}
        />
        <div className="filter">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.filter();
            }}
            wrapperCol={{ span: 24 }}
          >
            <Form.Item>
              <div style={{ flexDirection: "row", display: "flex" }}>
                {getFieldDecorator("name")(
                  <Input
                    onSubmit={this.filter}
                    className="firstInput"
                    placeholder={freire(texts.SEARCH_BY_GROUP_NAME)}
                  />
                )}
                <Button
                  icon="search"
                  className="buttonSearch"
                  onClick={this.filter}
                >
                  {freire(texts.SEARCH)}
                </Button>
                <Show when={"add-visibility-group"}>
                  <Button
                    icon="plus"
                    className="buttonNew"
                    onClick={this.goNew}
                    type="primary"
                  >
                    {freire(texts.NEW)}
                  </Button>
                </Show>
              </div>
            </Form.Item>
          </Form>

          <div className="footer">
            <Button style={{ marginRight: 10 }} onClick={this.clearFilters}>
              {freire(texts.CLEAR)}
            </Button>
            <Button onClick={this.filter} type="primary">
              {freire(texts.FILTER)}
            </Button>
          </div>
        </div>

        <Table
          style={{ backgroundColor: "white" }}
          //scroll={{ x: 1300 }}
          tableLayout="auto"
          rowKey={(data) => data._id}
          loading={loadingTable}
          dataSource={dateFiltrada}
          columns={columns}
          pagination={{
            current: this.state.tablePage,
            onChange: (page) => this.setState({ tablePage: page }),
          }}
        />

        <EditDrawer
          visible={visibleDrawer}
          loading={loadingDrawer}
          onClose={() => this.setState({ visibleDrawer: false })}
          onSave={(values) => this.updateGroup(values)}
          group={currentGroup}
        />
      </div>
    );
  }
}
const PageListVisibilityGroup = Form.create({ name: "filters" })(
  ListVisibilityGroup
);
export default withRouter(PageListVisibilityGroup);
