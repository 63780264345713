import React, { Fragment, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  notification,
  Radio,
  Row,
  Select,
} from "antd";
import apiOcpp from "../../../../services/apiOcpp";
import { useFreire } from "../../../../utils/freireContext";
import * as texts from "../../locales";

const { Option } = Select;

const ChangeConfiguration = ({
  stationId = "",
  redirectTo = () => {},
  form,
}) => {
  const { getFieldDecorator } = form;
  const [loading, setLoading] = useState(false);
  const [currentConfig, setCurrentConfig] = useState(null);
  const { freire } = useFreire();

  const configsKey = [
    {
      name: "ConnectionTimeOut",
      type: "integer",
      description: freire(texts.SECONDS),
    },
    {
      name: "ResetRetries",
      type: "integer",
      description: freire(texts.QTD_OF_TIMES),
    },
    {
      name: "BlinkRepeat",
      type: "integer",
      description: freire(texts.QTD_OF_TIMES),
    },
    {
      name: "LightIntensity",
      type: "integer",
      description: freire(texts.PERCENTAGEM),
    },
    {
      name: "MeterValueSampleInterval",
      type: "integer",
      description: freire(texts.SECONDS),
    },
    {
      name: "ClockAlignedDataInterval",
      type: "integer",
      description: freire(texts.SECONDS),
    },
    {
      name: "MeterValuesSampledData",
      type: "string",
      description: freire(texts.COMMA_SEPARATED_LIST),
    },
    {
      name: "MeterValuesAlignedData",
      type: "string",
      description: freire(texts.COMMA_SEPARATED_LIST),
    },
    {
      name: "StopTxnSampledData",
      type: "string",
      description: freire(texts.COMMA_SEPARATED_LIST),
    },
    {
      name: "StopTxnAlignedData",
      type: "string",
      description: freire(texts.COMMA_SEPARATED_LIST),
    },
    {
      name: "HeartbeatInterval",
      type: "integer",
      description: freire(texts.SECONDS),
    },
    {
      name: "AllowOfflineTxForUnknownId",
      type: "boolean",
      description: freire(texts.ACTIVE_DISABLE),
    },
    {
      name: "AuthorizationCacheEnabled",
      type: "boolean",
      description: freire(texts.ACTIVE_DISABLE),
    },
    {
      name: "AuthorizeRemoteTxRequests",
      type: "boolean",
      description: freire(texts.ACTIVE_DISABLE),
    },
    {
      name: "ConnectorPhaseRotation",
      type: "string",
      description: freire(texts.COMMA_SEPARATED_LIST),
    },
    {
      name: "LocalAuthorizeOffline",
      type: "boolean",
      description: freire(texts.ACTIVE_DISABLE),
    },
    {
      name: "LocalPreAuthorize",
      type: "boolean",
      description: freire(texts.ACTIVE_DISABLE),
    },
    {
      name: "MaxEnergyOnInvalidId",
      type: "integer",
      description: freire(texts.IN_KH),
    },
    {
      name: "MinimumStatusDuration",
      type: "integer",
      description: freire(texts.SECONDS),
    },
    {
      name: "StopTransactionOnEVSideDisconnect",
      type: "boolean",
      description: freire(texts.ACTIVE_DISABLE),
    },
    {
      name: "StopTransactionOnInvalidId",
      type: "boolean",
      description: freire(texts.ACTIVE_DISABLE),
    },
    {
      name: "TransactionMessageAttempts",
      type: "integer",
      description: freire(texts.QTD_OF_TIMES),
    },
    {
      name: "TransactionMessageRetryInterval",
      type: "integer",
      description: freire(texts.SECONDS),
    },
    {
      name: "UnlockConnectorOnEVSideDisconnect",
      type: "boolean",
      description: freire(texts.ACTIVE_DISABLE),
    },
    {
      name: "WebSocketPingInterval",
      type: "integer",
      description: freire(texts.SECONDS),
    },
    {
      name: "LocalAuthListEnabled",
      type: "boolean",
      description: freire(texts.ACTIVE_DISABLE),
    },
  ];

  const send = () => {
    form.validateFields(async (err, payload) => {
      if (err) {
        return;
      }

      try {
        setLoading(true);
        const { data } = await apiOcpp.post(
          `/ocpp16/changeConfiguration/${stationId}`,
          {
            key: payload.key,
            value: String(payload.value),
          }
        );

        setLoading(false);

        if (data && data.result) {
          if (data.result === "Accepted") {
            notification.success({
              message: freire(texts.ALL_RIGHT),
              description: `${freire(texts.STATION_RESPONSE)} '${data.result}'`,
            });
            redirectTo("/dashboard/ocpp/stations");
          } else {
            notification.error({
              message: "Ops...",
              description: `${freire(texts.STATION_RESPONSE)} '${data.result}'`,
            });
          }
        } else {
          throw new Error();
        }
      } catch (error) {
        setLoading(false);
        notification.error({
          message: freire(texts.OOPS),
          description: freire(texts.VERIFY_AGAIN),
        });
      }
    });
  };

  const renderInputValue = () => {
    if (
      form.getFieldValue("configType") === "Predefinida" &&
      form.getFieldValue("key") &&
      currentConfig
    ) {
      if (currentConfig.type === "boolean") {
        return getFieldDecorator("value", {
          rules: [{ required: true, message: freire(texts.REQUIRED) }],
        })(
          <Radio.Group disabled={!form.getFieldValue("key")}>
            <Radio.Button value={true}>{freire(texts.ACTIVATE)}</Radio.Button>
            <Radio.Button value={undefined}>----</Radio.Button>
            <Radio.Button value={false}>{freire(texts.DISABLE)}</Radio.Button>
          </Radio.Group>
        );
      }

      if (currentConfig.type === "integer") {
        return getFieldDecorator("value", {
          rules: [
            { required: true, message: freire(texts.REQUIRED) },
            {
              validator: async (rule, value, callback) => {
                if (typeof value === "number" && !Number.isInteger(value)) {
                  callback(freire(texts.ONLY_INTERGER_NUMBERS));
                }
                callback();
              },
            },
          ],
        })(
          <InputNumber
            disabled={!form.getFieldValue("key")}
            min={0}
            style={{ width: "100%" }}
            placeholder={freire(texts.ENTER_VALUE_OF_CONFIGURATION)}
          />
        );
      }
    }

    return getFieldDecorator("value", {
      rules: [
        { required: true, message: freire(texts.REQUIRED) },
        {
          validator: async (rule, value, callback) => {
            if (!/^\S*$/.test(value)) {
              callback(freire(texts.NO_BLANK_SPACE_ALLOWED));
            }
            callback();
          },
        },
      ],
    })(
      <Input
        disabled={!form.getFieldValue("key")}
        placeholder={freire(texts.ENTER_VALUE_OF_CONFIGURATION)}
      />
    );
  };

  return (
    <Fragment>
      <Form layout="vertical" wrapperCol={{ span: 24 }}>
        <Row gutter={[16, 0]}>
          <Col span={6}>
            <Form.Item label={freire(texts.TYPE)}>
              {getFieldDecorator("configType", {
                initialValue: freire(texts.PRESET),
                rules: [
                  {
                    required: true,
                    message: freire(texts.REQUIRED),
                  },
                ],
              })(
                <Select
                  onChange={() => {
                    form.resetFields(["key", "value"]);
                  }}
                  placeholder={freire(texts.SELECT_TYPE_CONFIGURATION)}
                >
                  <Option value={"Predefinida"}>{freire(texts.PRESET)}</Option>
                  <Option value={"Personalizada"}>
                    {freire(texts.CUSTOMIZED)}
                  </Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={18}>
            <Form.Item label={freire(texts.CONFIGURATION)}>
              {getFieldDecorator("key", {
                rules: [
                  {
                    required: true,
                    message: freire(texts.REQUIRED),
                  },
                ],
              })(
                form.getFieldValue("configType") === "Predefinida" ? (
                  <Select
                    showSearch
                    optionLabelProp="label"
                    placeholder={freire(texts.SELECT_CONFIGURATION)}
                    onChange={(value) => {
                      form.resetFields(["value"]);

                      const config = configsKey.filter(
                        (c) => c.name === value
                      )[0];
                      setCurrentConfig(config);
                    }}
                    filterOption={(input, option) => {
                      return (
                        option.props.value
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                  >
                    {configsKey.map((config) => (
                      <Option
                        label={config.name}
                        key={config.name}
                        value={config.name}
                      >
                        {config.name}
                        <br />
                        <span style={{ fontSize: 12.5, opacity: 0.6 }}>
                          ({config.description})
                        </span>
                      </Option>
                    ))}
                  </Select>
                ) : (
                  <Input placeholder={freire(texts.INFORM_THE_CONFIGURATION)} />
                )
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Form.Item
              label={
                form.getFieldValue("configType") === "Predefinida" &&
                currentConfig &&
                currentConfig.type !== "boolean"
                  ? `${freire(texts.VALUE)} (${currentConfig.description})`
                  : freire(texts.VALUE)
              }
            >
              {renderInputValue()}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        <Button
          disabled={!stationId || loading}
          loading={loading}
          onClick={send}
          type="primary"
        >
          <span>
            {freire(texts.EXECUTE)}{" "}
            <span style={{ fontWeight: "bold" }}> ChangeConfiguration</span>
          </span>
        </Button>
      </div>
    </Fragment>
  );
};

const PageChangeConfiguration = Form.create({ name: "inputs" })(
  ChangeConfiguration
);

export default PageChangeConfiguration;
