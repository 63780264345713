import { Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import React, { forwardRef, ReactNode } from "react";

interface ISimpleTable {
  columns: ColumnProps<any>[] | undefined;
  dataSource: Array<any>;
  endAdornment?: ReactNode;
  footer?: (currentPageData: any[]) => React.ReactNode;
}

const SimpleTable: React.FC<ISimpleTable> = forwardRef<
  HTMLDivElement,
  ISimpleTable
>(({ columns, dataSource, endAdornment, footer }, ref) => {
  const highlightStyles = {
    border: "solid #1890FF 1px",
    background: "#E6F7FF",
    width: "530px",
  };

  return (
    <div
      ref={ref}
      style={{
        ...highlightStyles,
        marginTop: "-1rem",
        marginBottom: "1rem",
        padding: "1rem",
      }}
    >
      <div style={{ backgroundColor: "white" }}>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          defaultExpandAllRows
          footer={footer}
        />
      </div>
      {endAdornment}
    </div>
  );
});

export default SimpleTable;
