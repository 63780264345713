import React from "react";
import { Button, Drawer } from "antd";
import { withRouter } from "react-router-dom";
import Charges from "./Charges";
import Details from "./Details";
import Coupons from "./Coupons";
import * as texts from "../../../locales";
import { useFreire } from "../../../../../utils/freireContext";
import { Show } from "../../../../../contexts/PermissionContext";

const UserDetailsAndCharges = ({ user, visible, onClose,}) => {
  const { freire } = useFreire();

  return (
    <Drawer
      title={`${user.displayName} (${user.email})`}
      width={"min(max(min(30%, 750px), 700px), 100%)"}
      onClose={onClose}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      {visible && (
        <>
          <Show when={"show-user-personal-data"}>
            <Details userID={user.userID} />
          </Show>
          <Coupons userID={user.userID}/>
          <Charges userID={user.userID} />
        </>
      )}

      <div className="drawerFooter">
        <Button onClick={onClose}>{freire(texts.CLOSE)}</Button>
      </div>
    </Drawer>
  );
};

export default withRouter(UserDetailsAndCharges);
