import React, { useEffect, useState } from "react";
import { Form } from "antd";
import { Button } from "antd";
import { Row } from "antd";
import { Drawer } from "antd";
import { Input } from "antd";
import { withRouter } from "react-router-dom";
import { Col } from "antd";
import api from "../../../../services/api";
import { notification } from "antd";
import { useFreire } from "../../../../utils/freireContext";
import * as texts from "../locales";

const NFSeDrawer = ({
  form,
  visibleNewNFSe,
  closeDrawerNewNFSe,
  user,
  charger,
  clear,
}) => {
  const [loading, setLoading] = useState(false);
  const { freire } = useFreire();

  useEffect(() => {
    if (visibleNewNFSe) {
      form.setFieldsValue({
        name: user.fullName ?? user.displayName,
        document: user.document,
        endereco: user.address?.logradouro,
        endereco_numero: user.address?.numero,
        bairro: user.address?.bairro,
        estado: user.address?.estadoUf,
        cep: user.address?.cep,
        cidade: user.address?.cidade,
        email: user.email,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleNewNFSe]);

  const send = async () => {
    setLoading(true);

    form.validateFields(async (err, usuario) => {
      const user = {
        fullName: usuario.name,
        document: usuario.document,
        email: usuario.email,
        address: {
          logradouro: usuario.endereco,
          numero: usuario.endereco_numero,
          bairro: usuario.bairro,
          estadoUf: usuario.estado,
          cidade: usuario.cidade,
          cep: usuario.cep,
        },
      };
      try {
        await api.post(`nfse/${charger._id}`, { user });
        clear();
        closeDrawerNewNFSe();
      } catch (error) {
        notification.error({
          message: "Ops...",
          description: freire(texts.ERROR_LOAD_USER),
        });
      }
      setLoading(false);
    });
  };

  return (
    <Drawer
      title={freire(texts.SHOW_NFE_DATA)}
      width={500}
      onClose={() => closeDrawerNewNFSe()}
      visible={visibleNewNFSe}
      bodyStyle={{ paddingBottom: 10 }}
    >
      <Form layout="vertical">
        {charger.nfse?.error && (
          <div
            style={{
              borderRadius: 8,
              padding: 10,
              marginBottom: 20,
              backgroundColor: "#ffd769cc",
            }}
          >
            {charger.nfse?.error}
          </div>
        )}
        <span style={{ color: "#fd5876", fontWeight: "bold" }}>
          {freire(texts.USER_INFO)}
        </span>
        <Row style={{ marginTop: 20 }}>
          <Col span={12}>
            <Form.Item label={freire(texts.USER_NAME)}>
              {form.getFieldDecorator("name", {
                rules: [
                  {
                    required: true,
                    message: freire(texts.REQUIRED_FIELD),
                  },
                ],
              })(<Input placeholder="Ex.: Joao" />)}
            </Form.Item>
          </Col>
          <Col span={10} style={{ marginLeft: 10 }}>
            <Form.Item label={freire(texts.DOCUMENT_TEXT)}>
              {form.getFieldDecorator("document", {
                rules: [
                  {
                    required: true,
                    message: freire(texts.REQUIRED_FIELD),
                  },
                ],
              })(<Input placeholder="Ex.: 000.000.000-00" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label={freire(texts.STREET)}>
              {form.getFieldDecorator("endereco", {
                rules: [
                  {
                    required: true,
                    message: freire(texts.REQUIRED_FIELD),
                  },
                ],
              })(<Input placeholder={freire(texts.GROUP_NAME_EX)} />)}
            </Form.Item>
          </Col>
          <Col span={10} style={{ marginLeft: 10 }}>
            <Form.Item label={freire(texts.NUMBER)}>
              {form.getFieldDecorator("endereco_numero", {
                rules: [
                  {
                    required: true,
                    message: freire(texts.REQUIRED_FIELD),
                  },
                ],
              })(<Input placeholder="Ex.: 123" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label={freire(texts.NEIGHBORHOOD)}>
              {form.getFieldDecorator("bairro", {
                rules: [
                  {
                    required: true,
                    message: freire(texts.REQUIRED_FIELD),
                  },
                ],
              })(<Input placeholder="Ex.: Pinheiros" />)}
            </Form.Item>
          </Col>
          <Col span={10} style={{ marginLeft: 10 }}>
            <Form.Item label={freire(texts.CITY)}>
              {form.getFieldDecorator("cidade", {
                rules: [
                  {
                    required: true,
                    message: freire(texts.REQUIRED_FIELD),
                  },
                ],
              })(<Input placeholder="Ex.: Sao Paulo." />)}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label={freire(texts.STATE)}>
              {form.getFieldDecorator("estado", {
                rules: [
                  {
                    required: true,
                    message: freire(texts.REQUIRED_FIELD),
                  },
                ],
              })(<Input placeholder="Ex.: SP, CE, BH..." />)}
            </Form.Item>
          </Col>
          <Col span={10} style={{ marginLeft: 10 }}>
            <Form.Item label="CEP">
              {form.getFieldDecorator("cep", {
                rules: [
                  {
                    required: true,
                    message: freire(texts.REQUIRED_FIELD),
                  },
                ],
              })(<Input placeholder="Ex.: 000000-000" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label={freire(texts.EMAIL)}>
              {form.getFieldDecorator("email", {
                rules: [
                  {
                    required: true,
                    message: freire(texts.REQUIRED_FIELD),
                  },
                ],
              })(
                <Input
                  placeholder={`Ex.: ${freire(
                    texts.USER_LOWER_CASE
                  )}@gmail.com`}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div
        style={{
          position: "absolute",
          right: 0,
          bottom: 0,
          width: "100%",
          borderTop: "1px solid #e9e9e9",
          padding: "10px 16px",
          background: "#fff",
          textAlign: "right",
        }}
      >
        <Button onClick={() => closeDrawerNewNFSe()} style={{ marginRight: 8 }}>
          {freire(texts.CANCEL)}
        </Button>
        <Button loading={loading} onClick={() => send()} type="primary">
          {freire(texts.SEND)}
        </Button>
      </div>
    </Drawer>
  );
};

const ComponentEditDrawer = Form.create({ name: "NFSe" })(NFSeDrawer);
export default withRouter(ComponentEditDrawer);
