import React, { useState, useEffect } from 'react';
import { Table, Button, Tooltip, Form, Input, Menu, Icon, Dropdown, Tag } from "antd";
import api from "../../../services/api";
import * as texts from "../locales";
import { useFreire } from "../../../utils/freireContext";
import Breadcrumbs from "../../../components/Breadcrumbs";
import "./styles.css";
import { Link } from 'react-router-dom';
import moment from 'moment';
import { copyToClipboard, statusToTagColor, statusToText } from '../proposalHelper';
import ModalExpireProposal from './ModalExpireProposal';

const ProposalList = () => {
  const { freire } = useFreire();
  const [proposals, setProposals] = useState([]);
  const [plans, setPlans] = useState({})
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [currentProposalId, setCurrentProposalId] = useState(null);

  const showExpireModal = (id) => {
    setCurrentProposalId(id);
    setModalVisible(true);
};

  const handlePageSizeChange = (current, size) => {
    setPageSize(size);
    setCurrentPage(current);
  };

  const fetchData = async (search) => {
    const query = search ? `?textSearch=${search}` : '';
    const response = await api.get(`/proposals${query}`);
    setProposals(response.data);
  };

  const fetchPlans = async() => {
    const response = await api.get('/plans');
    const plansMap = {}
    response.data.forEach(plan => plansMap[plan._id] = plan)
    setPlans(plansMap);
  }
  
  const doSearch = (event) => {
    event.preventDefault();
    fetchData(search);
  }

  const clearSearchOnClick = (event) => {
    event.preventDefault();
    fetchData();
    setSearch('');
  }

  const deleteProposal = async (id) => {
    await api.delete(`/proposals/${id}`);
    fetchData(search);
    setModalVisible(false);
  };


  const getDropdownMenu = (id) => {
    return (
      <div className='proposals-list-actions-dropdown-menu'>
        <Menu>
          <Menu.Item>
            <Link to={`/dashboard/proposal_edit/${id}`}>
              <Button type='secondary'>
                <Icon type='edit'></Icon> Editar
              </Button>
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Button type='danger' onClick={() => showExpireModal(id)}>
              <Icon type='clock-circle'></Icon> Expirar
            </Button>
          </Menu.Item>
        </Menu>
      </div>
    )
  }
  
  useEffect(() => { 
    fetchData();
    fetchPlans(); 
  }, []);

const columns = [
  {
    title: 'ID',
    render: (proposal) => (
      <Tooltip title="Copiar Link da proposta">
        <Button icon="copy" onClick={() => copyToClipboard(proposal.url)}>
          {proposal._id}
        </Button>
      </Tooltip>
    ),
  },
  {
    title: 'Nome do Cliente',
    dataIndex: 'client.name',
    key: 'clientName',
    render: (text) => (
      <div style={{ maxWidth: 200, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {text}
      </div>
    ),
  },
  {
    title: 'Nome da Empresa',
    dataIndex: 'company.name',
    key: 'companyName',
    render: (text) => (
      <div style={{ maxWidth: 200, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {text}
      </div>
    ),
  },
  {
    title: 'Tipo da Proposta',
    dataIndex: 'planId',
    key: 'plan',
    render: (planId) => plans[planId]?.name || planId
  },
  {
    title: 'Data de Criação',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (date) => moment(date).format('DD/MM/YYYY HH:mm:SS')
  },
  {
    title: 'Status',
    render: (proposal) => (
      <Tag color={statusToTagColor[proposal.status]}>{statusToText[proposal.status]}</Tag>
    ),
  },
  {
    title: 'Ações',
    render: (proposal) => (
      <Dropdown overlay={getDropdownMenu(proposal._id)} placement='bottomRight'>
        <Button>
          <Icon type='more'></Icon>
        </Button>
      </Dropdown>
    ),
  }
];

  return (
    <div className='container proposals-list-page'>
      <Breadcrumbs
        breadcrumbs={[
          freire(texts.ADMIN),
          freire(texts.PROPOSAL),
          freire(texts.LIST),
        ]}
      />
      <div className='filter'>
        <div style={{ flexDirection: "row", display: "flex" }}>
          <div className='title-wrapper'>
            <h2>Consulta de Propostas</h2>
          </div>
          <div className='new-proposal-wrapper'>
            <Link to='/dashboard/proposal_creation'>
              <Button type='primary' icon='plus-circle' id='new-proposal'>Nova Proposta</Button>
            </Link>
          </div>
        </div>

        <span>Nome</span>
        <Form onSubmit={doSearch}>
          <Form.Item>
            <div style={{ flexDirection: "row", display: "flex" }}>
              <Input 
                style={{ marginRight: 15 }} 
                type='text' 
                placeholder='Pesquisar por Nome ou Empresa' 
                id='search'
                value={search}
                onChange={(e) => { setSearch(e.target.value) }}
              />
              <Button style={{ marginRight: 15 }} onClick={clearSearchOnClick}>Limpar</Button>
              <Button type="primary" htmlType="submit">Pesquisar</Button>
            </div>
          </Form.Item>
        </Form>

      </div>
      <div>
        <Table
          style={{ backgroundColor: "white" }} 
          dataSource={proposals} columns={columns} 
          rowKey="_id" 
          pagination={{
            pageSize,
            pageSizeOptions: ['10', '20', '50', '100'],
            showSizeChanger: true,
            onShowSizeChange: handlePageSizeChange,
            current: currentPage,
            onChange: setCurrentPage,
          }}
          scroll={{ x: 'max-content' }}
        />
      </div>

      <ModalExpireProposal
        visible={modalVisible}
        proposalId={currentProposalId}
        onCancel={() => setModalVisible(false)}
        onConfirm={() => deleteProposal(currentProposalId)}
      />
    </div>
  );
};

export default ProposalList;
