import moment from "moment";
import {
  WARNING_START_TIME_AFTER_END_TIME,
  WARNING_PRICE_PER_HOUR_VALUE,
} from "../../locales";

type TPricePerHour = {
  start_time: moment.Moment;
  end_time: moment.Moment;
  value: number;
};

export const validateValue = (value: string, callback: any, freire: any) => {
  const valueParse = parseInt(value?.replace(",", "") ?? 0);

  if (valueParse <= 0) {
    callback(freire(WARNING_PRICE_PER_HOUR_VALUE));
  } else {
    callback();
  }
};

export const validateStartTime = (
  end_time: moment.Moment,
  value: moment.Moment,
  callback: any,
  freire: any
) => {
  if (value && end_time && value.isAfter(end_time)) {
    callback(freire(WARNING_START_TIME_AFTER_END_TIME));
  } else {
    callback();
  }
};
export const validateEndTime = (
  start_time: moment.Moment,
  value: moment.Moment,
  callback: any,
  freire: any
) => {
  if (value && start_time && value.isBefore(start_time)) {
    callback(freire(WARNING_START_TIME_AFTER_END_TIME));
  } else {
    callback();
  }
};

export const orderPrices = (prices: TPricePerHour[]) => {
  return prices.sort((a, b) => {
    return a.start_time.diff(b.start_time);
  });
}

export const validateOverlappingRangesInPricePerHour = (
  price_per_hour: TPricePerHour[]
): boolean => {
  const rangeMap = new Map();
  let lastEndTime: moment.Moment | null = null;

  const valid = price_per_hour.map((hour, index) => {
    const key = `${hour.start_time.format("HH:mm")}-${hour.end_time.format(
      "HH:mm"
    )}`;

    if (lastEndTime && hour.start_time.isBefore(lastEndTime)) {
      return true;
    }

    lastEndTime = hour.end_time;

    if (rangeMap.has(key)) {
      const existingRange = rangeMap.get(key);
      if (existingRange) {
        return true;
      }
    } else {
      rangeMap.set(key, hour);
    }

    return false;
  });

  return valid.includes(true);
};
