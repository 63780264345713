export const ALL_RIGHT = {
  pt: "Tudo certo!",
  es: "¡Muy bien!",
  en: "All set!",
};

export const CAMPAIGN_CREATED = {
  pt: "Campanha criada com sucesso!",
  es: "¡Campaña creada con éxito!",
  en: "Campaign successfully created!",
};

export const CAMPAIGN_EDITED = {
  pt: "Campanha editada com sucesso!",
  es: "¡Campaña editada con éxito!",
  en: "Campaign successfully edited!",
};

export const ERROR_EDITED_CAMPAIGN = {
  pt: "Erro ao editar campanha",
  es: "Error al editar la campaña",
  en: "Error editing the campaign",
};

export const CAMPAIGN_DELETED_SUCCESS = {
  pt: "Campanha deletada com sucesso!",
  es: "¡Campaña eliminada con éxito!",
  en: "Campaign successfully deleted!",
};

export const WENT_WONG = {
  pt: "Algo deu errado!",
  es: "¡Algo salió mal!",
  en: "Something went wrong!",
};

export const ERROR_DELETE_CAMPAIGN = {
  pt: "Erro ao deletar campanha",
  es: "Error al eliminar la campaña",
  en: "Error deleting the campaign",
};

export const ERROR_GET_CAMPAIGNS = {
  pt: "Erro ao buscar campanhas",
  es: "Error al buscar campañas",
  en: "Error searching campaigns",
};

export const OOPS = {
  pt: "Ops...",
  es: "Epa...",
  en: "Ops...",
};

export const ONLY_CSV_FILE_ALLOWED = {
  pt: "Só é permitido arquivos .csv",
  es: "Solo se permiten archivos .csv",
  en: "Only .csv files are allowed",
};

export const ADMIN = {
  pt: "Admin",
  es: "Admin",
  en: "Admin",
};

export const SHEET_WRONG = {
  pt: "A planilha está no formato errado",
  es: "La hoja de cálculo está en el formato incorrecto",
  en: "The spreadsheet is in the wrong format",
};

export const ERROR_SHEET_PROCESS = {
  pt: "Algo deu errado ao processar a planilha",
  es: "Algo salió mal al procesar la hoja de cálculo",
  en: "Something went wrong when processing the spreadsheet",
};

export const NEW_CAMPAIGN = {
  pt: "Nova campanha",
  es: "Nueva campaña",
  en: "New campaign",
};

export const CREATE_CAMPAIGN_RFID = {
  pt: "Crie uma campanha de tags RFID",
  es: "Crea una campaña de etiquetas RFID",
  en: "Create a RFID tags campaign",
};

export const NEW = {
  pt: "Novo",
  es: "Nuevo",
  en: "New",
};

export const ALL = {
  pt: "Todas",
  es: "Todas",
  en: "All",
};

export const ACTIVED = {
  pt: "Ativas",
  es: "Activadas",
  en: "Enabled",
};

export const DESACTIVED = {
  pt: "Desativadas",
  es: "Desactivadas",
  en: "Disabled",
};

export const SEARCH = {
  pt: "Pesquisar",
  es: "Buscar",
  en: "Search",
};

export const TAGS_RFID = {
  pt: "Tags RFID",
  es: "Etiquetas RFID",
  en: "RFID Tags",
};

export const COMPANY = {
  pt: "Empresa",
  es: "Empresa",
  en: "Company",
};

export const REQUIRED_FIELD = {
  pt: "Campo obrigatório",
  es: "Campo obligatorio",
  en: "Mandatory field",
};

export const CLIENT = {
  pt: "Cliente",
  es: "Cliente",
  en: "Client",
};

export const OPTIONAL = {
  pt: "(opcional)",
  es: "(opcional)",
  en: "(optional)",
};

export const INFORM_CLIENT_NAME = {
  pt: "Informe o nome do cliente",
  es: "Informe el nombre del cliente",
  en: "Inform the client name",
};

export const CAMPAIGN_NAME = {
  pt: "Nome da campanha",
  es: "Nombre de la campaña",
  en: "Campaign name",
};

export const CAMPAIGN_NAME_EX = {
  pt: "Ex.: Operações Tupi",
  es: "p.ej.: Operaciones de Tupi",
  en: "Example: Operações Tupi",
};

export const CODS_IMPORT = {
  pt: "Importar códigos",
  es: "Importar códigos",
  en: "Import codes",
};

export const UPLOAD_DESCRIPTION = {
  pt: "A planilha importada deve conter uma única coluna com os códigos separados por linha ",
  es: "La hoja de cálculo importada debe contener una sola columna con los códigos separados por línea",
  en: "The spreadsheet must contain a sole column with the codes separated by row",
};

export const SHEET_RFID_EX = {
  pt: "Exemplo - Planilha de Tags RFID",
  es: "p.ej.: - Hoja de cálculo de etiquetas RFID",
  en: "Example - RFID Tags Spreadsheet",
};

export const EXAMPLE = {
  pt: "exemplo",
  es: "ejemplo",
  en: "example",
};

export const SHEET_IMPORT = {
  pt: "Importar planilha",
  es: "Importar hoja de cálculo",
  en: "Import spreadsheet",
};

export const CANCEL = {
  pt: "Cancelar",
  es: "Cancelar",
  en: "Cancel",
};

export const SAVE = {
  pt: "Salvar",
  es: "Guardar",
  en: "Save",
};

export const ERROR_GET_RIFID_CAMPAIGN = {
  pt: "Erro ao buscar os rfid da campanha",
  es: "Error al buscar los rfid de la campaña",
  en: "Error searching campaign's rfid",
};

export const YES = {
  pt: "SIM",
  es: "SÍ",
  en: "YES",
};

export const NO = {
  pt: "NÃO",
  es: "NO",
  en: "NO",
};

export const CODE = {
  pt: "Código",
  es: "Código",
  en: "Code",
};

export const ACTIVE = {
  pt: "Ativa",
  es: "Activada",
  en: "Enabled",
};

export const DESACTIVE = {
  pt: "Desativada",
  es: "Desactivada",
  en: "Disabled",
};

export const CLEAR = {
  pt: "Limpar",
  es: "Limpiar",
  en: "Clear",
};

export const FILTER = {
  pt: "Filtrar",
  es: "Filtrar",
  en: "Filter",
};

export const QTD_USED = {
  pt: "Qtd. usada",
  es: "Ctd. usada",
  en: "Used quantity",
};

export const STATUS = {
  pt: "Status",
  es: "Estado",
  en: "Status",
};

export const LAST_USE = {
  pt: "Último uso",
  es: "Último uso",
  en: "Last use",
};

export const USER = {
  pt: "Usuário",
  es: "Usuario",
  en: "User",
};
export const USER_LOWER_CASE = {
  pt: "usuario",
  es: "usuario",
  en: "user",
};

export const DOWNLOAD_CSV = {
  pt: "Baixar em excel",
  es: "Descargar en excel",
  en: "Download in excel",
};

export const COPY = {
  pt: "Copiar",
  es: "Copiar",
  en: "Copy",
};

export const COPY_SUCCESS = {
  pt: "Copiado com sucesso!",
  es: "¡Copiado con éxito!",
  en: "Successfully copied!",
};

export const CLOSE = {
  pt: "Fechar",
  es: "Cerrar",
  en: "Close",
};

export const QTD_ACTIVE = {
  pt: "Ativos",
  es: "Activos",
  en: "Actived",
};

export const TOTAL = {
  pt: "Total",
  es: "Total",
  en: "Total",
};

export const UPDATED_AT = {
  pt: "Última edição em",
  es: "Última edición el",
  en: "Last edition at",
};

export const ACTIONS = {
  pt: "Ações",
  es: "Acciones",
  en: "Actions",
};

export const CREATED_AT = {
  pt: "Criada em",
  es: "Creada el",
  en: "Created at",
};

export const USES = {
  pt: "Utilizações",
  es: "Usos",
  en: "Uses",
};

export const CAMPAIGN_CSV_FILE_NAME = (name, date) => ({
  pt: `Tags RFID - ${name ?? "Campanha"} - ${date}.csv`,
  es: `Etiquetas RFID - ${name ?? "Campaña"} - ${date}. csv`,
  en: `RFID Tags - ${name ?? "Campaign"} - ${date}.csv`,
});

export const EDIT_CAMPAIGN = {
  pt: "Editar Campanha",
  es: "Editar campaña",
  en: "Edit campaign",
};

export const DELETE = {
  pt: "Deletar",
  es: "Eliminar",
  en: "Delete",
};

export const CONFIRM_DELETE = {
  pt: "Deseja realmente DELETAR?",
  es: "¿Desea realmente ELIMINAR?",
  en: "Do you really want to DELETE?",
};

export const SEE_LIST_RFID_TAGS = {
  pt: "Ver lista de Tags RFID",
  es: "Ver lista de etiquetas RFID",
  en: "See RFID Tags list",
};

export const EDIT = {
  pt: "Editar",
  es: "Editar",
  en: "Edit campaign",
};

export const SELECT_COMPANY = {
  pt: "Selecione a empresa",
  es: "Seleccione la empresa",
  en: "Select a company",
};

export const CAMPAIGN_STATUS = {
  pt: "Status da campanha",
  es: "Estado de la campaña",
  en: "Company status",
};

export const SEARCH_CAMPAIGN = {
  pt: "Pesquise por campanha...",
  en: "Search by campaign...",
  es: "Búsqueda por campaña...",
};

export const DATE_TIME = {
  pt: "L HH[h]mm",
};

export const SHOW = {
  pt: "Exibir",
  es: "Mostrar",
  en: "Show",
};
