import React, { Component } from "react";
import {
  Button,
  Select,
  Input,
  Form,
  Col,
  Row,
  Icon,
  Steps,
  notification,
  message,
} from "antd";
import { withRouter } from "react-router-dom";
import api from "../../../services/api";
import "./index.css";
import * as texts from "../locales";
import { FreireContext } from "../../../utils/freireContext";

const { Step } = Steps;
const { Option } = Select;

class NewVisibilityGroup extends Component {
  static contextType = FreireContext;
  state = {
    loadingSave: false,
    loadingStations: false,
    stations: [],
  };

  getStations() {
    const { freire } = this.context;

    this.setState({ loadingStations: true, stations: [] }, async () => {
      try {
        const { data } = await api.get(`select/connected_stations`);
        this.setState({
          loadingStations: false,
          stations: data,
        });
      } catch (error) {
        message.error(freire(texts.ERROR_GET_STATIONS));
        this.setState({
          loadingStations: false,
        });
      }
    });
  }

  save = () => {
    const { freire } = this.context;

    this.props.form.validateFields(async (err, payload) => {
      if (err) {
        return;
      }

      try {
        this.setState({ loadingSave: true });

        await api.post("visibilityGroup", payload);

        notification.success({
          message: freire(texts.ALL_RIGHT),
          description: freire(texts.VISIBILITY_CREATED_SUCCESS),
        });
        this.setState({ loadingSave: false });

        this.props.history.push("/dashboard/visibility_group");
      } catch (error) {
        this.setState({ loadingSave: false });

        const {
          response: {
            data: { message },
          },
        } = error;

        notification.error({
          message: freire(texts.WENT_WONG),
          description: message || error.message,
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { loadingSave, loadingStations, stations } = this.state;
    const { freire } = this.context;

    return (
      <div className="container">
        <div className="content">
          <Row gutter={[16, 0]}>
            <Col span={6}>
              <Steps current={1} size="small" direction="vertical">
                <Step
                  icon={<Icon type="profile" />}
                  title={freire(texts.NEW_VISIBILITY_GROUP)}
                  description={freire(texts.CREATE_VISIBILITY_GROUP)}
                />
              </Steps>
            </Col>

            <Col span={18}>
              <Form wrapperCol={{ span: 24 }}>
                <Row gutter={[16, 0]}>
                  <Col span={12}>
                    <Form.Item label={freire(texts.GROUP_NAME)}>
                      {getFieldDecorator("name", {
                        rules: [
                          {
                            required: true,
                            message: freire(texts.REQUIRED_FIELD),
                          },
                        ],
                      })(
                        <Input
                          disabled={loadingSave}
                          placeholder={freire(texts.GROUP_NAME_EX)}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={freire(texts.EMAIL_DOMAIN)}>
                      {getFieldDecorator("email", {
                        rules: [
                          {
                            required: false,
                          },
                        ],
                      })(
                        <Input
                          addonBefore="@"
                          disabled={loadingSave}
                          placeholder={freire(texts.EMAIL_EX)}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label={freire(texts.STATIONS)}>
                      {getFieldDecorator("stations", {
                        rules: [
                          {
                            required: true,
                            message: freire(texts.REQUIRED_FIELD),
                          },
                        ],
                      })(
                        <Select
                          mode="multiple"
                          disabled={loadingSave}
                          loading={loadingStations}
                          onDropdownVisibleChange={(opened) => {
                            if (opened && stations.length === 0) {
                              this.getStations();
                            }
                          }}
                          style={{ width: "100%" }}
                          placeholder={
                            loadingStations
                              ? freire(texts.CHARGING)
                              : freire(texts.SELECT_STATIONS)
                          }
                        >
                          {stations.map((station) => {
                            return (
                              <Option
                                key={station._id}
                                value={station.stationID}
                              >
                                {`${station.name} (${station.stationID})`}
                              </Option>
                            );
                          })}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Form>

              <div className="footer">
                <Button
                  style={{ marginRight: 20 }}
                  onClick={() =>
                    this.props.history.push("/dashboard/visibility_group")
                  }
                  type="default"
                  disabled={loadingSave}
                >
                  {freire(texts.CANCEL)}
                </Button>
                <Button
                  loading={loadingSave}
                  onClick={this.save}
                  type="primary"
                >
                  {freire(texts.SAVE)}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const PageNewVisibilityGroup = Form.create({ name: "inputs" })(
  NewVisibilityGroup
);
export default withRouter(PageNewVisibilityGroup);
