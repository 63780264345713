import { useState } from 'react';
import { Col, Icon, Row } from "antd";
import api from "../../../services/api";
import * as texts from "../locales";
import { useFreire } from "../../../utils/freireContext";
import Breadcrumbs from "../../../components/Breadcrumbs";
import "./styles.css";
import { useHistory } from "react-router-dom";
import ProposalForm from '../proposalForm';
import { defaultEmptyProposal } from '../proposalHelper';

const ProposalCreation = () => {
  const { freire } = useFreire();
  const [proposal, setProposal] = useState(defaultEmptyProposal);
  const [error, setError] = useState(null);
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await api.post('/proposals', proposal);

      // Exibir o popup com a URL da nova proposta
      const createdProposal = response.data;
      history.push(`/dashboard/proposal_edit/${createdProposal._id}?showPopup`)
    } catch (err) {
      console.error("Erro ao criar proposta:", err);
      setError('Erro ao criar proposta');
    }
  };

  const handleFormChange = (newProposalState) => {
    setProposal({ ...newProposalState });
  };

  return (
    <div className='container proposal-form'>
      <Breadcrumbs
        breadcrumbs={[
          freire(texts.ADMIN),
          freire(texts.PROPOSAL),
          freire(texts.CREATION),
        ]}
      />
      <div className='page-wrapper'>
        <Row>
          <Col span={6} offset={1}>
            <Icon type="profile" />
            <span className='title-wrapper'>
              Criar proposta
            </span>
          </Col>
          <Col span={12} offset={8}>
            <ProposalForm 
              submitText='Criar Proposta'
              handleSubmit={handleSubmit} 
              onFormChange={handleFormChange}
              proposal={proposal} />
          </Col>
        </Row>
      </div>
      {error && <p>{error}</p>}
    </div>
  );
};

export default ProposalCreation;
