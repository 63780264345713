import moment from 'moment';

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text).then(() => {
    alert("Link copiado para a área de transferência!");
  }).catch((err) => {
    console.error("Erro ao copiar o link: ", err);
  });
};

// how constants arrive in the response
export const PROPOSAL_STATUS = {
  SUBMITTED: 'SUBMITTED',
  FILLED: 'FILLED',
  ACCEPTED: 'ACCEPTED',
  EXPIRED: 'EXPIRED',
};

export const FISCAL_MODEL = {
  TAKE_RATE: 'TAKE_RATE',
  FULL_SERVICE: 'FULL_SERVICE',
  SOFTWARE_LICENCE: 'SOFTWARE_LICENCE',
};

// converters
export const statusToText = {
  SUBMITTED: 'Enviada',
  FILLED: 'Preenchida',
  ACCEPTED: 'Aceita',
  EXPIRED: 'Expirada',
};

export const statusToTagColor = {
  SUBMITTED: 'blue',
  FILLED: 'orange',
  ACCEPTED: 'green',
  EXPIRED: 'red'
};

export const fiscalModelToText = {
  TAKE_RATE: 'Take rate',
  FULL_SERVICE: 'Full service',
  SOFTWARE_LICENCE: 'Licença de software',
};

export const defaultEmptyProposal = {
  planId: null,
  fiscalModel: FISCAL_MODEL.TAKE_RATE,
  tupiRate: '',
  expirationDate: moment().add(1, 'month').toISOString(),
  status: PROPOSAL_STATUS.SUBMITTED,
  client: {
    name: '',
    email: '',
    cpf: '',
  },
  company: {
    name: '',
    cnpj: '',
    municipalRegistrationNumber: '',
    stateRegistrationNumber: '',
    cep: '',
    address: '',
    addressComplement: '',
  },
  chargers: {
    quantity: '',
    brands: [],
  },
}