export const formatCurrency = (value) => {
  value = value.replace(/\D/g, "");
  value = String(Number(value));
  var len = value.length;
  if (1 === len) value = value.replace(/(\d)/, "0.0$1");
  else if (2 === len) value = value.replace(/(\d)/, "0.$1");
  else if (len > 2) {
    value = value.replace(/(\d{2})$/, ".$1");
    if (len > 5) {
      var x = len - 5,
        er = new RegExp("(\\d{" + x + "})(\\d)");
      value = value.replace(er, "$1.$2");
    }
  }
  return value;
};