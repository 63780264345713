import React from 'react';
import { Button, Col, DatePicker, Divider, Form, Input, InputNumber, Row, Select, Tag } from "antd";
import { Link } from 'react-router-dom';
import api from "../../services/api";
import moment from 'moment';
import { PROPOSAL_STATUS, FISCAL_MODEL, statusToText, fiscalModelToText, statusToTagColor, copyToClipboard } from './proposalHelper';


const showTupiRate = proposal => proposal.fiscalModel !== FISCAL_MODEL.SOFTWARE_LICENCE;

const currencyFormatter = value => {
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formatter.format(value);
};
const percentFormatter = value => `${value}%`;
const percentParser = value => value.replace('%', '');
const dateFormat = 'DD/MM/YYYY';

class ProposalForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chargerBrands: [],
      availablePlans: [],
      plansMap: {},
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();

    if (!showTupiRate(this.props.proposal)) 
      this.props.proposal.tupiRate = 0;

    this.props.form.validateFieldsAndScroll(
      { scroll: { offsetTop: 50 } },
      async (err, values) => {
        if (err) {
          return;
        }
        this.props.handleSubmit(e);
      }
    )
  };

  handleChange = (field, value) => {
    const { proposal, onFormChange } = this.props;
    const newState = { ...proposal, [field]: value };
    onFormChange(newState);
  };

  componentDidMount() {
    api.get('/station-models').then((response) => {
      const { data } = response
      const chargerBrands = data.map((brand) => <Select.Option key={brand.name}>{brand.name}</Select.Option>)
      this.setState({ chargerBrands })
    });

    api.get('/plans').then((response) => {
      const { data } = response
      const availablePlans = data
        .sort((a, b) => a.enabled && !b.enabled ? -1 : 1)
        .map((plan) => <Select.Option key={plan._id} disabled={!plan.enabled}>{plan.name}</Select.Option>)
      this.setState({ availablePlans })
      const plansMap = {}
      response.data.forEach(plan => plansMap[plan._id] = plan)
      this.setState({ plansMap })
    });
  }

  getPricePerCharger = (proposal) => proposal?.planId?.price || this.state.plansMap[proposal?.planId]?.price || 0;

  render() {
    const { submitText, proposal } = this.props;
    const { client, company, chargers } = proposal;
    const { getFieldDecorator } = this.props.form;
    const isProposalAccepted = proposal.status === PROPOSAL_STATUS.ACCEPTED;

    return (
      <div className='form-wrapper'>
        <Form>
          {proposal._id && (
            <div>
              <h1>Informações da proposta</h1>
              <Row>
                <Col span={17}>
                  <Form.Item label='ID da Proposta'>
                    <Input
                      type="text"
                      value={proposal._id}
                      readOnly
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col span={3} offset={4}>
                  <Tag 
                    color={statusToTagColor[proposal.status]} 
                    style={{ marginTop: '40px', fontSize: '16px', padding: '6px 12px' }}
                  >
                    {statusToText[proposal.status]}
                  </Tag>
                </Col>
              </Row>
              <Form.Item label='Link da Proposta'>
                <Input
                  type="text"
                  value={proposal.url}
                  className='readonly-proposal-input'
                  readOnly
                />
                <Button type='link' onClick={() => copyToClipboard(proposal.url)}>Copiar link</Button>
              </Form.Item>
              <Divider />
            </div>
          )}
          <h1>Informações do Cliente</h1>
          <Form.Item label='Nome do Cliente'>
            <Input
              type="text"
              value={client.name}
              onChange={(e) => this.handleChange('client', { ...client, name: e.target.value })}
              maxLength={100}
              readOnly={isProposalAccepted}
            />
          </Form.Item>
          <Form.Item label='Email do Cliente'>
            <Input
              type="email"
              value={client.email}
              onChange={(e) => this.handleChange('client', { ...client, email: e.target.value })}
              maxLength={254}
              readOnly={isProposalAccepted}
            />
          </Form.Item>
          <Form.Item label='CPF do Cliente'>
            <Input
              type="text"
              value={client.cpf}
              onChange={(e) => this.handleChange('client', { ...client, cpf: e.target.value })}
              maxLength={14}
              readOnly={isProposalAccepted}
            />
          </Form.Item>
          <Divider />
          <h1>Informações da Companhia</h1>
          <Form.Item label='Nome da Companhia'>
            <Input
              type="text"
              value={company.name}
              onChange={(e) => this.handleChange('company', { ...company, name: e.target.value })}
              maxLength={100}
              readOnly={isProposalAccepted}
            />
          </Form.Item>
          <Form.Item label='CNPJ da Companhia'>
            <Input
              type="text"
              value={company.cnpj}
              onChange={(e) => this.handleChange('company', { ...company, cnpj: e.target.value })}
              maxLength={18}
              readOnly={isProposalAccepted}
            />
          </Form.Item>
          <Form.Item label='Inscrição Municipal'>
            <Input
              type="text"
              value={company.municipalRegistrationNumber}
              onChange={(e) => this.handleChange('company', { ...company, municipalRegistrationNumber: e.target.value })}
              maxLength={15}
              readOnly={isProposalAccepted}
            />
          </Form.Item>
          <Form.Item label='Inscrição Estadual'>
            <Input
              type="text"
              value={company.stateRegistrationNumber}
              onChange={(e) => this.handleChange('company', { ...company, stateRegistrationNumber: e.target.value })}
              maxLength={15}
              readOnly={isProposalAccepted}
            />
          </Form.Item>
          <Form.Item label='CEP'>
            <Input
              type="text"
              value={company.cep}
              onChange={(e) => this.handleChange('company', { ...company, cep: e.target.value })}
              maxLength={9}
              readOnly={isProposalAccepted}
            />
          </Form.Item>
          <Form.Item label='Endereço'>
            <Input.TextArea
              value={company.address}
              onChange={(e) => this.handleChange('company', { ...company, address: e.target.value })}
              maxLength={200}
              readOnly={isProposalAccepted}
            />
          </Form.Item>
          <Form.Item label='Complemento'>
            <Input.TextArea
              value={company.addressComplement}
              onChange={(e) => this.handleChange('company', { ...company, addressComplement: e.target.value })}
              maxLength={100}
              readOnly={isProposalAccepted}
            />
          </Form.Item>
          <Divider />
          <h1>Operação</h1>
          <Row>
            <Col span={11}>
              <Form.Item label='Plano' required>
                {getFieldDecorator('planId', {
                  initialValue: typeof proposal.planId === 'string' 
                      ? proposal.planId
                      : proposal.planId?._id,
                  rules: [{ required: true, message: 'Selecione um plano!' }],
                })(
                  <Select 
                    onChange={(value) => this.handleChange('planId', value )}
                    placeholder='Clique para selecionar'
                    disabled={isProposalAccepted}
                  >
                    { this.state.availablePlans }
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={11} offset={2}>
              <Form.Item label='Valor por Carregador'>
                <InputNumber
                  className='readonly-proposal-input'
                  value={this.getPricePerCharger(proposal)}
                  precision={2}
                  formatter={currencyFormatter}
                  readOnly
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={11}>
            
            <Form.Item label='Quantidade de Carregadores'>
              <InputNumber
                value={chargers.quantity}
                onChange={(value) => this.handleChange('chargers', { ...chargers, quantity: value })}
                max={999}
                readOnly={isProposalAccepted}
              />
            </Form.Item>

            </Col>
            <Col span={11} offset={2}>
              <Form.Item label='Valor Total'>
                <InputNumber
                  value={Number(this.getPricePerCharger(proposal) || 0) * Number(chargers.quantity || 0)}
                  className='readonly-proposal-input'
                  precision={2}
                  formatter={currencyFormatter}
                  readOnly
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={11}>
              <Form.Item label='Marcas dos Carregadores'>
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  value={chargers.brands}
                  placeholder="Clique para selecionar"
                  onChange={(value) => this.handleChange('chargers', { ...chargers, brands: value })}
                  disabled={isProposalAccepted}
                >
                  {this.state.chargerBrands}
                </Select>
              </Form.Item>
            </Col>
            <Col span={11} offset={2}>
              <Form.Item label='Modelo Fiscal' required>
                <Select 
                  value={proposal.fiscalModel || FISCAL_MODEL.TAKE_RATE} 
                  onChange={(value) => this.handleChange('fiscalModel', value )}
                  disabled={isProposalAccepted}
                >
                  <Select.Option value={FISCAL_MODEL.TAKE_RATE}>{fiscalModelToText[FISCAL_MODEL.TAKE_RATE]}</Select.Option>
                  <Select.Option value={FISCAL_MODEL.FULL_SERVICE}>{fiscalModelToText[FISCAL_MODEL.FULL_SERVICE]}</Select.Option>
                  <Select.Option value={FISCAL_MODEL.SOFTWARE_LICENCE}>{fiscalModelToText[FISCAL_MODEL.SOFTWARE_LICENCE]}</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row className={showTupiRate(proposal) ? 'visible' : 'hidden'}>
            <Col span={11}>
              <Form.Item label='Taxa da Tupi' required={showTupiRate(proposal)}>
                {getFieldDecorator('tupiRate', {
                  initialValue: proposal.tupiRate,
                  rules: [
                    { 
                      required: showTupiRate(proposal), 
                      message: 'Defina a taxa!' 
                    },
                    {
                      validator: (rule, value, callback) => {
                        if (value < 0) {
                          callback('O valor deve ser maior que 0!');
                        } else if (value > 100) {
                          callback('O valor deve ser menor que 100!');
                        } else {
                          callback();
                        }
                      }
                    },
                  ],
                })(
                  <InputNumber
                    onChange={(value) => this.handleChange('tupiRate', value )}
                    precision={2}
                    min={0}
                    max={100}
                    step={0.1}
                    formatter={percentFormatter}
                    parser={percentParser}
                    readOnly={isProposalAccepted}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={11} offset={2}>
              <Form.Item label='Taxa do Cliente'>
                <InputNumber
                  value={proposal.tupiRate ? (100 - proposal.tupiRate) : 0}
                  className='readonly-proposal-input'
                  precision={2}
                  formatter={percentFormatter}
                  parser={percentParser}
                  readOnly
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={11}>
              <Form.Item label='Data de Expiração' required>
                <DatePicker 
                  value={
                    proposal.expirationDate 
                      ? moment(proposal.expirationDate) 
                      : moment().add(1, 'month')
                  }
                  format={dateFormat} 
                  style={{width: '100%'}}
                  onChange={(value) => this.handleChange('expirationDate', value.toISOString() )}
                  disabled={isProposalAccepted}
                />
              </Form.Item>
            </Col>
          </Row>
          
          <div className='footer'>
            <Link to='/dashboard/proposal_list'>
              <Button type="secondary">Cancelar</Button>
            </Link>
            <Button type="primary" onClick={this.handleSubmit}>{submitText}</Button>
          </div>
        </Form>
      </div>
    );
  }
}

export default Form.create({ name: "edit" })(ProposalForm);