import api from "./api";
import firebase from "./firebaseConfig";

export const isAuthenticated = async () => {
  return !firebase.auth().currentUser !== null;
};

export const getCurrentUser = async () => {
  return firebase.auth().currentUser.toJSON();
};

export const getCompanyCurrentUser = async () => {
  const user = firebase.auth().currentUser;

  if (user) {
    const result = await user.getIdTokenResult();
    if (result && result.claims && result.claims.companyID) {
      return {
        id: result.claims.companyID,
        parentCompanyID: result.claims.parentCompanyID,
        name: result.claims.companyName,
      };
    }
  }

  return null;
};

export const getUserPermissions = async () => {
  try {
    const { data } = await api.get("/auth/permissions");

    if (data?.permissions) {
      const objPermissions = {};
      data.permissions.forEach((p) => (objPermissions[p] = true));

      return objPermissions;
    }

    return {};
  } catch (error) {}
};
