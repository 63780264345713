import { Radio, Input, Button, Col, Form, Row, DatePicker } from "antd";
import TupiSelect from "../TupiSelect";
import { Show, useAccess } from "../../contexts/PermissionContext";
import { forwardRef, useCallback } from "react";
import { useFreire } from "../../utils/freireContext";
import * as texts from "./locales";
import "./styles.css";
import { IGenerateFilterProps } from "../../interfaces/generateFilter";
const { RangePicker } = DatePicker;

const GenerateFilter = forwardRef(
  ({ form, fields, handleClear, handleFilter }: IGenerateFilterProps , ref: any) => {
    const { freire } = useFreire();
    const { hasPermission } = useAccess();

    const formatMainFilterConfig = useCallback(
      (inputSearch, buttonNew) => ({
        inputSearch: {
          key: "name",
          label: freire(texts.NAME),
          placeholder: freire(texts.FILTER_BY_NAME),
          ...inputSearch,
        },
        buttonNew: {
          permission: undefined,
          text: freire(texts.NEW),
          onPress: () => {},
          ...buttonNew,
        },
      }),
      [freire]
    );

    const onClear = () => {
      if (handleClear) handleClear();
    };

    const { getFieldDecorator } = form;
    return (
      <div className="filterContainer" ref={ref}>
        <Form wrapperCol={{ span: 24 }}>
          <strong>{freire(texts.FILTER_BY)}:</strong>

          <Row gutter={[16, 8]}>
            {fields.map((field) => {
              if (field.isMainFilter) {
                const { inputSearch, buttonNew } = formatMainFilterConfig(
                  field.inputSearch,
                  field.buttonNew
                );

                const canCreate = hasPermission(buttonNew.permission);

                return (
                  <Col span={24} key="mainFilter">
                    <Form.Item
                      colon={false}
                      label={inputSearch.label}
                      help={field.help}
                    >
                      <Row
                        gutter={[16, 8]}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Col span={canCreate ? 20 : 24}>
                          <div className="mainFilterContainer">
                            {getFieldDecorator(inputSearch.key as never)(
                              <Input
                                className="mainFilterInput"
                                onPressEnter={handleFilter}
                                placeholder={inputSearch.placeholder}
                              />
                            )}
                            <Button
                              icon="search"
                              className="mainFilterBtnSearch"
                              onClick={handleFilter}
                            >
                              {freire(texts.SEARCH)}
                            </Button>
                          </div>
                        </Col>
                        {canCreate && (
                          <Col span={4}>
                            <Button
                              icon="plus-circle"
                              className="mainFilterBtnNew"
                              onClick={buttonNew.onPress}
                              type="primary"
                            >
                              {buttonNew.text}
                            </Button>
                          </Col>
                        )}
                      </Row>
                    </Form.Item>
                  </Col>
                );
              }
              const when = field.when ?? undefined;
              return (
                <Show
                  when={when}
                  key={field.name}
                >
                  <Col span={field.colSpan}>
                    <Form.Item
                      colon={false}
                      label={field.label}
                      help={field.help}
                    >
                      {field.component === "tupi.select" &&
                        getFieldDecorator(field.name)(
                          <TupiSelect
                            form={form}
                            fieldName={field.name!}
                            enableAllSelect
                            multiple={field.isMultiple}
                            onChange={field.onChange}
                            options={field.options!}
                            {...(typeof field.placeholder === "string" && {
                              placeholder: field.placeholder
                            })}
                          />
                        )}
                      {field.component === "tupi.radioGroup" &&
                        getFieldDecorator(field.name)(
                          <Radio.Group buttonStyle="solid">
                            {field.options!.map(({ value, label }) => (
                              <Radio.Button value={value} key={label}>
                                {label}
                              </Radio.Button>
                            ))}
                          </Radio.Group>
                        )}
                      {field.component === "rangePicker" &&
                        getFieldDecorator(field.name)(
                          <RangePicker
                            locale={field.locale}
                            {...(typeof field.placeholder !== "string" && {
                              placeholder: field.placeholder})}
                            style={{ width: "100%" }}
                          />
                        )}
                    </Form.Item>
                  </Col>
                </Show>
              );
            })}
          </Row>
        </Form>

        <div className="footer">
          <Button style={{ marginRight: 10 }} onClick={onClear}>
            {freire(texts.CLEAN)}
          </Button>
          <Button onClick={handleFilter} type="primary">
            {freire(texts.FILTER)}
          </Button>
        </div>
      </div>
    );
  }
);

export default GenerateFilter;
