export const TRY_AGAIN = {
  pt: "Tentar Novamente",
  es: "Intentar de nuevo",
  en: "Try again",
};

export const RESET_FILTER = {
  pt: "Limpar filtro",
  // TODO: translate es
  en: "Clear filter",
};

export const RESET_FILTERS = {
  pt: "Limpar filtros",
  // TODO: translate es
  en: "Clear filters",
};

export const SHOW_DETAILS = {
  pt: "Ver detalhes",
  es: "Ver detalles",
  en: "See details",
};

export const ERROR_FIND_COMPANY = {
  pt: "Erro ao buscar as empresas",
  es: "Error al buscar las empresas",
  en: "Error searching companies",
};

export const ERROR_FIND_STATIONS = {
  pt: "Erro ao buscar as estações",
  es: "Error al buscar las estaciones",
  en: "Error searching stations",
};

export const ERROR_GET_DATA = {
  pt: "Erro ao buscar dados",
  es: "Error al buscar datos",
  en: "Error searching data",
};

export const ERROR_NO_AVAILABILITY = (connectionText) => ({
  pt: `Nenhuma estação${
    connectionText === undefined ? "" : ` ${connectionText}`
  } no momento.`,
  // TODO: translate es
  // TODO: translate ,
});

export const ADMIN = {
  pt: "Admin",
  es: "Admin",
  en: "Admin",
};

export const AVAILABILITY = {
  pt: "Disponibilidade",
  es: "Disponibilidad",
  en: "Availability",
};

export const SYSTEM = {
  pt: "Sistema",
  es: "Sistema",
  en: "System",
};

export const STATIONS = {
  pt: "Estações",
  es: "Estaciones",
  en: "Stations",
};

export const STATIONS_REAL = {
  pt: "Estações (real)",
  es: "Estaciones (real)",
  en: "Stations (real)",
};

export const STATIONS_24H = {
  pt: "Estações (24H)",
  es: "Estaciones (24H)",
  en: "Stations (24H)",
};

export const OF = {
  pt: "de",
  // TODO: translate es
  en: "of",
};

export const OF_STATIONS = (firstRange, secondRange, total) => ({
  pt: (
    <>
      {firstRange}-{secondRange} {OF["pt"]} <strong>{total}</strong>{" "}
      {total === 1 ? "estação" : "estações"}
    </>
  ),
  // TODO: translate es
  en: (
    <>
      {firstRange}-{secondRange} {OF["en"]} <strong>{total}</strong> station
      {total === 1 ? "" : "s"}
    </>
  ),
});

export const COMPANIES = {
  pt: "Empresas",
  es: "Empresas",
  en: "Companies",
};

export const CONNECTION = {
  pt: "Conexão",
  // TODO: translate es
  en: "Connection",
};

export const DEFAULT_CONNECTION = () => ({
  pt: "Todas",
  // TODO: translate es
  en: "All",
});

export const CONNECTED_CONNECTION = (count = 0) => ({
  pt: `Conectada${count === 1 ? "" : "s"}`,
  // TODO: translate es
  en: "Connected",
});

export const DISCONNECTED_CONNECTION = (count = 0) => ({
  pt: `Desconectada${count === 1 ? "" : "s"}`,
  // TODO: translate es
  en: "Disconnected",
});

export const SELECT_COMPANIES = {
  pt: "Selecione as empresas",
  es: "Seleccione las empresas",
  en: "Select companies",
};

export const SELECT_STATIONS = {
  pt: "Selecione as estações",
  es: "Seleccione las estaciones",
  en: "Select stations",
};

export const UPDATE = {
  pt: "Atualizar",
  es: "Actualizar",
  en: "Update",
};

export const STATIONS_AVAILABILITY = {
  pt: "Disponibilidade das Estações",
  es: "Disponibilidad de las Estaciones",
  en: "Stations Availability",
};

export const STATIONS_AVAILABILITY_REAL = {
  pt: "Disponibilidade das Estações <br />no horário de funcionamento",
  es: "Disponibilidad de las Estaciones <br />en el Horario de Funcionamiento",
  en: "Stations Availability <br />during Operating Hours",
};

export const SYSTEM_AVAILABILITY = {
  pt: "Disponibilidade do Sistema",
  es: "Disponibilidad del Sistema",
  en: "System Availability",
};

export const LAST_12_HOURS = {
  pt: "Últimas 12 horas",
  es: "Las últimas 12 horas",
  en: "Last 12 hours",
};

export const LAST_24_HOURS = {
  pt: "Últimas 24 horas",
  es: "Las últimas 24 horas",
  en: "Last 24 hours",
};

export const LAST_7_DAYS = {
  pt: "Últimos 7 dias",
  es: "Los últimos 7 días",
  en: "Last 7 days",
};

export const LAST_30_DAYS = {
  pt: "Últimos 30 dias",
  es: "Los últimos 30 días",
  en: "Last 30 days",
};

export const LAST_60_DAYS = {
  pt: "Últimos 60 dias",
  es: "Los últimos 60 días",
  en: "Last 60 days",
};

export const LAST_90_DAYS = {
  pt: "Últimos 90 dias",
  es: "Los últimos 90 días",
  en: "Last 90 days",
};

export const OFFLINE_TIME = {
  pt: "Tempo Offline",
  es: "Tiempo Offline",
  en: "Offline time",
};

export const DISCONNECTIONS_ALT = {
  pt: "Quedas",
  // TODO: translate es
  // TODO: translate en
};

// TODO: review
export const AGO = {
  pt: "atrás",
  es: "atrás",
  en: "ago",
};

export const NOW = {
  pt: "agora",
  es: "ahora",
  en: "now",
};

export const DISCONNECTIONS = (count = 0) => ({
  pt: `${count} ${count === 1 ? "desconexão" : "desconexões"}`,
  es: `${count} ${count === 1 ? "desconexión" : "desconexiones"}`,
  en: `${count} ${count === 1 ? "disconnection" : "disconnections"}`,
});

export const DISCONNECTIONS_REAL = (count = 0) => ({
  pt: `${count} ${count === 1 ? "desconexão" : "desconexões"} dentro do horário de funcionamento`,
  es: `${count} ${count === 1 ? "desconexión" : "desconexiones"} dentro del horario de funcionamiento`,
  en: `${count} ${count === 1 ? "disconnection" : "disconnections"} within operating hours`,
});

export const NO_DISCONNECT = {
  pt: "Nenhuma desconexão",
  es: "No hay desconexión",
  en: "No disconnection",
};

export const NO_DISCONNECT_REAL = {
  pt: "Nenhuma desconexão dentro do horário de funcionamento",
  es: "No hay desconexión dentro del horario de funcionamiento",
  en: "No disconnection within operating hours",
};




export const SELECT_ALL_COMPANIES = (count = 0) => ({
  pt: `Selecionar todas as empresas (${count})`,
  es: `Seleccionar todas las empresas (${count})`,
  en: `Select all companies (${count})`,
});

export const SELECT_ALL_STATIONS = (count = 0) => ({
  pt: `Selecionar todas as estações (${count})`,
  es: `Seleccionar todas las estaciones (${count})`,
  en: `Select all stations (${count})`,
});

export const LAST_UPDATE_LOADING = {
  pt: "Última atualização em",
  es: "Última actualización el",
  en: "Last update in",
};

export const CALCULATED_REAL_STATIONS_TIME = {
  pt: "Calculado com base no horário de funcionamento das estações.",
  es: "Calculado con base en el horario de funcionamiento de las estaciones.",
  en: "Calculated based on the operating hours of the stations.",
};


export const LAST_UPDATE = (date, time) => ({
  pt: `${LAST_UPDATE_LOADING["pt"]} ${date} às ${time}.`,
  es: `${LAST_UPDATE_LOADING["es"]} ${date} a ${time}.`,
  en: `${LAST_UPDATE_LOADING["en"]} ${date} at ${time}.`,
});

export const LAST_UPDATE_REAL = (date, time) => ({
  pt: `${LAST_UPDATE_LOADING["pt"]} ${date} às ${time}. ${CALCULATED_REAL_STATIONS_TIME["pt"]}`,
  es: `${LAST_UPDATE_LOADING["es"]} ${date} a ${time}. ${CALCULATED_REAL_STATIONS_TIME["es"]}`,
  en: `${LAST_UPDATE_LOADING["en"]} ${date} at ${time}. ${CALCULATED_REAL_STATIONS_TIME["en"]}`,
});

export const LOW_AVAILABILITY = (count = 0) => ({
  pt: `${count} ${
    count === 1 ? "estação" : "estações"
  } com disponibilidade baixa`,
  es: `${count} ${
    count === 1 ? "estación" : "estaciones"
  } con baja disponibilidad`,
  en: `${count} station${count === 1 ? "" : "s"} with low availability`,
});

export const LOW_AVAILABILITY_REAL = (count = 0) => ({
  pt: `${count} ${count === 1 ? "estação" : "estações"} com disponibilidade baixa <br />dentro do horário de funcionamento`,
  es: `${count} ${count === 1 ? "estación" : "estaciones"} con baja disponibilidad <br />dentro del horario de funcionamiento`,
  en: `${count} station${count === 1 ? "" : "s"} with low availability <br />during operating hours`,
});

export const LOW_AVAILABILITY_DESCRIPTION = (percentage) => ({
  pt: `Estações com disponibilidade abaixo de ${percentage}.`,
  // TODO: translate es
  // TODO: translate en
});

export const ALL = (count = 0, label) => ({
  pt: `${count} ${label} (todas)`,
  // TODO: translate es`,
  en: `${count} ${label} (all)`,
});

export const MONTH_TIME = {
  pt: "DD/MM LT",
  es: "DD/MM LT",
  en: "MM/DD LT",
};

export const DASHBOARD = {
  pt: "Dashboard",
  es: "Tablero de mandos",
  en: "Dashboard",
};
