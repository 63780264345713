import api from "../../services/api";
import {
  GetCitiesResponse,
  GetCityResponse,
  GetProvinceResponse,
  GetProvincesResponse,
} from "./types";
import { getEstados, getMunicipiosByEstado } from "../ibge";
import { COUNTRY_AR, COUNTRY_BR } from "../../constants/countries";

export const getRecommendedCountries = async () => {
  const {
    data: { countries },
  } = await api.get("/countries/recommended");

  return countries;
};

export const getStatesByCountry = async (countryCode: string) => {
  const getStates = async () => {
    if (countryCode === COUNTRY_BR) {
      return getEstados();
    }

    // TODO: verificar necessidade
    if (countryCode === COUNTRY_AR) {
      return getProvincesByCountry(countryCode);
    }

    return [];
  };

  return await getStates();
};

export const getCitiesByState = async (countryCode: string, state: string) => {
  const getCities = async () => {
    if (countryCode === COUNTRY_BR) {
      return getMunicipiosByEstado(state);
    }

    // TODO: verificar necessidade
    if (countryCode === COUNTRY_AR) {
      return getCitiesByProvince(countryCode, state);
    }

    return [];
  };

  return await getCities();
};

export const getProvincesByCountry = async (countryCode: string) => {
  const {
    data: { provinces },
  } = await api.get<GetProvincesResponse>(
    `/countries/${countryCode}/provinces`
  );

  return provinces;
};

export const getProvinceByCode = async (
  countryCode: string,
  provinceCode: string
) => {
  const {
    data: { province },
  } = await api.get<GetProvinceResponse>(
    `/countries/${countryCode}/provinces/${provinceCode}`
  );

  return province;
};

export const getCitiesByProvince = async (
  countryCode: string,
  provinceCode: string
) => {
  const {
    data: { cities },
  } = await api.get<GetCitiesResponse>(
    `/countries/${countryCode}/provinces/${provinceCode}/cities`
  );

  return cities;
};

export const getCityByProvinceAndId = async (
  countryCode: string,
  provinceCode: string,
  cityId: string
) => {
  const {
    data: { city },
  } = await api.get<GetCityResponse>(
    `/countries/${countryCode}/provinces/${provinceCode}/cities/${cityId}`
  );

  return city;
};

export const getCityById = async (countryCode: string, cityId: string) => {
  const {
    data: { city },
  } = await api.get<GetCityResponse>(
    `/countries/${countryCode}/cities/${cityId}`
  );

  return city;
};
