import { Button, Tooltip } from "antd";
import { Popconfirm } from "antd";
import { Icon } from "antd";
import moment from "moment";
import { Show } from "../../../contexts/PermissionContext";
import * as texts from "../locales";

export const getInitialColumns = ({
  handleClickEdit,
  handleClickTagRFID,
  handleClickDelete,
  freire,
}) => [
  {
    title: freire(texts.COMPANY),
    dataIndex: "company",
    key: "company",
    render: (company) => (
      <span>{(company && company.companyName) || "---"}</span>
    ),
  },
  {
    title: freire(texts.CLIENT),
    dataIndex: "clientName",
    key: "clientName",
    render: (clientName) => <span>{clientName || "---"}</span>,
  },
  {
    title: freire(texts.CAMPAIGN_NAME),
    dataIndex: "name",
    key: "name",
    width: 200,
  },
  {
    title: freire(texts.STATUS),
    dataIndex: "enabled",
    key: "enabled",
    render: (enabled) =>
      enabled ? (
        <Icon style={{ color: "green" }} type="check" />
      ) : (
        <Icon style={{ color: "red" }} type="close" />
      ),
  },
  {
    title: freire(texts.QTD_ACTIVE),
    dataIndex: "amountActive",
    key: "amountActive",
  },
  {
    title: freire(texts.TOTAL),
    dataIndex: "amountTags",
    key: "amountTags",
  },
  {
    title: freire(texts.USES),
    dataIndex: "amountUsage",
    key: "amountUsage",
  },
  {
    title: freire(texts.UPDATED_AT),
    dataIndex: "updatedAt",
    key: "updatedAt",
    render: (date) => <span>{moment(date).format("L LTS")}</span>,
  },
  {
    title: freire(texts.CREATED_AT),
    dataIndex: "createdAt",
    key: "createdAt",
    render: (date) => <span>{moment(date).format("L LTS")}</span>,
  },
  {
    title: freire(texts.ACTIONS),
    key: "acoes",
    width: 150,
    render: (rfidCampaign) => (
      <>
        <Show when={"edit-tags-rfid"}>
          <Tooltip placement="top" title={freire(texts.EDIT)}>
            <Button
              shape="circle"
              size="small"
              ghost
              type="primary"
              style={{ marginRight: 8 }}
              onClick={() => handleClickEdit(rfidCampaign)}
            >
              <Icon type="edit" />
            </Button>
          </Tooltip>
        </Show>

        <Show when={"show-tags-rfid-list"}>
          <Tooltip placement="top" title={freire(texts.SEE_LIST_RFID_TAGS)}>
            <Button
              shape="circle"
              size="small"
              ghost
              type="primary"
              style={{ marginRight: 8 }}
              onClick={() => handleClickTagRFID(rfidCampaign)}
            >
              <Icon type="tags" />
            </Button>
          </Tooltip>
        </Show>

        <Show when={"delete-tags-rfid"}>
          <Tooltip placement="top" title={freire(texts.DELETE)}>
            <Popconfirm
              placement="top"
              title={freire(texts.CONFIRM_DELETE)}
              okText={freire(texts.YES)}
              cancelText={freire(texts.NO)}
              onConfirm={() => handleClickDelete(rfidCampaign)}
            >
              <Button
                style={{ marginRight: 8 }}
                shape="circle"
                size="small"
                ghost
                type="danger"
              >
                <Icon type="delete" />
              </Button>
            </Popconfirm>
          </Tooltip>
        </Show>
      </>
    ),
  },
];
