import React, { memo } from "react";
import { Popover } from "antd";
import moment from "moment-timezone";
import { formatters } from "../../../../../utils/formatters";
import * as texts from "../../../locales";
import "./styles.css";

const getPeriodText = (currentTab, period, freire) => {
  if (currentTab.fullDayBlocks) {
    return moment(period.start).format("L");
  }

  const format = freire(texts.MONTH_TIME);

  return `${moment(period.start).format(format)} - ${moment(period.end).format(
    format
  )}`;
};

const Details = memo(
  ({ formattedPeriod, offlineTime, maxOfflineTime, empty, freire }) => {
    const offlineTimeSecondPrecision = Math.floor(offlineTime / 1000) * 1000;
    const onlineTime = empty
      ? 0
      : maxOfflineTime.clone().subtract(offlineTimeSecondPrecision).valueOf();
    const _offlineTime = empty ? 0 : offlineTimeSecondPrecision;

    return (
      <div className="contentPopover">
        <span>{formattedPeriod}</span>
        <div className="times">
          <div className="time">
            <div className="squareColor green" />
            <span>{formatters.durationOneDayTweak(freire)(onlineTime)}</span>
          </div>
          <div className="time">
            <div className="squareColor red" />
            <span>{formatters.durationOneDayTweak(freire)(_offlineTime)}</span>
          </div>
        </div>
      </div>
    );
  }
);

export const BlockColor = memo(
  ({ currentTab, block, maxOfflineTime, freire }) => (
    <Popover
      content={
        <Details
          formattedPeriod={getPeriodText(currentTab, block.period, freire)}
          offlineTime={block.offlineTime}
          maxOfflineTime={maxOfflineTime}
          empty={block.empty}
          freire={freire}
        />
      }
    >
      <div
        className={`blockColor ${
          block.empty ? "white" : block.color.toLowerCase()
        }`}
      />
    </Popover>
  )
);
