const DIGITS = 2;

export const getCurrencySymbol = (locale, currency) =>
  (0)
    .toLocaleString(locale, {
      style: "currency",
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, "")
    .trim();

export const currency =
  (locale, currency = "BRL") =>
  (value = null, digits = DIGITS) =>
    value === null
      ? getCurrencySymbol(locale, currency)
      : value.toLocaleString(locale, {
          style: "currency",
          currency,
          minimumFractionDigits: digits,
          maximumFractionDigits: digits,
        });
