import { Button, Spin, notification } from "antd";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Show } from "../../contexts/PermissionContext";
import api from "../../services/api";
import { useFreire } from "../../utils/freireContext";
import StatusWithDot from "../StatusWithDot";
import * as texts from "./locales";
import styles from "./styles.module.css";

const CardStatusStations = forwardRef(({ filter = () => {} }, ref) => {
  const [data, setData] = useState({
    total: 0,
    operation: {
      connected: 0,
      total: 0,
    },
    status: {
      Available: 0,
      Charging: 0,
      Reserved: 0,
      Faulted: 0,
      Unavailable: 0,

      Preparing: 0,
      Finishing: 0,
      SuspendedEV: 0,
      SuspendedEVSE: 0,

      ToInstall: 0,
    },
    offline: {
      thirdParty: 0,
      disabled: 0,
      test: 0,
    },
  });
  const [loading, setLoading] = useState(false);
  const { freire } = useFreire();

  const getData = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await api.get(`/getAllStatus`);

      setData(data);
    } catch (error) {
      notification.error({
        message: "Ops...",
        description: freire(texts.ERROR_GET_INFO_CARD),
      });
    } finally {
      setLoading(false);
    }
  }, [freire]);

  useImperativeHandle(ref, () => ({
    getData,
  }));

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div className={styles.container}>
      <div className={styles.containerHeader}>
        <div className={styles.row}>
          <span className={styles.title}>{freire(texts.OPERATION)}:</span>
          <span className={styles.text}>
            <strong>
              {data.operation.connected} {freire(texts.CONNECTED)}
            </strong>{" "}
            ({data.operation.total} {freire(texts.TOTALS)})
          </span>

          <Button
            style={{
              marginLeft: 20,
              height: 21,
              fontSize: 12.5,
              padding: "0px 10px",
            }}
            disabled={loading}
            ghost
            type="primary"
            icon="sync"
            size="small"
            shape="round"
            onClick={getData}
          >
            {freire(texts.UPDATE)}
          </Button>
        </div>

        <div className={styles.row}>
          <span className={styles.title} style={{ color: "#bdbdbd" }}>
            {freire(texts.OFFLINE)}:
          </span>
          <Show when={"card-third-party-stations"}>
            <StatusWithDot
              status={"ThirdParty"}
              suffixText={`(${data.offline.thirdParty})`}
              textStyle={{ fontSize: 12, color: "#747277" }}
              hideTooltip
            />
          </Show>

          <StatusWithDot
            status={"Disabled"}
            suffixText={`(${data.offline.disabled})`}
            textStyle={{ fontSize: 12, color: "#747277" }}
            hideTooltip
          />
          <Show when={"card-test-stations"}>
            <StatusWithDot
              status={"Test"}
              suffixText={`(${data.offline.test})`}
              textStyle={{ fontSize: 12, color: "#747277" }}
              containerStyle={{ marginRight: 0 }}
              hideTooltip
            />
          </Show>
        </div>
      </div>

      <div className={styles.row} style={{ alignItems: "baseline" }}>
        <span className={styles.title}>{freire(texts.STATUS)}:</span>
        <div>
          <div className={styles.row}>
            <StatusWithDot
              status={"Available"}
              onPress={filter}
              suffixText={`(${data.status.Available})`}
            />
            <StatusWithDot
              status={"Charging"}
              onPress={filter}
              suffixText={`(${data.status.Charging})`}
            />
            <StatusWithDot
              status={"Reserved"}
              onPress={filter}
              suffixText={`(${data.status.Reserved})`}
            />
            <StatusWithDot
              status={"Preparing"}
              onPress={filter}
              suffixText={`(${data.status.Preparing})`}
            />
            <StatusWithDot
              status={"Finishing"}
              onPress={filter}
              suffixText={`(${data.status.Finishing})`}
            />
          </div>
          <div className={styles.row} style={{ marginTop: 15 }}>
            <StatusWithDot
              status={"SuspendedEV"}
              onPress={filter}
              suffixText={`(${data.status.SuspendedEV})`}
            />
            <StatusWithDot
              status={"SuspendedEVSE"}
              onPress={filter}
              suffixText={`(${data.status.SuspendedEVSE})`}
            />
            <StatusWithDot
              status={"Faulted"}
              onPress={filter}
              suffixText={`(${data.status.Faulted})`}
            />
            <StatusWithDot
              status={"Unavailable"}
              onPress={filter}
              suffixText={`(${data.status.Unavailable})`}
            />
            <StatusWithDot
              status={"Upkeep"}
              onPress={filter}
              suffixText={`(${data.status.Upkeep})`}
            />
            <StatusWithDot
              status={"ToInstall"}
              onPress={filter}
              suffixText={`(${data.status.ToInstall})`}
            />
          </div>
        </div>
      </div>

      {loading ? (
        <div className={styles.loading}>
          <Spin tip={freire(texts.LOOKING_INFO)} />
        </div>
      ) : null}
    </div>
  );
});

export default CardStatusStations;
