import { Tag } from "antd";

import { getBenefitDescription } from "../../../utils";
import { formatters } from "../../../../../utils/formatters";
import CampaignBalance from "../../../../../components/CampaignBalance";
import * as texts from "../../../locales";

const getColumns = (freire) => ({
  // Ambos
  amountOfCoupons: {
    title: freire(texts.CREATED_COUPONS),
    dataIndex: "amountOfCoupons",
    key: "amountOfCoupons",
    align: "center",
  },
  amountOfActiveCoupons: {
    title: freire(texts.ACTIVE_COUPONS),
    dataIndex: "amountOfActiveCoupons",
    key: "amountOfActiveCoupons",
    align: "center",
  },
  themeAlias: {
    title: freire(texts.THEME),
    dataIndex: "themeAlias",
    key: "themeAlias",
    align: "center",
    render: (themeAlias) => <span>{themeAlias || "---"}</span>,
  },

  // Simple
  benefit: {
    title: freire(texts.BENEFIT_TYPE),
    dataIndex: "benefit",
    key: "benefit",
    align: "center",
    render: (benefit, data) => {
      if (benefit) {
        const { tagColor, text } = getBenefitDescription(
          benefit,
          data.companyCurrency,
          freire
        );

        return (
          <Tag color={tagColor} style={{ marginRight: 0 }}>
            {text}
          </Tag>
        );
      }

      return "---";
    },
  },
  usageLimitPerCoupon: {
    title: freire(texts.QUESTION_LIMITE_USE_COUPON),
    dataIndex: "usageLimitPerCoupon",
    key: "usageLimitPerCoupon",
    align: "center",
    render: (usageLimitPerCoupon) => {
      if (usageLimitPerCoupon === -1) return freire(texts.NO_LIMIT);

      return usageLimitPerCoupon;
    },
  },

  // Credit
  balancePerCoupon: {
    title: freire(texts.VALUE_PER_COUPON),
    dataIndex: "balancePerCoupon",
    key: "balancePerCoupon",
    align: "center",
    render: (balancePerCoupon, resume) =>
      formatters.currency(
        freire.userLanguage,
        resume.companyCurrency
      )(balancePerCoupon / 100),
  },
  balance: {
    title: freire(texts.CAMPAIGN_BALANCE),
    dataIndex: "balance",
    key: "balance",
    align: "center",
    render: (_, resume) => (
      <CampaignBalance
        textLeft={freire(texts.TOTAL_CONSUMED_VALUE)}
        textRight={freire(texts.TOTAL_AMOUNT_REMAINING)}
        currency={resume.companyCurrency}
        total={resume.totalBalance}
        remaining={resume.remainingBalance}
      />
    ),
  },
});

const ResumeColumns = { getColumns };

export default ResumeColumns;
