import { useEffect, useState } from 'react';
import { Button, Col, Divider, Icon, Row } from "antd";
import api from "../../../services/api";
import * as texts from "../locales";
import { useFreire } from "../../../utils/freireContext";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { useHistory, useParams } from "react-router-dom";
import ProposalForm from '../proposalForm';
import { copyToClipboard, defaultEmptyProposal } from '../proposalHelper';

const ProposalEdit = (props) => {
  const { freire } = useFreire();
  const { proposalId } = useParams();
  const [proposal, setProposal] = useState(defaultEmptyProposal);
  const [error, setError] = useState(null);
  const [popupVisible, setPopupVisible] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.get(`/proposals/${proposalId}`);
      setProposal(response.data);
    };
    
    const shouldShowPopup = props?.location?.search.includes('showPopup')

    fetchData();
    setPopupVisible(shouldShowPopup)
  }, [proposalId, props?.location?.search]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.put(`/proposals/${proposalId}`, proposal);
      setPopupVisible(true);
    } catch (err) {
      console.error("Erro ao criar proposta:", err);
      setError('Erro ao criar proposta');
    }
  };

  const closePopup = () => {
    setPopupVisible(false);
  };

  const handleFormChange = (newProposalState) => {
    setProposal({ ...newProposalState });
  };

  return (
    <div className='container proposal-form'>
      <Breadcrumbs
        breadcrumbs={[
          freire(texts.ADMIN),
          freire(texts.PROPOSAL),
          freire(texts.EDIT),
        ]}
      />
      <div className='page-wrapper'>
        <Row>
          <Col span={6} offset={1}>
            <Icon type="profile" />
            <span className='title-wrapper'>
              Editar proposta
            </span>
          </Col>
          <Col span={12} offset={8}>
            <ProposalForm 
              submitText='Salvar Proposta'
              handleSubmit={handleSubmit} 
              onFormChange={handleFormChange}
              history={history}
              proposal={proposal} 
            />
          </Col>
        </Row>
      </div>
      
      {error && <p>{error}</p>}
      
      {popupVisible && (
        <div className="popup">
          <div className="popup-content">
            <h1>Link gerado</h1>
            <strong>O link para a sua proposta foi gerado com sucesso.</strong>
            <br></br>
            <br></br>
            O que fazer agora:
            <ol>
              <li>Copiar o link: Basta copiar o link abaixo.</li>
              <li>Enviar para o cliente: Cole o link no corpo do e-mail que você enviará ao cliente.</li>
            </ol>
            <br></br>
            <strong>Link da proposta</strong>
            <br></br>
            <input type="text" className='readonly-proposal-input' value={proposal.url} readOnly />
            <Button type='link' onClick={() => copyToClipboard(proposal.url)}>Copiar link</Button>
            <Divider />
            <div className='footer'>
              <Button type='secondary' onClick={closePopup}>Fechar</Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProposalEdit;
